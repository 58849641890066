const required_fields = [
   "first_name",
   "last_name",
   "telephone",
   "email_address",
   "email_verified",
   "welcome_pack_method",
   ["address", "line_1"],
   ["address", "city"],
   ["address", "postcode"],
   ["billing_address", "line_1"],
   ["billing_address", "city"],
   ["billing_address", "postcode"]
]

export default required_fields