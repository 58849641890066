import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, useSearchParams } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <div className="small-screen-block">
      <i className="error-icon"/>
      <h3>Your device screen is too small</h3>
      <p style={{marginTop: -10}}>Please reload the site on a larger device.</p>
    </div>
    <App />
  </BrowserRouter>
);