import React, { useEffect, useState } from "react";
import Error from "../../components/global/error";
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input";
import axios from 'axios'
import required_fields from "./resources/required_fields_personal";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../context/userAuthContext";

const PersonalDetails = ({
   saleData,
   updateSaleData,
   updateStage,
   addressList,
   setAddressList,
   showManualAddress,
   setShowManualAddress,
   showManualBillingAddress,
   setShowManualBillingAddress,
   updateScriptStage,
   updateForceScriptData,
   forceScriptData,
   setStreetName
}) => {
   const { user } = useUserAuth();

   // Promise tracker loader
   const VerifyEmailLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'email'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Verifying email address...</h3>
         </div>
      )
   }

   const AddressesLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: "postcode"});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Loading addresses in {saleData.personal_details.address.postcode}...</h3>
         </div>
      )
   }

   const BAAddressesLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: "ba_postcode"});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Loading addresses in {saleData.personal_details.billing_address.postcode}...</h3>
         </div>
      )
   }

   // Email verification check
   const [lastCheckedEmail, setLastCheckedEmail] = useState('')

   const [contactPhone, updateContactPhone] = useState(saleData.personal_details.telephone)
   
   const [errors, setErrors] = useState({
      first_name: false,
      last_name: false,
      email_address: false,
      email_verified: false,
      telephone: false,
      welcome_pack_method: false,
      line_1: false,
      city: false,
      postcode: false,
      address: false,
      ba_line_1: false,
      ba_city: false,
      ba_postcode: false,
      ba_address: false
   })

   const autoCompleted = saleData.personal_details.address.index !== '';
   const baAutoCompleted = saleData.personal_details.billing_address.index !== '';

   const goBack = () => {
      if(saleData.campaign.lead_appliance !== '' && saleData.chosen_package.appliances.max === 1) {
         updateStage(2)
      } else {
         updateStage(3)
      }
   }

   useEffect(() => {
      handleUpdateScriptStage()
   }, [])

   const handleVerifyEmailAddress = async (e) => {
      const val = e.target.value;

      if(val !== lastCheckedEmail && val !== '') {
         setLastCheckedEmail(val)
         
         await trackPromise(
            axios({
               method: 'get',
               url: "https://api.appliancesure.com/zero/gen/email/verification",
               params: {
                  email_address: val
               }
            })
            .then((val) => {
               const response = val.data.valid

               updateSaleData({
                  ...saleData,
                  personal_details: {
                     ...saleData.personal_details,
                     email_verified: response
                  }
               })

               setErrors({
                  ...errors,
                  email_verified: !response
               })
            })
            .catch((err) => {
               updateSaleData({
                  ...saleData,
                  personal_details: {
                     ...saleData.personal_details,
                     email_verified: false
                  }
               })

               setErrors({
                  ...errors,
                  email_verified: true
               })
            })
         , 'email')
      }
   }

   const handleUpdateScriptStage = () => {
      if(saleData.personal_details.pre_provisioned === true) {
         updateScriptStage('3.4')
      } else {
         updateScriptStage('3.1')
      }
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      let val = event.target.value;
      if(name === 'marketing_optin') val = event.target.checked;
      const type = event.target.dataset.type

      if(type === 'address') {
         updateSaleData({
            ...saleData,
            personal_details: {
               ...saleData.personal_details,
               address: {
                  ...saleData.personal_details.address,
                  [name]: val
               }
            }
         })
      } else if (name === 'email_address') {
         updateSaleData({
            ...saleData,
            personal_details: {
               ...saleData.personal_details,
               [name]: val,
               email_verified: false
            }
         })
      } else {
         updateSaleData({
            ...saleData,
            personal_details: {
               ...saleData.personal_details,
               [name]: val
            }
         })
      }

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleFillInBillingAddress = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateSaleData({
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            billing_address: {
               ...saleData.personal_details.billing_address,
               [name]: val
            }
         }
      })

      setErrors({
         ...errors,
         [`ba_${name}`]: false
      })
   }

   const handleFillInAddress = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      let update_object = {
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            address: {
               ...saleData.personal_details.address,
               [name]: val
            }
         }
      }

      if(saleData.personal_details.billing_address.same_as_address === true) {
         update_object.personal_details.billing_address = {
            ...saleData.personal_details.billing_address,
            [name]: val
         }
      }

      updateSaleData(update_object)

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleBillingPostcodeLookup = async (event) => {
      event.preventDefault()
      setErrors({
         ...errors,
         ba_postcode: false,
         ba_address: false
      })

      const postcode = saleData.personal_details.billing_address.postcode;

      if(postcode.length < 5) {
         setErrors({
            ...errors,
            ba_postcode: true
         })
      } else {
         trackPromise(
            new Promise( async (resolve, reject) => {
               const postcodeLookup = await axios({
                  method: 'get',
                  url: 'https://api.appliancesure.com/two/admin/address/postcodeLookup',
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  params: {
                     postcode: postcode
                  }
               })

               try {
                  const addresses = postcodeLookup.data

                  if(addresses !== 'Nothing found' && addresses.includes("Error") === false) {
                     setAddressList(addresses)
                     updateSaleData({
                        ...saleData,
                        personal_details: {
                           ...saleData.personal_details,
                           billing_address: {
                              ...saleData.personal_details.billing_address,
                              postcode: addresses[0].JSON.postcode
                           }
                        }
                     })
                    setStreetName(addresses[0].JSON.street_name)
                    updateScriptStage('3.2')
                    resolve(true) 
                  } else {
                     setAddressList([{
                        JSON: {
                           line_1: '',
                           line_2: '',
                           line_3: '',
                           postcode: '',
                           city: '',
                           street_name: '',
                           county: ''
                        },
                        one_liner_short: 'No address found',
                        one_liner: 'No address found',
                        line_broken: ''
                     }])
                     updateScriptStage('3.3')
                     setShowManualBillingAddress(true)
                     resolve(true)
                  }
               }

               catch (err) {
                  setAddressList([{
                     JSON: {
                        line_1: '',
                        line_2: '',
                        line_3: '',
                        postcode: '',
                        city: '',
                        street_name: '',
                        county: ''
                     },
                     one_liner_short: 'No address found',
                     one_liner: 'No address found',
                     line_broken: ''
                  }])
                  setShowManualBillingAddress(true)
                  updateScriptStage('3.3')
                  reject(err)
               }
            })
         , 'ba_postcode')
      }
   }

   const handlePostcodeLookup = async (event) => {
      event.preventDefault()
      setShowManualAddress(false)
      setErrors({
         ...errors,
         postcode: false,
         address: false
      })

      const postcode = saleData.personal_details.address.postcode;

      if(postcode.length < 5) {
         setErrors({
            ...errors,
            postcode: true
         })
      } else {
         trackPromise(
            new Promise( async (resolve, reject) => {
               const postcodeLookup = await axios({
                  method: 'get',
                  url: 'https://api.appliancesure.com/two/admin/address/postcodeLookup',
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  params: {
                     postcode: postcode
                  }
               })
      
               try {
                  const addresses = postcodeLookup.data;
      
                  if(addresses !== 'Nothing found' && addresses.includes("Error") === false) {
                     setAddressList(addresses)
                     updateSaleData({
                        ...saleData,
                        personal_details: {
                           ...saleData.personal_details,
                           address: {
                              ...saleData.personal_details.address,
                              postcode: addresses[0].JSON.postcode
                           }
                        }
                     })
                     updateScriptStage('3.2')
                     setStreetName(addresses[0].JSON.street_name)
                     resolve(true)
                  } else {
                     setAddressList([{
                        JSON: {
                           line_1: '',
                           line_2: '',
                           line_3: '',
                           postcode: '',
                           city: '',
                           street_name: '',
                           county: ''
                        },
                        one_liner_short: 'No address found',
                        one_liner: 'No address found',
                        line_broken: ''
                     }])
                     updateScriptStage('3.3')
                     setShowManualAddress(true)
                     resolve(true)
                  }
               }
      
               catch (err) {
                  setAddressList([{
                     JSON: {
                        line_1: '',
                        line_2: '',
                        line_3: '',
                        postcode: '',
                        city: '',
                        street_name: '',
                        county: ''
                     },
                     one_liner_short: 'No address found',
                     one_liner: 'No address found',
                     line_broken: ''
                  }])
                  setShowManualAddress(true)
                  updateScriptStage('3.3')
                  reject(err)
               }
            })
         , 'postcode')  
      }
   }

   useEffect(() => {
      updateSaleData({
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            telephone: contactPhone
         }
      })
      setErrors({
         ...errors,
         telephone: false
      })
   }, [contactPhone])

   const handleSwitchBillingAddress = (event) => {
      const value = event.target.checked;

      if(value === true) {
         updateSaleData({
            ...saleData,
            personal_details: {
               ...saleData.personal_details,
               billing_address: {
                  same_as_address: true,
                  ...saleData.personal_details.address
               }
            }
         })
      } else {
         updateSaleData({
            ...saleData,
            personal_details: {
               ...saleData.personal_details,
               billing_address: {
                  same_as_address: false,
                  line_1: '',
                  line_2: '',
                  city: '',
                  county: '',
                  postcode: '',
                  index: ''
               }
            }
         })

         setAddressList([])
         setShowManualBillingAddress(false)
      }
   }

   const handleChooseAddress = (i) => {
      const chosenAddress = addressList[i];

      let update_object = {
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            address: {
               ...chosenAddress.JSON,
               index: i
            }
         }
      }

      if(saleData.personal_details.billing_address.same_as_address === true) {
         update_object.personal_details.billing_address = {
            ...saleData.personal_details.billing_address,
            ...chosenAddress.JSON,
            index: i
         }
      }

      updateSaleData(update_object)

      setShowManualAddress(true)
      setErrors({
         ...errors,
         line_1: false,
         city: false,
         postcode: false,
         address: false
      })
   }

   const handleChooseBillingAddress = (i) => {
      const chosenAddress = addressList[i]

      updateSaleData({
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            billing_address: {
               ...saleData.personal_details.billing_address,
               ...chosenAddress.JSON,
               index: i
            }
         }
      })

      setShowManualBillingAddress(true)
      setErrors({
         ...errors,
         ba_line_1: false,
         ba_city: false,
         ba_postcode: false,
         ba_address: false
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0
      let errors_object = {}
      const dv = saleData.personal_details

      for (let i = 0; i < required_fields.length; i++) {
         const el = required_fields[i];
         
         // Address
         if(el.length === 2) {
            if(el[0] === 'address') {
               if(dv[el[0]][el[1]] === '') {
                  errors_count += 1
   
                  if(showManualAddress) {
                     errors_object[el[1]] = true
                  } else {
                     errors_object.address = true
                  }
               }
            } else if (el[0] === 'billing_address' && saleData.personal_details.billing_address.same_as_address === false) {
               if(dv[el[0]][el[1]] === '') {
                  errors_count += 1

                  if(showManualBillingAddress) {
                     errors_object[`ba_${el[1]}`] = true
                  } else {
                     errors_object.ba_address = true
                  }
               }
            }
         } else {
            // Other fields
            if(el === 'email_address') {
               if(dv.welcome_pack_method === 'email') {
                  if(dv[el].includes('@') === false || dv[el].includes('.') === false || dv[el].length < 6) {
                     errors_count += 1;
                     errors_object[el] = true
                  } 
               }
            } else if (el === 'email_verified') {
               if(dv[el] === false && dv[el] !== '' && dv.welcome_pack_method === 'email') {
                  errors_count += 1;
                  errors_object[el] = true
               }
            } else {
               if(dv[el] === '') {
                  errors_count += 1;
                  errors_object[el] = true
               }
            }
         }
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })
         return false
      }
   }

   const goToNextPage = () => {
      if(handleDataValidation() === true) {
         updateStage(5)
      }
   }

   const handleResetAddress = () => {
      setShowManualAddress(false)
      setAddressList([])
      updateSaleData({
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            address: {
               line_1: '',
               line_2: '',
               city: '',
               county: '',
               postcode: '',
               index: ''
            }
         }
      })
      updateScriptStage('3.1')
   }

   const handleResetBillingAddress = () => {
      setShowManualBillingAddress(false)
      setAddressList([])
      updateSaleData({
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            billing_address: {
               same_as_address: false,
               line_1: '',
               line_2: '',
               city: '',
               county: '',
               postcode: '',
               index: ''
            }
         }
      })
   }

   return (
      <div className="grid-container">
         <VerifyEmailLoader/>
         <AddressesLoader/>
         <BAAddressesLoader/>

         <button className="back-button" onClick={goBack}>Go back</button>
         <h2>Personal details</h2>

         <br/>

         <form onChange={handleFillInForm}>
            <div className="form-2col">
               <span>
                  <label htmlFor="first_name" className="form-label">First name</label>
                  <input
                     id="first_name"
                     name="first_name"
                     type={"text"}
                     className="form-input"
                     aria-autocomplete="none"
                     value={saleData.personal_details.first_name}
                     placeholder="Enter customer's first name"
                  />

                  {
                     errors.first_name ? (
                        <Error type="warning" message="Please enter the first name"/>
                     ) : null
                  }
               </span>

               <span>
                  <label htmlFor="last_name" className="form-label">Last name</label>
                  <input
                     id="last_name"
                     name="last_name"
                     type="text"
                     className="form-input"
                     value={saleData.personal_details.last_name}
                     placeholder="Enter customer's last name"
                  />

                  {
                     errors.last_name ? (
                        <Error type="warning" message="Please enter the last name"/>
                     ) : null
                  }
               </span>
            </div>
         </form>

         <label className="form-label">Contact phone</label>
         <PhoneInput
            placeholder="Enter phone no. of primary contact"
            value={contactPhone}
            onChange={updateContactPhone}
            defaultCountry="GB"
         />

         {
            errors.telephone ? (
               <Error type="warning" message="Please enter a valid phone number"/>
            ) : null
         }

         <form onChange={handleFillInForm}>
            <label htmlFor="email_address" className="form-label">Email address</label>
            <input
               id="email_address"
               name="email_address"
               type="email"
               className="form-input"
               value={saleData.personal_details.email_address}
               placeholder="Enter customer's email address"
               onBlur={(e) => { handleVerifyEmailAddress(e); updateForceScriptData(forceScriptData+1)}}
            />

            {
               errors.email_address ? (
                  <Error type="warning" message="Please enter a valid email address"/>
               ) : null
            }

            {
               errors.email_verified ? (
                  <Error type="warning" message="According to our system, this is not a valid email address"/>
               ) : null
            }

            <label htmlFor="welcome_pack_method" className="form-label">How would you like your welcome documentation sent?</label>
            <select 
               className="form-input"
               name="welcome_pack_method"
               id="welcome_pack_method"
               value={saleData.personal_details.welcome_pack_method}   
            >
               <option value="" selected disabled>Choose a communication method</option>
               <option value="post">Post</option>
               <option value="email">Email</option>
            </select>

            {
               errors.welcome_pack_method ? (
                  <Error type="warning" message="Please choose a method to send the customer's welcome pack"/>
               ) : null
            }

            <br/>
            <br/>

            <h3>Address lookup</h3>
         </form>
            
         <br/>

         {
            showManualAddress ? (
               <React.Fragment>
                  {
                     autoCompleted ? null : (
                        <h4>Manually enter your address</h4>
                     )
                  }
                  <div className={`manual-address-container ${autoCompleted ? 'auto-completed' : ''}`}>
                     {/* Address line 1 */}
                     <label className="form-label" htmlFor="line_1">Address line 1*</label>
                     <input
                        className="form-input"
                        id="line_1"
                        name="line_1"
                        data-category="address"
                        autoComplete="off"
                        disabled={autoCompleted}
                        value={saleData.personal_details.address.line_1}
                        onChange={handleFillInAddress}
                     />

                     {
                        errors.line_1 ? (
                           <Error message="Please enter the first line of the address"/>
                        ) : null
                     }

                     {/* Address line 2 */}
                     {
                        autoCompleted && saleData.personal_details.address.line_2 === '' ? null : (
                           <React.Fragment>
                              <label className="form-label" htmlFor="line_2">Address line 2</label>
                              <input
                                 className="form-input"
                                 id="line_2"
                                 name="line_2"
                                 data-category="address"
                                 autoComplete="off"
                                 disabled={autoCompleted}
                                 value={saleData.personal_details.address.line_2}
                                 onChange={handleFillInAddress}
                              />
                           </React.Fragment>
                        )
                     }
                     
                     {/* Address line 3 */}
                     {
                        autoCompleted && saleData.personal_details.address.line_3 === '' ? null : (
                           <React.Fragment>
                              <label className="form-label" htmlFor="line_3">Address line 3</label>
                              <input
                                 className="form-input"
                                 id="line_3"
                                 name="line_3"
                                 data-category="address"
                                 autoComplete="off"
                                 disabled={autoCompleted}
                                 value={saleData.personal_details.address.line_3}
                                 onChange={handleFillInAddress}
                              />
                           </React.Fragment>
                        )
                     }

                     {/* Address city */}
                     <label className="form-label" htmlFor="city">City*</label>
                     <input
                        className="form-input"
                        id="city"
                        name="city"
                        data-category="address"
                        autoComplete="off"
                        disabled={autoCompleted}
                        value={saleData.personal_details.address.city}
                        onChange={handleFillInAddress}
                     />

                     {
                        errors.city ? (
                           <Error message="Please enter the town or city of the address"/>
                        ) : null
                     }

                     {/* Address County */}
                     <label className="form-label" htmlFor="county">County</label>
                     <input
                        className="form-input"
                        id="county"
                        name="county"
                        data-category="address"
                        autoComplete="off"
                        disabled={autoCompleted}
                        value={saleData.personal_details.address.county}
                        onChange={handleFillInAddress}
                     />

                     {/* Address Postcode */}
                     <label className="form-label" htmlFor="postcode">Postcode</label>
                     <input
                        className="form-input"
                        id="postcode"
                        name="postcode"
                        data-category="address"
                        autoComplete="off"
                        disabled
                        value={saleData.personal_details.address.postcode}
                     />
                  </div>

                  <button 
                     className="plain-text-button"
                     style={{marginTop: 10}}
                     onClick={handleResetAddress}
                  >Search address again</button>

                  <br/>
                  <br/>

                  <input 
                     type="checkbox"
                     id="same_as_address"
                     name="same_as_address"
                     checked={saleData.personal_details.billing_address.same_as_address}
                     onChange={handleSwitchBillingAddress}
                  />               

                  <label htmlFor="same_as_address">Billing address same as service address?</label>

                  <br/>
                  <br/>

                  {
                     saleData.personal_details.billing_address.same_as_address === false ? (
                        <React.Fragment>
                           <h3>Billing address</h3>

                           <br/>
                           {
                              showManualBillingAddress ? (
                                 <React.Fragment>
                                    {
                                       baAutoCompleted ? null : (
                                          <h4>Manually enter your billing address</h4>
                                       )
                                    }
                                    <div className={`manual-address-container ${baAutoCompleted ? 'auto-completed' : ''}`}>
                                       {/* Address line 1 */}
                                       <label className="form-label" htmlFor="line_1">Address line 1*</label>
                                       <input
                                          className="form-input"
                                          id="line_1"
                                          name="line_1"
                                          data-category="billing_address"
                                          autoComplete="off"
                                          disabled={baAutoCompleted}
                                          value={saleData.personal_details.billing_address.line_1}
                                          onChange={handleFillInBillingAddress}
                                       />

                                       {
                                          errors.ba_line_1 ? (
                                             <Error message="Please enter the first line of the billing address"/>
                                          ) : null
                                       }

                                       {/* Address line 2 */}
                                       {
                                          baAutoCompleted && saleData.personal_details.billing_address.line_2 === '' ? null : (
                                             <React.Fragment>
                                                <label className="form-label" htmlFor="line_2">Address line 2</label>
                                                <input
                                                   className="form-input"
                                                   id="line_2"
                                                   name="line_2"
                                                   data-category="billing_address"
                                                   autoComplete="off"
                                                   disabled={baAutoCompleted}
                                                   value={saleData.personal_details.billing_address.line_2}
                                                   onChange={handleFillInBillingAddress}
                                                />
                                             </React.Fragment>
                                          )
                                       }
                                       
                                       {/* Address line 3 */}
                                       {
                                          baAutoCompleted && saleData.personal_details.billing_address.line_3 === '' ? null : (
                                             <React.Fragment>
                                                <label className="form-label" htmlFor="line_3">Address line 3</label>
                                                <input
                                                   className="form-input"
                                                   id="line_3"
                                                   name="line_3"
                                                   data-category="billing_address"
                                                   autoComplete="off"
                                                   disabled={baAutoCompleted}
                                                   value={saleData.personal_details.billing_address.line_3}
                                                   onChange={handleFillInBillingAddress}
                                                />
                                             </React.Fragment>
                                          )
                                       }

                                       {/* Address city */}
                                       <label className="form-label" htmlFor="city">City*</label>
                                       <input
                                          className="form-input"
                                          id="city"
                                          name="city"
                                          data-category="billing_address"
                                          autoComplete="off"
                                          disabled={baAutoCompleted}
                                          value={saleData.personal_details.billing_address.city}
                                          onChange={handleFillInBillingAddress}
                                       />

                                       {
                                          errors.ba_city ? (
                                             <Error message="Please enter the town or city of the billing address"/>
                                          ) : null
                                       }

                                       {/* Address County */}
                                       <label className="form-label" htmlFor="county">County</label>
                                       <input
                                          className="form-input"
                                          id="county"
                                          name="county"
                                          data-category="billing_address"
                                          autoComplete="off"
                                          disabled={baAutoCompleted}
                                          value={saleData.personal_details.billing_address.county}
                                          onChange={handleFillInBillingAddress}
                                       />

                                       {/* Address Postcode */}
                                       <label className="form-label" htmlFor="postcode">Postcode</label>
                                       <input
                                          className="form-input"
                                          id="postcode"
                                          name="postcode"
                                          data-category="billing_address"
                                          autoComplete="off"
                                          disabled
                                          value={saleData.personal_details.billing_address.postcode}
                                       />
                                    </div>

                                    <button 
                                       className="plain-text-button"
                                       style={{marginTop: 10}}
                                       onClick={handleResetBillingAddress}
                                    >Search billing address again</button>
                                 </React.Fragment>
                              ) : (
                                 <React.Fragment>
                                    <div className="form-2col">
                                       <span>
                                          <label htmlFor="ba_postcode" className="form-label">Postcode</label>
                                          <input
                                             id="postcode"
                                             name="postcode"
                                             type="text"
                                             data-type="billing_address"
                                             className="form-input"
                                             value={saleData.personal_details.billing_address.postcode}
                                             placeholder="e.g. SW1A 1AA"
                                             style={{textTransform: 'uppercase'}}
                                             onChange={handleFillInBillingAddress}
                                          />
                                       </span>

                                       <button 
                                          className="welcome-button green"
                                          style={{width: 'fit-content', marginTop: 10}}
                                          onClick={handleBillingPostcodeLookup}
                                       >Look up</button>
                                    </div>

                                    {
                                       errors.ba_postcode ? (
                                          <React.Fragment>
                                             <Error type="warning" message="Please enter a valid UK postcode"/>
                                          </React.Fragment>
                                       ) : null
                                    }

                                    {
                                       addressList.length > 0 ? (
                                          <React.Fragment>
                                             <div className="partner-access-scrollarea address">
                                                {addressList.map((address, i) => (
                                                   <button 
                                                      className={`partner-access-row address ${saleData.personal_details.billing_address.index === i ? 'selected' : ''}`}
                                                      onClick={() => handleChooseBillingAddress(i)}
                                                   >
                                                      {address.one_liner}
                                                   </button>
                                                ))}
                                             </div>

                                             <button 
                                                className="plain-text-button"
                                                onClick={() => setShowManualBillingAddress(true)}
                                             >Address not found?</button>
                                             <br/>
                                          </React.Fragment>
                                       ) : null
                                    }

                                    {
                                       errors.ba_postcode === false && errors.ba_address ? (
                                          <React.Fragment>
                                             <br/>
                                             <br/>
                                             <Error type="warning" message="Please select an address"/>
                                          </React.Fragment>
                                          
                                       ) : null
                                    }
                                 </React.Fragment>
                              )
                           }

                           
                        </React.Fragment>
                     ) : null
                  }

                  
                  <br/>
               </React.Fragment>
            ) : (
               <React.Fragment>
                  <div className="form-2col">
                     <span>
                        <label htmlFor="postcode" className="form-label">Postcode</label>
                        <input
                           id="postcode"
                           name="postcode"
                           type="text"
                           data-type="address"
                           className="form-input"
                           value={saleData.personal_details.address.postcode}
                           placeholder="e.g. SW1A 1AA"
                           style={{textTransform: 'uppercase'}}
                           onChange={handleFillInForm}
                        />
                     </span>

                     <button 
                        className="welcome-button green"
                        style={{width: 'fit-content', marginTop: 10}}
                        onClick={handlePostcodeLookup}
                     >Look up</button>
                  </div>

                  {
                     errors.postcode ? (
                        <React.Fragment>
                           <Error type="warning" message="Please enter a valid UK postcode"/>
                        </React.Fragment>
                     ) : null
                  }

                  {
                     addressList.length > 0 ? (
                        <React.Fragment>
                           <div className="partner-access-scrollarea address">
                              {addressList.map((address, i) => (
                                 <button 
                                    className={`partner-access-row address ${saleData.personal_details.address.index === i ? 'selected' : ''}`}
                                    onClick={() => handleChooseAddress(i)}
                                 >
                                    {address.one_liner}
                                 </button>
                              ))}
                           </div>

                           <button 
                              className="plain-text-button"
                              onClick={() => setShowManualAddress(true)}
                           >Address not found?</button>
                           <br/>
                        </React.Fragment>
                     ) : null
                  }

                  {
                     errors.postcode === false && errors.address ? (
                        <React.Fragment>
                           <br/>
                           <br/>
                           <Error type="warning" message="Please select an address"/>
                        </React.Fragment>
                        
                     ) : null
                  }
               </React.Fragment>
            )
         }
            

         <br/>

         <button
            className="welcome-button secondary"
            onClick={goToNextPage}
         >Continue</button>
      </div>
   )
}

export default PersonalDetails