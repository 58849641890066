import axios from "axios";
import React, { useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import bank_names from "../resources/interbacs_bank_names";

const DirectDebit = ({
   upgradeData,
   updateUpgradeData,
   updateStage,
   updateScriptStage
}) => {
   const { user } = useUserAuth();

   const getBankName = async () => {
      axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/upgrades/getBankName',
         headers: {
            Authorization: "Bearer " + user
         },
         params: {
            sort_code: upgradeData.bank_details.sort_code,
            account_number: upgradeData.bank_details.account_number
         }
      })
      .then((value) => {
         updateUpgradeData({
            ...upgradeData,
            bank_details: {
               ...upgradeData.bank_details,
               bank_name: value.data
            }
         })
      })
      .catch(() => {
         // Do nothing
      })
   }

   useEffect(() => {
      if(upgradeData.bank_details.bank_name === '') {
         getBankName()
      }
   }, [])

   const handleAgreeChange = () => {
      updateUpgradeData({
         ...upgradeData,
         dd_amendment_agreement: !upgradeData.dd_amendment_agreement
      })
   }

   const goToNextPage = () => {
      if(upgradeData.current_package.chosen_frequency === "Annually") {
         updateStage(7)
      } else {

      }
   }

   // Bank account name
   let bank_name = 'Current account'
   if(typeof upgradeData.bank_details.bank_name === 'string' && upgradeData.bank_details.bank_name !== '') {
      bank_name = bank_names[upgradeData.bank_details.bank_name].name
   }

   // Sort code formatted
   const sort_code = upgradeData.bank_details.sort_code
   const sort_code_formatted = sort_code.substring(0, 2) + "-" + sort_code.substring(2, 4) + "-" + sort_code.substring(4, 6)
   
   return (
      <div className="grid-container">
         <button className="back-button" onClick={() => updateStage(5)}>Go back</button>
         <h2>Direct debit amendment</h2>

         <br/>

         <h3>Your details</h3>

         <br/>

         <div className="form-2col">
            <span>
               <label className="form-label">Account</label>
               <input 
                  className="form-input read-only"
                  value={bank_name}
                  disabled
               />
            </span>

            {
               bank_name !== 'Current account' ? (
                  <img
                     className="direct-debit-bank"
                     src={`/images/banks/${bank_names[upgradeData.bank_details.bank_name].url}-logo.jpg`}
                  />
               ) : null
            }
         </div>

         <label className="form-label">Account name</label>
         <input
            className="form-input read-only"
            value={upgradeData.bank_details.account_name}
            disabled
         />

         <div className="form-2col">
            <span>
               <label className="form-label">Sort code</label>
               <input
                  className="form-input read-only"
                  value={sort_code_formatted}
                  disabled
               />
            </span>

            <span>
               <label className="form-label">Account number</label>
               <input
                  className="form-input read-only"
                  value={upgradeData.bank_details.account_number}
                  disabled
               />
            </span>
         </div>

         <br/>

         <h3>Change of instruction</h3>

         <p>In order to process your upgrade of plan, we will need to amend your direct debit instruction. Below are the details:</p>

         <table className="tariff-information-table">
            <tbody>
               <tr>
                  <td>Payment frequency</td>
                  <td>{upgradeData.current_package.chosen_frequency}</td>
               </tr>
               <tr>
                  <td>Sign up date</td>
                  <td>{new Date(upgradeData.current_package.sign_up_date).toLocaleDateString("en-GB")}</td>
               </tr>
               <tr>
                  <td>Contract remaining</td>
                  <td>{upgradeData.current_package.contract_remaining}</td>
               </tr>
               <tr>
                  <td>Current direct debit</td>
                  <td>£{parseFloat(upgradeData.current_package.chosen_cost).toFixed(2)}</td>
               </tr>
               <tr>
                  <td>New direct debit</td>
                  <td>£{parseFloat(upgradeData.chosen_package.chosen_cost).toFixed(2)}</td>
               </tr>
               <tr>
                  <td>Effective date</td>
                  <td>{new Date(upgradeData.current_package.next_payment_date).toLocaleDateString("en-GB")}</td>
               </tr>
            </tbody>
         </table>

         <br/>

         <div className='checkbox-form-container'>
            <input
               type="checkbox"
               className='checkbox-trigger invisible'
               name="terms_service"
               id="terms_service"
               checked={upgradeData.dd_amendment_agreement}
               onChange={handleAgreeChange}
            />
            <label htmlFor='terms_service' className="checkbox"/>

            <span style={{textAlign: 'left'}}>
               <label htmlFor='terms_service' className='form-label'>I agree to change my Direct Debit instruction</label>
               <span>In order to proceed, it is mandatory to gain a clear yes from the customer that they wish to proceed.</span>
            </span>
         </div>

         {
            upgradeData.dd_amendment_agreement === true ? (
               <React.Fragment>
                  <br/>
                  <br/>
                  <button
                     className="welcome-button secondary"
                     onClick={goToNextPage}
                  >
                     {
                        upgradeData.current_package.chosen_frequency === 'Annually' ? "Continue" : "Submit upgrade"
                     }
                  </button>
               </React.Fragment>
            ) : null
         }
      </div>
   )
}

export default DirectDebit