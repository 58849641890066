import React from "react";

const SavedSuccessfully = ({ content, reset }) => {
   return (
      <div className="saved-successfully-container">
         <p>{content}</p>
         <button 
            className="saved-x-close"
            onClick={() => reset('')}
         />
      </div>
   )
}

export default SavedSuccessfully