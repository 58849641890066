import React, { useEffect, useState } from "react";
import { MdLock as Lock } from 'react-icons/md'
import Error from "../../components/global/error";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import jwt from 'jsonwebtoken'
import { RotatingLines } from "react-loader-spinner";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import { detect } from "detect-browser";

const PasswordReset = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const [accessToken, setAccessToken] = useState("")
   const [newSetupError, setNewSetupError] = useState("")
   const [errors, setErrors] = useState({
      new_password: false,
      new_password_confirm: false
   })

   const [authenticated, setAuthenticated] = useState('pending')

   const [createCredentials, setCreateCredentials] = useState({
      email_address: '',
      first_name: '',
      new_password: '',
      new_password_confirm: '',
   })

   const navigate = useNavigate()
   const browser = detect();

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Changing password...</h3>
         </div>
      )
   }

   const validateToken = async (access_token) => {
      await axios({
         method: 'post',
         url: 'https://api.appliancesure.com/two/admin/portal/account/verifyPasswordResetToken',
         headers: {
            Authorization: "Bearer " + access_token
         }
      })
      .then((value) => {
         const response = value.data;

         if(response.validated === true) {
            const parsed_details = jwt.decode(access_token)

            setAuthenticated('authenticated')
            setAccessToken(access_token)
            setCreateCredentials({
               ...createCredentials,
               first_name: parsed_details.first_name,
               email_address: parsed_details.email_address
            })
         } else {
            setAuthenticated('already_completed')
         }
      })
      .catch(() => {
         setAuthenticated('expired')
      })
   }

   useEffect( async () => {
      const get_params = searchParams.get("at")
      
      if(get_params === undefined || get_params === null) {
         setAuthenticated('unauthenticated')
      } else {
         await validateToken(get_params)
      }
   }, [])

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      setCreateCredentials({
         ...createCredentials,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const [createPasswordMeetsGuidelines, setCreatePasswordMeetsGuidelines] = useState(false)
   const [passwordGuidelines, setPasswordGuidelines] = useState({
      uppercase: false,
      lowercase: false,
      minimum: false,
      special: false
   })

   const handleGuidelinesCheck = () => {
      setErrors({
         ...errors,
         new_password: false
      })

      // Contains one uppercase letter (A-Z)
      const uppercase_regex = new RegExp(/^(?=.*?[A-Z])/)
      const uppercase_match = uppercase_regex.test(createCredentials.new_password)

      // Contains one lowercase letter (A-Z)
      const lowercase_regex = new RegExp(/^(?=.*?[a-z])/)
      const lowercase_match = lowercase_regex.test(createCredentials.new_password)

      // At least 6 characters
      const length_match = createCredentials.new_password.length >= 6

      // Contains one special character
      const special_regex = new RegExp(/^(?=.*?[#?!@$%^&*-])/)
      const special_match = special_regex.test(createCredentials.new_password)


      setPasswordGuidelines({
         uppercase: uppercase_match,
         lowercase: lowercase_match,
         minimum: length_match,
         special: special_match
      })
      
      if(uppercase_match && lowercase_match && length_match && special_match) {
         setCreatePasswordMeetsGuidelines(true)
      } else {
         setCreatePasswordMeetsGuidelines(false)
         setCreateCredentials({
            ...createCredentials,
            new_password_confirm: ''
         })
      }
   }

   // Check password guidelines
   useEffect(() => {
      if(createCredentials.email_address !== '') {
         handleGuidelinesCheck()
      }
   }, [createCredentials.new_password])

   const handleDataValidation = () => {
      let error_count = 0;
      let error_object = {}

      if(createPasswordMeetsGuidelines === false) {
         error_count++
         error_object.new_password = true
      } else {
         if(createCredentials.new_password !== createCredentials.new_password_confirm) {
            error_count++
            error_object.new_password_confirm = true
         }
      }

      if(error_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...error_object
         })
         return false
      }
   }

   const handleSubmit = async (event) => {
      event.preventDefault();

      if(handleDataValidation() === true) {
         const userDetails = jwt.decode(accessToken)

         trackPromise(
            new Promise( async (resolve) => {
               try {
                  // Get IP & device details
                  let ip_address = 'Unknown'

                  try {
                     const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                     ip_address = ip_lookup.data.IPv4
                  }
               
                  catch (err) {
                     console.log(err)
                  }

                  await axios({
                     method: 'put',
                     url: 'https://api.appliancesure.com/two/admin/portal/account/resetPassword',
                     headers: {
                        Authorization: "Bearer " + accessToken
                     },
                     data: {
                        password: createCredentials.new_password,
                        log_details: {
                           ip_address: ip_address,
                           action_by: {
                              name: userDetails.first_name + " " + userDetails.last_name,
                              partner: userDetails.partner,
                              email_address: userDetails.email_address
                           },
                           browser: browser.name,
                           os: browser.os
                        }
                     }
                  })
                  .then((value) => {
                     const response = value.data;

                     setTimeout(() => {
                        if(response.success === true) {
                           alert("Your password was reset successfully, you will now be redirected.")
                           navigate("/")
                           window.location.reload();
                           resolve("Yes")
                        } else {
                           // There was an error
                           alert(response.reason)
                           resolve("Yes")
                        }
                     }, 1500)
                  })
                  .catch(() => {
                     alert("There was an error")
                     resolve("Yes")
                  })
               }

               catch (err) {
                  setNewSetupError(err)
                  resolve("Yes")
               }
            })
         , 'submit')
      }
   }
   
   switch(authenticated) {
      case 'pending':
         return (
            <div className="promise-tracker-container">
               <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
               <h3>Loading...</h3>
            </div>
         )
      
      case 'expired':
         return (
            <div className="promise-tracker-container">
               <i className="forbidden-icon"/>
               <h3>This link has expired</h3>
               <p style={{marginTop: -10}}>Please request another link.</p>
            </div>
         )

      case 'unauthenticated':
         return (
            <div className="promise-tracker-container">
               <i className="forbidden-icon"/>
               <h3>You do not have access to this page</h3>
               <p style={{marginTop: -10}}>Please request a reset password link.</p>
            </div>
         )

      case 'authenticated':
         return (
            <div className="login-screen-container show">
               <SubmitLoading/>
               <img src="/images/appliance-sure-logo.png" alt="Appliance Sure logo" className='secondary-welcome-logo'/>

               <div className='login-content-container'>
                  <h2>Reset your password</h2>

                  <form onChange={handleFillInForm} autoComplete="off">
                     <label htmlFor='password' className='form-label'>New password</label>

                     <input
                        type="password"
                        autoComplete='new-password'
                        className="form-input icon login"
                        placeholder='Enter your password'
                        value={createCredentials.new_password}
                        name="new_password"
                     />

                     <div className="form-icon login" style={{marginBottom: -35}}>
                        <Lock/>
                     </div>

                     {
                        errors.new_password ? (
                           <React.Fragment>
                              <div style={{marginTop: -50}}/>
                              <Error
                                 message="Password does not meet below guidelines"
                              />
                           </React.Fragment>
                        ) : null
                     }

                     {
                        createPasswordMeetsGuidelines ? (
                           <React.Fragment>
                              <label htmlFor='new_password_confirm' className='form-label'>Confirm password</label>

                              <input
                                 type="password"
                                 autoComplete="offf"
                                 className="form-input icon login"
                                 placeholder='Enter your password again'
                                 value={createCredentials.new_password_confirm}
                                 name="new_password_confirm"
                              />

                              <div className="form-icon login" style={{marginBottom: -35}}>
                                 <Lock/>
                              </div>

                              {
                                 errors.new_password_confirm ? (
                                    <React.Fragment>
                                       <div style={{marginTop: -50}}/>
                                       <Error
                                          message="Your passwords do not match"
                                       />
                                    </React.Fragment>
                                 ) : null
                              }
                           </React.Fragment>
                        ) : (
                           <React.Fragment>
                              <label className="form-label">Password guidelines</label>

                              <ul className='password-guidelines'>
                                 <li className={passwordGuidelines.uppercase ? 'met' : 'not-met'}>Contains one uppercase letter (A-Z)</li>
                                 <li className={passwordGuidelines.lowercase ? 'met' : 'not-met'}>Contains one lowercase letter (a-z)</li>
                                 <li className={passwordGuidelines.minimum ? 'met' : 'not-met'}>Minimum of 6 characters</li>
                                 <li className={passwordGuidelines.special ? 'met' : 'not-met'}>Contains one special character</li>
                              </ul>
                           </React.Fragment>
                        )
                     }

                     <br/>

                     <button 
                        type="submit" 
                        className='form-button'
                        onClick={handleSubmit}
                     >Set password</button>

                     { newSetupError && (
                        <React.Fragment>
                           <div style={{marginTop: 30}}/>
                           <Error
                              message={newSetupError}
                           />
                           <div style={{marginBottom: -40}}/>
                        </React.Fragment>
                     )}
                  </form>
               </div>
            </div>
         )

      default:
         return null
   }
}

export default PasswordReset