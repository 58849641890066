import axios from "axios";
import React, { useState } from "react";
import Error from "../../components/global/error";
import { useUserAuth } from "../../context/userAuthContext";
import required_fields from "./resources/required_fields_ip";

const CreateIPAddress = ({
   partnerData,
   setShowScreen
}) => {
   const { userDetails } = useUserAuth();

   const [newIpData, setNewIpData] = useState({
      ip_address: '',
      ip_type: 'IPv4',
      company: '',
      description: '',
      added: {
         by: userDetails.email_address
      }
   })

   const [errors, setErrors] = useState({
      ip_address: false,
      ip_type: false,
      company: false,
      description: false,
      already_exists: false,
      error: false
   })

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      setNewIpData({
         ...newIpData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false,
         already_exists: false,
         error: false
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {}

      for (let i = 0; i < required_fields.length; i++) {
         const field = required_fields[i];
         
         if(newIpData[field] === '') {
            errors_count++
            errors_object[field] = true
         }
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })
         return false
      }
   }

   const submitIP = async (event) => {
      event.preventDefault()

      if(handleDataValidation() === true) {
         await axios({
            method: 'post',
            url: 'https://api.appliancesure.com/two/admin/portal/access/createIP',
            data: newIpData
         })
         .then((value) => {
            const response = value.data

            if(response.success === true) {
               setShowScreen('manage_ip_whitelist')
            } else {
               if(response.reason.includes("already exists")) {
                  setErrors({
                     ...errors,
                     already_exists: true
                  })
               } else {
                  setErrors({
                     ...errors,
                     error: true
                  })
               }
            }
         })
         .catch(() => {
            setErrors({
               ...errors,
               error: true
            })
         })
      }
   }

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('manage_ip_whitelist')}>Back to IP whitelist</button>
         <h2>Add a new IP address</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>New IP details <span role="img" aria-label="jsx-a11y/accessible-emoji">✏️</span></h2>

               <br/>

               <form onChange={handleFillInForm}>
                  <div className="form-2col">
                     <span>
                        <label htmlFor="ip_address" className="form-label">IP Address</label>
                        <input
                           id="ip_address"
                           name="ip_address"
                           type="text"
                           className="form-input"
                           placeholder="Enter the IP address"
                        />

                        {
                           errors.ip_address ? (
                              <Error type="warning" message="Please enter a valid IP address"/>
                           ) : null
                        }
                     </span>
                     <span>
                        <label htmlFor="ip_type" className="form-label">IP version</label>
                        <select
                           name="ip_type"
                           id="ip_type"
                           className="form-input"
                        >
                           <option value="IPv4">IPv4</option>
                        </select>

                        {
                           errors.ip_type ? (
                              <Error type="warning" message="Please choose an IP version"/>
                           ) : null
                        }
                     </span>
                  </div>

                  <label htmlFor="company" className="form-label">Associated company</label>
                  <select
                     name="company"
                     id="company"
                     className="form-input"
                  >
                     <option value="" selected disabled>Select a company</option>
                     {partnerData.map(partner => (
                        <option value={partner.partner_name}>{partner.partner_name}</option>
                     ))}
                  </select>

                  {
                     errors.company ? (
                        <Error type="warning" message="Please choose who the IP belongs to"/>
                     ) : null
                  }

                  <label htmlFor="description" className="form-label">Description</label>
                  <input
                     id="description"
                     name="description"
                     type="text"
                     className="form-input"
                     placeholder="Please write a small description"
                  />

                  {
                     errors.description ? (
                        <Error type="warning" message="Please write a small description"/>
                     ) : null
                  }

                  {
                     errors.already_exists ? (
                        <p style={{marginTop: -10}} className="info-statement">This IP already exists in our whitelist. Be sure to check active & inactive.</p>
                     ) : null
                  }

                  {
                     errors.error ? (
                        <Error type="warning" message="There was an error, please try again later"/>
                     ) : null
                  }

                  <button
                     className="welcome-button secondary"
                     onClick={submitIP}
                  >Add IP address</button>
               </form>
            </div>
         </div>
      </div>
   )
}

export default CreateIPAddress