import React, { useEffect, useState } from "react";
import axios from "axios";

const ManageTimetables = ({
   setShowScreen
}) => {
   // States
   const [timetables, setTimetables] = useState([])
   const [selectTimetable, setSelectTimetable] = useState('')
   const [permissionMode, setPermissionMode] = useState('view only')

   const [editTimetable, updateEditTimetable] = useState({})

   const [hours, setHours] = useState([])
   const [minutes, setMinutes] = useState([])

   const mode_emojis = {
      'view only': '👁',
      'edit': '✏️'
   }

   const getAccessTimetables = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/access/getAccessTimetables'
      })
      .then((value) => {
         const response = value.data.records

         setTimetables(response)
      })
      .catch(() => {

      })
   }

   useEffect(() => {
      getAccessTimetables()

      // Give time select options
      //--- Hours
      let hour_array = []
      for (let i = 0; i < 24; i++) {
         const number = i.toString()
         let hour_string = number
         if(number.length === 1) {
            hour_string = "0" + number
         }
         hour_array.push(hour_string)
      }

      setHours(hour_array)

      //--- Minutes
      let min_array = []
      for (let i = 0; i < 60; i++) {
         const number = i.toString()
         let min_string = number
         if(number.length === 1) {
            min_string = "0" + number
         }
         min_array.push(min_string)
      }

      setMinutes(min_array)
   }, [])

   const setEditTimetable = () => {
      if(selectTimetable !== '') {
         const timetable_details = timetables[selectTimetable]
         // 

         updateEditTimetable({
            ...timetable_details,
            hours: {
               from: {
                  hour: timetable_details.hours.from.split(":")[0],
                  minute: timetable_details.hours.from.split(":")[1]
               },
               to: {
                  hour: timetable_details.hours.to.split(":")[0],
                  minute: timetable_details.hours.to.split(":")[1]
               }
            }
         })
      }
   }

   useEffect(() => {
      setPermissionMode('view only')
      setEditTimetable()
   }, [selectTimetable])

   const handleChangeAccessType = (event) => {
      const val = event.target.value;

      if(val === 'open') {
         updateEditTimetable({
            ...editTimetable,
            access_type: val,
            hours: {
               from: {
                  hour: '00',
                  minute: '00',
               },
               to: {
                  hour: '23',
                  minute: '59'
               }
            },
            weekends: true
         })
      } else if (val === 'restricted') {
         updateEditTimetable({
            ...editTimetable,
            access_type: val
         })
      }
   }

   const handleChangeAccessHours = (event) => {
      const name = event.target.name;
      const val = event.target.value;
      const type = event.target.dataset.type;

      updateEditTimetable({
         ...editTimetable,
         hours: {
            ...editTimetable.hours,
            [name]: {
               ...editTimetable.hours[name],
               [type]: val
            }
         }
      })
   }

   const handleDiscardChanges = () => {
      setPermissionMode('view only')
      setEditTimetable()
   }

   const handleSubmitChanges = async () => {
      await axios({
         method: 'put',
         url: 'https://api.appliancesure.com/two/admin/portal/access/editAccessTimetable',
         data: editTimetable
      })
      .then(async (value) => {
         const response = value.data.success;

         if(response === true) {
            await getAccessTimetables()
            setPermissionMode('view only')
         }
      })
      .catch((err) => {
         console.log(err)
         alert("There was an error, please try again")
      })
   }

   const createTimetableList = () => {
      let jsx = []

      for (let i = 0; i < timetables.length; i++) {
         const record = timetables[i];

         jsx.push(
            <li className={selectTimetable === i ? 'selected' : ''} onClick={() => setSelectTimetable(i)}>{record.user_type}</li>
         )
      }

      return jsx
   }

   const handleResetTimetableSelection = () => {
      setSelectTimetable('')
      updateEditTimetable({})
   }

   const showSelectedTimetable = () => {
      let jsx = []

      if(selectTimetable !== '') {
         const timetable_details = timetables[selectTimetable]

         jsx.push(
            <div className="grid-container">
               <button className="back-button" onClick={handleResetTimetableSelection}>Close</button>
               <br/>
               <br/>
               <h2>{timetable_details.user_type}</h2>
               <p style={{marginTop: 5}}>Access timetable</p>
               <p className={`permission-mode ${permissionMode === 'edit' ? 'edit' : 'view'}`}>You are currently in {permissionMode} mode {mode_emojis[permissionMode]}           
               {
                  permissionMode === 'view only' ? (
                     <button className="edit-mode-button" onClick={() => setPermissionMode('edit')}>Edit timetable</button>
                  ) : null
               }
               </p>

               <label className="form-label" htmlFor="access_type">Access type</label>
               <label className="description">Restricted allows you to limit the times when users can access the portal</label>
               
               <select
                  className="form-input"
                  name="access_type"
                  id="access_type"
                  value={editTimetable.access_type}
                  disabled={permissionMode === 'view only'}
                  onChange={handleChangeAccessType}
               >
                  <option value="open">Open</option>
                  <option value="restricted">Restricted</option>
               </select>

               {
                  editTimetable.access_type === 'restricted' ? (
                     <React.Fragment>
                        <label className="form-label">Access window</label>
                        <br/>
                        <form className="form-2col" onChange={handleChangeAccessHours}>
                           <span>
                              <div className="flex-time-container">
                                 <label style={{marginRight: 10}}>From</label>
                                 <select
                                    className="time-select"
                                    name="from"
                                    data-type="hour"
                                    value={editTimetable.hours.from.hour}
                                    disabled={permissionMode === 'view only'}

                                 >
                                    {hours.map(hour => (
                                       <option value={hour}>{hour}</option>
                                    ))}
                                 </select>
                                 :
                                 <select
                                    className="time-select"
                                    name="from"
                                    data-type="minute"
                                    value={editTimetable.hours.from.minute}
                                    disabled={permissionMode === 'view only'}
                                 >
                                    {minutes.map(min => (
                                       <option value={min}>{min}</option>
                                    ))}
                                 </select>
                              </div>
                           </span>

                           <div>       
                              <div className="flex-time-container">
                                 <label style={{marginRight: 10}}>To</label>
                                 <select
                                    className="time-select"
                                    name="to"
                                    data-type="hour"
                                    value={editTimetable.hours.to.hour}
                                    disabled={permissionMode === 'view only'}
                                 >
                                    {hours.map(hour => (
                                       <option value={hour}>{hour}</option>
                                    ))}
                                 </select>
                                 :
                                 <select
                                    className="time-select"
                                    name="to"
                                    data-type="minute"
                                    value={editTimetable.hours.to.minute}
                                    disabled={permissionMode === 'view only'}
                                 >
                                    {minutes.map(min => (
                                       <option value={min}>{min}</option>
                                    ))}
                                 </select>
                              </div>
                           </div>
                        </form>

                        <br/>

                        <label htmlFor="weekends" className="form-label">Weekend access</label>

                        <br/>
                        <div className="flex-container">
                        <p>{editTimetable.weekends === true ? 'Enabled' : 'Disabled'}</p>
                        <label className="switchbox standard">
                           <input
                              id="auto-update"
                              type="checkbox"
                              disabled={permissionMode === 'view only'}
                              checked={editTimetable.weekends}
                              onChange={(e) => updateEditTimetable({...editTimetable, weekends: e.target.checked})}
                           />
                           <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                        </label>
                        </div>
                     </React.Fragment>
                  ) : null
               }
               
               {
                  permissionMode === 'edit' ? (
                     <React.Fragment>
                        <br/>
                        <button
                           className="welcome-button secondary"
                           onClick={handleSubmitChanges}
                        >Save changes</button>

                        <button
                           className="welcome-button primary"
                           onClick={handleDiscardChanges}
                        >Discard changes</button>
                     </React.Fragment>
                  ) : null
               }
               
            </div>
         )
      }

      return jsx
   }

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Manage access timetables</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>Timetable by user type <span role="img" aria-label="jsx-a11y/accessible-emoji">⏰</span></h2>

               <p>Access timetables define when certain user types are able to access the Appliance Sure Partner Portal.</p>

               <ul className="grid-list">
                  {createTimetableList()}
               </ul>
            </div>

            {showSelectedTimetable()}
         </div>
      </div>
   )
}

export default ManageTimetables