import React from "react";

const SalesCallMarkCompleted = ({ markedCallData }) => {
   return (
      <div className="grid-container">
         <h2>Mark submitted successfully <span role="img" aria-label="jsx-a11y/accessible-emoji">👏</span> <span role="img" aria-label="jsx-a11y/accessible-emoji">🎊</span></h2>

         <p>Your mark has now been submitted and will be available in quality control reports inside of the Partner Platform. Here's a summary of the call details:</p>

         <h3>Summary</h3>

         <table className="tariff-information-table">
            <tbody>
               <h4>Score</h4>

               <tr>
                  <td>Score</td>
                  <td>{markedCallData.score.toFixed(2)}%</td>
               </tr>
               <tr>
                  <td>Passed marks</td>
                  <td>{markedCallData.breakdown.passes}</td>
               </tr>
               <tr>
                  <td>Minor fails</td>
                  <td>{markedCallData.breakdown.minor_fails}</td>
               </tr>
               <tr>
                  <td>Major fails</td>
                  <td>{markedCallData.breakdown.major_fails}</td>
               </tr>
            </tbody>
         </table>

         <table className="tariff-information-table">
            <tbody>
               <br/>
               <h4>Call details</h4>

               <tr>
                  <td>Service number</td>
                  <td>{markedCallData.call_data.service_number}</td>
               </tr>
               <tr>
                  <td>Phone number</td>
                  <td>{markedCallData.call_data.Called_number}</td>
               </tr>
               <tr>
                  <td>Call by</td>
                  <td>{markedCallData.call_data.call_by}</td>
               </tr>
            </tbody>
         </table>

         <table className="tariff-information-table">
            <tbody>
               <br/>
               <h4>Productivity</h4>

               <tr>
                  <td>Call length</td>
                  <td>{markedCallData.performance.track_length.toFixed(2)} minutes</td>
               </tr>
               <tr>
                  <td>Start time</td>
                  <td>{new Date(markedCallData.performance.start_time).toLocaleString('en-GB')}</td>
               </tr>
               <tr>
                  <td>Completion time</td>
                  <td>{new Date(markedCallData.performance.end_time).toLocaleString('en-GB')}</td>
               </tr>
               <tr>
                  <td>Time to mark call</td>
                  <td>{markedCallData.performance.time_to_mark.toFixed(2)} minutes</td>
               </tr>
            </tbody>
         </table>

         <br/>

         <button
            className="welcome-button secondary refresh"
            onClick={() => window.location.reload()}
         >Mark another call?</button>
      </div>
   )
}

export default SalesCallMarkCompleted