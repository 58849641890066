import axios from "axios";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useUserAuth } from "../context/userAuthContext";
import DailyVitalsTile from "./welcome_tiles/daily_vitals";
import KnowledgeBaseTile from "./welcome_tiles/knowledge_base";
import LeaderboardTile from "./welcome_tiles/leaderboard";
import QuickActionsTile from "./welcome_tiles/quick_actions";

const WelcomeScreen = ({ 
   setShowScreen,
   setShowAdminScreen,
   permissions
}) => {
   const [timeOfDay, setTimeOfDay] = useState({
      text: '',
      emoji: ''
   });

   const { userDetails, user } = useUserAuth()
   const tile_permissions = permissions.modules.dashboard.tiles
   const [stats, setStats] = useState('')

   const getDashboardStats = async (tiles, scope) => {
      trackPromise(
         new Promise( async (resolve) => {
            await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/dashboard/getStats',
               headers: {
                  Authorization: "Bearer " + user
               },
               params: {
                  partner: userDetails.partner,
                  agent: userDetails.email_address,
                  scope: scope,
                  tiles: tiles
               }
            })
            .then((value) => {
               if(value.data.success === true) {
                  setStats(value.data.data)
               } else {
                  setStats({})
               }
               
               setTimeout(() => {
                  resolve("Yes")
               }, 100)
            })
            .catch(() => {
               alert({})
               resolve("Yes")
            })
         })
      , 'data_load')
   }

   const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

   useEffect(() => {
      let hour = parseInt((new Date()).toLocaleTimeString('en-GB').substring(0, 2))
      
      if(hour >= 0 && hour <= 11) {
         setTimeOfDay({
            text: 'morning',
            emoji: '☀️'
         })
      } else if(hour >= 12 && hour <= 17) {
         setTimeOfDay({
            text: 'afternoon',
            emoji: '☀️'
         })
      } else {
         setTimeOfDay({
            text: 'evening',
            emoji: '🌙'
         })
      }

      let permissions_object = {}
      let permission_scope = ''

      for (let i = 0; i < tile_permissions.length; i++) {
         const el = tile_permissions[i];
         
         permissions_object[el.name] = true

         if(el.scope !== undefined) {
            permission_scope = el.scope
         }
      }

      getDashboardStats(permissions_object, permission_scope)
   }, [])

   const tilesSelect = (tile, index) => {
      switch (tile) {
         case 'daily_vitals':
            return <DailyVitalsTile
                     data={stats.daily_vitals || {}}
                  />

         case 'quick_actions':
            return <QuickActionsTile
                     setShowAdminScreen={setShowAdminScreen}
                     setShowScreen={setShowScreen}
                     actions={tile_permissions[index].actions}
                  />

         case 'knowledge_base':
            return <KnowledgeBaseTile/>

         case 'leaderboard':
            return <LeaderboardTile
                     data={stats.leaderboard || []}
                  />

         default:
            return null
      }
   }

   const generateTiles = () => {
      let jsx = [];

      let left_column_jsx = [];
      let right_column_jsx = [];

      for (let i = 0; i < tile_permissions.length; i++) {
         const el = tile_permissions[i];
         
         if(i < 2) {
            left_column_jsx.push(<React.Fragment key={i*100}>{tilesSelect(el.name, i)}</React.Fragment>)
         } else {
            right_column_jsx.push(<React.Fragment key={i*100}>{tilesSelect(el.name, i)}</React.Fragment>)
         }
      }

      jsx.push([<div className="welcome-left-column">{left_column_jsx}</div>, right_column_jsx])

      return jsx;
   }

   return (
      <div className="screen-inner-container">
         <div className="welcome-container">
            <h1>Good {timeOfDay.text}, {userDetails.first_name} {timeOfDay.emoji}</h1>
            <p>You're now connected to Appliance Sure's partner platform through {userDetails.partner}.</p>

            <div className="welcome-button-container">
               {
                  permissions.modules.new_sale.access_granted ? (
                     <span>
                        <br/>
                        <button className="welcome-button primary" onClick={() => setShowScreen('new_sale')}>New sale</button>
                     </span>
                  ) : null
               }
               
               {
                  permissions.modules.reporting.access_granted ? (
                     <span>
                        <br/>
                        <button className="welcome-button secondary" onClick={() => setShowScreen('reporting')}>Track performance</button>
                     </span>
                  ) : null
               }
            </div>
         </div>

         <div className="two-col-grid-container dashboard">
            {generateTiles()}
         </div>
      </div>
   )
}

export default WelcomeScreen