import React from "react"

const RunningApplianceCost = ({ saleData }) => {
   const amounts_array = Object.entries(saleData.chosen_appliances.object)
   
   return (
      <React.Fragment>
         <br/>
         
         <div className="running-cost-container">
            <h4>Running cost</h4>

            <div className="table-wrapper">
               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <th colSpan={2}>Chosen appliances</th>
                     </tr>
                     {amounts_array.map(appliance => (
                        <tr>
                           <td>{appliance[0]}</td>
                           <td>x{appliance[1]}</td>
                        </tr>
                     ))}

                     <tr>
                        &nbsp;
                     </tr>

                     <tr>
                        <th colSpan={2}>Cost</th>
                     </tr>
                     
                     {
                        saleData.chosen_package.payment_frequency.includes("monthly") && saleData.chosen_package.cost.monthly_cost !== undefined ? (
                           <tr>
                              <td>Monthly cost</td>
                              <td>£{saleData.chosen_package.cost.monthly_cost.toFixed(2)}</td>
                           </tr>
                        ) : null
                     }

                     {
                        saleData.chosen_package.payment_frequency.includes("annually") && saleData.chosen_package.cost.annual_cost !== undefined ? (
                           <tr>
                              <td>Annual cost</td>
                              <td>£{saleData.chosen_package.cost.annual_cost.toFixed(2)}</td>
                           </tr>
                        ) : null
                     }
                  </tbody>
               </table>
            </div>
         </div>
      </React.Fragment>
   )
}

export default RunningApplianceCost