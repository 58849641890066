import axios from "axios";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import Error from "../../components/global/error";
import { useUserAuth } from "../../context/userAuthContext";
import { TbReportAnalytics as Report } from 'react-icons/tb'
import list_of_months from "../quality_control_screens/resources/list_of_months";
import { LineChart, ComposedChart, BarChart, PieChart, Cell, Pie, Bar, Line, XAxis, ResponsiveContainer, YAxis, Tooltip, CartesianGrid, ReferenceLine, Label, Legend } from 'recharts'
import CountUp from "react-countup";
import { CSVLink } from "react-csv";

const LeadBatchPerformanceAnalysis = ({ setShowScreen }) => {
   const { user } = useUserAuth();

   const [stats, setStats] = useState('')

   const [providersList, setProvidersList] = useState([])
   const [batchOptions, setBatchOptions] = useState('')
   const [totalResults, setTotalResults] = useState(0)
   const [showIndex, setShowIndex] = useState([1, 0])
   const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})

   const [showReport, setShowReport] = useState(false)
   const [selectedBatch, setSelectedBatch] = useState({
      batch_number: '',
      supplier: '',
      timestamp: ''
   })

   // Get month options (last 6 months)
   const today = new Date()
   let monthOptions = []

   monthOptions.push(
      new Date().toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7)
   )

   const [filters, setFilters] = useState({
      supplier: '',
      month: ''
   })

   const [errors, setErrors] = useState({
      supplier: false,
      month: false,
      not_found: false,
      error: false
   })

   const LookupLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'search_batches'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Searching database...</h3>
         </div>
      )
   }

   const DataLoadingIcon = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

      return (
         promiseInProgress &&
         <React.Fragment>
            <ThreeDots type="rotatingLines" color="#03989e" height={80} width={80}/>
         </React.Fragment>
      )
   }

   const getLeadProvidersList = async () => {
      await axios({
         method: 'get',
         url: "https://api.appliancesure.com/two/admin/portal/reporting/getLeadProviders",
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            let providers = []

            for (let i = 0; i < value.data.records.length; i++) {
               const el = value.data.records[i];
               
               providers.push(el.provider)
            }

            providers.sort()
            setProvidersList(providers)
         }
      })
      .catch(() => {
         // Do nothing
      })
   }

   useEffect(() => {
      getLeadProvidersList()
   }, [])

   const handleResetFilters = () => {
      setBatchOptions('')
      setTotalResults(0)
      setShowIndex([1, 0])
      setLastEvaluatedKey({})
   }

   const handleFillInFilters = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      handleResetFilters()

      setFilters({
         ...filters,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false,
         error: false,
         not_found: false
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0
      let errors_object = {}

      if(filters.month === '') {
         errors_count ++
         errors_object.month = true
      }

      if(filters.supplier === '') {
         errors_count ++
         errors_object.supplier = true
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })
      }
   }

   const getMoreBatches = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/reporting/leadData/searchBatch',
         params: {
            ...filters,
            lastEvaluatedKey: lastEvaluatedKey
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            const new_array = [...batchOptions, ...value.data.records]

            const starting_index = showIndex[1] + 1
            const end_index = new_array.length

            setBatchOptions(new_array)
            setTotalResults(new_array.length)
            setLastEvaluatedKey(value.data.lastEvaluatedKey)

            setShowIndex([starting_index, end_index])
         }
      })
   }

   const handleNextPage = async () => {
      const starting_index = showIndex[1] + 1
      let end_index = starting_index + 9

      if(batchOptions.length < end_index && lastEvaluatedKey !== undefined) {
         await getMoreBatches()
      } else {
         if(end_index > batchOptions.length) {
            end_index = batchOptions.length
         }

         setShowIndex([starting_index, end_index])
      }
   }

   const handlePreviousPage = () => {
      let starting_index = showIndex[0] - 10
      if(starting_index < 1) starting_index = 1
      const end_index = starting_index + 9

      setShowIndex([starting_index, end_index])
   }

   const handleSearchBatches = async (event) => {
      event.preventDefault()

      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'get',
                  url: 'https://api.appliancesure.com/two/admin/portal/reporting/leadData/searchBatch',
                  params: filters,
                  headers: {
                     Authorization: "Bearer " + user
                  }
               })
               .then((value) => {
                  setTimeout(() => {
                     setBatchOptions(value.data.records)
                     setTotalResults(value.data.records.length)
                     setLastEvaluatedKey(value.data.lastEvaluatedKey)

                     setShowIndex([1, value.data.records.length])
                     resolve("Yes")
                  }, 1000)
               })
               .catch(() => {
                  setTimeout(() => {
                     setErrors({
                        ...errors,
                        error: true
                     })
                  }, 500)
               })
            })
         , 'search_batches')
      }
   }

   const createBatchResults = () => {
      let jsx = []

      batchOptions.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))

      for (let i = showIndex[0] - 1; i < showIndex[1]; i++) {
         const el = batchOptions[i];
         
         jsx.push(
            <tr style={{cursor: 'pointer'}} onClick={() => handleSelectBatch(el.batch_number, filters.supplier, el.timestamp)}>
               <td>{el.batch_number}</td>
               <td>{new Date(el.timestamp).toLocaleString('en-GB')}</td>
               <td>{el.results.success}</td>
               <td><Report/></td>
            </tr>
         )
      }

      return jsx
   }

   const handleSelectBatch = (batch_number, supplier, timestamp) => {
      setShowReport(true)
      setSelectedBatch({
         batch_number: batch_number,
         supplier: supplier,
         timestamp: timestamp
      })
   }

   //----------------- REPORT ---------------------//

   useEffect(() => {
      if(selectedBatch.batch_number !== '' && selectedBatch.supplier !== '') {
         getDashboardStats()
      }
   }, [selectedBatch])

   const getDashboardStats = async () => {
      trackPromise(
         new Promise(async (resolve) => {
            await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/reporting/leadDataPerformanceAnalysis',
               headers: {
                  Authorization: "Bearer " + user
               },
               params: {
                  batch_number: selectedBatch.batch_number,
                  supplier: selectedBatch.supplier
               }
            })
            .then((value) => {
               if(value.data.success === true) {
                  setStats(value.data.data)
               } else {
                  setStats('No data')
               }

               resolve("Yes")
            })
            .catch(() => {
               alert("There was an error getting the requested data, please try again")
               resolve("Yes")
            })
         })
      , 'data_load')
   }

   //---------------------------------------
   //----- JSX GENERATION FOR TILES ------//
   //---------------------------------------

   //--- Tile #1 | Key results
   const generateKeyResultsTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>
               <center>
                  <CountUp
                     start={0}
                     end={stats.key_results.sales}
                     duration={0.5}
                     className="quality-score"
                     suffix={stats.key_results.sales === 1 ? ' sale' : ' sales'}
                     style={{fontSize: 50}}
                  />
               </center>

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Batch size</td>
                           <td>{stats.key_results.loaded_leads}</td>
                        </tr>
                        <tr>
                           <td>Attempted leads</td>
                           <td>{stats.key_results.attempted_leads}</td>
                        </tr>
                        <tr>
                           <td>Answered leads</td>
                           <td>{stats.key_results.answered_leads}</td>
                        </tr>
                        <tr>
                           <td>Total attempts</td>
                           <td>{stats.key_results.total_attempts}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #2 | Call lengths
   const generateCallLengthsTile = () => {
      let jsx = []

      const CustomLabel = ({ active, payload, label }) => {
         if(active && payload && payload.length) {

            return (
               <div className="custom-chart-label">
                  <p><span style={{color: '#03989e'}}>{payload[0].payload.count} calls</span><br/><br/>
                  {((payload[0].payload.count / stats.call_lengths.total_calls) * 100).toFixed(2)}%</p>
                  <p>{`>= ${label.replaceAll("m", "")} minutes`}</p>
               </div>
            )
         }
      }

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <div className="daily-scores-high-low">
                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">Calls answered</p>
                     <p className="quality-comparison">{stats.call_lengths.total_calls}</p>
                  </span>

                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">Avg. call time</p>
                     <p className="quality-comparison">{new Date(2022, 10, 10, 0, 0, stats.call_lengths.average_call_time).toTimeString().substring(0, 8)}</p>
                  </span>
               </div>

               <div className="chart-container">
                  <ResponsiveContainer height={150} width="100%">
                     <BarChart data={stats.call_lengths.data} margin={{ top: 10, right: -20, left: -20, bottom: 0 }}>
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey="category"/>
                        <YAxis />
                        <Bar dataKey="count" fill="#03989e" maxBarSize={20}/>
                        <Tooltip content={<CustomLabel/>} cursor={false}/>
                     </BarChart>
                  </ResponsiveContainer>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #3 | Answer rate
   const generateAnswerRateTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>
               <center>
                  <CountUp
                     start={0}
                     end={stats.answer_rate.answer_rate}
                     duration={0.5}
                     decimals={2}
                     className="quality-score"
                     suffix="%"
                     style={{fontSize: 50}}
                  />
               </center>

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Avg. attempts per answer</td>
                           <td>{stats.answer_rate.average_attempts_per_answer.toFixed(2)}</td>
                        </tr>
                        <tr>
                           <td>Answered calls</td>
                           <td>{stats.answer_rate.answered_count}</td>
                        </tr>
                        <tr>
                           <td>Unanswered calls</td>
                           <td>{stats.answer_rate.unanswered_count}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #4 | Outcome summary
   const generateOutcomeSummaryTile = () => {
      let jsx = []

      if(stats !== '') {
         let category_jsx = {
            positive: [],
            neutral: [],
            negative: [],
            uncategorised: []
         }

         for (let i = 0; i < stats.outcome_summary.summary.length; i++) {
            const el = stats.outcome_summary.summary[i];
            
            category_jsx[el.category].push(
               <tr count={el.count}>
                  <td>{el.outcome}</td>
                  <td>{el.count} <span style={{fontSize: 14, fontWeight: 'normal'}}>({el.percentage}%)</span></td>
               </tr>
            )
         }

         const colors = ["#7AC200", "#fea501", '#FF0000']

         category_jsx.positive.sort((b, a) => a.props.count - b.props.count)
         category_jsx.neutral.sort((b, a) => a.props.count - b.props.count)
         category_jsx.negative.sort((b, a) => a.props.count - b.props.count)
         category_jsx.uncategorised.sort((b, a) => a.props.count - b.props.count)

         jsx.push(
            <React.Fragment>
               <br/>
               {/* Pie chart */}
               <div className="chart-container" style={{marginTop: 10}}>
                  <ResponsiveContainer height={200} width="100%">
                     <PieChart width={200} height={200} margin={{ top: -30, right: 0, left: 0, bottom: 0 }}>
                        <Pie
                           dataKey="count"
                           data={stats.outcome_summary.category_counts_array}
                           outerRadius={80}
                           isAnimationActive={true}
                           fill
                        >
                           {stats.outcome_summary.category_counts_array.map((entry, index) => (
                              <Cell key={`percentage-cell-${index}`} fill={colors[index]}/>
                           ))}
                        </Pie>
                     </PieChart>
                  </ResponsiveContainer>
               </div>

               {/* Explantory table */}
               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <th colSpan={2} style={{color: '#7AC200'}}>Positive</th>
                        </tr>
                        {category_jsx.positive}
                        
                        <tr>
                           &nbsp;
                        </tr>

                        <tr>
                           <th colSpan={2} style={{color: '#fea501'}}>Neutral</th>
                        </tr>
                        {category_jsx.neutral}

                        <tr>
                           &nbsp;
                        </tr>

                        <tr>
                           <th colSpan={2} style={{color: 'red'}}>Negative</th>
                        </tr>
                        {category_jsx.negative}

                        {category_jsx.uncategorised.length > 0 ? (
                           <React.Fragment>
                              <tr>
                                 &nbsp;
                              </tr>

                              <tr>
                                 <th colSpan={2} style={{color: 'gray'}}>Uncategorised</th>
                              </tr>
                              {category_jsx.uncategorised}
                           </React.Fragment>
                        ) : null}
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #5 | Mobile phones
   const generateMobilePhonesTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <div className="table-wrapper">
               <table className="tariff-information-table">
                  <tbody>
                     <br/>
                     <tr>
                        <td>Total attempts</td>
                        <td>{stats.device_stats.mobile.attempts}</td>
                     </tr>
                     <tr>
                        <td>Answered</td>
                        <td>{stats.device_stats.mobile.answered}</td>
                     </tr>
                     <tr>
                        <td>Answer rate</td>
                        <td>{stats.device_stats.mobile.answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>Unanswered</td>
                        <td>{stats.device_stats.mobile.unanswered}</td>
                     </tr>
                     <tr>
                        <td>Sales</td>
                        <td>{stats.device_stats.mobile.sales}</td>
                     </tr>
                     <tr>
                        <td>Avg. call length</td>
                        <td>{new Date(2022, 10, 10, 0, 0, stats.device_stats.mobile.average_call_time).toTimeString().substring(0, 8)}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         )
      }

      return jsx
   }

   //--- Tile #6 | Landlines
   const generateLandlinesTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <div className="table-wrapper">
               <table className="tariff-information-table">
                  <tbody>
                     <br/>
                     <tr>
                        <td>Total attempts</td>
                        <td>{stats.device_stats.landline.attempts}</td>
                     </tr>
                     <tr>
                        <td>Answered</td>
                        <td>{stats.device_stats.landline.answered}</td>
                     </tr>
                     <tr>
                        <td>Answer rate</td>
                        <td>{stats.device_stats.landline.answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>Unanswered</td>
                        <td>{stats.device_stats.landline.unanswered}</td>
                     </tr>
                     <tr>
                        <td>Sales</td>
                        <td>{stats.device_stats.landline.sales}</td>
                     </tr>
                     <tr>
                        <td>Avg. call length</td>
                        <td>{new Date(2022, 10, 10, 0, 0, stats.device_stats.landline.average_call_time).toTimeString().substring(0, 8)}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         )
      }

      return jsx
   }

   //--- Tile #7 | Hourly breakdown
   const generateHourlyBreakdownTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <div className="table-wrapper" style={{marginTop: 15}}>
               <table className="activity-log-table">
                  <tbody>
                     <tr className="sticky-table-header">
                        <th>Hour</th>
                        <th>Attempts</th>
                        <th>Answers</th>
                        <th>Answer rate %</th>
                     </tr>
                     <tr>
                        <td>10am</td>
                        <td>{stats.hourly_breakdown[10].attempts}</td>
                        <td>{stats.hourly_breakdown[10].answered}</td>
                        <td>{stats.hourly_breakdown[10].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>11am</td>
                        <td>{stats.hourly_breakdown[11].attempts}</td>
                        <td>{stats.hourly_breakdown[11].answered}</td>
                        <td>{stats.hourly_breakdown[11].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>12pm</td>
                        <td>{stats.hourly_breakdown[12].attempts}</td>
                        <td>{stats.hourly_breakdown[12].answered}</td>
                        <td>{stats.hourly_breakdown[12].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>1pm</td>
                        <td>{stats.hourly_breakdown[13].attempts}</td>
                        <td>{stats.hourly_breakdown[13].answered}</td>
                        <td>{stats.hourly_breakdown[13].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>2pm</td>
                        <td>{stats.hourly_breakdown[14].attempts}</td>
                        <td>{stats.hourly_breakdown[14].answered}</td>
                        <td>{stats.hourly_breakdown[14].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>3pm</td>
                        <td>{stats.hourly_breakdown[15].attempts}</td>
                        <td>{stats.hourly_breakdown[15].answered}</td>
                        <td>{stats.hourly_breakdown[15].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>4pm</td>
                        <td>{stats.hourly_breakdown[16].attempts}</td>
                        <td>{stats.hourly_breakdown[16].answered}</td>
                        <td>{stats.hourly_breakdown[16].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>5pm</td>
                        <td>{stats.hourly_breakdown[17].attempts}</td>
                        <td>{stats.hourly_breakdown[17].answered}</td>
                        <td>{stats.hourly_breakdown[17].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>6pm</td>
                        <td>{stats.hourly_breakdown[18].attempts}</td>
                        <td>{stats.hourly_breakdown[18].answered}</td>
                        <td>{stats.hourly_breakdown[18].answer_rate}%</td>
                     </tr>
                     <tr>
                        <td>7pm</td>
                        <td>{stats.hourly_breakdown[19].attempts}</td>
                        <td>{stats.hourly_breakdown[19].answered}</td>
                        <td>{stats.hourly_breakdown[19].answer_rate}%</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         )
      }

      return jsx
   }

   //--- Tile #8 | Attempt timeline
   const generateAttemptTimelineTile = () => {
      let jsx = []


      const CustomLabel = ({ active, payload, label }) => {
         if(active && payload && payload.length) {

            return (
               <div className="custom-chart-label">
                  <p><span style={{color: '#03989e'}}>{payload[0].payload.attempts} attempts</span><br/><br/>
                  {payload[0].payload.answered} answers</p>
                  <p>{new Date(label).toLocaleDateString('en-GB')}</p>
               </div>
            )
         }
      }

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <div className="daily-scores-high-low">
                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">First used</p>
                     <p className="quality-comparison">{new Date(stats.attempt_timeline[0].date).toLocaleDateString("en-GB")}</p>
                  </span>

                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">Days used</p>
                     <p className="quality-comparison">{stats.attempt_timeline.length}</p>
                  </span>
               </div>

               <div className="chart-container">
                  <ResponsiveContainer height={150} width="100%">
                     <LineChart data={stats.attempt_timeline} margin={{ top: 10, right: 0, left: -10, bottom: 0 }}>
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey="date"/>
                        <YAxis />
                        <Line dataKey="attempts" stroke="#00c2cb"/>
                        <Line dataKey="answered" stroke="#7AC200"/>
                        <Tooltip content={<CustomLabel/>} cursor={false}/>
                     </LineChart>
                  </ResponsiveContainer>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #9 | Best performing leads
   const generateBestPerformingLeadsTile = () => {
      let jsx = []

      if(stats !== '') {

         const headers = [
            { label: "Lead provider", key: "supplier" },
            { label: "Batch number", key: "batch_number" },
            { label: "Phone number", key: "phone_number" },
            { label: "Outcome", key: "outcome" },
            { label: "Handle time (secs)",key: "talk_time" },
            { label: "Call date", key: "date"},
            { label: "Call timestamp", key: "timestamp"}
         ]
         
         jsx.push(
            <React.Fragment>
               <p style={{margin: 0}}>Download top performing leads to send to lead provider.</p>

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Leads</td>
                           <td>{stats.best_performing_leads.data.length}</td>
                        </tr>
                        <tr>
                           <td>Sales</td>
                           <td>{stats.key_results.sales}</td>
                        </tr>
                        <tr>
                           <td>Non-sales</td>
                           <td>{stats.best_performing_leads.data.length - stats.key_results.sales}</td>
                        </tr>
                        <tr>
                           <td>Format</td>
                           <td>CSV</td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <CSVLink 
                  data={stats.best_performing_leads.data} 
                  headers={headers}
                  className="welcome-button secondary"
                  filename={`${selectedBatch.batch_number}_best_performing_leads.csv`}
               >
                  Download CSV
               </CSVLink>
            </React.Fragment>
         )
      }

      return jsx
   }

   const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

   return (
      <div className="screen-inner-container">
         {
            showReport === false ? (
               <React.Fragment>
                  <LookupLoading/>
                  {/* SEARCH FOR BATCH */}
                  <button className="back-button" onClick={() => setShowScreen('')}>Back to reporting</button>
                  <h2>Search for a batch 🔍</h2>

                  <div className="two-col-grid-container">
                     <div className="grid-container">
                        <h3>Filters</h3>
                        <p>Please use the filters below to search for the batch you're looking for. You must select a value for both filters.</p>

                        <form onChange={handleFillInFilters}>
                           <div className="form-2col">
                              <span>
                                 <label className="form-label">Provider</label>
                                 <select
                                    className="form-input"
                                    value={filters.supplier}
                                    name="supplier"
                                 >
                                    <option value="" selected disabled>Select a provider</option>
                                    {providersList.map(provider => (
                                       <option value={provider}>{provider}</option>
                                    ))}
                                 </select>

                                 {
                                    errors.supplier ? (
                                       <Error message="Please select a lead provider"/>
                                    ) : null
                                 }
                              </span>

                              <span>
                                 <label className="form-label">Month</label>
                                 <select 
                                    className="form-input"
                                    value={filters.month}
                                    name="month"
                                 >
                                    <option value="" selected disabled>Select a month</option>
                                    {monthOptions.map(option => (
                                       <option value={option}>
                                          {list_of_months[parseInt(option.split("-")[1]) - 1]} {option.split("-")[0]}
                                       </option>
                                    ))}
                                 </select>

                                 {
                                    errors.month ? (
                                       <Error message="Please select the month of the batch"/>
                                    ) : null
                                 }
                              </span>
                           </div>

                           <br/>

                           <button
                              className="welcome-button secondary"
                              onClick={handleSearchBatches}
                           >Search</button>
                        </form>
                     </div>

                     {/* SEARCH RESULTS */}
                     {batchOptions !== '' ? (
                        <div>
                           <h3>Results</h3>

                           <p>Click on the batch number that you'd like to see</p>

                           <br/>

                           <div className="table-wrapper">
                              <table className="activity-log-table" id="batch-select-table">
                                 <tbody>
                                    <tr>
                                       <th>Batch number</th>
                                       <th>Time uploaded</th>
                                       <th>Batch size</th>
                                       <th></th>
                                    </tr>
                                    {createBatchResults()}
                                 </tbody>
                              </table>
                           </div>
                           
                           <div className="activity-log-counts">
                              {
                                 showIndex[0] > 1 ? (
                                    <button 
                                       className="plain-text-button arrow-left"
                                       onClick={handlePreviousPage}
                                    >Previous page</button>
                                 ) : null
                              }

                              {
                                 (showIndex[1] < totalResults) || (showIndex[1] === totalResults && lastEvaluatedKey !== undefined) ? (
                                    <button 
                                       className="plain-text-button arrow-right"
                                       onClick={handleNextPage}
                                    >Next page</button>
                                 ) : null
                              }

                              <br/>
                              <br/>
                              <p><strong>Showing results {showIndex[0]} - {showIndex[1]}</strong></p>
                              <p>Total results {lastEvaluatedKey !== undefined ? '- Many' : totalResults}</p>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </React.Fragment>
            ) : (
               <React.Fragment>
                  {/* REPORT */}
                  <button className="back-button" onClick={() => setShowReport(false)}>Back to search</button>
                  <h2>{selectedBatch.batch_number} - analysis</h2>
                  <p className="disclaimer">{filters.supplier} - {new Date(selectedBatch.timestamp).toLocaleString('en-GB')}</p>

                  <br/>
                  <br/>

                  {
                     stats !== '' ? (
                        <button disabled={promiseInProgress} style={{marginBottom: 10}} onClick={getDashboardStats} className="plain-text-button refresh">Refresh data</button> 
                     ) : null
                  }

                  {/*----------------------------------------
                  --------------- TILE JSX ------------------
                  ----------------------------------------*/}

                  <div className="lead-data-grid-container">
                     {/* Key results */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile1">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Key results</h4>

                                 {generateKeyResultsTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Call lengths */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile2">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Call lengths</h4>

                                 {generateCallLengthsTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Answer rate */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile3">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Answer rate</h4>

                                 {generateAnswerRateTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Outcome breakdown */}
                     <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile4">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Outcome breakdown</h4>

                                 {generateOutcomeSummaryTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Mobile phones */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile5">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Mobile phones</h4>

                                 {generateMobilePhonesTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Landlines */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile6">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Landlines</h4>

                                 {generateLandlinesTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Hourly breakdown */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile7">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Hourly breakdown</h4>

                                 {generateHourlyBreakdownTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Best performing leads */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile8">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Best performing leads</h4>

                                 {generateBestPerformingLeadsTile()}
                              </React.Fragment>
                           )
                        }
                     </div>

                     {/* Attempt timeline */}
                     <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile9">
                        {
                           promiseInProgress ? (
                              <DataLoadingIcon/>
                           ) : stats === 'No data' || stats === '' ? (
                              <p className="no-data-loader">No data</p>
                           ) : (
                              <React.Fragment>
                                 <h4>Attempt timeline</h4>

                                 {generateAttemptTimelineTile()}
                              </React.Fragment>
                           )
                        }
                     </div>
                  </div>
               </React.Fragment>
            )
         }
      </div>
   )
}

export default LeadBatchPerformanceAnalysis