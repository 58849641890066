import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useSearchParams } from 'react-router-dom'
import { useUserAuth } from '../context/userAuthContext'
import Appliances from './sales_screens/appliances'
import BankDetails from './sales_screens/bank_details'

// Screens
import CampaignType from './sales_screens/campaign_type'
import ApplianceDetails from './sales_screens/components/appliance_details'
import RunningApplianceCost from './sales_screens/components/running_appliance_cost'
import Script from './sales_screens/components/script'
import Legal from './sales_screens/legal'
import Package from './sales_screens/package'
import PersonalDetails from './sales_screens/personal_details'
import SaleCompleted from './sales_screens/success'

const NewSale = () => {
   const [stage, updateStage] = useState(1)
   const [scriptStage, updateScriptStage] = useState('')
   const [forceScriptData, updateForceScriptData] = useState(1)
   const [availableAppliances, setAvailableAppliances] = useState([])
   const [packages, setPackages] = useState([])
   const [scriptPackageCosts, setScriptPackageCosts] = useState('')
   const [streetName, setStreetName] = useState('')
   const { userDetails } = useUserAuth();

   const [searchParams, setSearchParams] = useSearchParams();

   // PAYMENT DATES
   let date = new Date();
   date.setDate(date.getDate()+14);
   date.setHours(0, 0, 0)

   let payment_date = new Date();
   payment_date.setHours(0, 0, 0);

   let day = date.getDate();
   let payment_date_of_month;


   let new_date;

   if( day >= 2 && day <= 8 ) {
      new_date = new Date(date.getFullYear(), date.getMonth(), 16);
      let difference = ((Date.parse(new_date) - Date.parse(date))/86400000);
      payment_date.setDate(payment_date.getDate()+(14+difference));
      payment_date_of_month = "16th"
   } else if( day >= 9 && day <= 16 ) {
      new_date = new Date(date.getFullYear(), date.getMonth(), 24, 12, 0, 0);

      let difference = ((Date.parse(new_date) - Date.parse(date))/86400000);
      payment_date.setDate(payment_date.getDate()+(14+difference));
      payment_date_of_month = "24th"
   } else if (day >= 17 && day <= 23 ) {
      new_date = new Date(date.getFullYear(), date.getMonth()+1, 1);
      let difference = ((Date.parse(new_date) - Date.parse(date))/86400000);
      payment_date.setDate(payment_date.getDate()+(14+difference));
      payment_date_of_month = "1st"
   } else if (day >= 24 && day <= 31) {
      new_date = new Date(date.getFullYear(), date.getMonth()+1, 9);
      let difference = ((Date.parse(new_date) - Date.parse(date))/86400000);
      payment_date.setDate(payment_date.getDate()+(14+difference));
      payment_date_of_month = "9th"
   } else if (day === 1) {
      new_date = new Date(date.getFullYear(), date.getMonth(), 9);
      let difference = ((Date.parse(new_date) - Date.parse(date))/86400000);
      console.log(difference)
      payment_date.setDate(payment_date.getDate()+(14+difference));
      payment_date_of_month = "9th"
   }

   const convert_iso_utc = (new Date(payment_date)).toISOString()
   const utc_as_date = new Date(convert_iso_utc)
   utc_as_date.setHours(utc_as_date.getHours() + 2)
   const first_payment_date_iso = utc_as_date.toISOString().substring(0, 10);

   const defaultSaleData = {
      campaign: {
         campaign_type: '',
         lead_appliance: '',
         called_number: ''
      },
      appliance_details: {
         age: 'Unknown',
         brand: 'Unknown'
      },
      chosen_package: {
         name: '',
         appliances: {
            min: '',
            max: ''
         },
         payment_frequency: '',
         cost: '',
         pricing_model: '',
         length_of_contract: '',
         chosen_frequency: '',
         chosen_cost: '',
         complex_pricing: false
      },
      chosen_appliances: {
         object: {},
         array: []
      },
      personal_details: {
         first_name: '',
         last_name: '',
         telephone: '',
         email_address: '',
         email_verified: false,
         customer_age: 'Unknown',
         address: {
            line_1: '',
            line_2: '',
            city: '',
            county: '',
            postcode: '',
            index: ''
         },
         billing_address: {
            same_as_address: true,
            line_1: '',
            line_2: '',
            city: '',
            county: '',
            postcode: '',
            index: ''
         },
         welcome_pack_method: '',
         marketing_optin: false,
         pre_provisioned: false
      },
      bank_details: {
         sort_code: '',
         account_number: '',
         account_name: '',
         regular_date: payment_date_of_month,
         first_payment_date: first_payment_date_iso,
         payment_instruction: 'Telephone'
      },
      source_details: {
         agent_name: userDetails.first_name + " " + userDetails.last_name,
         agent_email: userDetails.email_address,
         company: userDetails.partner
      },
      tandc_agreement: false
   }
   const [saleData, updateSaleData] = useState(defaultSaleData)
   const [addressList, setAddressList] = useState([])
   const [showManualAddress, setShowManualAddress] = useState(false)
   const [showManualBillingAddress, setShowManualBillingAddress] = useState(false)

   useBeforeunload((event) => {
      if(saleData.campaign.campaign_type !== '') {
         event.preventDefault();
      }
   })

   useEffect(() => {
      let update_object = {
         source_details: {
            agent_name: userDetails.first_name + " " + userDetails.last_name,
            agent_email: userDetails.email_address,
            company: userDetails.partner
         }
      }

      if(searchParams.get("source") === 'cnx1') {
         update_object = {
            ...update_object,
            campaign: {
               lead_appliance: searchParams.get("lead_appliance") || '',
               campaign_type: searchParams.get("campaign") || '',
               called_number: searchParams.get("phone_number") || ''
            },
            appliance_details: {
               age: searchParams.get("appliance_age") || 'Unknown',
               brand: searchParams.get("appliance_brand") || 'Unknown'
            },
            personal_details: {
               ...saleData.personal_details,
               title: searchParams.get("title") || '',
               first_name: searchParams.get("first_name") || '',
               last_name: searchParams.get("last_name") || '',
               telephone: searchParams.get("phone_number") || '',
               customer_age: searchParams.get("customer_age") || 'Unknown',
               address: {
                  ...saleData.personal_details.address,
                  postcode: searchParams.get("postcode") || '',
                  line_1: searchParams.get("street_name") || '',
                  street_name: searchParams.get("street_name").replace(/[0-9]/g, '').trim() || ''
               },
               pre_provisioned: true
            }
         }

         setStreetName(searchParams.get("street_name").replace(/[0-9]/g, '').trim() || '')

         updateStage(2)
      }

      updateSaleData({
         ...saleData,
         ...update_object
      })

      
   }, [])

   const refreshComponent = () => {
      updateSaleData(defaultSaleData)
      updateStage(1)
      setAddressList([])
      setShowManualAddress(false)
      getAvailableAppliances();
   }

   useEffect(() => {
      getAvailableAppliances()
   }, [])

   const getAvailableAppliances = async () => {
      const applianceLookup = await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/sales/getAppliancesList'
      })

      try {
         let response = applianceLookup.data.records

         response.sort((a, b) => {
            if (a.code_name < b.code_name) { return -1 }
            else { return 1 }
         })

         setAvailableAppliances(response)
      }

      catch {
         console.log("There was an error fetching appliances")
      }
   }

   const chooseScreen = () => {
      switch(stage) {
         case 1:
            return <CampaignType
                     saleData={saleData}
                     updateSaleData={updateSaleData}
                     availableAppliances={availableAppliances}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 2:
            return <Package
                     saleData={saleData}
                     updateSaleData={updateSaleData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                     packages={packages}
                     setPackages={setPackages}
                     setScriptPackageCosts={setScriptPackageCosts}
                  />

         case 3:
            return <Appliances
                     saleData={saleData}
                     updateSaleData={updateSaleData}
                     availableAppliances={availableAppliances}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 4:
            return <PersonalDetails
                     saleData={saleData}
                     updateSaleData={updateSaleData}
                     updateStage={updateStage}
                     addressList={addressList}
                     setAddressList={setAddressList}
                     showManualAddress={showManualAddress}
                     setShowManualAddress={setShowManualAddress}
                     showManualBillingAddress={showManualBillingAddress}
                     setShowManualBillingAddress={setShowManualBillingAddress}
                     updateScriptStage={updateScriptStage}
                     updateForceScriptData={updateForceScriptData}
                     forceScriptData={forceScriptData}
                     setStreetName={setStreetName}
                  />

         case 5:
            return <BankDetails
                     saleData={saleData}
                     updateSaleData={updateSaleData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 6:
            return <Legal
                     saleData={saleData}
                     updateSaleData={updateSaleData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 7:
            return <SaleCompleted
                     saleData={saleData}
                     refreshComponent={refreshComponent}
                     updateScriptStage={updateScriptStage}
                  />

         default:
            return null
      }
   }

   return (
      <React.Fragment>
         <div className='screen-inner-container'>
            <h1>New sale</h1>

            <div className="progress-bar-col-grid-container">
               {/* Progress bar */}
               <div className="progress-bar-container">
                  <div className={`progress-stage ${stage >= 1 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Campaign type</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 2 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Package</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 3 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Appliance(s)</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 4 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Personal details</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 5 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Payment</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 6 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Legal</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 7 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Done <span role="img" aria-label="jsx-a11y/accessible-emoji">🎉</span></h4>
                  </div>
               </div>

               {chooseScreen()}

               <div>
                  <span id="sticky-sidebar">
                     {
                        scriptStage !== '' ? (
                           <Script
                              scriptStage={scriptStage}
                              saleData={saleData}
                              scriptPackageCosts={scriptPackageCosts}
                              forceScriptData={forceScriptData}
                              streetName={streetName}
                              agent={userDetails.first_name}
                           />
                        ) : null
                     }

                     {
                        stage === 3 && saleData.chosen_package.pricing_model === 'appliance' ? (
                           <RunningApplianceCost saleData={saleData}/>
                        ) : null
                     }

                     {
                        stage === 2 && saleData.campaign.campaign_type === 'warranty_expiry' ? (
                           <ApplianceDetails details={saleData.appliance_details} customerAge={saleData.personal_details.customer_age}/>
                        ) : null
                     }
                  </span>
               </div>
            </div>

            <br/>
            <br/>
            <br/>
         </div>
      </React.Fragment>
   )
}

export default NewSale