import React from "react"
import MarkLeadSourceCall from "./lead_source/mark_call"
import MarkSalesCall from "./sales_call/mark_call"

const MarkCall = ({
   updateStage,
   markedCallData,
   updateMarkedCallData,
   callType
}) => {
   switch (callType) {
      case "lead_source":
         return <MarkLeadSourceCall
                  updateStage={updateStage}
                  markedCallData={markedCallData}
                  updateMarkedCallData={updateMarkedCallData}
               />

      case "sales_call":
         return <MarkSalesCall
                  updateStage={updateStage}
                  markedCallData={markedCallData}
                  updateMarkedCallData={updateMarkedCallData}
               />
   }
}

export default MarkCall