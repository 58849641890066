import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";

export const PermContext = React.createContext();

const ProtectedRoute = ({ children, adminRoute }) => {
   const { user, userDetails, LogOut } = useUserAuth()
   let navigate = useNavigate();

   const [permissions, setPermissions] = useState({})
   const [authenticated, setAuthenticated] = useState('pending')

   const check_timetable = async () => {
      const verification = new Promise( async (resolve, reject) => {
         await axios({
            method: 'get',
            url: 'https://api.appliancesure.com/two/admin/portal/access/verifyTimetable',
            params: {
               user_type: userDetails.user_type
            }
         }).then((value) => {
            const response = value.data.verified
            resolve(response)
         }).catch(() => {
            setAuthenticated('error')
            resolve(false)
         })
      })

      const response = await verification
      if(response === false) {
         setAuthenticated('out_of_timetable')
      } else {
         get_permission()
      }

      return response
   }
   
   const get_permission = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/permissions/fetch',
         params: {
            user_type: userDetails.user_type
         }
      })
      .then(async (value) => {
         const response = value.data;

         if(response.success === false) {
            setAuthenticated('unauthenticated')
         } else {
            setPermissions(response.data)
            validate_token()
         }
      })
      .catch(() => {
         setAuthenticated('error')
      })
   }

   const validate_token = async () => {
      await axios({
         method: 'post',
         url: 'https://api.appliancesure.com/two/admin/portal/access/validateAccessToken',
         headers: {
            Authorization: 'Bearer ' + user
         }
      })
      .then((value) => {
         const response = value.data.validated

         if(response === true) {
            if(value.data.new_access_token !== undefined) {
               localStorage.setItem("user_auth", value.data.new_access_token.access_token)
               window.location.reload();
            }

            setAuthenticated('authenticated')
         } else {
            alert("Your session is no longer valid, please log in again")
            LogOut();
         }
      })
      .catch(() => {
         LogOut();
      })
   }

   useEffect(() => {
      if(userDetails.user_type !== '' && userDetails.user_type !== undefined) {
         check_timetable()
      }
   }, [userDetails])

   useEffect(() => {
      if(user === '') {
         navigate('/login')
      }
   }, [user])

   switch (authenticated) {
      case 'pending':
         return (
            <div className="promise-tracker-container">
               <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
               <h3>Loading...</h3>
            </div>
         )
      
      case 'error':
         return (
            <div className="promise-tracker-container">
               <i className="error-icon"/>
               <h3>Oops, something went wrong!</h3>
               <p style={{marginTop: -10}}>There was an error authenticating your account, please try again.</p>
            </div>
         )

      case 'unauthenticated':
         return (
            <div className="promise-tracker-container">
               <i className="forbidden-icon"/>
               <h3>You do not have access to this page</h3>
               <p style={{marginTop: -10}}>Please speak to your manager to gain access.</p>
            </div>
         )
      
      case 'out_of_timetable':
         return (
            <div className="promise-tracker-container">
               <i className="forbidden-icon"/>
               <h3>You are outside of your access hours</h3>
               <p style={{marginTop: -10}}>Please try again during your permitted schedule.</p>
            </div>
         )

      case 'authenticated':
         return (
            <PermContext.Provider value={permissions}>
               {children}
            </PermContext.Provider>
         )
      
      default:
         return "404"
   }

   /*if(!user === true) {
      navigate('/login')
      return null
   } else {
      if(userDetails === "") {
         return setTimeout(() => {
            return (
               <React.Fragment>
                  <p>403 - Permission denied</p>
               </React.Fragment>
            )
         }, 5)
      } else {
         if(adminRoute === 'true' && userDetails.user_type !== 'admin') {
            return (
               <React.Fragment>
                     <p>403 - Permission denied</p>
               </React.Fragment>
            )
         } else {
            return (
               <React.Fragment>
                   {children}
               </React.Fragment>
            )
         }
      } 
   }*/
}

export default ProtectedRoute