import React from "react"
import ViewAgentScores from "./quality_control_screens/agent_scores"
import CreateScorecard from "./quality_control_screens/create_scorecard"
import FindACall from "./quality_control_screens/find_a_call"
import ManageScorecards from "./quality_control_screens/manage_scorecards"
import MarkNewCall from "./quality_control_screens/mark_new_call"
import ViewMarkingQueue from "./quality_control_screens/view_marking_queue"
import ViewQualityReports from "./quality_control_screens/view_reports"
import action_categories from "./resources/quality_control_categories"

const QualityControlScreen = ({
   setShowQualityScreen,
   showQualityScreen,
   permissions,
}) => {
   // Create navigation options per permissions
   const actionOptions = (action) => {
      switch (action) {
         case "create_scorecard":
            return <li onClick={() => setShowQualityScreen('create_scorecard')}>Create scorecard</li>

         case "manage_scorecards":
            return <li onClick={() => setShowQualityScreen('manage_scorecards')}>Manage scorecards</li>

         case "view_scorecards":
            return <li onClick={() => setShowQualityScreen('view_scorecards')}>View scorecards</li>

         case "mark_new_call":
            return <li onClick={() => setShowQualityScreen('mark_new_call')}>Mark new call</li>

         case "view_reports":
            return <li onClick={() => setShowQualityScreen('view_reports')}>View call reports</li>

         case "find_a_call":
            return <li onClick={() => setShowQualityScreen('find_a_call')}>Find a call</li>

         case "agent_scores":
            return <li onClick={() => setShowQualityScreen('agent_scores')}>View agent scores</li>

         case "marking_queue":
            return <li onClick={() => setShowQualityScreen('marking_queue')}>View marking queue</li>
         
         default:
            return null
      }
   }

   const chooseScreen = () => {
      switch (showQualityScreen) {
         case "create_scorecard":
            return <CreateScorecard
                     setShowScreen={setShowQualityScreen}
                     permissions={permissions.actions.create_scorecard.types}
                  />

         case "mark_new_call":
            return <MarkNewCall
                     setShowScreen={setShowQualityScreen}
                     permissions={permissions.actions.mark_new_call.types}
                  />

         case "view_reports":
            return <ViewQualityReports
                     setShowScreen={setShowQualityScreen}
                     permissions={permissions.actions.view_reports}
                  />

         case "find_a_call":
            return <FindACall
                     setShowScreen={setShowQualityScreen}
                  />

         case "marking_queue":
            return <ViewMarkingQueue
                     setShowScreen={setShowQualityScreen}
                  />

         case "agent_scores":
            return <ViewAgentScores
                     setShowScreen={setShowQualityScreen}
                     permissions={permissions.actions.agent_scores}
                  />

         case "manage_scorecards":
            return <ManageScorecards
                     setShowScreen={setShowQualityScreen}
                     permissions={permissions.actions.manage_scorecards.types}
                  />

         default:
            return null
      }
   }

   const generateNavigationOptions = () => {
      let jsx = []

      const sub_modules = permissions.actions;
      const sub_modules_array = Object.entries(sub_modules)

      let jsx_types = {
         monitor: [],
         scorecards: [],
         reports: []
      }

      for (let i = 0; i < sub_modules_array.length; i++) {
         const name = sub_modules_array[i][0];
         const details = sub_modules_array[i][1];

         if(details.access_granted === true) {
            const action_jsx = actionOptions(name)

            jsx_types[action_categories[name].category] = [ ...jsx_types[action_categories[name].category],
               <React.Fragment order={action_categories[name].order}>
                  {action_jsx}
               </React.Fragment>
            ]
         }
      }

      if(jsx_types.monitor.length > 0) {
         jsx_types.monitor.sort((a, b) => a.props.order - b.props.order)

         jsx.push(
            <React.Fragment>
               <h3>Monitor</h3>

               {jsx_types.monitor}

               <br/>
               <br/>
            </React.Fragment>
         )
      }

      if(jsx_types.reports.length > 0) {
         jsx_types.reports.sort((a, b) => a.props.order - b.props.order)

         jsx.push(
            <React.Fragment>
               <h3>Reporting</h3>

               {jsx_types.reports}

               <br/>
               <br/>
            </React.Fragment>
         )
      }

      if(jsx_types.scorecards.length > 0) {
         jsx_types.scorecards.sort((a, b) => a.props.order - b.props.order)

         jsx.push(
            <React.Fragment>
               <h3>Scorecards</h3>

               {jsx_types.scorecards}
            </React.Fragment>
         )
      }

      return jsx;
   }

   return (
      <React.Fragment>
         { showQualityScreen === '' ? (
            <div className="screen-inner-containner">
               <h1>Quality Control</h1>

               <div className="two-col-grid-container">
                  <div className="grid-container">
                     <h2>Actions 🛠</h2>

                     <br/>
                     
                     <ul className="grid-list">
                        {generateNavigationOptions()}
                     </ul>
                  </div>
               </div>
            </div>
         ) : chooseScreen()}
      </React.Fragment>
   )
}

export default QualityControlScreen