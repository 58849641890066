import axios from "axios";
import React, { useEffect, useState } from "react";
import Error from "../../components/global/error";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../context/userAuthContext";

const BankDetails = ({
   saleData,
   updateSaleData,
   updateStage,
   updateScriptStage
}) => {
   const { user } = useUserAuth();

   const [errors, setErrors] = useState({
      account_name: false,
      account_number: false,
      sort_code: false,
      invalid_bank_details: false,
      submission_error: false
   })

   // Variables
   const frequency_options = saleData.chosen_package.payment_frequency.length
   let discount_amount = 0;
   if(frequency_options === 2) {
      discount_amount = ((parseFloat(saleData.chosen_package.cost.annual_cost)/12)-parseFloat(saleData.chosen_package.cost.monthly_cost))*12
   }

   // Promise tracker
   const SubmitLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit' });

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Validating bank details...</h3>
         </div>
      )
   }

   useEffect(() => {
      if(frequency_options > 1) {
         updateScriptStage('4.1')
      } else {
         updateScriptStage('4.2')
      }
   }, [])

   const handleChangePaymentFrequency = (event) => {
      const value = event.target.value;

      let cost = 0;
      if(value === 'annually') {
         cost = saleData.chosen_package.cost.annual_cost
      } else {
         cost = saleData.chosen_package.cost.monthly_cost
      }

      updateSaleData({
         ...saleData,
         chosen_package: {
            ...saleData.chosen_package,
            chosen_frequency: value,
            chosen_cost: cost
         }
      })

      setErrors({
         ...errors,
         account_name: false,
         account_number: false,
         sort_code: false,
         invalid_bank_details: false
      })

      updateScriptStage('4.2')
   }

   useEffect(() => {
      if(frequency_options === 1) {
         let cost = 0;
         if(saleData.chosen_package.payment_frequency[0] === 'annually') {
            cost = saleData.chosen_package.cost.annual_cost
         } else {
            cost = saleData.chosen_package.cost.monthly_cost
         }

         updateSaleData({
            ...saleData,
            chosen_package: {
               ...saleData.chosen_package,
               chosen_frequency: saleData.chosen_package.payment_frequency[0],
               chosen_cost: cost
            }
         })
      }
   }, [])

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateSaleData({
         ...saleData,
         bank_details: {
            ...saleData.bank_details,
            [name]: val
         }
      })

      setErrors({
         ...errors,
         [name]: false,
         invalid_bank_details: false
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0
      let errors_object = {}
      const bank_details = saleData.bank_details

      if(bank_details.account_name === '') {
         errors_count++
         errors_object.account_name = true
      }

      if(bank_details.account_number.length !== 8) {
         errors_count++
         errors_object.account_number = true
      }

      if(bank_details.sort_code.length !== 6) {
         errors_count++
         errors_object.sort_code = true
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })
         return false
      }
   }

   const validateBankDetails = async () => {
      if (handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'post',
                  url: 'https://api.appliancesure.com/one/onboarding/directDebit/verifyDetails',
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  data: {
                     bank: saleData.bank_details,
                     personal: {
                        first_name: saleData.personal_details.first_name,
                        last_name: saleData.personal_details.last_name
                     }
                  }
               })
               .then((value) => {
                  setTimeout(() => {
                     if(value.data.validated === true) {
                        updateStage(6)
                        resolve("Yes")
                     } else {
                        setErrors({
                           ...errors,
                           invalid_bank_details: true
                        })
                        resolve('Error')
                     }
                  }, 500)
               })
               .catch(() => {
                  alert("There was an error, please try again")
                  resolve("Yes")
               })
            })
         , 'submit')
      }
   }

   return (
      <div className="grid-container">
         <SubmitLoader/>
         <button className="back-button" onClick={() => updateStage(4)}>Go back</button>
         <h2>Payment</h2>

         <p>The customer is about to sign up to the <strong>{saleData.chosen_package.name}</strong>. <br/>
            {frequency_options > 1 ? 'Please choose how they would like to pay...' : ''}
         </p>

         <label className="form-label">How would you like to pay?</label>
         <form className="payment-method-choice-container" onChange={handleChangePaymentFrequency}>
            {
               saleData.chosen_package.payment_frequency.includes("monthly") ? (
                  <span>
                     <input
                        type="radio"
                        name="payment_type"
                        className="payment-method-trigger invisible"
                        value="monthly"
                        id="monthly"
                        checked={saleData.chosen_package.chosen_frequency === 'monthly'}
                     />
                     <label className="payment-method-option" htmlFor="monthly">
                        <p>Pay monthly - <strong>£{parseFloat(saleData.chosen_package.cost.monthly_cost).toFixed(2)} per month</strong></p>
                     </label>
                  </span>
               ) : null
            }
            
            {
               saleData.chosen_package.payment_frequency.includes("annually") ? (
                  <span>
                     <input
                        type="radio"
                        name="payment_type"
                        className="payment-method-trigger invisible"
                        value="annually"
                        id="annually"
                        checked={saleData.chosen_package.chosen_frequency === 'annually'}
                     />
                     <label className="payment-method-option" htmlFor="annually">
                        <p>Pay annually - <strong>£{parseFloat(saleData.chosen_package.cost.annual_cost).toFixed(2)} per year</strong>
                           {discount_amount > 0 ? <span>&nbsp;(-£{discount_amount.toFixed(2)})</span> : null}
                        </p>
                     </label>
                  </span>
               ) : null
            } 
         </form>

         {
            saleData.chosen_package.chosen_frequency !== '' ? (
               <React.Fragment>
                  <br/>
                  <h3>Bank details</h3>

                  <p className="info-statement"><span>Your direct debit will be paid {saleData.chosen_package.chosen_frequency} at a rate of £{parseFloat(saleData.chosen_package.chosen_cost).toFixed(2)}. It will appear on your bank statement as Appliance Sure Ltd. <a href="https://www.directdebit.co.uk/direct-debit-explained/direct-debit-guarantee/" target="_blank" rel="noopener noreferrer">View direct debit guarantee</a></span></p>
            
                  <form onChange={handleFillInForm}>
                     <label htmlFor="account_name" className="form-label">Account name</label>
                     <input
                        id="account_name"
                        name="account_name"
                        type={"text"}
                        className="form-input"
                        value={saleData.bank_details.account_name}
                        placeholder="Enter how name appears in account"
                     />

                     {
                        errors.account_name ? (
                           <Error type="warning" message="Please enter the account name"/>
                        ) : null
                     }

                     <div className="form-2col">
                        <span>
                           <label htmlFor="account_number" className="form-label">Account number</label>
                           <input
                              id="account_number"
                              name="account_number"
                              type={"text"}
                              className="form-input"
                              autoComplete="off"
                              maxLength={8}
                              value={saleData.bank_details.account_number}
                              placeholder="Enter account number"
                           />

                           {
                              errors.account_number ? (
                                 <Error type="warning" message="Please enter a valid 8 digit account number"/>
                              ) : null
                           }
                        </span>

                        <span>
                           <label htmlFor="sort_code" className="form-label">Sort code</label>
                           <input
                              id="sort_code"
                              name="sort_code"
                              type={"text"}
                              className="form-input"
                              autoComplete="off"
                              maxLength={6}
                              value={saleData.bank_details.sort_code}
                              placeholder="Enter sort code"
                           />

                           {
                              errors.sort_code ? (
                                 <Error type="warning" message="Please enter a valid 6 digit sort code"/>
                              ) : null
                           }
                        </span>
                     </div>
                  </form>

                  {
                     errors.invalid_bank_details ? (
                        <Error type="warning" message="The bank details that you have entered appear to be invalid, please double check" />
                     ) : null
                  }

                  <button 
                     className="welcome-button secondary"
                     onClick={validateBankDetails}
                  >Validate bank details</button>
               </React.Fragment>
            ) : null }
      </div>
   )
}

export default BankDetails