const action_categories = {
   "mark_new_call": {
      order: 1,
      category: "monitor"
   },
   "find_a_call": {
      order: 2,
      category: "monitor"
   },
   "marking_queue": {
      order: 3,
      category: "monitor"
   },
   
   "create_scorecard": {
      order: 1,
      category: "scorecards"
   },
   "manage_scorecards": {
      order: 2,
      category: "scorecards"
   },
   "view_scorecards": {
      order: 3,
      category: "scorecards"
   },

   "agent_scores": {
      order: 1,
      category: "reports"
   },
   "view_reports": {
      order: 2,
      category: "reports"
   }
}

export default action_categories