import React from "react";
import LeadSourceMarkCompleted from "./lead_source/success";
import SalesCallMarkCompleted from "./sales_call/success";

const MarkCompleted = ({
   markedCallData,
   callType
}) => {
   switch (callType) {
      case "lead_source":
         return <LeadSourceMarkCompleted
                  markedCallData={markedCallData}
               />

      case "sales_call":
         return <SalesCallMarkCompleted
                  markedCallData={markedCallData}
               />
   }
}

export default MarkCompleted