import React, { useEffect, useState } from "react";
import axios from "axios";
import 'draft-js/dist/Draft.css'
import required_fields from "./resources/required_fields_new_content";
import Error from "../../components/global/error";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../context/userAuthContext";
import TextEditor from "../../components/global/text-editor";

const AddNewContent = ({
   category,
   setCreateContent,
   getContentByCategory,
   setSavedSuccessfully
}) => {
   const { userDetails } = useUserAuth()

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Saving content...</h3>
         </div>
      )
   }

   const [contentData, setContentData] = useState({
      title: '',
      description: '',
      category: '',
      media_format: '',
      text: '',
      url: '',
      added: {
         by: userDetails.email_address
      }
   })

   const [errors, setErrors] = useState({
      title: false,
      description: false,
      category: false,
      media_format: false,
      text: false,
      url: false,
      already_exists: false
   })

   const handleUpdateContent = (e) => {
      setContentData({
         ...contentData,
         text: e
      })

      setErrors({
         ...errors,
         text: false
      })
   }

   useEffect(() => {
      if(category !== undefined) {
         setContentData({
            ...contentData,
            category: category
         })
      }
   }, [])

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      setContentData({
         ...contentData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false,
         already_exists: false
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {};

      for (let i = 0; i < required_fields.length; i++) {
         const field = required_fields[i];
         
         if(contentData[field] === '') {
            errors_count++
            errors_object[field] = true
         }
      }

      if(contentData.media_format === 'text') {
         if(contentData.text === '') {
            errors_count++
            errors_object.text = true
         }
      } else if (contentData.media_format === 'url' || contentData.media_format === 'presentation') {
         if(contentData.url.includes("http") === false || contentData.url.includes("://") === false || contentData.url.includes(".") === false) {
            errors_count++
            errors_object.url = true
         }
      }

      if (errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         return false
      }
   }

   const handleSaveContent = async () => {
      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'post',
                  url: 'https://api.appliancesure.com/two/admin/portal/knowledge/addNewContent',
                  data: contentData
               })
               .then((value) => {
                  if(value.data.success === false) {
                     if(value.data.reason.includes("already a content")) {
                        setErrors({
                           ...errors,
                           already_exists: true
                        })
                     }
                  } else {
                     getContentByCategory()
                     setSavedSuccessfully('Content added successully')
                     setCreateContent('')
                  }
                  
                  resolve("Resolved")
               })
               .catch(() => {
                  alert("There was an error")
                  resolve("Resolved")
               })
            })
         , 'submit')
      }
   }


   return (
      <div className="center-modal-backdrop">
         <SubmitLoading/>
         <div className="script-modal-container knowledge-content">
            <h2>Add new content <span role="img" aria-label="jsx-a11y/accessible-emoji">📝</span></h2>
            
            <br/>

            <form onChange={handleFillInForm}>
               <label htmlFor="title" className="form-label">Title</label>

               <input
                  className="form-input"
                  placeholder="The name of your content entry"
                  type="text"
                  name="title"
                  value={contentData.title}
               />

               {
                  errors.title ? (
                     <Error
                        message="Please enter the title of your content"
                     />
                  ) : null
               }

               <label htmlFor="description" className="form-label">Description</label>

               <input
                  className="form-input"
                  placeholder="A short description of your content entry"
                  type="text"
                  name="description"
                  value={contentData.description}
               />

               {
                  errors.description ? (
                     <Error
                        message="Please enter a short description of your content"
                     />
                  ) : null
               }

               <div className="form-2col">
                  <span>
                     <label htmlFor="category" className="form-label">Category</label>

                     <select 
                        className="form-input"
                        name="category"
                        value={contentData.category}
                     >
                        <option value="" selected disabled>Choose a category</option>
                        <option value="objection_handling">Objection handling</option>
                        <option value="product_knowledge">Product knowledge</option>
                        <option value="competitor_analysis">Competitor analysis</option>
                        <option value="policies">Policy</option>
                     </select>

                     {
                        errors.category ? (
                           <Error
                              message="Please choose the category of your content"
                           />
                        ) : null
                     }
                  </span>

                  <span>
                     <label className="form-label" htmlFor="media_format">Media format</label>

                     <select 
                        className="form-input"
                        name="media_format"
                        value={contentData.media_format}
                     >
                        <option value="" selected disabled>Choose a format</option>
                        <option value="presentation">Presentation</option>
                        <option value="text">Text</option>
                        <option value="url">URL</option>
                        <option value disabled="image">Image</option>
                     </select>

                     {
                        errors.media_format ? (
                           <Error
                              message="Please choose the format of your content"
                           />
                        ) : null
                     }
                  </span>
               </div>
            </form>
            

            {
               contentData.media_format === 'text' ? (
                  <React.Fragment>
                     <label className="form-label">Content</label>
                     <br/>
                     
                     <TextEditor
                        initialContent=""
                        updateSource={handleUpdateContent}
                     />

                     <br/>
                     <br/>
                  </React.Fragment>
               ) : contentData.media_format === 'url' ? (
                  <React.Fragment>
                     <label htmlFor="url" className="form-label">URL</label>

                     <input
                        className="form-input"
                        type="text"
                        name="url"
                        onChange={handleFillInForm}
                        value={contentData.url}
                        placeholder="Enter the URL you'd like to link to"
                     />

                     {
                        errors.url ? (
                           <Error
                              message="Please enter a valid url"
                           />
                        ) : null
                     }
                  </React.Fragment>
               ) : contentData.media_format === 'presentation' ? (
                  <React.Fragment>
                     <label htmlFor="url" className="form-label">Embed URL</label>

                     <input
                        className="form-input"
                        type="text"
                        name="url"
                        onChange={handleFillInForm}
                        value={contentData.url}
                        placeholder="Enter the Canva link to embed your URL"
                     />

                     {
                        errors.url ? (
                           <Error
                              message="Please enter a valid url"
                           />
                        ) : null
                     }
                  </React.Fragment>
               ) : null
            }

            {
               errors.already_exists ? (
                  <p style={{marginTop: -10}} className="info-statement">A content entry of this title and category already exists</p>
               ) : null
            }

            <div className="script-button-container">
               <button
                  className="welcome-button secondary"
                  style={{width: 'fit-content'}}
                  onClick={handleSaveContent}
               >Save changes</button>

               <button
                  className="plain-text-button"
                  onClick={() => setCreateContent('')}
               >Cancel</button>
            </div>
         </div>
      </div>
   )
}

export default AddNewContent