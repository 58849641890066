import React, { useEffect, useState, useRef } from "react";
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from "draft-js-import-html";
import { 
   FaBold as Bold, 
   FaUnderline as Underline, 
   FaItalic as Italic, 
   FaListUl as UnList,
   FaListOl as OrList
} from 'react-icons/fa'
import {
   RiH2, RiH3, RiH4
} from 'react-icons/ri'
import 'draft-js/dist/Draft.css'

const TextEditor = ({
   initialContent,
   updateSource
}) => {
   // Text content
   const initialState = stateFromHTML(initialContent || '')
   const [stylesSelected, setStylesSelected] = useState({
      bold: false,
      underline: false,
      italic: false,
      ul: false,
      ol: false,
      h2: false,
      h3: false,
      h4: false
   })

   const [editorContent, setEditorContent] = useState(EditorState.createWithContent(initialState))
   
   useEffect(() => {
      updateSource(stateToHTML(editorContent.getCurrentContent()))
   }, [editorContent])

   const editor = useRef(null)

   const handleKeyCommand = (command, editorContent) => {
      const newState = RichUtils.handleKeyCommand(editorContent, command)
      setStylesSelected({
         ...stylesSelected,
         [command]: !stylesSelected[command]
      })

      if (newState) {
        setEditorContent(newState)
        return 'handled';
      }
  
      return 'not-handled';
   }

   const makeTextBold = (e) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         bold: !stylesSelected.bold
      })
      setEditorContent(RichUtils.toggleInlineStyle(editorContent, 'BOLD'))
      editor.current.focus();
   }

   const makeTextUnderline = (e) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         underline: !stylesSelected.underline
      })
      setEditorContent(RichUtils.toggleInlineStyle(editorContent, 'UNDERLINE'))
      editor.current.focus();
   }

   const makeTextItalic = (e) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         italic: !stylesSelected.italic
      })
      setEditorContent(RichUtils.toggleInlineStyle(editorContent, 'ITALIC'))
      editor.current.focus();
   }

   const block_types = {
      h2: 'header-two',
      h3: 'header-three',
      h4: 'header-four',
      ul: 'unordered-list-item',
      ol: 'ordered-list-item'
   }
   
   const changeBlockType = (e, type) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         [type]: !stylesSelected[type]
      })
      setEditorContent(RichUtils.toggleBlockType(editorContent, block_types[type]))
      editor.current.focus();
   }

   return (
      <div className="script-editor-container">
         <div className="script-editor-styles">
            <button
               className={`script-style-button ${stylesSelected.bold ? 'selected' : ''}`}
               onMouseDown={(e) => makeTextBold(e)}
            ><Bold/></button>

            <button
               className={`script-style-button ${stylesSelected.underline ? 'selected' : ''}`}
               onMouseDown={(e) => makeTextUnderline(e)}
            ><Underline/></button>

            <button
               className={`script-style-button ${stylesSelected.italic ? 'selected' : ''}`}
               onMouseDown={(e) => makeTextItalic(e)}
            ><Italic/></button>

            <button
               className={`script-style-button ${stylesSelected.ul ? 'selected' : ''}`}
               onMouseDown={(e) => changeBlockType(e, 'ul')}
            ><UnList/></button>

            <button
               className={`script-style-button ${stylesSelected.ol ? 'selected' : ''}`}
               onMouseDown={(e) => changeBlockType(e, 'ol')}
            ><OrList/></button>

            <br/>

            <button
               className={`script-style-button ${stylesSelected.h2 ? 'selected' : ''}`}
               onMouseDown={(e) => changeBlockType(e, 'h2')}
            ><RiH2/></button>

            <button
               className={`script-style-button ${stylesSelected.h3 ? 'selected' : ''}`}
               onMouseDown={(e) => changeBlockType(e, 'h3')}
            ><RiH3/></button>

            <button
               className={`script-style-button ${stylesSelected.h4 ? 'selected' : ''}`}
               onMouseDown={(e) => changeBlockType(e, 'h4')}
            ><RiH4/></button>
         </div>
         <div style={{minHeight: 200, maxHeight: 250}} className="script-editor-area" onClick={() => editor.current.focus()}>
            <Editor
               ref={editor}
               editorState={editorContent}
               onChange={editorState => setEditorContent(editorState)}
               handleKeyCommand={handleKeyCommand}
               placeholder="Write your content entry here"
               
            />
         </div>
      </div>
   )
}

export default TextEditor