import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSearchParams } from "react-router-dom"
import { useUserAuth } from "../context/userAuthContext"

// Components
import CampaignType from "./upgrade_screens/campaign_type"
import ExistingPolicy from "./upgrade_screens/existing_policy"
import Package from "./upgrade_screens/package"
import Legal from "./upgrade_screens/legal"
import DirectDebit from "./upgrade_screens/direct_debit"
import CardPayment from "./upgrade_screens/card_payment"
import Appliances from "./upgrade_screens/appliances"

const NewUpgrade = ({ }) => {
   const [stage, updateStage] = useState(4)
   const [scriptStage, updateScriptStage] = useState('')
   const [forceScriptData, updateForceScriptData] = useState(1)
   const [availableAppliances, setAvailableAppliances] = useState([])
   const [packages, setPackages] = useState('')
   const [scriptPackageCosts, setScriptPackageCosts] = useState('')
   const [streetName, setStreetName] = useState('')
   const { user, userDetails } = useUserAuth();

   const [searchParams, setSearchParams] = useSearchParams();

   const defaultUpgradeData = {
      campaign: {
         campaign_type: '',
         lead_appliance: ''
      },
      current_package: {
         service_number: '',
         name: '',
         appliances: {
            min: '',
            max: ''
         },
         payment_frequency: '',
         cost: '',
         pricing_model: '',
         length_of_contract: '',
         chosen_frequency: '',
         chosen_cost: '',
         complex_pricing: false
      },
      chosen_package: {
         name: '',
         appliances: {
            min: '',
            max: ''
         },
         payment_frequency: '',
         cost: '',
         pricing_model: '',
         length_of_contract: '',
         chosen_frequency: '',
         chosen_cost: '',
         complex_pricing: false
      },
      chosen_appliances: {
         object: {},
         array: []
      },
      personal_details: {
         first_name: '',
         last_name: '',
         telephone: '',
         email_address: '',
         email_verified: false,
         address: {
            line_1: '',
            line_2: '',
            city: '',
            county: '',
            postcode: '',
            index: ''
         },
         billing_address: {
            same_as_address: true,
            line_1: '',
            line_2: '',
            city: '',
            county: '',
            postcode: '',
            index: ''
         },
         welcome_pack_method: '',
         marketing_optin: false,
         pre_provisioned: false
      },
      bank_details: {
         sort_code: '',
         account_number: '',
         account_name: '',
         payment_instruction: 'Telephone'
      },
      source_details: {
         agent_name: userDetails.first_name + " " + userDetails.last_name,
         agent_email: userDetails.email_address,
         company: userDetails.partner
      },
      tandc_agreement: false
   }

   const defaultTestData = {
      campaign: {
         campaign_type: 'warranty_expiry',
         lead_appliance: 'Fridge'
      },
      current_package: {
         service_number: 'APS-30289',
         name: 'Single Package v2.0',
         appliances: {
            min: 1,
            max: 1
         },
         pricing_model: 'Package',
         length_of_contract: 12,
         chosen_frequency: 'Annually',
         chosen_cost: 95,
         complex_pricing: false,
         chosen_appliances: {
            object: {
               "Washing Machine": 1
            },
            array: ['Washing Machine']
         },
         sign_up_date: '2022-10-21',
         contract_remaining: '11 months, 3 weeks',
         first_contractual_payment: '2022-11-16',
         next_payment_date: '2023-11-16'
      },
      chosen_package: {
         same_as_existing: false,
         single_upgrade: false,
         name: 'Flexible Package v1.1',
         appliances: {
            min: 1,
            max: 5
         },
         cost: {
            1: {
               annual_cost: "95",
               monthly_cost: "9.50"
            },
            2: {
               annual_cost: "95",
               monthly_cost: "9.50"
            },
            3: {
               annual_cost: "95",
               monthly_cost: "9.50"
            },
            4: {
               annual_cost: "70",
               monthly_cost: "9.50"
            },
            5: {
               annual_cost: "0.00",
               monthly_cost: "0.00"
            }
         },
         pricing_model: 'appliance',
         length_of_contract: '12',
         chosen_frequency: 'Anually',
         chosen_cost: '',
         pro_rata_payment: '',
         complex_pricing: true,
         chosen_appliances: {
            array: ["Washing Machine", "Fridge"],
            object: {
               "Fridge": 1,
               "Washing Machine": 1
            }
         }
      },
      personal_details: {
         first_name: 'Jacqueline',
         last_name: 'Dickson',
         telephone: '+441316202687',
         email_address: null,
         email_verified: false,
         address: {
            line_1: '7 Magdalene Place',
            line_2: null,
            city: 'Edinburgh',
            postcode: 'EH15 3BJ',
         },
         billing_address: {
            same_as_address: true,
            line_1: '7 Magdalene Place',
            line_2: null,
            city: 'Edinburgh',
            postcode: 'EH15 3BJ',
         },
         communications_preference: 'Post'
      },
      bank_details: {
         reference_number: '3956APS',
         bank_name: '',
         sort_code: '209956',
         account_number: '10396622',
         account_name: 'Jacqueline Dixon',
         payment_instruction: 'Telephone'
      },
      source_details: {
         agent_name: userDetails.first_name + " " + userDetails.last_name,
         agent_email: userDetails.email_address,
         company: userDetails.partner
      },
      pci_details: {
         cvc: '',
         expiry: '',
         focus: '',
         name: '',
         number: ''
      },
      tandc_agreement: true,
      dd_amendment_agreement: true
   }

   const [upgradeData, updateUpgradeData] = useState(defaultTestData)

   useEffect(() => {
      let update_object = {
         source_details: {
            agent_name: userDetails.first_name + " " + userDetails.last_name,
            agent_email: userDetails.email_address,
            company: userDetails.partner
         }
      }

      updateUpgradeData({
         ...upgradeData,
         ...update_object
      })
   }, [])

   useEffect(() => {
      getAvailableAppliances()
   }, [])

   const getAvailableAppliances = async () => {
      const applianceLookup = await axios({
         method: 'get',
         headers: {
            Authorization: "Bearer " + user
         },
         url: 'https://api.appliancesure.com/two/admin/portal/sales/getAppliancesList'
      })

      try {
         let response = applianceLookup.data.records

         response.sort((a, b) => {
            if (a.code_name < b.code_name) { return -1 }
            else { return 1 }
         })

         setAvailableAppliances(response)
      }

      catch {
         console.log("There was an error fetching appliances")
      }
   }

   const chooseScreen = () => {
      switch(stage) {
         case 1:
            return <CampaignType
                     upgradeData={upgradeData}
                     updateUpgradeData={updateUpgradeData}
                     availableAppliances={availableAppliances}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 2:
            return <ExistingPolicy
                     upgradeData={upgradeData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 3:
            return <Package
                     upgradeData={upgradeData}
                     updateUpgradeData={updateUpgradeData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                     packages={packages}
                     setPackages={setPackages}
                  />

         case 4:
            return <Appliances
                     upgradeData={upgradeData}
                     updateUpgradeData={updateUpgradeData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                     availableAppliances={availableAppliances}
                  />

         case 5:
            return <Legal
                     upgradeData={upgradeData}
                     updateUpgradeData={updateUpgradeData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 6:
            return <DirectDebit
                     upgradeData={upgradeData}
                     updateUpgradeData={updateUpgradeData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />

         case 7:
            return <CardPayment
                     upgradeData={upgradeData}
                     updateUpgradeData={updateUpgradeData}
                     updateStage={updateStage}
                     updateScriptStage={updateScriptStage}
                  />
      }
   }

   return (
      <React.Fragment>
         <div className='screen-inner-container'>
            <h1>New upgrade</h1>

            <div className="progress-bar-col-grid-container">
               {/* Progress bar */}
               <div className="progress-bar-container">
                  <div className={`progress-stage ${stage >= 1 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Campaign type</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 2 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Existing policy</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 3 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Packages</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 4 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Appliances</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 5 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Legal</h4>
                  </div>

                  <div className={`progress-stage ${stage >= 6 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Direct debit</h4>
                  </div>

                  {
                     upgradeData.current_package.chosen_frequency === 'Annually' ? (
                        <div className={`progress-stage ${stage >= 7 ? 'completed' : ''}`}>
                           <div className='progress-circle'/>

                           <h4>Card payment</h4>
                        </div>
                     ) : null
                  }

                  <div className={`progress-stage ${stage >= 8 ? 'completed' : ''}`}>
                     <div className='progress-circle'/>

                     <h4>Done <span role="img" aria-label="jsx-a11y/accessible-emoji">🎉</span></h4>
                  </div>
               </div>

               {chooseScreen()}

            </div>
         </div>
      </React.Fragment>
   )
}

export default NewUpgrade