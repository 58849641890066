import React from "react";

const ConfirmModal = ({
   onConfirm,
   onReject,
   operation
}) => {
   return (
      <React.Fragment>
         <div className="center-modal-backdrop">
            <div className="confirm-modal-container">
               <h3>Are you sure you want to {operation}?</h3>

               <div className="confirm-modal-button-container">
                  <button className="confirm-modal-button yes" onClick={onConfirm}>
                     Yes
                  </button>

                  <button className="confirm-modal-button no" onClick={onReject}>
                     No
                  </button>
               </div>
            </div>
         </div>
      </React.Fragment>
   )
}

export default ConfirmModal