import axios from "axios";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import { useUserAuth } from "../../context/userAuthContext";
import list_of_months from "./resources/list_of_months";
import { FaStar as Star, FaStarHalfAlt as HalfStar, FaRegStar as NoStar, FaCircle as Circle } from 'react-icons/fa'
import { LineChart, BarChart, PieChart, Cell, Pie, Bar, Line, XAxis, ResponsiveContainer, YAxis, Tooltip, CartesianGrid, ReferenceLine, Label } from 'recharts'
import CountUp from 'react-countup'

const ViewAgentScores = ({
   setShowScreen,
   permissions
}) => {
   // Variables
   const { user, userDetails } = useUserAuth();
   const [stats, setStats] = useState('')

   // Data
   const [partnerList, setPartnerList] = useState([])
   const [agentList, setAgentList] = useState([])

   // Filters
   const [monthFilter, setMonthFilter] = useState(new Date().toISOString().substring(0, 7))
   const [partnerFilter, setPartnerFilter] = useState('')
   const [agentFilter, setAgentFilter] = useState('')

   // Get month options (last 3 months)
   const today = new Date()
   let monthOptions = []
   
   monthOptions.push(
      new Date().toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7)
   )

   ///--------------------------

   const DataLoadingIcon = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

      return (
         promiseInProgress &&
         <React.Fragment>
            <ThreeDots type="rotatingLines" color="#03989e" height={80} width={80}/>
         </React.Fragment>
      )
   }

   const getPartnerList = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/partners/getPartnerList',
         params: {
            limit_data: true
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            let partners = []

            for (let i = 0; i < value.data.records.length; i++) {
               const partner = value.data.records[i];
               
               partners.push(partner.partner_name)
            }

            setPartnerList(partners)
         }
      })
      .catch(() => {
         // Do nothing
      })
   }

   useEffect(() => {
      if(agentFilter === '') {
         if(permissions.scope === 'global') {
            getPartnerList()
         } else {
            setPartnerList([userDetails.partner])
            setPartnerFilter(userDetails.partner)
         }

         if(permissions.scope === 'individual') {
            setAgentFilter(userDetails.email_address)
            setAgentList([{
               first_name: userDetails.first_name,
               last_name: userDetails.last_name,
               email_address: userDetails.email_address
            }])
         }
      }
   }, [])

   const getDashboardStats = async () => {
      trackPromise(
         new Promise( async (resolve) => {
            await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/quality/getDashboardStats',
               headers: {
                  Authorization: "Bearer " + user 
               },
               params: {
                  partner: partnerFilter,
                  agent: agentFilter,
                  month: monthFilter
               }
            })
            .then((value) => {
               setTimeout(() => {
                  if(value.data.success === true) {
                     setStats(value.data.data)
                  } else {
                     setStats("No data")
                  }
   
                  resolve("Yes")
               }, 1000)
               
            })
            .catch(() => {
               alert("There was an error getting the requested data, please try again")
               resolve("Yes")
            })
         })
      , 'data_load')
   }

   useEffect(() => {
      if(agentFilter !== '') {
         getDashboardStats()
      }
   }, [agentFilter, monthFilter])

   const getAgentList = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/users/getUsersByPartner',
         params: {
            partner: partnerFilter
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then( async (value) => {
         if(value.data.success === true) {
            let user_list = value.data.records;
            user_list.sort((a, b) => a.first_name.localeCompare(b.first_name))
            setAgentList(value.data.records)
         }
      })
   }

   useEffect(() => {
      if(permissions.scope !== 'individual' && partnerFilter !== '') {
         getAgentList()
      }
   }, [partnerFilter])

   //---------------------------------------
   //----- JSX GENERATION FOR TILES ------//
   //---------------------------------------

   //--- Tile #1 | Score over time
   const generateScoreOverTimeTile = () => {
      let jsx = []

      const CustomLabel = ({ active, payload, label }) => {
         if(active && payload && payload.length) {
            return (
               <div className="custom-chart-label">
                  <p>{payload[0].value}%</p>
                  <p>{label} {list_of_months[parseInt(monthFilter.split("-")[1]) - 1]} {monthFilter.split("-")[0]}</p>
               </div>
            )
         }
      }

      if(stats !== '') {
         // Get improvement
         const last_score = stats.scores_over_time[stats.scores_over_time.length - 1].score
         const compare_score = stats.scores_over_time[stats.scores_over_time.length - 2].score
         const difference = last_score - compare_score
      
         jsx.push(
            <React.Fragment>
               <p style={{marginTop: 5}} className={`quality-comparison ${difference > 0 ? 'positive' : 'negative'}`}>{difference > 0 ? "+" : null}{difference.toFixed(2)}%</p>
               <p className="quality-comparison-sub-heading">In the last 24 hours</p>

               <div className="chart-container" id="sales-over-time">
                  <ResponsiveContainer height={150} width="100%">
                     <LineChart data={stats.scores_over_time} margin={{ top: 10, right: 10, left: -10, bottom: 0 }}>
                        <XAxis axisLine={true} tickLine={false} dataKey="date" />
                        <Tooltip content={<CustomLabel/>}/>
                        <Line type="monotone" dataKey="score" stroke="#03989e" strokeWidth={2} />
                     </LineChart>
                  </ResponsiveContainer>
               </div>
            </React.Fragment>
         )
      }
   
      return jsx;
   }

   //--- Tile #2 | Daily score
   const generateDailyScoreTile = () => {
      let jsx = []

      const CustomLabel = ({ active, payload, label }) => {
         if(active && payload && payload.length) {
            return (
               <div className="custom-chart-label">
                  <p>{payload[0].value}%</p>
                  <p>{label} {list_of_months[parseInt(monthFilter.split("-")[1]) - 1]} {monthFilter.split("-")[0]}</p>
               </div>
            )
         }
      }

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <div className="daily-scores-high-low">
                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">Highest day</p>
                     <p className="quality-comparison positive">{stats.daily_scores.highest_score}%</p>
                  </span>

                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">Lowest day</p>
                     <p className="quality-comparison">{stats.daily_scores.lowest_score}%</p>
                  </span>
               </div>

               <div className="chart-container">
                  <ResponsiveContainer height={150} width="100%">
                     <BarChart data={stats.daily_scores.scores} margin={{ top: 10, right: 10, left: -25, bottom: 0 }}>
                        <XAxis dataKey="date"/>
                        <YAxis/>
                        <Bar dataKey="score" fill="#03989e" maxBarSize={20}/>
                        <Tooltip content={<CustomLabel/>} cursor={false}/>
                     </BarChart>
                  </ResponsiveContainer>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #3 | Overall score
   const generateOverallScoreTile = () => {
      let jsx = []

      if(stats !== '') {
         const chart_data = [
            { 
               name: "score",
               value: stats.overall_score
            },
            {
               name: 'remainder',
               value: 100 - stats.overall_score
            }
         ]

         const colors = [stats.overall_score > 90 ? '#7AC200' : stats.overall_score > 75 ? '#ffa500' : '#ff0000', '#FFF']

         jsx.push(
            <React.Fragment>
               <div className="chart-container" style={{marginTop: 10}}>
                  <ResponsiveContainer height={200} width="100%">
                     <PieChart width={200} height={200} margin={{ top: -30, right: 0, left: 0, bottom: 0 }}>
                        <Pie
                           dataKey="value"
                           data={chart_data}
                           innerRadius={65}
                           outerRadius={75}
                           isAnimationActive={true}
                           fill
                        >
                           {chart_data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={colors[index]}/>
                           ))}
                           <Label id="center-pie-label" position="center" value={stats.overall_score + "%"}/>
                        </Pie>
                     </PieChart>
                  </ResponsiveContainer>

                  <p className={`quality-comparison`} style={{textAlign: 'center', marginTop: -25, fontSize: 24}}>
                     {stats.overall_score > 90 ? 'Great score! 🚀' : stats.overall_score > 75 ? 'Almost there! 💪' : 'Needs work 📉'}
                  </p>
               </div>

            </React.Fragment>
         )
      }
      
      return jsx;
   }

   //--- Tile #4 | Recent commments
   const generateRecentCommentsTile = () => {
      let jsx = []

      const getStarRating = (score) => {
         if(score >= 0 && score < 10) {
            return (
               <React.Fragment>
                  <NoStar className="nostar"/><NoStar/><NoStar/><NoStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 10 && score < 20) {
            return (
               <React.Fragment>
                  <HalfStar/><NoStar/><NoStar/><NoStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 20 && score < 30) {
            return (
               <React.Fragment>
                  <Star/><NoStar/><NoStar/><NoStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 30 && score < 40) {
            return (
               <React.Fragment>
                  <Star/><HalfStar/><NoStar/><NoStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 40 && score < 50) {
            return (
               <React.Fragment>
                  <Star/><Star/><NoStar/><NoStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 50 && score < 60) {
            return (
               <React.Fragment>
                  <Star/><Star/><HalfStar/><NoStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 60 && score < 70) {
            return (
               <React.Fragment>
                  <Star/><Star/><Star/><NoStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 70 && score < 80) {
            return (
               <React.Fragment>
                  <Star/><Star/><Star/><HalfStar/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 80 && score < 90) {
            return (
               <React.Fragment>
                  <Star/><Star/><Star/><Star/><NoStar/>
               </React.Fragment>
            )
         } else if(score >= 80 && score < 95) {
            return (
               <React.Fragment>
                  <Star/><Star/><Star/><Star/><HalfStar/>
               </React.Fragment>
            )
         } else {
            return (
               <React.Fragment>
                  <Star/><Star/><Star/><Star/><Star/>
               </React.Fragment>
            )
         }
      }

      if(stats !== '') {
         for (let i = 0; i < stats.recent_comments.length; i++) {
            const comment = stats.recent_comments[i];
            
            jsx.push(
               <li date={comment.call_date}>
                  <div>
                     <h5>{comment.submitted_by}</h5>
                     <div className="comment-subheader-row">
                        <span>{getStarRating(comment.score)}</span>
                        ·
                        <span>{comment.score.toFixed(2)}%</span>
                        ·
                        <span>{comment.service_number}</span>
                     </div>
                     
                     
                     <p>{comment.comment}</p>

                     <p className="disclaimer">{new Date(comment.call_date).toLocaleDateString('en-GB')}</p>
                  </div>
               </li>
            )
         }
      }

      jsx.sort((a, b) => new Date(b.props.date) - new Date(a.props.date))
      
      return [
         <ul className="recent-comments-list">
            {jsx}
         </ul>
      ]
   }

   //--- Tile #5 | Most frequent fails
   const generateFrequentFailsTile = () => {
      let jsx = []

      const colors = ["#03989e", "#00c2cb", "#7AC200", "#4caf50", '#424242']

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <div className="chart-container" style={{marginTop: 10}}>
                  <ResponsiveContainer height={200} width="100%">
                     <PieChart width={200} height={200} margin={{ top: -30, right: 0, left: 0, bottom: 0 }}>
                        <Pie
                           dataKey="percentage"
                           data={stats.frequent_fails}
                           outerRadius={75}
                           isAnimationActive={true}
                           fill
                        >
                           {stats.frequent_fails.map((entry, index) => (
                              <Cell key={`percentage-cell-${index}`} fill={colors[index]}/>
                           ))}
                        </Pie>
                     </PieChart>
                  </ResponsiveContainer>
               </div>

               {
                  stats.frequent_fails.map((fail, index) => (
                     <React.Fragment>
                     
                        <h5 style={{margin: 0}}><Circle style={{color: colors[index]}}/> {fail.question}</h5>
                        <p style={{marginTop: 5}} className="disclaimer">Count: {fail.count} ({fail.percentage.toFixed(2)}%)</p>
                        
                     </React.Fragment>
                  ))
               }
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #6 | Pending marks
   const generatePendingMarksTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>

               <center>
                  <CountUp
                     start={0}
                     end={stats.pending_marks}
                     duration={0.5}
                     className="quality-score"
                     style={{fontSize: 50}}
                  />
               </center>

               {
                  stats.pending_marks > 0 ? (
                     <React.Fragment>
                        <p>This means that there are {stats.pending_marks} calls that are still waiting to be marked for the month of {list_of_months[parseInt(monthFilter.split("-")[1]) - 1]} {monthFilter.split("-")[0]}</p>

                        <p>Your overall score may change before the month is closed.</p>
                     </React.Fragment>
                  ) : (
                     <p>Nice, all of your calls have been marked so far! 🚀</p>
                  )
               }
               
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #7 | Score breakdown
   const generateScoreBreakdownTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <td>Calls marked</td>
                        <td>{stats.score_breakdown.calls_marked}</td>
                     </tr>
                     <tr>
                        <td>Questions assessed</td>
                        <td>{stats.score_breakdown.questions_assessed}</td>
                     </tr>

                     <tr>
                        &nbsp;
                     </tr>

                     <tr>
                        <th colSpan={2}>Question breakdown</th>
                     </tr>
                     <tr>
                        <td>Passes</td>
                        <td>{stats.score_breakdown.passes}</td>
                     </tr>
                     <tr>
                        <td>Minor fails</td>
                        <td>{stats.score_breakdown.minor_fails}</td>
                     </tr>
                     <tr>
                        <td>Major fails</td>
                        <td>{stats.score_breakdown.major_fails}</td>
                     </tr>
                  </tbody>
               </table>
            </React.Fragment>
         )
      }

      return jsx
   }

   const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to quality control</button>
         <h2>Agent quality dashboard</h2>

         <br/>
         <br/>

         <div className="qa-dashboard-grid-container">
            {/* FILTER BAR*/}
            <div className="qa-dashboard-filter-bar" id="bar">
               {/* Left hand side of bar*/}
               <ul className="filter-bar-list left">
                  <li>
                     {/* Company filter */}
                     <select
                        className="qa-dashboard-filter-select"
                        value={partnerFilter}
                        onChange={(e) => setPartnerFilter(e.target.value)}
                        disabled={promiseInProgress}
                     >
                        <option value="" selected disabled>Choose a company</option>
                        {
                           partnerList.map(partner => (
                              <option value={partner}>{partner}</option>
                           ))
                        }
                     </select>
                  </li>
                  <li>
                     {/* Agent filter */}
                     <select
                        className="qa-dashboard-filter-select"
                        value={agentFilter}
                        onChange={(e) => setAgentFilter(e.target.value)}
                        disabled={promiseInProgress}
                     >
                        <option value="" selected disabled>Choose an agent</option>
                        {
                           agentList.map(agent => (
                              <option value={agent.email_address}>{agent.first_name} {agent.last_name}</option>
                           ))
                        }
                     </select>
                  </li>
               </ul>

               {/* Right hand side of bar*/}
               <ul className="filter-bar-list right">
                  <li>
                     <select
                        className="qa-dashboard-filter-select"
                        value={monthFilter}
                        onChange={(e) => setMonthFilter(e.target.value)}
                        disabled={promiseInProgress}
                     >
                        {monthOptions.map(option => (
                           <option value={option}>
                              {list_of_months[parseInt(option.split("-")[1]) - 1]} {option.split("-")[0]}
                           </option>
                        ))}
                     </select>
                  </li>
               </ul>
            </div>

            {/*----------------------------------------
            --------------- TILE JSX ------------------
            ----------------------------------------*/}

            {/* Score over time */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile1">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Score over time</h4>

                        {generateScoreOverTimeTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Daily score */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile2">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Daily scores</h4>

                        {generateDailyScoreTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Overall score */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile3">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Overall score</h4>

                        {generateOverallScoreTile()} 
                     </React.Fragment>
                  )
               }
            </div>

            {/* Recent comments */}
            <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile4">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Recent comments</h4>

                        {generateRecentCommentsTile()}
                     </React.Fragment>
                  )
               }
               
            </div>

            {/* Frequent fails */}
            <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile5">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Most frequent fails</h4>
         
                        {generateFrequentFailsTile()}
                     </React.Fragment>
                  )
               }
            </div>

            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile6">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Pending marks</h4>
         
                        {generatePendingMarksTile()}
                     </React.Fragment>
                  )
               }
            </div>

            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile7">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Score breakdown</h4>
         
                        {generateScoreBreakdownTile()}
                     </React.Fragment>
                  )
               }
            </div>
         </div>
      </div>
   )
   
}

export default ViewAgentScores