import axios from "axios";
import React, { useEffect, useState } from "react";
import Error from "../../components/global/error";
import { useUserAuth } from "../../context/userAuthContext";
import calculatePackagePricing from "../sales_screens/functions/calculate_pricing";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";

const Package = ({
   upgradeData,
   updateUpgradeData,
   updateStage,
   updateScriptStage,
   packages,
   setPackages
}) => {
   // States
   const [operationsModal, setOperationsModal] = useState('')

   const { user, userDetails } = useUserAuth();
   
   const [errors, setErrors] = useState({
      email_address: false,
      invalid_email: false,
      first_name: false
   })

   // Promise tracker loader
   const SendingLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'send_quote'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Sending quote...</h3>
         </div>
      )
   }

   const getAvailablePackages = async () => {
      await axios({
         method: 'get',
         url: "https://api.appliancesure.com/two/admin/portal/products/upgrades/getAvailableProducts",
         headers: {
            Authorization: "Bearer " + user
         },
         params: {
            partner_name: userDetails.partner,
            payment_frequency: upgradeData.current_package.chosen_frequency.toLowerCase()
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            setPackages(value.data.records)
         } else {
            setPackages("No data")
         }
      })
      .catch(() => {
         alert("There was an error fetching products, please try again")
      })
   }

   useEffect(() => {
      if(packages === '') {
         getAvailablePackages()
      }
   }, [])

   const chooseSingleApplianceProduct = (product_details) => {
      updateUpgradeData({
         ...upgradeData,
         chosen_package: {
            same_as_existing: product_details.product_name === upgradeData.current_package.name,
            single_upgrade: true,
            name: product_details.product_name,
            appliances: product_details.appliances,
            cost: product_details.cost,
            pricing_model: product_details.pricing_model,
            length_of_contract: product_details.length_of_contract,
            chosen_cost: product_details.new_price,
            pro_rata_payment: '',
            chosen_frequency: upgradeData.current_package.chosen_frequency,
            complex_pricing: product_details.complex_pricing,
            chosen_appliances: {
               array: [...upgradeData.current_package.chosen_appliances.array, upgradeData.campaign.lead_appliance],
               object: {
                  ...upgradeData.current_package.chosen_appliances.object,
                  [upgradeData.campaign.lead_appliance]: 1
               }
            }
         }
      })

      updateStage(5)
   }

   const chooseMultiApplianceProduct = (product_details) => {
      updateUpgradeData({
         ...upgradeData,
         chosen_package: {
            same_as_existing: product_details.product_name === upgradeData.current_package.name,
            single_upgrade: false,
            name: product_details.product_name,
            appliances: product_details.appliances,
            cost: product_details.cost,
            pricing_model: product_details.pricing_model,
            length_of_contract: product_details.length_of_contract,
            chosen_cost: '',
            pro_rata_payment: '',
            chosen_frequency: upgradeData.current_package.chosen_frequency,
            complex_pricing: product_details.complex_pricing,
            chosen_appliances: {
               array: [...upgradeData.current_package.chosen_appliances.array, upgradeData.campaign.lead_appliance],
               object: {
                  ...upgradeData.current_package.chosen_appliances.object,
                  [upgradeData.campaign.lead_appliance]: 1
               }
            }
         }
      })

      updateStage(4)
   }

   const handleSubmitEmailQuote = (product_details, single_bool, new_appliance) => {
      trackPromise(
         new Promise( async (resolve) => {
            const verify_email = await axios({
               method: 'get',
               url: "https://api.appliancesure.com/zero/gen/email/verification",
               headers: {
                  Authorization: "Bearer " + user
               },
               params: {
                  email_address: upgradeData.personal_details.email_address
               }
            })

            const verification_response = verify_email.data.valid

            if(verification_response === true) {
               updateUpgradeData({
                  ...upgradeData,
                  personal_details: {
                     ...upgradeData.personal_details,
                     email_verified: true
                  }
               })

               // Proceed to send quote email
               try {
                  let axios_params = {}

                  if(single_bool === true) {
                     axios_params = {
                        method: 'post',
                        url: 'https://api.appliancesure.com/one/onboarding/new/upgrades/sendSingleQuoteEmail',
                        headers: {
                           Authorization: "Bearer " + user
                        },
                        data: {
                           customer_data: {
                              first_name: upgradeData.personal_details.first_name,
                              email_address: upgradeData.personal_details.email_address
                           },
                           product_details: {
                              new_appliance: new_appliance,
                              product_name: product_details.product_name,
                              existing_appliances: upgradeData.current_package.chosen_appliances.array.join(", "),
                              old_price: parseFloat(upgradeData.current_package.chosen_cost),
                              new_price: product_details.new_price,
                              payment_frequency: upgradeData.current_package.chosen_frequency,
                              contract_remaining: upgradeData.current_package.contract_remaining,
                              sign_up_date: upgradeData.current_package.sign_up_date
                           }
                        }
                     }
                  } else {
                     axios_params = {
                        method: 'post',
                        url: 'https://api.appliancesure.com/one/onboarding/new/upgrades/sendMultiQuoteEmail',
                        headers: {
                           Authorization: "Bearer " + user
                        },
                        data: {
                           customer_data: {
                              first_name: upgradeData.personal_details.first_name,
                              email_address: upgradeData.personal_details.email_address
                           },
                           product_details: {
                              product_name: product_details.product_name,
                              existing_appliances: upgradeData.current_package.chosen_appliances.array.join(", "),
                              cost: product_details.cost,
                              old_price: parseFloat(upgradeData.current_package.chosen_cost),
                              payment_frequency: upgradeData.current_package.chosen_frequency,
                              contract_remaining: upgradeData.current_package.contract_remaining,
                              sign_up_date: upgradeData.current_package.sign_up_date,
                              pricing_model: product_details.pricing_model,
                              complex_pricing: product_details.complex_pricing,
                              appliances: product_details.appliances
                           }
                        }
                     }
                  }

                  await axios(axios_params)
                     .then((value) => {
                        if(value.data.success === true) {
                           alert("Email was sent successfully")
                           setOperationsModal('')
                           resolve("Yes")
                        } else {
                           alert("There was an error, please try again later")
                           resolve("Yes")
                        }
                     })
                     .catch(() => {
                        alert("There was an error, please try again later")
                        resolve("Yes")
                     })
               }

               catch {
                  alert("There was an error, please try again later")
                  resolve("Yes")
               }
            } else {
               setErrors({
                  ...errors,
                  invalid_email: true
               })
            }
         })
      , 'send_quote')
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateUpgradeData({
         ...upgradeData,
         personal_details: {
            ...upgradeData.personal_details,
            [name]: val
         }
      })

      if(name === 'email_address') {
         setErrors({
            ...errors,
            email_address: false,
            invalid_email: false
         })
      } else {
         setErrors({
            ...errors,
            [name]: false
         })
      } 
   }

   const emailQuoteModal = (record, single_bool, new_appliance) => {
      return (
         <React.Fragment>
            <h2>Send email quote - {record.product_name}</h2>
            <p>If there is any information missing or incorrect below, please fix this now.</p>

            <br/>

            <label className="form-label" htmlFor="email_address">Email address</label>
            <input
               type="text"
               className="form-input"
               placeholder="Please enter an email address"
               onChange={handleFillInForm}
               name="email_address"
               value={upgradeData.personal_details.email_address}
            />

            {
               errors.email_address ? (
                  <React.Fragment>
                     <div style={{marginTop: -20}}/>
                     <Error message="Please enter a valid email address"/>
                  </React.Fragment>
               ) : null
            }

            {
               errors.invalid_email ? (
                  <React.Fragment>
                     <div style={{marginTop: -20}}/>
                     <Error type="warning" message="According to our system, this is not a valid email address"/>
                  </React.Fragment>
               ) : null
            }

            <br/>

            <button 
               className="welcome-button secondary"
               onClick={() => handleSubmitEmailQuote(record, single_bool, new_appliance)}
            >Send quote</button>
         </React.Fragment>
      )
   }

   const createProductFeed = () => {
      let jsx = []

      if(packages !== '') {
         if(packages === 'No data') {
            // If there are no packages available
            jsx.push(<p>There are no packages currently available</p>)
         } else {
            const new_appliance_count = upgradeData.current_package.chosen_appliances.array.length + 1
            let used_products = []
            
            if(upgradeData.campaign.campaign_type === 'warranty_expiry') {
               // Add the lead appliance
               jsx.push(
                  <h3>Add your {upgradeData.campaign.lead_appliance}</h3>
               )

               const filtered_solo_lead_products = packages.filter(e => 
                  e.appliances.max >= (upgradeData.current_package.chosen_appliances.array.length + 1) && 
                  (e.pricing_model === 'appliance' || (e.pricing_model === 'package') && e.appliances.max === (upgradeData.current_package.chosen_appliances.array.length + 1))   
               )


               if(filtered_solo_lead_products.length === 0) {
                  // No products available
                  jsx.push(
                     <p>There are no packages currently available</p>
                  )
               } else {
                  // Work out cheapest price for add apliance
                  let single_pricing_array = []

                  for (let i = 0; i < filtered_solo_lead_products.length; i++) {
                     const el = filtered_solo_lead_products[i];
                     const pricing = calculatePackagePricing({
                        appliance_count: new_appliance_count,
                        pricing_model: el.pricing_model,
                        complex_pricing: el.complex_pricing,
                        pricing: el.cost,
                        payment_frequency: upgradeData.current_package.chosen_frequency.toLowerCase()
                     })

                     single_pricing_array.push({
                        name: el.product_name,
                        pricing: pricing
                     })
                  }

                  single_pricing_array.sort((a, b) => a.pricing - b.pricing)

                  const single_chosen_product = {
                     new_price: single_pricing_array[0].pricing,
                     ...filtered_solo_lead_products.filter(e => e.product_name === single_pricing_array[0].name)[0]
                  }

                  used_products.push(filtered_solo_lead_products.filter(e => e.product_name === single_pricing_array[0].name)[0])

                  const price_difference = single_chosen_product.new_price - upgradeData.current_package.chosen_cost

                  jsx.push(
                     <div className="package-container">
                        <div className="package-record-wrapper">
                           <div className="package-record-line"/>
                           <div className="package-record-container">
                              <div className="package-record-upper">
                                 <span>
                                    <h4>Add {upgradeData.campaign.lead_appliance.toLowerCase()} for £{price_difference.toFixed(2)}</h4>
                                    <p>Continue paying {upgradeData.current_package.chosen_frequency.toLowerCase()}, with a new direct debit amount of £{single_chosen_product.new_price.toFixed(2)}</p>

                                    <br/>

                                    <div className="button-container">
                                       <button className="welcome-button green" onClick={() => chooseSingleApplianceProduct(single_chosen_product)}>Choose</button>
                                       <button className="welcome-button primary" onClick={() => setOperationsModal(`Email quote - ${single_chosen_product.product_name} - single`)}>Email quote</button>
                                    </div>

                                    {operationsModal === `Email quote - ${single_chosen_product.product_name} - single` ? (
                                       <div className="center-modal-backdrop">
                                          <div className="user-activity-modal-container" style={{maxWidth: 700}}>
                                             <div className='close-modal-container activity-modal' onClick={() => setOperationsModal('')}>
                                                <button className='close-modal'/>
                                             </div>

                                             {emailQuoteModal(single_chosen_product, true, upgradeData.campaign.lead_appliance)}
                                          </div>
                                       </div>
                                    ) : null}
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               }
            }
            
            let other_products = packages
            if(upgradeData.campaign.campaign_type === 'warranty_expiry') {
               other_products = packages.filter(e => e.appliances.max > new_appliance_count)
            }
            
            if(other_products.length > 0) {
               jsx.push(
                  <React.Fragment>
                     <br/>
                     <h3>Add more appliances</h3>
                  </React.Fragment>
               )
            }

            let other_product_jsx = []

            for (let i = 0; i < other_products.length; i++) {
               const el = other_products[i];
               
               other_product_jsx.push(
                  <div className="package-record-wrapper">
                     <div className="package-record-line"/>
                     <div className="package-record-container">
                        <div className="package-record-upper">
                           <span>
                              <h4>{el.product_name}</h4>
                              <p>
                                 {
                                    el.complex_pricing === true ? (
                                       // Complex pricing workings
                                       <React.Fragment>
                                          <button 
                                             className="plain-text-button"
                                             onClick={() => setOperationsModal(`See pricing - ${el.product_name}`)}
                                          >Click to see pricing</button>

                                          {operationsModal === `See pricing - ${el.product_name}` ? (
                                             <div className="center-modal-backdrop">
                                                <div className="confirm-modal-container">
                                                   <h3>{el.product_name}</h3>
                                                   <h4>Pricing structure</h4>

                                                   <table className="activity-log-table">
                                                      <tbody>
                                                         <tr>
                                                            <th>#</th>
                                                            <th>Monthly</th>
                                                            <th>Annually</th>
                                                         </tr>
                                                         {Object.entries(el.cost).map(option => (
                                                            <tr>
                                                               <td>Appliance {option[0]}</td>
                                                               <td>{option[1].monthly_cost === '' ? 'N/A' : '£' + parseFloat(option[1].monthly_cost).toFixed(2)}</td>
                                                               <td>{option[1].annual_cost === '' ? 'N/A' : '£' + parseFloat(option[1].annual_cost).toFixed(2)}</td>
                                                            </tr>
                                                         ))}
                                                      </tbody>
                                                   </table>

                                                   <button className="confirm-modal-button no" onClick={() => setOperationsModal('')}>
                                                      Cancel
                                                   </button>
                                                </div>
                                             </div>
                                          ) : null}
                                       </React.Fragment>
                                    ) : (
                                       <React.Fragment>
                                          {
                                             el.payment_frequency.length > 1 ? (
                                                `£${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.monthly_cost).toFixed(2) : parseFloat(el.cost.monthly_cost).toFixed(2)} pcm or £${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.annual_cost).toFixed(2) : parseFloat(el.cost.annual_cost).toFixed(2)} annually`
                                             ) : el.payment_frequency[0] === 'monthly' ? (
                                                `£${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.monthly_cost).toFixed(2) : parseFloat(el.cost.monthly_cost).toFixed(2)} per month`
                                             ) : `£${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.annual_cost).toFixed(2) : parseFloat(el.cost.annual_cost).toFixed(2)} per year`
                                          }
                                       </React.Fragment>
                                    )
                                 }
                                 
                              </p>

                              <ul className="package-features-list">
                                 <li>{el.payment_frequency.length === 1 ? `Pay ${el.payment_frequency}` : 'Pay monthly or annually'}</li>
                                 <li>Price per {el.pricing_model}</li>
                                 <li>{el.length_of_contract} month contract</li>
                                 {
                                    el.complex_pricing ? <li>Discounted pricing available</li> : null
                                 }
                                 <li>{el.appliances.min === el.appliances.max ? el.appliances.min : `${el.appliances.min} - ${el.appliances.max}`} appliances</li>
                              </ul>
                           </span>
                           
                           <div className="button-container">
                              <button className="welcome-button green" onClick={() => chooseMultiApplianceProduct(el)}>Choose</button>
                              <button className="welcome-button primary" onClick={() => setOperationsModal(`Email quote - ${el.product_name} - multi`)}>Email&nbsp;quote</button>
                           </div>

                           {operationsModal === `Email quote - ${el.product_name} - multi` ? (
                              <div className="center-modal-backdrop">
                                 <div className="user-activity-modal-container" style={{maxWidth: 700}}>
                                    <div className='close-modal-container activity-modal' onClick={() => setOperationsModal('')}>
                                       <button className='close-modal'/>
                                    </div>

                                    {emailQuoteModal(el)}
                                 </div>
                              </div>
                           ) : null}
                        </div>
                     </div>
                  </div>
               )
            }

            jsx.push(
               <div className="package-container">
                  {other_product_jsx}
               </div>
            )
         }
      }

      return jsx
   }

   return (
      <div className="grid-container">
         <SendingLoader/>
         <button className="back-button" onClick={() => updateStage(2)}>Go back</button>
         <h2>Package</h2>

         <br/>

         {createProductFeed()}
      </div>
   )
}

export default Package