const required_fields = [
   ["partner_name", ""],

   ["legal_name", "legal"],
   ["company_number", "legal"],
   ["country_authority", "legal"],

   ["line_1", "address"],
   ["city", "address"],
   ["postcode", "address"],
   ["country", "address"],

   ["contact_name", "contact"],
   ["contact_email", "contact"],

   ["products", "products"]
]

export default required_fields