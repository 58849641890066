import React, { useEffect, useState } from "react";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";

const ManageUserPermissions = ({
   setShowScreen
}) => {
   const [permissions, setPermissions] = useState([])
   const [selectPermission, setSelectPermission] = useState('')
   const [permissionMode, setPermissionMode] = useState('view only')

   // Promise tracker loader
   const TrackerLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit_changes'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Making changes...</h3>
         </div>
      )
   }

   const [editPermission, updateEditPermission] = useState({})

   const mode_emojis = {
      'view only': '👁',
      'edit': '✏️'
   }

   const getUserPermissions = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/access/getUserPermissions'
      })
      .then((value) => {
         const response = value.data.records

         setPermissions(response)
      })
      .catch(() => {

      })
   }

   useEffect(() => {
      getUserPermissions()
   }, [])
   
   const createUserTypeList = () => {
      let jsx = []

      for (let i = 0; i < permissions.length; i++) {
         const record = permissions[i];
         
         jsx.push(
            <li className={selectPermission === i ? 'selected' : ''} onClick={() => setSelectPermission(i)}>{record.profile_name}</li>
         )
      }

      return jsx
   }

   const setEditPermission = async () => {
      if(selectPermission !== '') {
         const permission_details = permissions[selectPermission]

         let dashboard_tiles = {
            daily_vitals: {
               name: "Daily vitals",
               access_granted: false,
               scope: ""
            },
            quick_actions: {
               name: "Quick actions",
               access_granted: false,
               actions: {
                  create_new_user: false,
                  manage_users: false,
                  see_reports: false,
                  monitor_quality: false,
                  start_new_sale: false,
                  go_to_knowledge_base: false
               }
            },
            leaderboard: {
               name: "Leaderboard",
               access_granted: false,
               scope: ""
            },
            knowledge_base: {
               name: "Knowledge base",
               access_granted: false
            }
         }

         const granted_tiles = permission_details.modules.dashboard.tiles;
         for (let i = 0; i < granted_tiles.length; i++) {
            const tile = granted_tiles[i];
            
            dashboard_tiles[tile.name].access_granted = true
            if(tile.scope !== undefined) {
               dashboard_tiles[tile.name].scope = tile.scope
            }

            if(tile.name === 'quick_actions') {
               for (let ind = 0; ind < tile.actions.length; ind++) {
                  const action = tile.actions[ind];
                  dashboard_tiles.quick_actions.actions[action] = true
               }
            }
         }

         const sub_modules = permission_details.modules.admin.sub_modules
         let admin_actions = {
            security: {
               access_granted: sub_modules.security.access_granted,
               actions: {
                  manage_ip_whitelist: {
                     access_granted: false,
                     scope: ""
                  },
                  manage_timetables: {
                     access_granted: false,
                     scope: ""
                  },
                  user_permissions: {
                     access_granted: false,
                     scope: ""
                  },
                  about: {
                     access_granted: false
                  }
               }
            },
            scripts: {
               access_granted: sub_modules.scripts.access_granted,
               actions: {
                  manage_scripts: {
                     access_granted: false
                  }
               }
            },
            partners: {
               access_granted: sub_modules.partners.access_granted,
               actions: {
                  create_partners: {
                     access_granted: false
                  },
                  manage_partners: {
                     access_granted: false
                  }
               }
            },
            users: {
               access_granted: sub_modules.users.access_granted,
               actions: {
                  create_users: {
                     access_granted: false,
                     scope: ""
                  },
                  manage_users: {
                     access_granted: false,
                     scope: ""
                  }
               }
            },
            products: {
               access_granted: sub_modules.products.access_granted,
               actions: {
                  create_products: {
                     access_granted: false
                  },
                  manage_products: {
                     access_granted: false
                  }
               }
            }
         }

         const sub_modules_array = Object.entries(admin_actions)
         for (let i = 0; i < sub_modules_array.length; i++) {
            const name = sub_modules_array[i][0]

            const actions_access = Object.entries(sub_modules[name].actions)

            for (let i = 0; i < actions_access.length; i++) {
               const action = actions_access[i][1];
               
               admin_actions[name].actions[action.name].access_granted = true

               if(action.scope !== undefined) {
                  admin_actions[name].actions[action.name].scope = action.scope
               }
            }
         }

         updateEditPermission({
            ...permission_details,
            modules: {
               ...permission_details.modules,
               dashboard: {
                  ...permission_details.modules.dashboard,
                  tiles: dashboard_tiles
               },
               admin: {
                  ...permission_details.modules.admin,
                  sub_modules: admin_actions
               }
            },
            sorted: true
         })
      }

      return 
   }

   useEffect(() => {
      setPermissionMode('view only')
      setEditPermission()
   }, [selectPermission, permissions])

   const handleResetPermissionSelection = () => {
      setSelectPermission('')
      updateEditPermission({})
   }

   const handleDiscardChanges = () => {
      setPermissionMode('view only')
      setEditPermission()
   }

   const handleSubmitChanges = async () => {
      let final_object = editPermission

      // Re-format admin sub_modules
      const admin_submodules = Object.entries(editPermission.modules.admin.sub_modules)
      for (let i = 0; i < admin_submodules.length; i++) {
         const submodule = admin_submodules[i];
         const actions = Object.entries(admin_submodules[i][1].actions)
         
         let actions_array = []

         for (let ind = 0; ind < actions.length; ind++) {
            const element = actions[ind];
            
            if(element[1].access_granted === true) {
               if(element[1].scope !== undefined) {
                  actions_array.push({
                     name: element[0],
                     scope: element[1].scope
                  })
               } else {
                  actions_array.push({
                     name: element[0]
                  })
               }
            }
         }
         
         final_object.modules.admin.sub_modules[submodule[0]].actions = actions_array
      }

      // Re-format dashboard quick_actions
      const dashboard_quick_actions = Object.entries(editPermission.modules.dashboard.tiles.quick_actions.actions)
      let granted_quick_actions = []
      for (let i = 0; i < dashboard_quick_actions.length; i++) {
         const action = dashboard_quick_actions[i];
         
         if(action[1] === true) {
            granted_quick_actions.push(action[0])
         }
      }

      final_object.modules.dashboard.tiles.quick_actions.actions = granted_quick_actions

      const dashboard_tiles = Object.entries(editPermission.modules.dashboard.tiles)

      let granted_tiles = []
      for (let i = 0; i < dashboard_tiles.length; i++) {
         const element = dashboard_tiles[i];

         if(element[1].access_granted === true) {
            delete element[1].access_granted

            granted_tiles.push({
               ...element[1],
               name: element[0]
            })
         }
      }
      
      final_object.modules.dashboard.tiles = granted_tiles
      delete final_object.sorted

      
      trackPromise(
         new Promise( async (resolve, reject) => {
            await axios({
               method: 'put',
               url: 'https://api.appliancesure.com/two/admin/portal/access/editUserPermissions',
               data: final_object
            })
            .then(async (value) => {
               const response = value.data.success;
   
               if(response === true) {
                  await getUserPermissions()
                  setTimeout( async () => {
                     setPermissionMode('view only')
                     resolve("Done")
                  }, 2000)
               } else {
                  alert("There was an error, please try again")
                  resolve("Error")
               }
            })
            .catch((err) => {
               console.log(err)
               alert("There was an error, please try again")
               resolve("Error")
            })
         })
      , 'submit_changes')
   }

   // Handle changes to editPermission
   //---- DASHBOARD
   const handleStatusStandardDashboardTile = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      let update_object = {
         access_granted: val
      }

      if(editPermission.modules.dashboard.tiles[name].scope !== undefined) {
         if(val === true) {
            update_object.scope = 'global'
         } else if (val === false) {
            update_object.scope = ''
         }
      }

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            dashboard: {
               ...editPermission.modules.dashboard,
               tiles: {
                  ...editPermission.modules.dashboard.tiles,
                  [name]: {
                     ...editPermission.modules.dashboard.tiles[name],
                     ...update_object
                  }
               }
            }
         }
      })
   }

   const handleStatusActionsDashboardTile = (event) => {
      const val = event.target.checked;

      if(val === true) {
         updateEditPermission({
            ...editPermission,
            modules: {
               ...editPermission.modules,
               dashboard: {
                  ...editPermission.modules.dashboard,
                  tiles: {
                     ...editPermission.modules.dashboard.tiles,
                     quick_actions: {
                        ...editPermission.modules.dashboard.tiles.quick_actions,
                        access_granted: true
                     }
                  }
               }
            }
         })
      } else if (val === false) {
         updateEditPermission({
            ...editPermission,
            modules: {
               ...editPermission.modules,
               dashboard: {
                  ...editPermission.modules.dashboard,
                  tiles: {
                     ...editPermission.modules.dashboard.tiles,
                     quick_actions: {
                        ...editPermission.modules.dashboard.tiles.quick_actions,
                        access_granted: false,
                        actions: {
                           create_new_user: false,
                           manage_users: false,
                           see_reports: false,
                           monitor_quality: false,
                           start_new_sale: false,
                           go_to_knowledge_base: false
                        }
                     }
                  }
               }
            }
         })
      }
   }

   const handleChangeScopeDashboardTile = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            dashboard: {
               ...editPermission.modules.dashboard,
               tiles: {
                  ...editPermission.modules.dashboard.tiles,
                  [name]: {
                     ...editPermission.modules.dashboard.tiles[name],
                     scope: val
                  }
               }
            }
         }
      })
   }

   const handleChangeDashboardActionAccess = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            dashboard: {
               ...editPermission.modules.dashboard,
               tiles: {
                  ...editPermission.modules.dashboard.tiles,
                  quick_actions: {
                     ...editPermission.modules.dashboard.tiles.quick_actions,
                     actions: {
                        ...editPermission.modules.dashboard.tiles.quick_actions.actions,
                        [name]: val
                     }
                  }
               }
            }
         }
      })
   }

   //--- GENERAL
   const handleChangeModuleStatus = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            [name]: {
               ...editPermission.modules[name],
               access_granted: val
            }
         }
      })
   }

   //--- ADMIN
   const handleChangeAdminModuleStatus = (event) => {
      const val = event.target.checked;

      if(val === true) {
         updateEditPermission({
            ...editPermission,
            modules: {
               ...editPermission.modules,
               admin: {
                  ...editPermission.modules.admin,
                  access_granted: val
               }
            }
         })
      } else if (val === false) {
         updateEditPermission({
            ...editPermission,
            modules: {
               ...editPermission.modules,
               admin: {
                  access_granted: val,
                  sub_modules: {
                     security: {
                        access_granted: false,
                        actions: {
                           manage_ip_whitelist: {
                              access_granted: false,
                              scope: ""
                           },
                           manage_timetables: {
                              access_granted: false,
                              scope: ""
                           },
                           user_permissions: {
                              access_granted: false,
                              scope: ""
                           },
                           about: {
                              access_granted: false
                           }
                        }
                     },
                     scripts: {
                        access_granted: false,
                        actions: {
                           manage_scripts: {
                              access_granted: false
                           }
                        }
                     },
                     partners: {
                        access_granted: false,
                        actions: {
                           create_partners: {
                              access_granted: false
                           },
                           manage_partners: {
                              access_granted: false
                           }
                        }
                     },
                     users: {
                        access_granted: false,
                        actions: {
                           create_users: {
                              access_granted: false,
                              scope: ""
                           },
                           manage_users: {
                              access_granted: false,
                              scope: ""
                           }
                        }
                     },
                     products: {
                        access_granted: false,
                        actions: {
                           create_products: {
                              access_granted: false
                           },
                           manage_products: {
                              access_granted: false
                           }
                        }
                     }
                  }
               }
            }
         })
      }
   }

   const handleChangeAdminSubmoduleStatus = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      let update_object = {
         access_granted: val
      }

      if(val === false) {
         const actions = Object.entries(editPermission.modules.admin.sub_modules[name].actions)

         let add_to_update_object = {}
         for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            
            add_to_update_object[action[0]] = {
               access_granted: false
            }

            if(action[1].scope !== undefined) {
               add_to_update_object[action[0]].scope = ''
            }
         }

         update_object.actions = add_to_update_object
      }

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            admin: {
               ...editPermission.modules.admin,
               sub_modules: {
                  ...editPermission.modules.admin.sub_modules,
                  [name]: {
                     ...editPermission.modules.admin.sub_modules[name],
                     ...update_object
                  }
               }
            }
         }
      })
   }

   const handleChangeAdminSubmoduleActions = (event) => {
      const name = event.target.name;
      const type = event.target.type;
      let val = event.target.value;
      if(type === 'checkbox') val = event.target.checked;
      const submodule = event.target.dataset.submodule;

      let update_object = {}

      if(type === 'checkbox') {
         update_object.access_granted = val
      } else {
         if(val === '') {
            update_object = {
               access_granted: false,
               scope: ''
            }
         }  else {
            update_object = {
               access_granted: true,
               scope: val
            }
         }
      }

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            admin: {
               ...editPermission.modules.admin,
               sub_modules: {
                  ...editPermission.modules.admin.sub_modules,
                  [submodule]: {
                     ...editPermission.modules.admin.sub_modules[submodule],
                     actions: {
                        ...editPermission.modules.admin.sub_modules[submodule].actions,
                        [name]: {
                           ...editPermission.modules.admin.sub_modules[submodule].actions[name],
                           ...update_object
                        }
                     }
                  }
               }
            }
         }
      })
   }

   //--- KNOWLEDGE BASE
   const handleChangeScopeKnowledgeBase = (event) => {
      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            knowledge_base: {
               ...editPermission.modules.knowledge_base,
               permissions: event.target.value
            }
         }
      })
   }

   //--- QUALITY CONTROL
   const handleChangeQualityControlModuleStatus = (event) => {
      const val = event.target.checked;

      if(val === true) {
         updateEditPermission({
            ...editPermission,
            modules: {
               ...editPermission.modules,
               quality_control: {
                  ...editPermission.modules.quality_control,
                  access_granted: val
               }
            }
         })
      } else if (val === false) {
         updateEditPermission({
            ...editPermission,
            modules: {
               ...editPermission.modules,
               quality_control: {
                  access_granted: val,
                  actions: {
                     agent_scores: {
                        access_granted: false,
                        scope: 'individual'
                     },
                     create_scorecard: {
                        access_granted: false,
                        types: {
                           lead_source: {
                              access_granted: false
                           },
                           non_sales_call: {
                              access_granted: false
                           },
                           sales_call: {
                              access_granted: false
                           }
                        }
                     },
                     find_a_call: {
                        access_granted: false,
                        types: {
                           lead_source: {
                              access_granted: false
                           },
                           non_sales_call: {
                              access_granted: false
                           },
                           sales_call: {
                              access_granted: false
                           }
                        } 
                     },
                     manage_scorecards: {
                        access_granted: false,
                        types: {
                           lead_source: {
                              access_granted: false
                           },
                           non_sales_call: {
                              access_granted: false
                           },
                           sales_call: {
                              access_granted: false
                           }
                        } 
                     },
                     marking_queue: {
                        access_granted: false,
                        types: {
                           lead_source: {
                              access_granted: false
                           },
                           non_sales_call: {
                              access_granted: false
                           },
                           sales_call: {
                              access_granted: false
                           }
                        }
                     },
                     mark_new_call: {
                        access_granted: false,
                        types: {
                           lead_source: {
                              access_granted: false
                           },
                           non_sales_call: {
                              access_granted: false
                           },
                           sales_call: {
                              access_granted: false
                           }
                        }
                     },
                     view_reports: {
                        access_granted: false,
                        scope: "individual",
                        types: {
                           lead_source: {
                              access_granted: false
                           },
                           non_sales_call: {
                              access_granted: false
                           },
                           sales_call: {
                              access_granted: false
                           }
                        }
                     }
                  }
               }
            }
         })
      }
   }

   const handleChangeQualityControlActionStatus = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      let update_object = {
         access_granted: val
      }

      // Set all call types to access_granted = false
      if(val === false) {
         const call_types = Object.entries(editPermission.modules.quality_control.actions[name].types)

         let add_to_update_object = {}
         for (let i = 0; i < call_types.length; i++) {
            const call_type = call_types[i];
            
            add_to_update_object[call_type[0]] = {
               access_granted: false
            }
         }

         update_object.types = add_to_update_object
      }

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            quality_control: {
               ...editPermission.modules.quality_control,
               actions: {
                  ...editPermission.modules.quality_control.actions,
                  [name]: {
                     ...editPermission.modules.quality_control.actions[name],
                     ...update_object
                  }
               }
            }
         }
      })
   }

   const handleChangeQualityControlCallTypes = (event) => {
      const action = event.target.name;
      const type = event.target.dataset.calltype;
      const value = event.target.checked

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            quality_control: {
               ...editPermission.modules.quality_control,
               actions: {
                  ...editPermission.modules.quality_control.actions,
                  [action]: {
                     ...editPermission.modules.quality_control.actions[action],
                     types: {
                        ...editPermission.modules.quality_control.actions[action].types,
                        [type]: {
                           ...editPermission.modules.quality_control.actions[action].types[type],
                           access_granted: value
                        }
                     }
                  }
               }
            }
         }
      })
   }

   const handleChangeQualityControlReportsScope = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateEditPermission({
         ...editPermission,
         modules: {
            ...editPermission.modules,
            quality_control: {
               ...editPermission.modules.quality_control,
               actions: {
                  ...editPermission.modules.quality_control.actions,
                  [name]: {
                     ...editPermission.modules.quality_control.actions[name],
                     scope: val
                  }
               }
            }
         }
      })
   }

   ///------------------------------

   const showSelectedPermission = () => {
      let jsx = []

      if(selectPermission !== '') {
         if(editPermission.sorted !== undefined) {
            // Variables
            const quick_actions_array = Object.entries(editPermission.modules.dashboard.tiles.quick_actions.actions)
            const admin_submodules_array = Object.entries(editPermission.modules.admin.sub_modules)
            const quality_control_actions_array = Object.entries(editPermission.modules.quality_control.actions)

            jsx.push(
               <div className="grid-container">
                  <button className="back-button" onClick={handleResetPermissionSelection}>Close</button>
                  <br/>
                  <br/>
                  <h2>{editPermission.profile_name}</h2>
                  <p style={{marginTop: 5}}>{editPermission.description}</p>
                  <p className={`permission-mode ${permissionMode === 'edit' ? 'edit' : 'view'}`}>You are currently in {permissionMode} mode <span role="img" aria-label="jsx-a11y/accessible-emoji">{mode_emojis[permissionMode]}</span>         
                  {
                     permissionMode === 'view only' ? (
                        <button className="edit-mode-button" onClick={() => setPermissionMode('edit')}>Edit permissions</button>
                     ) : null
                  }
                  </p>
   
                  <h3>Module permissions</h3>
   
                  <br/>
   
                  <div className="table-wrapper">
                     <table className="permissions-table">
                        <tbody>
                           <tr>
                              <td><h4>Dashboard tiles</h4></td>
                           </tr>

                           <tr>
                              <td>Daily vitals</td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       name="daily_vitals"
                                       checked={editPermission.modules.dashboard.tiles.daily_vitals.access_granted}
                                       onChange={handleStatusStandardDashboardTile}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                              <td>
                                 {editPermission.modules.dashboard.tiles.daily_vitals.access_granted ? (
                                    <select
                                       className="scope-select"
                                       value={editPermission.modules.dashboard.tiles.daily_vitals.scope}
                                       disabled={permissionMode === 'view only'}
                                       name="daily_vitals"
                                       onChange={handleChangeScopeDashboardTile}
                                    >
                                       <option value="global">Global</option>
                                       <option value="local">Local</option>
                                       <option value="Individual">Individual</option>
                                    </select>
                                 ) : null}
                              </td>
                           </tr>

                           <tr>
                              <td>Quick actions</td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       checked={editPermission.modules.dashboard.tiles.quick_actions.access_granted}
                                       onChange={handleStatusActionsDashboardTile}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                              <td>
                                 {editPermission.modules.dashboard.tiles.quick_actions.access_granted ? (
                                    <React.Fragment>
                                       <p style={{margin: 0}}><strong>Actions</strong></p>
                                       {quick_actions_array.map(action => (
                                          <div className="action-select">
                                             <label htmlFor={action[0]}>{action[0].charAt(0).toUpperCase() + action[0].slice(1).replaceAll("_", " ")}</label>
                                             <input
                                                type="checkbox"
                                                disabled={permissionMode === 'view only'}
                                                checked={editPermission.modules.dashboard.tiles.quick_actions.actions[action[0]]}
                                                name={action[0]}
                                                onChange={handleChangeDashboardActionAccess}
                                             />
                                          </div>
                                       ))}
                                    </React.Fragment>
                                 ) : null}
                              </td>
                           </tr>

                           <tr>
                              <td>Leaderboard</td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       name="leaderboard"
                                       checked={editPermission.modules.dashboard.tiles.leaderboard.access_granted}
                                       onChange={handleStatusStandardDashboardTile}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                              <td>
                                 {editPermission.modules.dashboard.tiles.leaderboard.access_granted ? (
                                    <select
                                       className="scope-select"
                                       value={editPermission.modules.dashboard.tiles.leaderboard.scope}
                                       disabled={permissionMode === 'view only'}
                                       name="leaderboard"
                                       onChange={handleChangeScopeDashboardTile}
                                    >
                                       <option value="global">Global</option>
                                       <option value="local">Local</option>
                                    </select>
                                 ) : null}
                              </td>
                           </tr>

                           <tr>
                              <td>Knowledge base</td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       name="knowledge_base"
                                       checked={editPermission.modules.dashboard.tiles.knowledge_base.access_granted}
                                       onChange={handleStatusStandardDashboardTile}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                           </tr>

                           <tr>
                              <td><h4>New sale</h4></td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       checked={editPermission.modules.new_sale.access_granted}
                                       name="new_sale"
                                       onChange={handleChangeModuleStatus}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                           </tr>

                           <tr>
                              <td><h4>Admin</h4></td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       checked={editPermission.modules.admin.access_granted}
                                       onChange={handleChangeAdminModuleStatus}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                           </tr>
                           {editPermission.modules.admin.access_granted === true ? (
                              <React.Fragment>
                                 {admin_submodules_array.map(submodule => {
                                    const actions_array = Object.entries(submodule[1].actions)

                                    return (
                                       <tr>
                                          <td>{submodule[0].charAt(0).toUpperCase() + submodule[0].slice(1).replaceAll("_", " s")}</td>
                                          <td>
                                             <label className="switchbox standard">
                                                <input
                                                   id="auto-update"
                                                   type="checkbox"
                                                   disabled={permissionMode === 'view only'}
                                                   checked={editPermission.modules.admin.sub_modules[submodule[0]].access_granted}
                                                   name={submodule[0]}
                                                   onChange={handleChangeAdminSubmoduleStatus}
                                                />
                                                <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                             </label>
                                          </td>
                                          <td>
                                             {editPermission.modules.admin.sub_modules[submodule[0]].access_granted ? (
                                                <React.Fragment>
                                                   <p style={{margin: 0}}><strong>Actions</strong></p>
                                                   {actions_array.map(action => (
                                                      <div className="action-select">
                                                         <label htmlFor={action[0]}>{action[0].charAt(0).toUpperCase() + action[0].slice(1).replaceAll("_", " ")}</label>
                                                         {
                                                            editPermission.modules.admin.sub_modules[submodule[0]].actions[action[0]].scope !== undefined ? (
                                                               <select
                                                                  className="scope-select"
                                                                  value={editPermission.modules.admin.sub_modules[submodule[0]].actions[action[0]].access_granted === false ? '' : editPermission.modules.admin.sub_modules[submodule[0]].actions[action[0]].scope}
                                                                  disabled={permissionMode === 'view only'}
                                                                  name={action[0]}
                                                                  data-submodule={submodule[0]}
                                                                  onChange={handleChangeAdminSubmoduleActions}
                                                               >
                                                                  <option value="">None</option>
                                                                  {
                                                                     submodule[0] === 'users' ? (
                                                                        <option value="local">Local</option>
                                                                     ) : null
                                                                  }
                                                            
                                                                  <option value="global">Global</option>
                                                               </select>
                                                            ) : (
                                                               <input
                                                                  type="checkbox"
                                                                  style={{margin: '10px 0'}}
                                                                  disabled={permissionMode === 'view only'}
                                                                  checked={editPermission.modules.admin.sub_modules[submodule[0]].actions[action[0]].access_granted}
                                                                  name={action[0]}
                                                                  data-submodule={submodule[0]}
                                                                  onChange={handleChangeAdminSubmoduleActions}
                                                               />
                                                            )
                                                         }
                                                      </div>
                                                   ))}
                                                </React.Fragment>  
                                             ) : null}
                                          </td>
                                       </tr>
                                    )
                                 })}
                              </React.Fragment>
                           ) : null}

                           <tr>
                              <td><h4>Quality control</h4></td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       checked={editPermission.modules.quality_control.access_granted}
                                       name="quality_control"
                                       onChange={handleChangeQualityControlModuleStatus}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                           </tr>
                              
                           {
                              editPermission.modules.quality_control.access_granted ? (
                                 <React.Fragment>
                                    {quality_control_actions_array.map(action => (
                                       <tr>
                                          <td>{action[0].charAt(0).toUpperCase() + action[0].slice(1).replaceAll("_", " ")}</td>
                                          <td>
                                             <label className="switchbox standard">
                                                <input
                                                   id="auto-update"
                                                   type="checkbox"
                                                   disabled={permissionMode === 'view only'}
                                                   checked={editPermission.modules.quality_control.actions[action[0]].access_granted}
                                                   name={action[0]}
                                                   onChange={handleChangeQualityControlActionStatus}
                                                />
                                                <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                             </label>
                                          </td>
                                          <td>
                                             {editPermission.modules.quality_control.actions[action[0]].access_granted ? (
                                                <React.Fragment>
                                                   {editPermission.modules.quality_control.actions[action[0]].types !== undefined ? (
                                                      <React.Fragment>
                                                         <p style={{margin: '0 0 5px 0'}}><strong>Call types</strong></p>
                                                   
                                                         <div className="action-select">
                                                            <label>Sales calls</label>
                                                            <input
                                                               type="checkbox"
                                                               style={{margin: '5px 0'}}
                                                               checked={editPermission.modules.quality_control.actions[action[0]].types.sales_call.access_granted}
                                                               disabled={permissionMode === 'view only'}
                                                               name={action[0]}
                                                               data-calltype="sales_call"
                                                               onChange={handleChangeQualityControlCallTypes}
                                                            />
                                                         </div>

                                                         <div className="action-select">
                                                            <label>Non-sales calls</label>
                                                            <input
                                                               type="checkbox"
                                                               style={{margin: '5px 0'}}
                                                               checked={editPermission.modules.quality_control.actions[action[0]].types.non_sales_call.access_granted}
                                                               disabled={permissionMode === 'view only'}
                                                               name={action[0]}
                                                               data-calltype="non_sales_call"
                                                               onChange={handleChangeQualityControlCallTypes}
                                                            />
                                                         </div>

                                                         <div className="action-select">
                                                            <label>Lead source calls</label>
                                                            <input
                                                               type="checkbox"
                                                               style={{margin: '5px 0'}}
                                                               checked={editPermission.modules.quality_control.actions[action[0]].types.lead_source.access_granted}
                                                               disabled={permissionMode === 'view only'}
                                                               name={action[0]}
                                                               data-calltype="lead_source"
                                                               onChange={handleChangeQualityControlCallTypes}
                                                            />
                                                         </div>

                                                         <br/>
                                                      </React.Fragment>
                                                   ) : null}
                                                   

                                                   {action[1].scope !== undefined ? (
                                                      <div className="action-select">
                                                         {
                                                            action[1].types !== undefined ? (
                                                               <label style={{fontWeight: 'bold'}}>Scope</label>
                                                            ) : null
                                                         }
                                                      
                                                         <select
                                                            className="scope-select"
                                                            value={editPermission.modules.quality_control.actions[action[0]].scope}
                                                            disabled={permissionMode === 'view only'}
                                                            name={action[0]}
                                                            onChange={handleChangeQualityControlReportsScope}
                                                         >
                                                            <option value="individual">Individual</option>
                                                            <option value="local">Local</option>
                                                            <option value="global">Global</option>
                                                         </select>
                                                      </div>
                                                   ) : null}
                                                </React.Fragment>
                                             ) : null}
                                          </td>
                                       </tr>
                                    ))}
                                 </React.Fragment>
                              ) : null
                           }

                           <tr>
                              <td><h4>Knowledge base</h4></td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       checked={editPermission.modules.knowledge_base.access_granted}
                                       name="knowledge_base"
                                       onChange={handleChangeModuleStatus}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                              <tr>
                                 {editPermission.modules.dashboard.tiles.daily_vitals.access_granted ? (
                                    <select
                                       className="scope-select"
                                       value={editPermission.modules.knowledge_base.permissions}
                                       disabled={permissionMode === 'view only'}
                                       name="knowledge_base"
                                       onChange={handleChangeScopeKnowledgeBase}
                                    >
                                       <option value="read">Read only</option>
                                       <option value="edit">Read & Write</option>
                                    </select>
                                 ) : null}
                              </tr>
                           </tr>

                           <tr>
                              <td><h4>Audit trail</h4></td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       checked={editPermission.modules.audit_trail.access_granted}
                                       name="audit_trail"
                                       onChange={handleChangeModuleStatus}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                           </tr>

                           <tr>
                              <td><h4>Reporting</h4></td>
                              <td>
                                 <label className="switchbox standard">
                                    <input
                                       id="auto-update"
                                       type="checkbox"
                                       disabled={permissionMode === 'view only'}
                                       checked={editPermission.modules.reporting.access_granted}
                                       name="reporting"
                                       onChange={handleChangeModuleStatus}
                                    />
                                    <span className={`slider-switchbox standard ${permissionMode === 'view only' ? 'disabled' : ''}`}></span>
                                 </label>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>

                  {
                     permissionMode === 'edit' ? (
                        <React.Fragment>
                           <br/>
                           <br/>
                           <button
                              className="welcome-button secondary"
                              onClick={handleSubmitChanges}
                           >Save changes</button>

                           <button
                              className="welcome-button primary"
                              onClick={handleDiscardChanges}
                           >Discard changes</button>
                        </React.Fragment>
                     ) : null
                  }
               </div>
            )
         }
      }

      return jsx
   }

   return (
      <div className="screen-inner-container">
         <TrackerLoader/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Manage user permissions</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>User profile <span role="img" aria-label="jsx-a11y/accessible-emoji">👩</span></h2>

               <ul className="grid-list">
                  {createUserTypeList()}
               </ul>
            </div>

            {showSelectedPermission()}
         </div>
      </div>
   )
}

export default ManageUserPermissions