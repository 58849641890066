import React  from "react"

const KnowledgeBaseTile = () => {
   return (
      <div className="grid-container">
         <h2>Knowledge base <span role="img" aria-label="jsx-a11y/accessible-emoji">🔍</span></h2>

         <ul className="grid-list">
            <li>Benefits of appliance cover</li>
            <li>How do you make a claim?</li>
            <li>What appliances can be covered?</li>
            <li>Do you need proof of purchase?</li>
            <li>Can you get a free replacement?</li>
            <li>Who is Appliance Sure?</li>
         </ul>

         <br/>

         <button className="welcome-button secondary">See all topics</button>
      </div>
   )
}

export default KnowledgeBaseTile