import React from "react"
import FindLeadSourceCall from "./lead_source/find_a_call"
import FindSalesCall from "./sales_call/find_a_call"

const FindACall = ({
   updateStage,
   markedCallData,
   updateMarkedCallData,
   callType
}) => {
   switch (callType) {
      case "lead_source":
         return <FindLeadSourceCall
                  updateStage={updateStage}
                  markedCallData={markedCallData}
                  updateMarkedCallData={updateMarkedCallData}
               />

      case "sales_call":
         return <FindSalesCall
                  updateStage={updateStage}
                  markedCallData={markedCallData}
                  updateMarkedCallData={updateMarkedCallData}
               />
   }
}

export default FindACall