import axios from "axios";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../../../context/userAuthContext";
import Error from "../../../../components/global/error";

const FindLeadSourceCall = ({
   updateStage,
   markedCallData,
   updateMarkedCallData
}) => {
   const { user } = useUserAuth();

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'lookup'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Looking up record...</h3>
         </div>
      )
   }

   const [errors, setErrors] = useState({
      not_found: false,
      phone_number: false,
      batch_number: false
   })

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateMarkedCallData({
         ...markedCallData,
         call_data: {
            ...markedCallData.call_data,
            [name]: val
         }
      })

      setErrors({
         ...errors,
         [name]: false,
         not_found: false
      })
   }

   useEffect(() => {
      updateMarkedCallData({
         ...markedCallData,
         call_data: {
            ...markedCallData.call_data,
            phone_number: '',
            batch_number: ''
         }
      })
   }, [])

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {}

      if(markedCallData.call_data.phone_number.length < 7) {
         errors_count++
         errors_object.phone_number = true
      } 

      if(markedCallData.call_data.batch_number === '') {
         errors_count++
         errors_object.batch_number = true
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })
      }
   }

   const handleResetSearch = () => {
      updateMarkedCallData({
         ...markedCallData,
         call_data: {
            select_method: '',
            record_verified: false,
            phone_number: '',
            batch_number: ''
         }
      })
   }

   const handleLookupRecord = async (event) => {
      event.preventDefault();

      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               const endpoint_type = {
                  lead_source: "leadSource",
                  sales_call: "salesCall",
                  non_sales_call: "nonSalesCall"
               }


               await axios({
                  method: 'get',
                  url: `https://api.appliancesure.com/two/admin/portal/quality/${endpoint_type[markedCallData.call_type]}/getRecord`,
                  headers: {
                     Authorization: 'Bearer ' + user
                  },
                  params: {
                     phone_number: markedCallData.call_data.phone_number,
                     batch_number: markedCallData.call_data.batch_number
                  }
               })
               .then((value) => {
                  const response = value.data;

                  if(response.found === true) {
                     setTimeout(() => {
                        updateMarkedCallData({
                           ...markedCallData,
                           call_data: {
                              ...markedCallData.call_data,
                              record_verified: true,
                              ...response.record
                           }
                        })

                        resolve("Yes")
                     }, 1000)
                  } else {
                     setTimeout(() => {
                        setErrors({
                           ...errors,
                           not_found: true
                        })
   
                        resolve("Yes")
                     }, 1000)
                     
                  }
               })
               .catch(() => {
                  setTimeout(() => {
                     setErrors({
                        ...errors,
                        not_found: true
                     })

                     resolve("Yes")
                  }, 1000)
               })
            })
         , 'lookup')
      }
   }

   return (
      <div className="grid-container">
         <SubmitLoading/>
         {
            markedCallData.call_data.record_verified === false ? (
               <React.Fragment>
                  <button className="back-button" onClick={() => updateStage(1)}>Go back</button>
                  
                  <br/>
                  <br/>
               </React.Fragment>
            ) : null
         }

         <h2>Find a call</h2>

         <br/>

         {/* Once the file has been looked up and confirmed*/}
         {
            markedCallData.call_data.record_verified === false ? (
               <React.Fragment>
                  <label className="form-label">How will you find a call?</label>
                  <form className="select-option-grid" onChange={handleFillInForm}>
                     <span>
                        <input
                           className="select-option-trigger invisible"
                           type="radio"
                           value="random"
                           id="random"
                           name="select_method"
                           disabled
                           checked={markedCallData.call_data.select_method === 'random'}
                        />

                        <label htmlFor="random" style={{cursor: 'not-allowed'}} className="select-option">Random</label>
                     </span>

                     <span>
                        <input
                           className="select-option-trigger invisible"
                           type="radio"
                           value="manual"
                           id="manual"
                           name="select_method"
                           checked={markedCallData.call_data.select_method === 'manual'}
                        />

                        <label htmlFor="manual" className="select-option">Manual</label>
                     </span>
                  </form>
               </React.Fragment>
            ) : (
               <React.Fragment>
                  <div className="manual-address-container auto-completed">
                     <h3>Call details</h3>

                     <br/>

                     <label className="form-label" htmlFor="phone_number">Phone number</label>
                     <input
                        className="form-input"
                        id="phone_number"
                        disabled
                        value={markedCallData.call_data.phone_number}
                     />

                     <label className="form-label" htmlFor="batch_number">Batch number</label>
                     <input
                        className="form-input"
                        id="batch_number"
                        disabled
                        value={markedCallData.call_data.batch_number}
                     />

                     <label className="form-label" htmlFor="referenceID">Reference ID</label>
                     <input
                        className="form-input"
                        id="referenceID"
                        disabled
                        value={markedCallData.call_data.referenceID}
                     />

                     <label className="form-label" htmlFor="source">Data provider</label>
                     <input
                        className="form-input"
                        id="source"
                        disabled
                        value={markedCallData.call_data.source}
                     />
                  </div>

                  <br/>

                  <div className="stacked-buttons">
                     <button 
                        className="welcome-button secondary"
                        onClick={() => updateStage(3)}
                     >Continue</button>

                     <button
                        className="plain-text-button"
                        onClick={handleResetSearch}
                     >Search again</button>
                  </div>
                  
               </React.Fragment>
            )
         }

         {
            markedCallData.call_data.select_method === 'manual' && markedCallData.call_data.record_verified === false ? (
               <form onChange={handleFillInForm}>

                  <br/>

                  <label htmlFor="phone_number" className="form-label">Phone number</label>

                  <input
                     value={markedCallData.call_data.phone_number || ''}
                     className="form-input"
                     name="phone_number"
                     id="phone_number"
                     type="text"
                     placeholder="e.g. 07442348293"
                  />

                  {
                     errors.phone_number ? (
                        <Error message="Please enter a valid UK phone number"/>
                     ) : null
                  }

                  <label htmlFor="batch_number" className="form-label">Batch number</label>

                  <input
                     value={markedCallData.call_data.batch_number || ''}
                     className="form-input"
                     name="batch_number"
                     id="batch_number"
                     type="text"
                     placeholder="e.g. B3-293KD9"
                  />

                  {
                     errors.batch_number ? (
                        <Error message="Please enter the record's batch number"/>
                     ) : null
                  }

                  {
                     errors.not_found ? (
                        <p style={{marginTop: 0}} className="info-statement">This record could not be found. Please try again. (Be sure to enter the phone number starting with a 0). Remember, the call must not have not have already been marked.</p>
                     ) : null
                  }

                  <br/>

                  <button
                     className="welcome-button secondary"
                     onClick={handleLookupRecord}
                  >
                     Look up record
                  </button>
               </form>
            ) : markedCallData.call_data.select_method === 'random' && markedCallData.call_data.record_verified === false ? (
               <React.Fragment>

               </React.Fragment>
            ) : null
         }

         
         
      </div>
   )
}

export default FindLeadSourceCall