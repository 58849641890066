import React, { useEffect, useState } from "react";
import axios from "axios";
import CountUp from "react-countup";
import { useUserAuth } from "../../context/userAuthContext";
import {
   BsTelephoneInbound as Inbound,
   BsTelephoneOutbound as Outbound
} from 'react-icons/bs'

const ViewMarkingQueue = ({
   setShowScreen
}) => {
   const { user } = useUserAuth();

   // Records
   const [records, setRecords] = useState([])
   const [totalResults, setTotalResults] = useState(0)
   const [showIndex, setShowIndex] = useState([1, 0])
   const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})
   const [operationsModal, setOperationsModal] = useState('')

   const getPendingMarks = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/quality/salesCall/getMarkingQueue',
         headers: {
            Authorization: "Bearer " + user
         },
         params: {
            get_total_count: totalResults === 0 ? 'true' : 'false',
            lastEvaluatedKey: lastEvaluatedKey
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            const new_array = [...records, ...value.data.records]

            const starting_index = showIndex[1] + 1
            const end_index = new_array.length

            if(totalResults === 0) {
               setTotalResults(value.data.total_count)
            }

            setRecords(new_array)
            setLastEvaluatedKey(value.data.lastEvaluatedKey)

            setShowIndex([starting_index, end_index])
         }
      })
   }

   useEffect(() => {
      if(records.length === 0) {
         getPendingMarks()
      }
   }, [])

   const createCallLog = () => {
      let jsx = []

      records.sort((b, a) => new Date(a.timestamp) - new Date(b.timestamp))

     for (let i = showIndex[0] - 1; i < showIndex[1]; i++) {
         const el = records[i];

         const file_name_parts = el.recording.file_name.split("__")
         let phone_number = file_name_parts[1]
         if(phone_number.startsWith("+44")) {
            phone_number = "0" + phone_number.slice(3)
         } else if (phone_number.startsWith("44")) {
            phone_number = "0" + phone_number.slice(2)
         } else if (phone_number.startsWith("7") || phone_number.startsWith("1") || phone_number.startsWith("2")) {
            phone_number = "0" + phone_number
         }

         jsx.push(
            <tr>
               <td>{el.service_number}</td>
               <td>{phone_number}</td>
               <td>{el.direction === 'outbound' ? <Outbound/> : <Inbound/>}</td>
               <td>{new Date(el.timestamp).toLocaleString('en-GB')}</td>
               <td>{(el.duration / 60).toFixed(2)} minutes</td>
               <td style={{lineBreak: 'anywhere'}}>{el.call_by}</td>
            </tr>
         )
      }

      if(jsx.length === 0) {
         jsx.push(
            <p>The marking queue is currently empty. Nice! 🎉</p>
         )
      }

      return jsx
   }

   const handleNextPage = async () => {
      const starting_index = showIndex[1] + 1
      let end_index = starting_index + 9

      if(records.length < end_index && lastEvaluatedKey !== undefined) {
         await getPendingMarks();
      } else {
         if(end_index > records.length) {
            end_index = records.length
         }

         setShowIndex([starting_index, end_index])
      }
   }

   const handlePreviousPage = () => {
      let starting_index = showIndex[0] - 10;
      if (starting_index < 1) starting_index = 1
      const end_index = starting_index + 9

      setShowIndex([starting_index, end_index])
   }

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to quality control</button>
         <h2>Marking queue</h2>
      
         <br/>

         <CountUp
            start={0}
            end={totalResults}
            duration={0.5}
            className="quality-score"
            prefix="Total calls pending mark: "
         />

         <br/>
         <br/>
         <br/>

         <h3>Filters</h3>

         <br/>

         <div className="filter-options-container">
            <span>
               <label className="form-label">Call type</label>
               <select
                  className="form-input admin-row"
                  name="call_type"
                  id="call_type"
               >
                  <option value="sales_call" selected>Sales call</option>
               </select>
            </span>
         </div>

         <table className="activity-log-table">
            <tbody>
               <tr>
                  <th>Service number</th>
                  <th>Phone number</th>
                  <th>Direction</th>
                  <th>Timestamp</th>
                  <th>Duration</th>
                  <th>Call by</th>
               </tr>
               {createCallLog()}
            </tbody>
         </table>

         <div className="activity-log-counts">
            {
               showIndex[0] > 1 ? (
                  <button 
                     className="plain-text-button arrow-left"
                     onClick={handlePreviousPage}
                  >Previous page</button>
               ) : null
            }

            {
               (showIndex[1] < totalResults) || (showIndex[1] === totalResults && lastEvaluatedKey !== undefined) ? (
                  <button 
                     className="plain-text-button arrow-right"
                     onClick={handleNextPage}
                  >Next page</button>
               ) : null
            }

            <br/>
            <br/>
            <p><strong>Showing results {showIndex[0]} - {showIndex[1]}</strong></p>
            <p>Total results {totalResults}</p>
         </div>
      </div>
   )
}

export default ViewMarkingQueue