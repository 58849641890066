import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import Error from "../../components/global/error";
import axiosRetry from "axios-retry";

const Legal = ({
   saleData,
   updateSaleData,
   updateStage,
   updateScriptStage
}) => {
   // States
   const [errors, setErrors] = useState('')

   // Promise tracker
   const SubmitLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit' });

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Submitting sale...</h3>
         </div>
      )
   }

   useEffect(() => {
      updateScriptStage('5.1')
   }, [])

   const goBack = () => {
      updateStage(5)
   }

   const handleAgreeTCs = (event) => {
      const value = event.target.checked;
      updateSaleData({
         ...saleData, 
         tandc_agreement: value
      })

      if(value) {
         updateScriptStage('5.2')
      } else {
         updateScriptStage('5.1')
      }
   }

   const handleChangeMarketingOptin = (event) => {
      const val = event.target.checked

      updateSaleData({
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            marketing_optin: val
         }
      })
   }

   const submitSale = async () => {
      trackPromise(
         new Promise( async (resolve) => {
            await axios({
               method: 'post',
               url: 'https://api.appliancesure.com/one/onboarding/new/createCustomer',
               data: saleData,
               params: {
                  channel: saleData.personal_details.welcome_pack_method,
                  comms_type: 'welcome'
               }
            })
            .then((value) => {
               if(value.data.success === true) {
                  updateStage(7)
                  resolve(true)
               } else {
                  setErrors('There was an error submitting the sale, please try again')
                  resolve(false)
               }
            })
            .catch(() => {
               setErrors('There was an error submitting the sale, please try again')
               resolve(false)
            })      
         })
      , 'submit')
   }

   return (
      <div className="grid-container">
         <SubmitLoader/>
         <button className="back-button" onClick={goBack}>Go back</button>
         <h2>Legal</h2>

         <br/>
         
         <h3>Terms & conditions</h3>

         <p>I have been read the principle <a href="https://appliancesure.com/terms-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a> of this contract and agree in principle. I understand I have a 14 day cooling off period to read through these documents in more detail and cancel during this period if I no longer wish to proceed.</p>

         <div className='checkbox-form-container'>
            <input
               type="checkbox"
               className='checkbox-trigger invisible'
               name="terms_service"
               id="terms_service"
               checked={saleData.tandc_agreement}
               onChange={handleAgreeTCs}
            />
            <label htmlFor='terms_service' className="checkbox"/>

            <span style={{textAlign: 'left'}}>
               <label htmlFor='terms_service' className='form-label'>I agree to the principle terms</label>
               <span>In order to proceed, it is mandatory to gain a clear yes from the customer that they wish to proceed.</span>
            </span>
         </div>

         {
            saleData.tandc_agreement === true ? (
               <React.Fragment>
                  <br/>
                  <br/>

                  <div className='checkbox-form-container'>
                     <input
                        type="checkbox"
                        className='checkbox-trigger invisible'
                        name="marketing_optin"
                        id="marketing_optin"
                        checked={saleData.personal_details.marketing_optin}
                        onChange={handleChangeMarketingOptin}
                     />
                     <label htmlFor='marketing_optin' className="checkbox"/>

                     <span style={{textAlign: 'left'}}>
                        <label htmlFor='marketing_optin' className='form-label'>Marketing communications</label>
                        <span>Are you happy to receive communications about discounts, offers and other relevant information from Appliance Sure and its parent organisation?</span>
                     </span>
                  </div>
                  
                  <br/>
                  <br/>

                  {
                     errors !== '' ? (
                        <Error message={errors}/>
                     ) : null
                  }

                  <button 
                     className="welcome-button secondary"
                     onClick={submitSale}
                  >Submit sale</button>
               </React.Fragment>
            ) : null
         }

         
      </div>
   )  
}

export default Legal