import React, { useEffect, useState } from 'react'
import { useUserAuth } from "../context/userAuthContext";
import { useNavigate } from "react-router-dom";
import Error from '../components/global/error';
import { MdMail as Mail, MdLock as Lock } from 'react-icons/md'
import axios from 'axios';

const Login = () => {
   // Variables
   const { user } = useUserAuth();
   const navigate = useNavigate();

   // Show / hide sections
   const [showScreen, setShowScreen] = useState('initial');

   const [errors, setErrors] = useState({
      login_email: false,
      login_password: false
   })
   const [newSetupError, setNewSetupError] = useState('')

   const [loginCredentials, setLoginCredentials] = useState({
      email: '',
      password: ''
   })

   // If already logged in, redirect to home page
   useEffect(() => {
      if(!user === false) {
         navigate('/')
         window.location.reload()
         return null
      }
   }, [user])

   const handleSetSignIn = () => {
      setShowScreen('login')
      setLoginCredentials({
         email: '',
         password: ''
      })
   }

   const handleCredentialsLogIn = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      setErrors({
         ...errors,
         [`login_${name}`]: false
      })

      setNewSetupError('')

      setLoginCredentials({
         ...loginCredentials,
         [name]: val
      })
   }

   const handleLogIn = async (event) => {
      event.preventDefault();
      // Data validation
      let error_count = 0;
      let error_object = {}
      
         //--- Email address 
         if(loginCredentials.email.includes("@") === false ||
            loginCredentials.email.includes(".") === false ||
            loginCredentials.email.length < 6) {
               error_count += 1
               error_object.login_email = true
            }

         //--- Password
         if(loginCredentials.password.length < 5) {
            error_count += 1
            error_object.login_password = true
         }
      
      if(error_count === 0) {
         try {
            // Proceed to login
            await axios({
               method: 'post',
               url: 'https://api.appliancesure.com/two/admin/portal/account/login',
               data: {
                  email_address: loginCredentials.email,
                  password: loginCredentials.password
               }
            })
            .then((value) => {
               const response = value.data;

               if(response.validated === true) {
                  localStorage.setItem("user_auth", response.accessToken.access_token)
                  window.location.reload()
               } else {
                  setNewSetupError(response.reason)
               }
            })
         }

         catch (err) {
            setNewSetupError(err)
         }
      } else {
         setErrors({
            ...errors,
            ...error_object
         })
      }
   }

   return (
      <React.Fragment>
      {/* Welcome screen */}
      <div className="welcome-screen-container">
         <div className='welcome-content-container'>
            <img className='welcome-logo' alt="Appliance Sure logo" src="/images/appliance-sure-logo-white.png"/>
            <h1>Partner portal</h1>
            <br/>
            <br/>
            <br/>
            <button className='rounded-button green login' onClick={handleSetSignIn}>Log in</button>
         </div>
      </div>

      {/* Log in form */}
      <div className={`login-screen-container ${showScreen === 'login' ? 'show' : showScreen === 'initial' ? 'initial' : 'hide'}`}>
         <div className='close-login-screen close-modal-container' onClick={() => setShowScreen('')}>
            <button className='close-modal'/>
         </div>

         <img src="/images/appliance-sure-logo.png" alt="Appliance Sure dimmed logo" className='secondary-welcome-logo'/>
            <div className='login-content-container'>
               <h2>Welcome back</h2>

               <form onChange={handleCredentialsLogIn}>
                  <label htmlFor='email' className='form-label'>Email address</label>

                  <input
                     type="text"
                     className='form-input icon login'
                     placeholder="Enter your email address"
                     name="email"
                     value={loginCredentials.email}
                  />

                  <div className="form-icon login" style={{marginBottom: -35}}>
                     <Mail/>
                  </div>

                  {
                     errors.login_email ? (
                        <React.Fragment>
                           <div style={{marginTop: -50}}/>
                           <Error
                              message="Please enter a valid email address"
                           />
                        </React.Fragment>
                     ) : null
                  }


                  <label htmlFor='email' className='form-label'>Password</label>

                  <input
                     type="password"
                     className='form-input icon login'
                     placeholder="Enter your password"
                     name="password"
                     value={loginCredentials.password}
                  />

                  <div className="form-icon login">
                     <Lock/>
                  </div>

                  {
                     errors.login_password ? (
                        <React.Fragment>
                           <div style={{marginTop: -50}}/>
                           <Error
                              message="Please enter a valid password"
                           />
                        </React.Fragment>
                     ) : null
                  }
               </form>

               <button className='form-button' onClick={handleLogIn}>Log in</button>
                  { newSetupError && (
                     <React.Fragment>
                        <div style={{marginTop: 30}}/>
                        <Error
                           message={newSetupError}
                        />
                        <div style={{marginBottom: -30}}/>
                     </React.Fragment>
                  )}
            </div>
         </div>
      </React.Fragment>
   )
}

export default Login