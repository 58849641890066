import phonetic_alphabet from "../resources/phonetic_alphabet"

const replaceVariablePlaceholders = (text, saleData, scriptPackageCosts, streetName, agent) => {
   let contentToReturn = text

   // lead_appliance
   let contentToReturn1 = contentToReturn.replaceAll("{{lead_appliance}}", saleData.campaign.lead_appliance)
   
   // No longer  applicable
   let contentToReturn2 = contentToReturn1

   // chosen_package
   let contentToReturn3 = contentToReturn2.replaceAll("{{chosen_package}}", saleData.chosen_package.name)

   // #_of_appliances_in_package
   let contentToReturn4 = contentToReturn3.replaceAll("{{#_of_appliances_in_package}}", saleData.chosen_package.appliances.max)

   // phonetic_email_address
   let phonetic_email_address = '<i>* Awaiting email input *</i>'
   const email_address = saleData.personal_details.email_address
   if(email_address !== '') {
      let phonetics = ''

      const email_parts = email_address.split("@");
      if(email_parts.length > 1) {
         const letters = email_parts[0].split("")

         for (let i = 0; i < letters.length; i++) {
            const letter = letters[i].toUpperCase();
            
            phonetics += (phonetic_alphabet[letter] || letter) + " - "
         }

         phonetic_email_address = phonetics + " @ " + email_parts[1]
      }
      
   }

   let contentToReturn5 = contentToReturn4.replaceAll("{{phonetic_email_address}}", phonetic_email_address)

   // first_name
   let contentToReturn6 = contentToReturn5.replaceAll("{{first_name}}", saleData.personal_details.first_name)

   // street_name
   let contentToReturn7 = contentToReturn6.replaceAll("{{street_name}}", streetName)

   // monthly_cost
   let contentToReturn8 = contentToReturn7.replaceAll("{{monthly_cost}}", "£" + parseFloat(saleData.chosen_package.cost.monthly_cost).toFixed(2))

   // annual_cost
   let contentToReturn9 = contentToReturn8.replaceAll("{{annual_cost}}", "£" + parseFloat(saleData.chosen_package.cost.annual_cost).toFixed(2))

   // chosen_cost
   let contentToReturn10 = contentToReturn9.replaceAll("{{chosen_cost}}", "£" + parseFloat(saleData.chosen_package.chosen_cost).toFixed(2))

   // first_payment_date
   let contentToReturn11 = contentToReturn10.replaceAll("{{first_payment_date}}", new Date(saleData.bank_details.first_payment_date).toLocaleDateString("en-GB", {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'}))

   // last_name
   let contentToReturn12 = contentToReturn11.replaceAll("{{last_name}}", saleData.personal_details.last_name)

   // contact_number
   let contentToReturn13 = contentToReturn12.replaceAll("{{contact_number}}", saleData.personal_details.telephone)
   
   // postcode
   let contentToReturn14 = contentToReturn13.replaceAll("{{postcode}}", saleData.personal_details.address.postcode)

   // payment_frequency
   let contentToReturn15 = contentToReturn14.replaceAll("{{payment_frequency}}", saleData.chosen_package.chosen_frequency)

   // address_line_1
   let contentToReturn16 = contentToReturn15.replaceAll("{{address_line_1}}", saleData.personal_details.address.line_1)

   // agent_first_name
   let contentToReturn17 = contentToReturn16.replaceAll("{{agent_first_name}}", agent)

   // single_package_monthly_price
   let contentToReturn18 = contentToReturn17.replaceAll("{{single_package_monthly_price}}", "£" + parseFloat(scriptPackageCosts.single_monthly).toFixed(2))

   // single_package_monthly_price
   let contentToReturn19 = contentToReturn18.replaceAll("{{single_package_annual_price}}", "£" + parseFloat(scriptPackageCosts.single_annual).toFixed(2))

   // single_package_monthly_price
   let contentToReturn20 = contentToReturn19.replaceAll("{{platinum_package_monthly_price}}", "£" + parseFloat(scriptPackageCosts.platinum_monthly).toFixed(2))

   // single_package_monthly_price
   let contentToReturn21 = contentToReturn20.replaceAll("{{platinum_package_annual_price}}", "£" + parseFloat(scriptPackageCosts.platinum_annual).toFixed(2))

   // title
   let contentToReturn22 = contentToReturn21.replaceAll("{{title}}", saleData.personal_details.title || "Mr/Mrs")

   // guess_account_name
   let contentToReturn23 = contentToReturn22.replaceAll("{{guess_account_name}}", (saleData.personal_details.title || "Mr/Mrs") + " " + saleData.personal_details.first_name.substring(0, 1).toUpperCase() + " " + saleData.personal_details.last_name)

   // chosen_appliances
   let appliances_covered_string = ''
   const appliances_array = Object.entries(saleData.chosen_appliances.object)

   for (let i = 0; i < appliances_array.length; i++) {
      const el = appliances_array[i];
      
      if(i === 0) {
         appliances_covered_string += `${el[0]} ${el[1] > 1 ? `(x${el[1]})` : ''}`
      } else if (i === appliances_array.length - 1) {
         appliances_covered_string += ` & ${el[0]} ${el[1] > 1 ? `(x${el[1]})` : ''}`
      } else {
         appliances_covered_string += `, ${el[0]} ${el[1] > 1 ? `(x${el[1]})` : ''}`
      }
   }

   let contentToReturn24 = contentToReturn23.replaceAll("{{chosen_appliances}}", appliances_covered_string)

   return contentToReturn24
}

export default replaceVariablePlaceholders