import axios from "axios";
import React, { useEffect, useState } from "react";
import CountUp from 'react-countup'
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../../../context/userAuthContext";
import { BsInfoCircleFill as Info } from 'react-icons/bs'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css';
import Error from "../../../../components/global/error";

const MarkLeadSourceCall = ({
   updateStage,
   markedCallData,
   updateMarkedCallData
}) => {
   const [previousScore, setPreviousScore] = useState(markedCallData.score)
   const [pushRecalculation, setPushRecalculation] = useState(1)
   const [trackDuration, setTrackduration] = useState('')
   const { user } = useUserAuth();
   const [operationsModal, setOperationsModal] = useState('')
   const callRecording = process.env.REACT_APP_BLOB_ACT.replaceAll("{{FILE_PATH}}", "live/" + markedCallData.call_data.recording_file)
   const [errors, setErrors] = useState({
      missing_score: false
   })

   const LoadingScorecard = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'load_scorecard'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Loading scorecard...</h3>
         </div>
      )
   }

   useEffect(() => {
      let minor_fails = 0;
      let major_fails = 0;

      for (let i = 0; i < markedCallData.markings.length; i++) {
         const question = markedCallData.markings[i];
         
         if(question.q_score === 'fail') {
            if(question.weighting === 'major') {
               major_fails++
            } else {
               minor_fails++
            }
         }
      }

      const q_count = markedCallData.question_count;

      let score = 1 - (minor_fails / q_count)

      if(major_fails > 0) score = 0

      updateMarkedCallData({
         ...markedCallData,
         score: score * 100
      })

   }, [pushRecalculation])

   const handleChangeScore = (val, i) => {
      setPushRecalculation(pushRecalculation + 1)
      setPreviousScore(markedCallData.score)
      updateMarkedCallData({
         ...markedCallData,
         ...markedCallData.markings[i] = {
            ...markedCallData.markings[i],
            q_score: val
         }
      })
      setErrors({
         ...errors,
         [i]: false,
         missing_score: false
      })
   }
   
   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {}

      for (let i = 0; i < markedCallData.markings.length; i++) {
         const question = markedCallData.markings[i];

         if(question.q_score === undefined || question.q_score === '') {
            errors_count++
            errors_object[i] = true
         }
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object,
            missing_score: true
         })
      }
   }

   const handleSubmit = () => {
      if(handleDataValidation() === true) {
         updateMarkedCallData({
            ...markedCallData,
            performance: {
               ...markedCallData.performance,
               track_length: trackDuration / 60
            }
         })
         updateStage(4)
      }
   }

   const getScorecardQuestions = async () => {
      trackPromise(
         new Promise( async (resolve) => {
            await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/quality/getScorecardByType',
               headers: {
                  Authorization: "Bearer " + user
               },
               params: {
                  type: markedCallData.call_type
               }
            })
            .then((value) => {
               setTimeout( async () => {
                  if(value.data.success === true) {
                     updateMarkedCallData({
                        ...markedCallData,
                        markings: value.data.scorecard.questions,
                        question_count: value.data.scorecard.question_count,
                        score: 100
                     })

                     resolve("Yes")
                  } else {
                     alert(`${value.data.reason} - please refresh your page.`)
                  }
               }, 1000)
            })
            .catch(() => {
               alert(`There was an error loading the scorecard - please refresh your page.`)
            })
         })
      , 'load_scorecard')
   }

   useEffect(() => {
      if(markedCallData.markings.length === 0) {
         getScorecardQuestions();
      }
   }, [])

   const handleUpdateTrackLength = (event) => {
      setTrackduration(event.target.duration)
   }

   const generateQuestions = () => {
      const questions_array = markedCallData.markings

      let jsx = []

      if(questions_array.length > 0) {
         for (let i = 0; i < questions_array.length; i++) {
            const question = questions_array[i];
            
            jsx.push(
               <React.Fragment>
                  <tr key={question.order}>
                     <td className="left">{question.name}</td>
                     <td>
                        {
                           question.weighting === 'major' ? (
                              <p style={{color: 'red'}}><strong>M</strong></p>
                           ) : null
                        }
                     </td>
                     <td>
                        {
                           question.guidelines !== '' ? (
                              <button
                                 className="plain-text-button"
                                 style={{color: '#00c2cb', fontSize: 20}}
                                 onClick={() => setOperationsModal(`Guidelines - ${question.order}`)}
                              >
                                 <Info/>
                              </button>
                           ) : null
                        }
                        
                     </td>
                     <td>
                        <select
                           className="quality-pass-select"
                           value={markedCallData.markings[i].q_score || ''}
                           onChange={(e) => handleChangeScore(e.target.value, i)}
                           style={errors[i] === true ? { borderColor: 'red', borderWidth: 2} : {}}
                        >
                           <option value="" disabled selected>-</option>
                           <option value="pass">Pass</option>
                           <option value="fail">Fail</option>
                        </select>
                     </td>
                  </tr>

                  {
                     operationsModal === `Guidelines - ${question.order}` ? (
                        <div className="center-modal-backdrop">
                           <div className="confirm-modal-container">
                              <h4>Marking guidelines</h4>
                              <p style={{margin: 0}}>{question.guidelines}</p>

                              <button className="confirm-modal-button no" onClick={() => setOperationsModal('')}>Close</button>
                           </div>
                        </div>
                     ) : null
                  }
               </React.Fragment>
            )
         }
      }

      jsx.sort((a, b) => a.key - b.key)

      return jsx;
   }

   return (
      <React.Fragment>
         <LoadingScorecard/>

         <div className="quality-container">
            <CountUp
               start={previousScore}
               end={markedCallData.score}
               duration={0.5}
               className="quality-score"
               prefix="Score: "
               suffix="%"
            />

            <br/>
            <br/>
            <br/>

            <AudioPlayer
               src={callRecording}
               showJumpControls={false}
               autoPlay={false}
               autoPlayAfterSrcChange={false}
               customAdditionalControls={[]}
               onLoadedData={(e) => handleUpdateTrackLength(e)}
            />

            <br/>
            <br/>

            <table className="activity-log-table">
               <tbody>
                  <tr>
                     <th>Question</th>
                     <th></th>
                     <th>Guidelines</th>
                     <th>Mark</th>
                  </tr>
                  {generateQuestions()}
               </tbody>
            </table>

            {
               errors.missing_score ? (
                  <React.Fragment>
                     <br/>
                     <br/>
                     <Error message="Please ensure that all marks are filled in on the scorecard"/>
                  </React.Fragment>
               ) : null
            }

            <br/>
            <br/>

            <button
               className="welcome-button secondary"
               onClick={handleSubmit}
            >Continue</button>
         </div>
         
      </React.Fragment>
   )
}

export default MarkLeadSourceCall