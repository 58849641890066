import React from "react";
import { IoMdOpen as Open } from 'react-icons/io'
import AudioPlayer from 'react-h5-audio-player'

const LeadSourceRecord = ({
   data,
   operationsModal,
   setOperationsModal,
   i
}) => {
   const generateScorecard = () => {
      let scorecard_jsx = []

      for (let ind = 0; ind < data.markings.length; ind++) {
         const question = data.markings[ind];
         
         scorecard_jsx.push(
            <tr key={question.order * 100}>
               <td className="left">{question.name}</td>
               <td>
                  {
                     question.weighting === 'major' ? (
                        <p style={{color: 'red'}}><strong>M</strong></p>
                     ) : null
                  }
               </td>
               <td>
                  {question.q_score === 'pass' ? (
                     <p className="qscore-pass">Pass</p>
                  ) : (
                     <p className="qscore-fail">Fail</p>
                  )}
               </td>
            </tr>
         )
      }

      return scorecard_jsx
   }
   
   return (
      <tr>
         <td>{data.call_data.phone_number}</td>
         <td>{data.call_data.batch_number}</td>
         <td>{new Date(data.timestamp).toLocaleString('en-GB')}</td>
         <td>{data.submitted.by.name}<br/>{data.submitted.by.partner}</td>
         <td>{data.score.toFixed(2)}%</td>
         <td>
            <button
               className="plain-text-button"
               style={{fontSize: 20}}
               onClick={() => setOperationsModal(`Show score - ${i}`)}
            ><Open/></button>
         </td>

         {operationsModal === `Show score - ${i}` ? (
            <div className="center-modal-backdrop">
               <div className="user-activity-modal-container">
                  <div className='close-modal-container activity-modal' onClick={() => setOperationsModal('')}>
                     <button className='close-modal'/>
                  </div>

                  <h2>Scorecard</h2>
                  <p>ID: {data.score_id}</p>

                  <br/>

                  <h3>Call details</h3>
                  <div className="form-2col">
                     <table className="quality-table">
                        <tr>
                           <td>Marked by</td>
                           <td>{data.submitted.by.name}<br/>{data.submitted.by.email_address}<br/>{data.submitted.by.partner}</td>
                        </tr>
                        <tr>
                           <td>Call length</td>
                           <td>{data.performance.track_length.toFixed(2)} minutes</td>
                        </tr>
                        <tr>
                           <td>Mark start time</td>
                           <td>{new Date(data.performance.start_time).toLocaleString('en-GB')}</td>
                        </tr>
                        <tr>
                           <td>Mark end time</td>
                           <td>{new Date(data.performance.end_time).toLocaleString('en-GB')}</td>
                        </tr>
                        <tr>
                           <td>Time to mark call</td>
                           <td>{data.performance.time_to_mark.toFixed(2)} minutes</td>
                        </tr>
                     </table>
                     <table className="quality-table">
                        <tr>
                           <td>Score</td>
                           <td>{data.score.toFixed(2)}%</td>
                        </tr>
                        <tr>
                           <td>Question count</td>
                           <td>{data.question_count}</td>
                        </tr>
                        <tr>
                           <td>Passes</td>
                           <td>{data.breakdown.passes}</td>
                        </tr>
                        <tr>
                           <td>Minor fails</td>
                           <td>{data.breakdown.minor_fails}</td>
                        </tr>
                        <tr>
                           <td>Major fails</td>
                           <td>{data.breakdown.major_fails}</td>
                        </tr>
                     </table>
                     <table className="quality-table">
                        <tr>
                           <td>Call choice</td>
                           <td>{data.call_data.select_method.charAt(0).toUpperCase() + data.call_data.select_method.slice(1)}</td>
                        </tr>
                        <tr>
                           <td>Call type</td>
                           <td>{data.call_type}</td>
                        </tr>
                        <tr>
                           <td>Recording available</td>
                           <td>True</td>
                        </tr>
                        <tr>
                           <td>Record ID</td>
                           <td>{data.score_id}</td>
                        </tr>
                     </table>

                     <table className="quality-table">
                        <tr>
                           <td>Phone number</td>
                           <td>{data.call_data.phone_number}</td>
                        </tr>
                        <tr>
                           <td>Batch number</td>
                           <td>{data.call_data.batch_number}</td>
                        </tr>
                        <tr>
                           <td>Reference ID</td>
                           <td>{data.call_data.referenceID}</td>
                        </tr>
                        <tr>
                           <td>Data provider</td>
                           <td>{data.call_data.source}</td>
                        </tr>
                     </table>
                     
                  </div>

                  <br/>
                  <br/>
                  <br/>

                  <h3>Marking</h3>
                  
                  <p className="quality-score">Score: {data.score.toFixed(2)}%</p>

                  <br/>

                  <AudioPlayer
                     style={{maxWidth: 900}}
                     showJumpControls={false}
                     autoPlay={false}
                     autoPlayAfterSrcChange={false}
                     customAdditionalControls={[]}
                     src={process.env.REACT_APP_BLOB_ACT.replaceAll("{{FILE_PATH}}", "archive/" + data.call_data.recording_file)}
                  />

                  <br/>
                  <br/>
                  
                  <table className="activity-log-table" style={{maxWidth: 900}}>
                     <tbody>
                        <tr>
                           <th>Question</th>
                           <th></th>
                           <th>Mark</th>
                        </tr>
                        {generateScorecard()}
                     </tbody>
                  </table>
                  
               </div>
            </div>
         ) : null}
      </tr>
   )
}

export default LeadSourceRecord