import React, { useEffect, useState, useContext } from "react";
import { AiOutlineUserAdd as AddUser, AiOutlinePieChart as PieChart, AiOutlineUser as UserAvatar } from 'react-icons/ai'
import { BiCog as Cogs, BiCheckShield as Shield } from 'react-icons/bi'
import { TbHeadset as Headset } from 'react-icons/tb'
import { GoReport as ReportError } from 'react-icons/go'
import { MdOutlineSpaceDashboard as Home, MdOutlineLightbulb as Bulb, MdOutlineAddShoppingCart as Upgrade} from 'react-icons/md'
import { useUserAuth } from "../context/userAuthContext";
import WelcomeScreen from "./welcome";
import Admin from "./admin";
import NewSale from "./new_sale";
import Reporting from "./reporting";
import KnowledgeBase from "./knowledge";
import AuditTrail from "./audit";
import { PermContext } from "../components/global/protected-route";
import QualityControlScreen from "./quality_control";
import { useSearchParams } from "react-router-dom";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { detect } from 'detect-browser'
import { RotatingLines } from "react-loader-spinner";
import ConfirmModal from "../components/global/confirm-modal";
import axios from "axios";
import SavedSuccessfully from "../components/saved-successfully";
import NewUpgrade from "./new_upgrade";

const AppIndex = () => {
   const { user, userDetails, LogOut } = useUserAuth()
   const permissions = useContext(PermContext)

   // States
   const [showScreen, setShowScreen] = useState('dashboard')
   const [showUserOptions, setShowUserOptions] = useState(false)
   const [showAdminScreen, setShowAdminScreen] = useState('')
   const [showReportScreen, setShowReportScreen] = useState('')
   const [showQualityScreen, setShowQualityScreen] = useState('')
   const [operationsModal, setOperationsModal] = useState('')
   const [savedSuccessfully, setSavedSuccessfully] = useState('')

   const [searchParams, setSearchParams] = useSearchParams();
   const browser = detect();

   const SendingLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'reset_password'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Sending email...</h3>
         </div>
      )
   }

   const handleSendPasswordReset = () => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }
         
            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'post',
               url: "https://api.appliancesure.com/two/admin/portal/account/sendPasswordReset",
               headers: {
                  Authorization: "Bearer " + user
               },
               data: {
                  user_data: userDetails,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then( async (value) => {
               if(value.data.success === true) {
                  setTimeout(() => {
                     setSavedSuccessfully('Password reset sent successfully')
                     setOperationsModal('')
                     resolve('')
                  }, 1000)
               } else {
                  // There was an error
                  alert(value.data.reason)
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'reset_password')
   }

   const handleChangeScreen = (e) => {
      setShowScreen(e)
      setShowUserOptions(false)
   }

   useEffect(() => {
      if(searchParams.get("source") === "cnx1") {
         setShowScreen('new_sale')
      } else {
         //setShowScreen('dashboard')
      }
   }, [])

   useEffect(() => {
      if(showScreen !== 'admin') {
         setShowAdminScreen('')
      }
      
      if(showScreen !== 'quality_control') {
         setShowQualityScreen('')
      }

      if(showScreen !== 'reporting') {
         setShowReportScreen('')
      }
   }, [showScreen])

   const handleLogOut = () => {
      const confirm = window.confirm("Are you sure you want to log out?")

      if(confirm) {
         LogOut()
      } else {
         setShowUserOptions(false)
      }
   }

   const chooseScreen = () => {
      switch (showScreen) {
         case "admin":
            return <Admin
                     showAdminScreen={showAdminScreen}
                     setShowAdminScreen={setShowAdminScreen}
                     permissions={permissions.modules.admin}
                  />

         case "new_sale":
            return <NewSale/>

         case "new_upgrade":
            return <NewUpgrade/>

         case "dashboard":
            return <WelcomeScreen
                     setShowScreen={setShowScreen}
                     setShowAdminScreen={setShowAdminScreen}
                     permissions={permissions}
                  />

         case "quality_control":
            return <QualityControlScreen
                     showQualityScreen={showQualityScreen}
                     setShowQualityScreen={setShowQualityScreen}
                     permissions={permissions.modules.quality_control}
                  />

         case "reporting":
            return <Reporting
                     showReportScreen={showReportScreen}
                     setShowReportScreen={setShowReportScreen}
                  />

         case "knowledge_base":
            return <KnowledgeBase
                     permissions={permissions.modules.knowledge_base}
                  />

         case "audit_trail":
            return <AuditTrail/>

         default:
            return null
      }
   }

   // Create navigation options per permissions
   const navigationOptions = (module) => {
      switch (module) {
         case 'dashboard':
            return <li key={1} onClick={() => handleChangeScreen('dashboard')} className={showScreen === 'dashboard' ? 'selected' : ''}><div><Home/><div className="nav-option-text"> Dashboard</div></div></li>

         case 'new_sale':
            return <li key={2} onClick={() => handleChangeScreen('new_sale')} className={showScreen === 'new_sale' ? 'selected' : ''}><div><AddUser/><div className="nav-option-text"> New sale</div></div></li>

         case 'new_upgrade':
            return <li key={3} onClick={() => handleChangeScreen('new_upgrade')} className={showScreen === 'new_upgrade' ? 'selected' : ''}><div><Upgrade/><div className="nav-option-text"> New upgrade</div></div></li>

         case 'admin':
            return <li key={4} onClick={() => handleChangeScreen('admin')} className={showScreen === 'admin' ? 'selected' : ''}><div><Cogs/><div className="nav-option-text"> Admin</div></div></li>

         case 'knowledge_base':
            return <li key={5} onClick={() => handleChangeScreen('knowledge_base')} className={showScreen === 'knowledge_base' ? 'selected' : ''}><div><Bulb/><div className="nav-option-text"> Knowledge</div></div></li>

         case 'quality_control':
            return <li key={6} onClick={() => handleChangeScreen('quality_control')} className={showScreen === 'quality_control' ? 'selected' : ''}><div><Headset/><div className="nav-option-text"> Quality</div></div></li>

         case 'reporting':
            return <li key={7} onClick={() => handleChangeScreen('reporting')} className={showScreen === 'reporting' ? 'selected' : ''}><div><PieChart/><div className="nav-option-text"> Reporting</div></div></li> 

         case 'audit_trail':
            return <li key={8} onClick={() => handleChangeScreen('audit_trail')} className={showScreen === 'audit_trail' ? 'selected' : ''}><div><Shield/><div className="nav-option-text"> Audit trail</div></div></li>
         
         default:
            return null
      }
   }

   const generateNavigationOptions = () => {
      let jsx = []

      const modules = Object.entries(permissions.modules);

      for (let i = 0; i < modules.length; i++) {
         const name = modules[i][0];
         const details = modules[i][1];
         
         if(details.access_granted === true) {
            const module_jsx = navigationOptions(name)
            jsx.push(module_jsx)
         }
      }

      if(userDetails.email_address === 'elliot.waterhouse@switchboard-group.com') {
         jsx.push(navigationOptions('new_upgrade'))
      }

      jsx.sort((a, b) => a.key - b.key)

      return jsx
   }

   return (
      <React.Fragment>
         <SendingLoader/>
         <div className="primary-container">
            <div className="navigation-container">
               <div className="navigation-logo"/>

               <div className="navigation-inner-container">
                  <ul className="navigation-links">
                     {generateNavigationOptions()}
                  </ul>

                  <span>
                     {
                        showUserOptions ? (
                           <div className="navigation-option-container">
                              <button className="navigation-button" onClick={handleLogOut}>Log out</button>

                              <ul className="navigation-option-list">
                                 <li onClick={() => setOperationsModal('Reset password')}>Reset password</li>
                              </ul>
                           </div>
                        ) : null
                     }

                     <div className={`user-nav-container ${showUserOptions ? 'show' : 'hide'}`} onClick={() => setShowUserOptions(!showUserOptions)}>
                        <div className="user-nav-name">
                           <UserAvatar/>
                           <p>{userDetails.first_name}</p>
                        </div>

                     </div>
                  </span>
               </div>
            </div>

            <div className="screen-container">
               {chooseScreen()}
            </div>

            <a href={`mailto:tech.support@switchboard-group.com?subject=Technical error report: ${new Date().toLocaleString('en-GB')} || ${browser.os} ${browser.name}&body=Technical issue reported at ${new Date().toLocaleString('en-GB')} (UK)%0D%0A%0D%0ABrowser: ${browser.name}%0D%0AOperating system: ${browser.os}%0D%0AVersion: ${browser.version}%0D%0A%0D%0ALocation%0D%0AScreen: ${showScreen}%0D%0AAdmin screen: ${showAdminScreen === '' ? 'N/A' : showAdminScreen}%0D%0AQuality screen: ${showQualityScreen === '' ? 'N/A' : showQualityScreen}%0D%0AReport screen: ${showReportScreen === '' ? 'N/A' : showReportScreen}%0D%0A%0D%0A[PLEASE WRITE AN EXPLANATION OF YOUR ISSUE BELOW]%0D%0A_______________________________`} className="report-tech-issue">Report a technical issue <ReportError/></a>

            {
               operationsModal === 'Reset password' ? (
                  <ConfirmModal
                     onConfirm={handleSendPasswordReset}
                     onReject={() => setOperationsModal('')}
                     operation={`generate a password reset email`}
                  />
               ) : null
            }

            {
               savedSuccessfully !== '' ? (
                  <SavedSuccessfully
                     content={savedSuccessfully}
                     reset={setSavedSuccessfully}
                  />
               ) : null
            }
         </div>
      </React.Fragment>
   )
}

export default AppIndex