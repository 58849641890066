import axios from "axios";
import React, { useEffect } from "react";

const PresentationViewer = ({ 
   content,
   setLaunchContent,
   setEditContent,
   permissions
}) => {
   useEffect(() => {
      axios({
         method: 'put',
         url: 'https://api.appliancesure.com/two/admin/portal/knowledge/addViewCount',
         data: {
            title: content.title,
            category: content.category
         }
      })
   }, [])

   return (
      <div className="center-modal-backdrop">
         <div className="script-modal-container knowledge-content">
            <div className="close-modal-positioning-container">
               <div className='close-modal-container top-right' onClick={() => setLaunchContent('')}>
                  <button className='close-modal'/>
               </div>
            </div>
         
            <div className="presentation-container">
               <iframe title={content.title} className="presentation-viewer" src={content.url} allowFullScreen="allowfullscreen" allow="fullscreen"/>
            </div>

            <br/>

            {
               content.category === 'introductory_training' ? (
                  <p style={{marginBottom: 5}}>Introductory training - Episode {content.series_episode}</p>
               ) : null
            }
            
            <h3>{content.title}</h3>

            <p>{content.description}</p>
            <p className="disclaimer">View count: {content.views}</p>

            {
               content.category !== 'introductory_training' && permissions === 'edit' ? (
                  <button 
                     className="welcome-button secondary"
                     onClick={() => setEditContent(true)}
                  >Edit content</button>
               ) : null
            }
         </div>

         
      </div>
   )
}

export default PresentationViewer