const variables_list = [
   "Lead appliance",
   "Chosen package",
   "Chosen appliances",
   "# of appliances in package",
   "First payment date",
   "Title",
   "First name",
   "Last name",
   "Guess account name",
   "Contact number",
   "Email address",
   "Phonetic email address",
   "Postcode",
   "Street name",
   "Address line 1",
   "Payment frequency",
   "Chosen cost",
   "Monthly cost",
   "Annual cost",
   "Agent first name",
   "Single package monthly price",
   "Single package annual price",
   "Platinum package monthly price",
   "Platinum package annual price"
]

export default variables_list