import React, { useEffect, useState } from "react";
import Error from "../../components/global/error";

const CampaignType = ({
   saleData,
   updateSaleData,
   availableAppliances,
   updateStage,
   updateScriptStage
}) => {
   const [errors, setErrors] = useState({
      campaign_type: false,
      lead_appliance: false
   })

   useEffect(() => {
      updateScriptStage('')
   }, [])

   const handleChangeCampaignType = (event) => {
      const val = event.target.value;

      updateSaleData({
         ...saleData,
         campaign: {
            ...saleData.campaign,
            campaign_type: val,
            lead_appliance: ''
         }
      })

      setErrors({
         ...errors,
         campaign_type: false
      })
   }

   const handleChangeLeadAppliance = (event) => {
      const val = event.target.value;

      updateSaleData({
         ...saleData,
         campaign: {
            ...saleData.campaign,
            lead_appliance: val
         }
      })

      setErrors({
         ...errors,
         lead_appliance: false
      })
   }

   const handleDataValidation = () => {
      let error_count = 0
      let error_object = {}

      const dv = saleData.campaign

      // Campaign type
      if(dv.campaign_type === '') {
         error_count++
         error_object.campaign_type = true
      }

      if(dv.campaign_type === 'warranty_expiry' && dv.lead_appliance === '') {
         error_count++
         error_object.lead_appliance = true
      }

      if(error_count === 0) {
         return true
      } else {

         setErrors({
            ...errors,
            ...error_object
         })
         
         return false
      }
   }

   const goToNextPage = (event) => {
      event.preventDefault();

      if(handleDataValidation() === true) {
         if(saleData.campaign.campaign_type === 'warranty_expiry') {
            updateSaleData({
               ...saleData,
               chosen_appliances: {
                  object: {
                     [saleData.campaign.lead_appliance]: 1
                  },
                  array: [saleData.campaign.lead_appliance]
               }
            })
         }
         updateStage(2)
      }
   }

   return (
      <div className="grid-container">
         <h2>Campaign</h2>

         <br/>

         <label className="form-label">What kind of campaign is this sale part of?</label>
         <form className="select-option-grid" onChange={handleChangeCampaignType}>
            <span>
               <input
                  className="select-option-trigger invisible"
                  type="radio"
                  value="warranty_expiry"
                  id="warranty_expiry"
                  name="campaign_type"
                  checked={saleData.campaign.campaign_type === 'warranty_expiry'}
               />

               <label htmlFor="warranty_expiry" className="select-option">Warranty expiry</label>
            </span>

            <span>
               <input
                  className="select-option-trigger invisible"
                  type="radio"
                  value="free_choice"
                  id="free_choice"
                  name="campaign_type"
                  checked={saleData.campaign.campaign_type === 'free_choice'}
                  disabled
               />

               <label htmlFor="free_choice" className="select-option">Free choice</label>
            </span>
         </form>

         {
            errors.campaign_type ? (
               <React.Fragment>
                  <br/>
                  <Error message="Please select a campaign type" />
               </React.Fragment>
            ) : null
         }

         {
            saleData.campaign.campaign_type === 'warranty_expiry' ? (
               <form onChange={handleChangeLeadAppliance}>
                  <br/>
                  <label htmlFor="lead_appliance" className="form-label">Lead appliance</label>
                  <select
                     className="form-input"
                     name="lead_appliance"
                     id="lead_appliance"
                     value={saleData.campaign.lead_appliance}
                  >
                     <option value="" disabled>Select an appliance</option>
                     {
                        availableAppliances.map((appliance, i) => (
                           <option value={appliance.name}>{appliance.name}</option>
                        ))
                     }
                  </select>
               
                  {
                     errors.lead_appliance ? (
                        <Error message="Please select a lead appliance" />
                     ) : null
                  }
               </form>
            ) : null
         }

         <br/>

         <button 
            className="welcome-button secondary"
            onClick={goToNextPage}
         >Continue</button>
      </div>
   )
}

export default CampaignType