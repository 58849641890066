const calculatePackagePricing = (params) => {
   // Package pricing
   if(params.pricing_model === 'package') {
      if(params.payment_frequency === 'annually') {
         return parseFloat(params.pricing.annual_cost)
      } else {
         return parseFloat(params.pricing.monthly_cost)
      }
   // Appliance pricing
   } else if (params.pricing_model === 'appliance') {
      // Complex pricing
      if(params.complex_pricing === true) {
         let price = 0

         for (let i = 0; i < params.appliance_count; i++) {
            const index = i + 1

            if(params.payment_frequency === 'annually') {
               price += parseFloat(params.pricing[index].annual_cost)
            } else {
               price += parseFloat(params.pricing[index].monthly_cost)
            }
         }

         return price
      // Simple pricing
      } else {
         if(params.payment_frequency === 'annually') {
            return params.pricing.per_appliance.annual_cost * params.appliance_count
         } else {
            return params.pricing.per_appliance.monthly_cost * params.appliance_count
         }
      }
   }
}

export default calculatePackagePricing