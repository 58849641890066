import React, { useState, useEffect } from "react";
import Error from "../../components/global/error";

const Appliances = ({
   saleData,
   updateSaleData,
   availableAppliances,
   updateStage,
   updateScriptStage
}) => {
   // States
   const [chooseAppliances, setChooseAppliances] = useState('')
   const [errors, setErrors] = useState(false)

   // Variables
   const no_of_appliances = saleData.chosen_package.appliances.min === saleData.chosen_package.appliances.max ? saleData.chosen_package.appliances.min : `${saleData.chosen_package.appliances.min} - ${saleData.chosen_package.appliances.max}`

   useEffect(() => {
      let appliance_choice_object = {}
      let existing_choices_array = Object.entries(saleData.chosen_appliances.object)

      for (let i = 0; i < availableAppliances.length; i++) {
         const el = availableAppliances[i];
         
         appliance_choice_object[el.name] = 0
      }

      for (let i = 0; i < existing_choices_array.length; i++) {
         const el = existing_choices_array[i];
         
         appliance_choice_object[el[0]] = el[1]
      }

      setChooseAppliances(appliance_choice_object)

      if(saleData.campaign.campaign_type === 'warranty_expiry') {
         updateScriptStage('2.3')
      } else {
         if(saleData.chosen_package.appliances.max === 1) {
            updateScriptStage("2.1")
         } else {
            updateScriptStage("2.2")
         }
      }
   }, [])

   useEffect(() => {
      const rawArray = Object.entries(chooseAppliances)

      if(rawArray.length > 0) {
         let appliancesArray = []
         let appliancesObject = {}
   
         for (let i = 0; i < rawArray.length; i++) {
            const el = rawArray[i];
   
            if(el[1] > 0) {
               appliancesObject[el[0]] = el[1]
   
               for (let ind = 0; ind < el[1]; ind++) {
                  if(ind === 0) {
                     appliancesArray.push(el[0])
                  } else {
                     appliancesArray.push(`${el[0]} ${ind+1}`)
                  }
               }
            }
         }

         // Work out pricing
         let worked_pricing = {
            monthly_cost: 0,
            annual_cost: 0
         }

         if(saleData.chosen_package.complex_pricing === true) {
            const pricing = saleData.chosen_package.cost;
            
            for (let i = 0; i < appliancesArray.length; i++) {
               if(saleData.chosen_package.payment_frequency.includes("monthly")) {
                  worked_pricing.monthly_cost += parseFloat(pricing[i+1].monthly_cost)
               }

               if(saleData.chosen_package.payment_frequency.includes("annually")) {
                  worked_pricing.annual_cost += parseFloat(pricing[i+1].annual_cost)
               }
            }
         } else if (saleData.chosen_package.pricing_model === 'package') {
            const pricing = saleData.chosen_package.cost;
            
            worked_pricing.monthly_cost = parseFloat(pricing.monthly_cost)
            worked_pricing.annual_cost = parseFloat(pricing.annual_cost)
         } else {
            const pricing = saleData.chosen_package.cost.per_appliance;

            worked_pricing = {
               monthly_cost: appliancesArray.length * parseFloat(pricing.monthly_cost),
               annual_cost: appliancesArray.length * parseFloat(pricing.annual_cost)
            }
         }
   
         updateSaleData({
            ...saleData,
            chosen_appliances: {
               object: appliancesObject,
               array: appliancesArray
            },
            chosen_package: {
               ...saleData.chosen_package,
               cost: {
                  ...saleData.chosen_package.cost,
                  ...worked_pricing
               }
            }
         })
      }
   }, [chooseAppliances])

   const handleChangeApplianceQty = (e, max) => {
      e.preventDefault();
      const type = e.target.value;
      const name = e.target.name;

      if(type === 'increase') {
         if(chooseAppliances[name] < max && saleData.chosen_appliances.array.length < saleData.chosen_package.appliances.max) {
            setChooseAppliances({
               ...chooseAppliances,
               [name]: chooseAppliances[name]+1
            })
         }
      } else if (type === 'decrease') {
         if(chooseAppliances[name] >= 1) {
            setChooseAppliances({
               ...chooseAppliances,
               [name]: chooseAppliances[name]-1
            })
         }
      }

      setErrors(false)
   }

   const goBack = () => {
      updateSaleData({
         ...saleData,
         chosen_appliances: {
            object: {},
            array: []
         }
      })

      updateStage(2)
   }

   const goToNextPage = () => {
      const app_count = saleData.chosen_appliances.array.length;
      if(app_count >= saleData.chosen_package.appliances.min && app_count <= saleData.chosen_package.appliances.max) {
         updateStage(4)
      } else {
         setErrors(true)
      }
   }

   const createApplianceFeed = () => {
      let jsx = []

      let sort_az_appliances = {}
         if(chooseAppliances !== '') {
         // Most popular
         let popular_appliances_jsx = []     
         for (let i = 0; i < availableAppliances.length; i++) {
            const el = availableAppliances[i];
            
            if(el.most_popular === true) {
               popular_appliances_jsx.push(
                  <div className="appliance-most-popular">
                     <img className="most-popular-image" alt={el.name} src={`/images/appliances/${el.code_name}.png`}/>
                     
                     <h4>{el.name}</h4>

                     <div className="incdec-appliance on">
                        <button 
                              className="decrease-app"
                              value="decrease"
                              name={el.name}
                              onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                        />

                        <p>{chooseAppliances[el.name]}</p>
                        <button 
                              className="increase-app"
                              value="increase"
                              name={el.name}
                              onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                        />
                     </div>
                  </div>
               )
            }
         }

         jsx.push(
            <React.Fragment>
               <input
                  className="invisible accordion-trigger"
                  type="checkbox"
                  name="popular"
                  data-type="section"
                  id="popular"
                  defaultChecked={true}
               />

               <label htmlFor="popular" className="accordion-label">
                  <h3>Most popular appliances</h3>
               </label>
               
               <div className="form-section-container">
                  <div className="popular-appliance-container">
                     {popular_appliances_jsx}
                  </div>
               </div>
               
               <br/>
            </React.Fragment>
            
         )

         for (let i = 0; i < availableAppliances.length; i++) {
            const el = availableAppliances[i];
            const initial = el.name.substring(0, 1)

            if(!sort_az_appliances[initial]) {
               sort_az_appliances[initial] = []
            }

            sort_az_appliances[initial].push(el)
            sort_az_appliances[initial].sort()
         }

         const appliances_array = Object.entries(sort_az_appliances)
         
         for (let i = 0; i < appliances_array.length; i++) {
            const letter = appliances_array[i];
            const letter_appliances = letter[1];
            
            jsx.push(
               <React.Fragment>
                  <br/>
                  <h3>{letter[0]}</h3>
               </React.Fragment>
            )

            for (let ind = 0; ind < letter_appliances.length; ind++) {
               const el = letter_appliances[ind];
               
               jsx.push(
                  <div className="appliance-choice-container">
                     <p>{el.name}</p>

                     {
                        chooseAppliances[el.name] > 0 ? (
                           <div className="incdec-appliance on">
                              <button 
                                    className="decrease-app"
                                    value="decrease"
                                    name={el.name}
                                    onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                              />

                              <p>{chooseAppliances[el.name]}</p>
                              <button 
                                    className="increase-app"
                                    value="increase"
                                    name={el.name}
                                    onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                              />
                           </div>
                        ) : (
                           <button
                              className="add-appliance"
                              onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                              value="increase"
                              name={el.name}
                           />
                        )
                     }
                  </div>
               )
            }
         }
      }

      return jsx;
   }

   return (
      <div className="grid-container">
         <button className="back-button" onClick={goBack}>Go back</button>
         <h2>Appliances</h2>

         <p>You've chosen the <strong>{saleData.chosen_package.name}</strong>. This comes with {no_of_appliances} {no_of_appliances === 1 ? 'appliance' : 'appliances'}, please select which {no_of_appliances === 1 ? 'appliance' : 'appliances'} you would like to cover:</p>

         <p className="appliance-counter">{saleData.chosen_appliances.array.length} / {saleData.chosen_package.appliances.max} appliances selected</p>

         <hr/>
         <br/>

         {createApplianceFeed()}

         <br/>

         {
            errors ? (
               <React.Fragment>
                  <br/>
                  <Error message={`You must select ${no_of_appliances} appliances. You have selected ${saleData.chosen_appliances.array.length}.`}/>
               </React.Fragment>
            ) : null
         }

         <button 
            className="welcome-button secondary"
            onClick={goToNextPage}
         >Continue</button>
      </div>
   )
}

export default Appliances