import React, { useEffect, useState } from "react"
import axios from "axios";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import { useUserAuth } from "../../context/userAuthContext";
import list_of_months from "../quality_control_screens/resources/list_of_months";
import { FaCircle as Circle } from 'react-icons/fa'
import { HiOutlineMinusSm as Minus } from 'react-icons/hi'
import { GoPlus as Plus } from 'react-icons/go'
import { LineChart, ComposedChart, BarChart, PieChart, Cell, Pie, Bar, Line, XAxis, ResponsiveContainer, YAxis, Tooltip, CartesianGrid, ReferenceLine, Label, Legend } from 'recharts'
import CountUp from 'react-countup'

const TeamKPIPerformance = ({
   permissions,
   setShowScreen
}) => {
   // Variables
   const daily_target = 4
   const daily_appliance_target = 5

   const { user, userDetails } = useUserAuth();
   const [stats, setStats] = useState('')
 
   // Data
   const [partnerList, setPartnerList] = useState([])
 
   // Filters
   const [timeScopeFilter, setTimeScopeFilter] = useState('today')
   const [monthFilter, setMonthFilter] = useState('')
   const [dateFilter, setDateFilter] = useState({
      from: new Date().toISOString().substring(0, 10),
      to: new Date().toISOString().substring(0, 10)
   })
   const [partnerFilter, setPartnerFilter] = useState('')
   const [leaderboardSortBy, setLeaderboardSortBy] = useState('sales')
   
   const [extraLeaderboardDetails, setExtraLeaderboardDetails] = useState('')

   // Get month options (last 3 months)
   const today = new Date()
   let monthOptions = []

   monthOptions.push(
      new Date().toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7),
      new Date(today.setMonth(today.getMonth() - 1)).toISOString().substring(0, 7)
   )

   ///--------------------------

   const DataLoadingIcon = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

      return (
         promiseInProgress &&
         <React.Fragment>
            <ThreeDots type="rotatingLines" color="#03989e" height={80} width={80}/>
         </React.Fragment>
      )
   }

   const getPartnerList = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/partners/getPartnerList',
         params: {
            limit_data: true
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            let partners = []

            for (let i = 0; i < value.data.records.length; i++) {
               const partner = value.data.records[i];
               
               partners.push(partner.partner_name)
            }

            setPartnerList(partners)
         }
      })
      .catch(() => {
         // Do nothing
      })
   }

   useEffect(() => {
      if(permissions === 'global') {
         getPartnerList()
      } else {
         setPartnerList([userDetails.partner])
         setPartnerFilter(userDetails.partner)
      }
   }, [])


   const getDashboardStats = async () => {
      trackPromise(
         new Promise( async (resolve) => {
            await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/reporting/teamKPIPerformance',
               headers: {
                  Authorization: "Bearer " + user
               },
               params: {
                  partner: partnerFilter,
                  date_range: timeScopeFilter === 'month_select' ? { month: monthFilter.substring(0, 7) } : dateFilter
               }
            })
            .then((value) => {
               setTimeout(() => {
                  if(value.data.success === true) {
                     setStats({
                        ...value.data.data,
                        timeScope: timeScopeFilter
                     })
                  } else {
                     setStats("No data")
                  }

                  resolve("Yes")
               }, 0)
            })
            .catch(() => {
               alert("There was an error getting the requested data, please try again")
               resolve("Yes")
            })
         })
      , 'data_load')
   }

   useEffect(() => {
      if(partnerFilter !== '' && timeScopeFilter !== '') {
         if(timeScopeFilter === 'date_select' && dateFilter === '') {
            return
         } else if (timeScopeFilter === 'month_select' && monthFilter === '') {
            return
         } else {
           getDashboardStats()
         }
      }
   }, [partnerFilter, monthFilter, dateFilter, timeScopeFilter])

   const changeTimeScopeFilter = (event) => {
      const val = event.target.value;

      setTimeScopeFilter(val)

      let date = new Date()
      if(val === 'today') {
         setDateFilter({
            from: date.toISOString().substring(0, 10),
            to: date.toISOString().substring(0, 10)
         })
      } else if (val === 'yesterday') {
         date.setDate(date.getDate() - 1)
         setDateFilter({
            from: date.toISOString().substring(0, 10),
            to: date.toISOString().substring(0, 10)
         })
      } else if (val === 'last_7_days') {
         const today = date.toISOString().substring(0, 10)
         date.setDate(date.getDate() - 6)
         setDateFilter({
            from: date.toISOString().substring(0, 10),
            to: today
         })
      } else if (val === 'month_select') {
         setMonthFilter('')
      } else if (val === 'date_select') {
         setDateFilter('')
      }
   }

   const setShowExtraLeaderboard = (email) => {
      if(extraLeaderboardDetails === email) {
         setExtraLeaderboardDetails('')
      } else {
         setExtraLeaderboardDetails(email)
      }
   }

   

   //---------------------------------------
   //----- JSX GENERATION FOR TILES ------//
   //---------------------------------------

   //--- Tile #1 | Total sales
   const generateTotalSalesTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>
               <center>
                  <CountUp
                     start={0}
                     end={stats.total_sales.sales}
                     duration={0.5}
                     className="quality-score"
                     style={{fontSize: 70}}
                  />
               </center>

               <div className="table-wrapper" style={{marginTop: 10}}>
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Total team KPI</td>
                           <td>{stats.total_sales.total_team_kpi}</td>
                        </tr>
                        <tr>
                           <td>Daily sales per agent</td>
                           <td>{stats.total_sales.average_per_agent}</td>
                        </tr>
                        <tr>
                           <td>KPI achievement rate</td>
                           <td>{stats.total_sales.kpi_achievement_rate}%</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx;
   }

   //--- Tile #2 | Performance over time
   const generatePerformanceOverTimeTile = () => {
      let jsx = []

      const CustomLabel = ({ active, payload, label }) => {
         if(active && payload && payload.length) {

            return (
               <div className="custom-chart-label">
                  <p><span style={{color: '#03989e'}}>{payload[0].payload.sales} sales</span><br/><br/>
                  {payload[0].payload.calls} calls</p>
                  <p>{label}</p>
               </div>
            )
         }
      }

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <div className="daily-scores-high-low">
                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">Sales made</p>
                     <p className="quality-comparison">{stats.total_sales.sales}</p>
                  </span>

                  <span>
                     <p style={{marginBottom: 0}} className="quality-comparison-sub-heading">Leads connected</p>
                     <p className="quality-comparison">{stats.total_calls}</p>
                  </span>
               </div>

               <div className="chart-container">
                  <ResponsiveContainer height={150} width="100%">
                     <ComposedChart data={stats.sales_calls_over_time} margin={{ top: 10, right: -20, left: -20, bottom: 0 }}>
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey="interval"/>
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Bar dataKey="sales" fill="#03989e" maxBarSize={20} yAxisId="left"/>
                        <Line dataKey="calls" yAxisId="right" />
                        <Tooltip content={<CustomLabel/>} cursor={false}/>
                     </ComposedChart>
                  </ResponsiveContainer>
               </div>
            </React.Fragment>
         )
      }


      return jsx
   }

   //--- Tile #3 | Conversion rate
   const generateConversionRateTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>
               <center>
                  <CountUp
                     start={0}
                     end={stats.conversion_rate.conversion}
                     decimals={2}
                     duration={0.5}
                     className="quality-score"
                     style={{fontSize: 50}}
                     suffix="%"
                  />
               </center>

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Sales made</td>
                           <td>{stats.total_sales.sales}</td>
                        </tr>
                        <tr>
                           <td>Answered calls</td>
                           <td>{stats.conversion_rate.call_count}</td>
                        </tr>
                        <tr>
                           <td>Unanswered calls</td>
                           <td>{stats.total_calls - stats.conversion_rate.call_count}</td>
                        </tr>
                        <tr>
                           <td>Answer rate</td>
                           <td>{((stats.conversion_rate.call_count / stats.total_calls) * 100).toFixed(2)}%</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }
   

   //--- Tile #4 | Leaderboard
   const generateLeaderboardTile = () => {
      let jsx = []

      if(stats !== '') {
         let leaderboard_rows = []
         const single_day = timeScopeFilter !== "month_select" && timeScopeFilter !== "last_7_days"

         for (let index = 0; index < stats.leaderboard.length; index++) {
            const record = stats.leaderboard[index];

            // Daily breakdown
            let daily_breakdown = []
            for (let ind = 0; ind < record.daily_breakdown.length; ind++) {
               const day = record.daily_breakdown[ind];
               
               daily_breakdown.push(
                  <tr date={day.date} className={`leaderboard-extra-row ${extraLeaderboardDetails === record.agent_email ? 'show' : 'hide'}`}>
                     <td>{new Date(day.date).toLocaleDateString('en-GB')}</td>
                     <td>{day.sales}</td>
                     <td>{day.appliances}</td>
                     <td>{((day.sales / day.calls) * 100).toFixed(2)}%</td>
                     <td>{day.calls}</td>
                     <td></td>
                  </tr>
               )
            }

            daily_breakdown.sort((b, a) => new Date(a.props.date) - new Date(b.props.date))

            // Leaderboard entry
            leaderboard_rows.push(
               <React.Fragment key={record[leaderboardSortBy]}>
                  <tr onClick={() => {
                        if(single_day === false) {
                           setShowExtraLeaderboard(record.agent_email)
                        }
                     }} style={{cursor: single_day ? 'default' : 'pointer'}}>
                     <td>{record.agent_name}</td>
                     <td>{record.sales}</td>
                     <td>{record.appliances}</td>
                     <td>{((record.sales / record.calls) * 100).toFixed(2)}%</td>
                     <td>{record.calls}</td>
                     <td>
                        {
                           single_day ? null : (
                              <React.Fragment>
                                 { extraLeaderboardDetails === record.agent_email ? <Minus/> : <Plus/> }
                              </React.Fragment>
                           )
                        } 
                     </td>
                  </tr>

                  {daily_breakdown}
               </React.Fragment>
            )
         }

         leaderboard_rows.sort((a, b) => b.key - a.key)

         jsx.push(
            <React.Fragment>
               <br/>

               <div className="activity-log-counts" style={{marginBottom: 15}}>
                  <label htmlFor="filter" className="form-label" style={{textAlign: 'right', marginBottom: 5}}>Filter by</label>
                  <select
                     id="filter"
                     className="form-input"
                     style={{width: 120, marginBottom: 5}}
                     value={leaderboardSortBy}
                     onChange={(e) => setLeaderboardSortBy(e.target.value)}
                  >
                     <option value="sales">Sales</option>
                     <option value="appliances">Appliances</option>
                     <option value="calls">Calls</option>
                  </select>
               </div>

               <div className="table-wrapper">
                  <table className="activity-log-table">
                     <tbody>
                        <tr>
                           <th>Name</th>
                           <th>Sales</th>
                           <th>Appliances</th>
                           <th>Conversion</th>
                           <th>Calls</th>
                           <th></th>
                        </tr>
                        {leaderboard_rows}
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #5 | Product breakdown
   const generateProductBreakdownTile = () => {
      let jsx = []

      if(stats !== '') {
         const colors = ["#03989e", "#00c2cb", "#7AC200", "#4caf50", '#424242']

         jsx.push(
            <React.Fragment>
               <br/>

               {
                  stats.total_sales === 0 ? (
                     <p style={{marginTop: -10}}>No data to display</p>
                  ) : null
               }

               {/* Pie chart */}
               <div className="chart-container" style={{marginTop: 10}}>
                  <ResponsiveContainer height={200} width="100%">
                     <PieChart width={200} height={200} margin={{ top: -30, right: 0, left: 0, bottom: 0 }}>
                        <Pie
                           dataKey="count"
                           data={stats.product_breakdown.product_count}
                           outerRadius={80}
                           isAnimationActive={true}
                           fill
                        >
                           {stats.product_breakdown.product_count.map((entry, index) => (
                              <Cell key={`percentage-cell-${index}`} fill={colors[index]}/>
                           ))}
                        </Pie>
                     </PieChart>
                  </ResponsiveContainer>
               </div>

               

               {
                  stats.product_breakdown.product_count.map((item, index) => (
                     <React.Fragment>
                     
                        <h5 style={{margin: 0}}><Circle style={{color: colors[index]}}/> {item.product}</h5>
                        <p style={{marginTop: 5}} className="disclaimer">Count: {item.count} ({((item.count / stats.total_sales.sales) * 100).toFixed(2)}%)</p>
                     
                     </React.Fragment>
                  ))
               }

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <th colSpan={2} style={{color: colors[0]}}>Appliances</th>
                        </tr>

                        {
                           stats.total_sales === 0 ? (
                              <p>No data to display</p>
                           ) : null
                        }

                        {
                           stats.product_breakdown.appliance_count.map(item => (
                              <tr>
                                 <td>{item.appliance}</td>
                                 <td>{item.count}</td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #6 | Appliance count
   const generateApplianceCountTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>
               <center>
                  <CountUp
                     start={0}
                     end={stats.appliance_count}
                     duration={0.5}
                     className="quality-score"
                     style={{fontSize: 50}}
                  />
               </center>

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Products</td>
                           <td>{stats.total_sales.sales}</td>
                        </tr>
                        <tr>
                           <td>Appliances</td>
                           <td>{stats.appliance_count}</td>
                        </tr>
                        <tr>
                           <td>Target</td>
                           <td>{(stats.total_sales.total_team_kpi / 4) * 5}</td>
                        </tr>
                        <tr>
                           <td>Appliances per product</td>
                           <td>{stats.appliance_count > 0 ? (stats.appliance_count / stats.total_sales.sales).toFixed(2) : 0}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #7 | Go live rate
   const generateGoLiveRateTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>
               <center>
                  <CountUp
                     start={0}
                     end={stats.go_live_rate.go_live_rate}
                     duration={0.5}
                     decimals={2}
                     className="quality-score"
                     style={{fontSize: 50}}
                     suffix="%"
                  />
               </center>

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Active sales</td>
                           <td>{stats.go_live_rate.active_sales}</td>
                        </tr>
                        <tr>
                           <td>Live sales</td>
                           <td>{stats.go_live_rate.live_sales}</td>
                        </tr>
                        <tr>
                           <td>Pending sales</td>
                           <td>{stats.go_live_rate.pending_sales}</td>
                        </tr>
                        <tr>
                           <td>Cancelled sales</td>
                           <td>{stats.go_live_rate.lapsed_sales}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }


   const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to reporting</button>
         <h2>Team KPI Performance</h2>

         <br/>
         <br/>

         {
            stats !== '' ? (
               <button disabled={promiseInProgress} style={{marginBottom: 10}} onClick={getDashboardStats} className="plain-text-button refresh">Refresh data</button> 
            ) : null
         }
         <div className="qa-dashboard-grid-container">
            {/* FILTER BAR */}
            <div className="qa-dashboard-filter-bar" id="bar">
               {/* Left hand side of bar */}
               <ul className="filter-bar-list left">
                  <li>
                     {/* Company filter */}
                     <select
                        className="qa-dashboard-filter-select"
                        value={partnerFilter}
                        onChange={(e) => setPartnerFilter(e.target.value)}
                        disabled={promiseInProgress}
                     >
                        <option value="" selected disabled>Choose a company</option>
                        {
                           partnerList.map(partner => (
                              <option value={partner}>{partner}</option>
                           ))
                        }
                     </select>
                  </li>
               </ul>

               {/* Right hand side of bar */}
               <ul className="filter-bar-list right">
                  <li>
                     <select 
                        className="qa-dashboard-filter-select"
                        value={timeScopeFilter}
                        onChange={changeTimeScopeFilter}
                        disabled={promiseInProgress}
                     >
                        <option value="" disabled>Select a time scope</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="last_7_days">Last 7 days</option>
                        <option value="date_select">Date select</option>
                        <option value="month_select">Month select</option>
                     </select>
                  </li>

                  {
                     timeScopeFilter === 'date_select' ? (
                        <li>
                           <input
                              type="date"
                              className="qa-dashboard-filter-select"
                              value={dateFilter.from}
                              onChange={(e) => setDateFilter({ from: e.target.value, to: e.target.value })}
                              disabled={promiseInProgress}
                           />
                        </li>
                     ) : null
                  }

                  {
                     timeScopeFilter === 'month_select' ? (
                        <li>
                           <select
                              className="qa-dashboard-filter-select"
                              value={monthFilter}
                              onChange={(e) => setMonthFilter( e.target.value )}
                              disabled={promiseInProgress}
                           >
                              <option value="" selected disabled>Select a month</option>
                              {monthOptions.map(option => (
                                 <option value={option}>
                                    {list_of_months[parseInt(option.split("-")[1]) - 1]} {option.split("-")[0]}
                                 </option>
                              ))}
                           </select>
                        </li>
                     ) : null
                  }
               </ul>
            </div>

            {/*----------------------------------------
            --------------- TILE JSX ------------------
            ----------------------------------------*/}

            {/* Total sales */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile1">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Sales KPI</h4>

                        {generateTotalSalesTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Sales / calls over time */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile2">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Performance over time</h4>

                        {generatePerformanceOverTimeTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Conversion rate */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile3">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Conversion rate</h4>

                        {generateConversionRateTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Leaderboard */}
            <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile4">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Leaderboard</h4>

                        {generateLeaderboardTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Product breakdown */}
            <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile5">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Product breakdown</h4>

                        {generateProductBreakdownTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Number of appliances */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile6">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Appliance count</h4>
                        
                        {generateApplianceCountTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Go live rate*/}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile7">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Go live rate</h4>

                        {generateGoLiveRateTile()}
                     </React.Fragment>
                  )
               }
            </div>
         </div>
      </div>
   )
}

export default TeamKPIPerformance