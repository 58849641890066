import axios from "axios";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { IoMdOpen as Open } from 'react-icons/io'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css';
import LeadSourceRecord from "./report_components/lead_source_record";
import SalesCallRecord from "./report_components/sales_call_record";

const ViewQualityReports = ({
   setShowScreen,
   permissions
}) => {
   const call_types_array = Object.entries(permissions.types)
   const allowed_call_types = []
   for (let i = 0; i < call_types_array.length; i++) {
      const el = call_types_array[i];
      
      if(el[1].access_granted === true) {
         allowed_call_types.push(el[0])
      }
   }

   const { user, userDetails } = useUserAuth();

   // States
   const [records, setRecords] = useState([])
   const [totalResults, setTotalResults] = useState(0)
   const [showIndex, setShowIndex] = useState([1, 0])
   const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})
   const [operationsModal, setOperationsModal] = useState('')
   const [partnerList, setPartnerList] = useState([])
   const [agentList, setAgentList] = useState([])

   // Filters
   const [categoryFilter, setCategoryFilter] = useState('sales_call'/*allowed_call_types[0]*/)
   const [dateFilter, setDateFilter] = useState(new Date().toISOString().substring(0, 10))
   const [partnerFilter, setPartnerFilter] = useState(permissions.scope === 'global' ? '' : userDetails.partner)
   const [agentFilter, setAgentFilter] = useState(permissions.scope === 'individual' ? userDetails.email_address : '')

   const getQualityScores = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/quality/getScoresByType',
         headers: {
            Authorization: "Bearer " + user
         },
         params: {
            type: categoryFilter,
            lastEvaluatedKey: lastEvaluatedKey,
            date: dateFilter,
            partner: partnerFilter,
            agent: agentFilter
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            const new_array = [...records, ...value.data.records]

            const starting_index = showIndex[1] + 1
            const end_index = new_array.length

            setRecords(new_array)
            setTotalResults(new_array.length)
            setLastEvaluatedKey(value.data.lastEvaluatedKey)

            setShowIndex([starting_index, end_index])
         }
      })
   }

   const getUserList = async (passed_partner) => {
      let selected_partner = partnerFilter

      if(passed_partner !== undefined) {
         selected_partner = passed_partner
      }

      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/users/getUsersByPartner',
         params: {
            partner: selected_partner
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then( async (value) => {
         if(value.data.success === true) {
            let user_list = value.data.records;
            user_list.sort((a, b) => a.first_name.localeCompare(b.first_name))
            setAgentList(value.data.records)
         }
      })
   }

   useEffect(() => {
      if(permissions.scope !== 'individual' && partnerFilter !== '') {
         getUserList()
      }
   }, [partnerFilter])

   const getPartnerList = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/partners/getPartnerList',
         params: {
            limit_data: true
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then( async (value) => {
         if(value.data.success === true) {
            let partners = []

            for (let i = 0; i < value.data.records.length; i++) {
               const partner = value.data.records[i];
               
               partners.push(partner.partner_name)
            }

            setPartnerList(partners)
         }
      })
      .catch(() => {
         // Do nothing
      })
   }

   const getQualityScoresReset = async (change) => {
      let params = {
         type: categoryFilter,
         lastEvaluatedKey: {},
         date: dateFilter,
         partner: partnerFilter,
         agent: agentFilter
      }

      params[change.field] = change.value

      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/quality/getScoresByType',
         headers: {
            Authorization: "Bearer " + user
         },
         params: params
      })
      .then((value) => {
         if(value.data.success === true) {
            setRecords(value.data.records)
            setTotalResults(value.data.records.length)
            setLastEvaluatedKey(value.data.lastEvaluatedKey)

            setShowIndex([1, value.data.records.length])
         }
      })
   }

   useEffect(() => {
      if(records.length === 0) {
         getQualityScores()

         if(permissions.scope === 'global') {
            getPartnerList()
         } else {
            setPartnerFilter(userDetails.partner)
         }

         if(permissions.scope === 'local') {
            getUserList(userDetails.partner)
         } else if(permissions.scope === 'individual') {
            setAgentFilter({
               first_name: userDetails.first_name,
               last_name: userDetails.last_name,
               email_address: userDetails.email_address
            })
         }
      }
   }, [])

   const handleChangeDateFilter = (event) => {
      setDateFilter(event.target.value)

      getQualityScoresReset({
         field: 'date',
         value: event.target.value
      })
   }

   const chooseRecordType = (type, data, index) => {
      switch (type) {
         case "lead_source":
            return <LeadSourceRecord
                     data={data}
                     operationsModal={operationsModal}
                     setOperationsModal={setOperationsModal}
                     i={index}
                  />

         case "sales_call":
            return <SalesCallRecord
                     data={data}
                     operationsModal={operationsModal}
                     setOperationsModal={setOperationsModal}
                     i={index}
                  />
      }
   }

   const createScoreLog = () => {
      let jsx = []

      records.sort((b, a) => new Date(a.timestamp) - new Date(b.timestamp))

      for (let i = showIndex[0] - 1; i < showIndex[1]; i++) {
         const el = records[i];

         jsx.push(chooseRecordType(el.call_type, el, i))
      }

      if(jsx.length === 0) {
         jsx.push(
            <p>There are no scores available for your chosen filters</p>
         )
      }

      return jsx;
   }

   const handleNextPage = async () => {
      const starting_index = showIndex[1] + 1
      let end_index = starting_index + 9
      
      if(records.length < end_index && lastEvaluatedKey !== undefined) {
         await getQualityScores()
      } else {
         if(end_index > records.length) {
            end_index = records.length
         }

         setShowIndex([starting_index, end_index])
      }
   }

   const handlePreviousPage = () => {
      let starting_index = showIndex[0] - 10;
      if (starting_index < 1) starting_index = 1
      const end_index = starting_index + 9

      setShowIndex([starting_index, end_index])
   }

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to quality control</button>
         <h2>Quality control reports</h2>


         <br/>

         <h3>Filters</h3>
         <br/>

         <div className="filter-options-container">
            <span>
               <label className="form-label">Category</label>
               <select
                  className="form-input admin-row"
                  value={categoryFilter}
                  onChange={(e) => {
                     getQualityScoresReset({field: 'type', value: e.target.value});
                     setCategoryFilter(e.target.value)
                  }}
               >  
                  {allowed_call_types.map(type => (
                     <option value={type}>{type.charAt(0).toUpperCase() + type.slice(1).replaceAll("_", " ")}</option>
                  ))}
               </select>
            </span>

            <span>
               <label className="form-label">Date</label>
               <input 
                  className="form-input admin-row"
                  type="date"
                  value={dateFilter}
                  onChange={handleChangeDateFilter}
               />
            </span>

            {
               categoryFilter !== 'lead_source' ? (
                  <React.Fragment>
                     {
                        permissions.scope === 'global' ? (
                           <span>
                              <label className="form-label">Partner</label>

                              <select
                                 className="form-input admin-row"
                                 value={partnerFilter}
                                 onChange={(e) => {
                                    getQualityScoresReset({field: 'partner', value: e.target.value});
                                    setPartnerFilter(e.target.value)
                                 }}
                              >
                                 <option value="" selected>All partners</option>
                                    {partnerList.map(partner => (
                                       <option value={partner}>{partner}</option>
                                    ))}
                              </select>
                           </span>
                        ) : null
                     }
                     
                     {
                        permissions.scope === 'global' || permissions.scope === 'local' ? (
                           <span>
                              <label className="form-label">Agent</label>

                              <select
                                 className="form-input admin-row"
                                 value={agentFilter}
                                 onChange={(e) => {
                                    getQualityScoresReset({field: 'agent', value: e.target.value});
                                    setAgentFilter(e.target.value)
                                 }}
                              >
                                 {
                                    partnerFilter === '' ? (
                                       <option value="" selected disabled>-</option>
                                    ) : (
                                       <React.Fragment>
                                          <option value="" selected>All agents</option>
                                          {agentList.map(agent => (
                                             <option value={agent.email_address}>{agent.first_name} {agent.last_name}</option>
                                          ))}
                                       </React.Fragment>
                                    )
                                 }
                              </select>
                           </span>
                        ) : null
                     }
                     
                  </React.Fragment>
               ) : null
            }
         </div>

         <table className="activity-log-table">
            <tbody>
               <tr>
                  {
                     categoryFilter === 'lead_source' ? (
                        <React.Fragment>
                           <th>Phone number</th>
                           <th>Batch number</th>
                           <th>Timestamp</th>
                           <th>Marked by</th>
                           <th>Score</th>
                           <th>View</th>
                        </React.Fragment>
                     ) : categoryFilter === 'sales_call' ? (
                        <React.Fragment>
                           <th>Service number</th>
                           <th>Timestamp</th>
                           <th>Direction</th>
                           <th>Agent</th>
                           <th>Marked by</th>
                           <th>Score</th>
                           <th>View</th>
                        </React.Fragment>
                     ) : null
                  }
                  
               </tr>
               {createScoreLog()}
            </tbody>
         </table>

         <div className="activity-log-counts">
            {
               showIndex[0] > 1 ? (
                  <button 
                     className="plain-text-button arrow-left"
                     onClick={handlePreviousPage}
                  >Previous page</button>
               ) : null
            }

            {
               (showIndex[1] < totalResults) || (showIndex[1] === totalResults && lastEvaluatedKey !== undefined) ? (
                  <button 
                     className="plain-text-button arrow-right"
                     onClick={handleNextPage}
                  >Next page</button>
               ) : null
            }

            <br/>
            <br/>
            <p><strong>Showing results {showIndex[0]} - {showIndex[1]}</strong></p>
            <p>Total results {lastEvaluatedKey !== undefined ? '- Many' : totalResults}</p>
         </div>

      </div>
   )
   
}

export default ViewQualityReports