import React, { useState } from "react";
import axios from "axios";
import Error from "../../components/global/error";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { IoMdOpen as Open } from 'react-icons/io'
import { 
   BsCloudDownload as Download,
   BsTelephoneInbound as Inbound,
   BsTelephoneOutbound as Outbound
} from 'react-icons/bs'
import { useUserAuth } from "../../context/userAuthContext";
import AudioPlayer from "react-h5-audio-player";


const FindACall = ({
   setShowScreen
}) => {
   const { user, userDetails } = useUserAuth()

   // Records
   const [callsFound, setCallsFound] = useState("")
   const [totalResults, setTotalResults] = useState(0)
   const [showIndex, setShowIndex] = useState([1, 0])
   const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})
   const [operationsModal, setOperationsModal] = useState('')

   // Search params
   const [errors, setErrors] = useState({
      not_found: false,
      filters: false,
      error: false 
   })

   const [searchParams, setSearchParams] = useState({
      phone_number: '',
      date: '',
      outcome: '',
      direction: ''
   })

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'search'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Searching database...</h3>
         </div>
      )
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      setSearchParams({
         ...searchParams,
         [name]: val
      })

      setErrors({
         not_found: false,
         filters: false,
         error: false
      })
   }

   const handleResetSearch = () => {
      setCallsFound("")
      setTotalResults(0)
      setShowIndex([1, 0])
      setLastEvaluatedKey({})
      setOperationsModal('')
      setSearchParams({
         phone_number: '',
         date: '',
         outcome: '',
         direction: ''
      })
   }

   const handleDataValidation = () => {
      const params_array = Object.entries(searchParams)

      let filters_used = 0

      for (let i = 0; i < params_array.length; i++) {
         const el = params_array[i];
         
         if(el[1] !== '') {
            filters_used += 1
         }
      }

      if(filters_used === 0) {
         setErrors({
            ...errors,
            filters: true
         })
      } else {
         return true
      }
   }

   const handlePreviousPage = () => {
      let starting_index = showIndex[0] - 10
      if(starting_index < 1) starting_index = 1
      const end_index = starting_index + 9

      setShowIndex([starting_index, end_index])
   }

   const getMoreCalls = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/calls/search',
         params: {
            ...searchParams,
            lastEvaluatedKey: lastEvaluatedKey
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            const new_array = [...callsFound, ...value.data.records]

            const starting_index = showIndex[1] + 1
            const end_index = new_array.length

            setCallsFound(new_array)
            setTotalResults(new_array.length)
            setLastEvaluatedKey(value.data.lastEvaluatedKey)

            setShowIndex([starting_index, end_index])
         }
      })
   }

   const handleNextPage = async () => {
      const starting_index = showIndex[1] + 1
      let end_index = starting_index + 9

      if(callsFound.length < end_index && lastEvaluatedKey !== undefined) {
         await getMoreCalls()
      } else {
         if(end_index > callsFound.length) {
            end_index = callsFound.length
         }

         setShowIndex([starting_index, end_index])
      }
   }

   const handleSubmitSearch = () => {
      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'get',
                  url: 'https://api.appliancesure.com/two/admin/portal/calls/search',
                  params: searchParams,
                  headers: {
                     Authorization: "Bearer " + user
                  }
               })
               .then((value) => {
                  setTimeout(() => {
                     if(value.data.success === true) {
                        if(value.data.records.length > 0) {
                           setCallsFound(value.data.records)
                           setTotalResults(value.data.records.length)
                           setLastEvaluatedKey(value.data.lastEvaluatedKey)

                           setShowIndex([1, value.data.records.length])
                        } else {
                           setErrors({
                              ...errors,
                              not_found: true
                           })
                        }
                     } else {
                        setErrors({
                           ...errors,
                           error: true
                        })
                     }

                     resolve("Yes")
                  }, 1000)
               })
               .catch(() => {
                  setErrors({
                     ...errors,
                     error: true
                  })
                  resolve("Yes")
               })
            })
         , 'search')
      }
   }

   const createCallLog = () => {
      let jsx = []

      callsFound.sort((b, a) => new Date(a.timings.start_time) - new Date(b.timings.start_time))

      for (let i = showIndex[0] - 1; i < showIndex[1]; i++) {
         const el = callsFound[i];
         
         jsx.push(
            <tr>
               <td>{el.call_to}</td>
               <td>{el.direction === 'outbound' ? <Outbound/> : <Inbound/>}</td>
               <td>{new Date(el.timings.start_time).toLocaleString('en-GB')}</td>
               <td>{(el.handle_time / 60).toFixed(2)} minutes</td>
               <td style={{lineBreak: 'anywhere'}}>{el.call_by}</td>
               <td>{el.outcome}</td>
               <td>
                  <button
                     className="plain-text-button"
                     style={{fontSize: 20}}
                     onClick={() => setOperationsModal(`Show call - ${i}`)}
                  ><Open/></button>
               </td>

               {operationsModal === `Show call - ${i}` ? (
                  <div className="center-modal-backdrop">
                     <div className="user-activity-modal-container">
                        <div className='close-modal-container activity-modal' onClick={() => setOperationsModal('')}>
                           <button className='close-modal'/>
                        </div>

                        <h2>Call details</h2>
                        <p>ID: {el.call_id}</p>

                        <div className="form-2col">
                           <table className="quality-table">
                              <tbody>
                                 <tr>
                                    <td>Phone number</td>
                                    <td>{el.call_to}</td>
                                 </tr>
                                 <tr>
                                    <td>Direction</td>
                                    <td>{el.direction.charAt(0).toUpperCase() + el.direction.slice(1)}</td>
                                 </tr>
                                 <tr>
                                    <td>Agent</td>
                                    <td>{el.call_by}</td>
                                 </tr>
                                 <tr>
                                    <td>Start time</td>
                                    <td>{new Date(el.timings.start_time).toLocaleString('en-GB')}</td>
                                 </tr>
                                 <tr>
                                    <td>End time</td>
                                    <td>{new Date(el.timings.end_time).toLocaleString('en-GB')}</td>
                                 </tr>
                                 <tr>
                                    <td>Handle time</td>
                                    <td>{(el.handle_time / 60).toFixed(2)} minutes</td>
                                 </tr>
                              </tbody>
                           </table>

                           <table className="quality-table">
                              <tbody>
                                 <tr>
                                    <td>Recording file name</td>
                                    <td>{el.recording.file_name}</td>
                                 </tr>
                                 <tr>
                                    <td>Recording location</td>
                                    <td>{el.recording.location}</td>
                                 </tr>
                                 <tr>
                                    <td>Recording storage facility</td>
                                    <td>{el.recording.facility}</td>
                                 </tr>
                                 <tr>
                                    <td>Call ID</td>
                                    <td>{el.call_id}</td>
                                 </tr>
                                 <tr>
                                    <td>Call made via</td>
                                    <td>Connex One</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>

                        <br/>
                        <br/>
                        <br/>

                        <h3>Call recording</h3>
                        
                        <br/>

                        <AudioPlayer
                           style={{maxWidth: 900}}
                           showJumpControls={false}
                           autoPlay={false}
                           autoPlayAfterSrcChange={false}
                           customAdditionalControls={[]}
                           src={process.env.REACT_APP_CALL_RECORDINGS_ACCESS.replaceAll("{{FILE_PATH}}", el.recording.location)}
                        />

                        <br/>

                        {
                           userDetails.user_type === 'Super Admin' ? (
                              <React.Fragment>
                                 <br/>

                                 <a
                                    className="welcome-button secondary"
                                    href={process.env.REACT_APP_CALL_RECORDINGS_ACCESS.replaceAll("{{FILE_PATH}}", el.recording.location)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    Download recording <Download/>
                                 </a>
                              </React.Fragment>
                           ) : null
                        }
                        
                        <br/>
                        <br/>
                     </div>
                  </div>
               ) : null}
            </tr>
         )
      }

      return jsx
   }

   return (
      <div className="screen-inner-container">
         <SubmitLoading/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to quality control</button>
         <h2>Search for a call 🔍</h2>

         {
            callsFound === '' ? (
               <div className="two-col-grid-container">
                  <div className="grid-container">
                     <h3>Filters</h3>
                     <p>Please use the filters below to find the call(s) that you're looking for. Ensure that you use at least 1 filter.</p>
                  
                     <br/>

                     <form onChange={handleFillInForm}>
                        <div className="form-2col">
                           <span>
                              <label htmlFor="phone_number" className="form-label">Phone number</label>
                              <input
                                 id="phone_number"
                                 name="phone_number"
                                 className="form-input"
                                 type="text"
                                 placeholder="e.g. 07444123940"
                                 value={searchParams.phone_number}
                              />
                           </span>

                           <span>
                              <label htmlFor="direction" className="form-label">Direction</label>
                              <select
                                 className="form-input"
                                 id="direction"
                                 name="direction"
                                 value={searchParams.direction}
                              >
                                 <option value="" selected disabled>Select a direction</option>
                                 <option value="outbound">Outbound</option>
                                 <option value="inbound">Inbound</option>
                              </select>
                           </span>
                        </div>
                        
                        <div className="form-2col">
                           <span>
                              <label htmlFor="date" className="form-label">Date</label>
                              <input
                                 id="date"
                                 name="date"
                                 className="form-input"
                                 type={'date'}
                                 value={searchParams.date}
                              />
                           </span>

                           <span>
                              <label htmlFor="outcome" className="form-label">Call outcome</label>
                              <select
                                 id="outcome"
                                 name="outcome"
                                 className="form-input"
                                 value={searchParams.outcome}
                              >
                                 <option value="" selected disabled>Select an outcome</option>
                                 <option value="Answering Machine">Answering machine</option>
                                 <option value="Callback">Callback</option>
                                 <option value="Disconnected">Disconnected</option>
                                 <option value="Do not call again">Do not call again</option>
                                 <option value="Engaged">Engaged</option>
                                 <option value="Not Interested">Not interested</option>
                                 <option value="Sale made">Sale</option>
                                 <option value="Vulnerable Customer">Vulnerable customer</option>
                              </select>
                           </span>
                        </div>
                     </form>

                     {
                        errors.filters ? (
                           <Error message="Please use at least 1 filter to search calls"/>
                        ) : null
                     }

                     {
                        errors.not_found ? (
                           <p style={{marginTop: 0}} className="info-statement">No calls could be found for the filters used. Please refine your search.</p>
                        ) : null
                     }

                     {
                        errors.error ? (
                           <Error message="There was an error looking up the requested call(s), please try again later"/>
                        ) : null
                     }

                     <button
                        className="welcome-button secondary"
                        onClick={handleSubmitSearch}
                     >Search</button>
                  </div>
               </div>
            ) : (
               <React.Fragment>
                  <br/>

                  <h3>Search results</h3>

                  <br/>

                  <button 
                     className="plain-text-button refresh"
                     onClick={handleResetSearch}
                  >
                     Search again
                  </button>

                  <br/>
                  <br/>

                  <table className="activity-log-table">
                     <tbody>
                        <tr>
                           <th>Phone number</th>
                           <th>Direction</th>
                           <th>Timestamp</th>
                           <th>Handle time</th>
                           <th>Call by</th>
                           <th>Outcome</th>
                           <th>View</th>
                        </tr>
                        {createCallLog()}
                     </tbody>
                  </table>

                  <div className="activity-log-counts">
                     {
                        showIndex[0] > 1 ? (
                           <button 
                              className="plain-text-button arrow-left"
                              onClick={handlePreviousPage}
                           >Previous page</button>
                        ) : null
                     }

                     {
                        (showIndex[1] < totalResults) || (showIndex[1] === totalResults && lastEvaluatedKey !== undefined) ? (
                           <button 
                              className="plain-text-button arrow-right"
                              onClick={handleNextPage}
                           >Next page</button>
                        ) : null
                     }

                     <br/>
                     <br/>
                     <p><strong>Showing results {showIndex[0]} - {showIndex[1]}</strong></p>
                     <p>Total results {lastEvaluatedKey !== undefined ? '- Many' : totalResults}</p>
                  </div>
               </React.Fragment>
            )
         }
         
      </div>
   )
}

export default FindACall