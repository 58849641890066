import React, { useEffect } from "react";

const SaleCompleted = ({
   saleData,
   refreshComponent,
   updateScriptStage
}) => {
   useEffect(() => {
      updateScriptStage('6.1')
   }, [])
   
   return (
      <div className="grid-container">
         <h2>Sale completed <span role="img" aria-label="jsx-a11y/accessible-emoji">👏</span> <span role="img" aria-label="jsx-a11y/accessible-emoji">🎊</span></h2>

         <p>You signed {saleData.personal_details.first_name} {saleData.personal_details.last_name} up to the {saleData.chosen_package.name}. <br/>Here's a summary of the package details:</p>
      
         <h3>Summary</h3>

         <table className="tariff-information-table">
            <tbody>
               <h4>Personal details</h4>
               <tr>
                  <td>Name</td>
                  <td>{saleData.personal_details.first_name} {saleData.personal_details.last_name}</td>
               </tr>
               <tr>
                  <td>Email</td>
                  <td>{saleData.personal_details.email_address}</td>
               </tr>
               <tr>
                  <td>Phone number</td>
                  <td>{saleData.personal_details.telephone}</td>
               </tr>
               <tr>
                  <td>Address</td>
                  <td>{saleData.personal_details.address.line_1}</td>
               </tr>
            </tbody>
         </table>


         <table className="tariff-information-table">
            <tbody>
               <br/>
               <h4>Package details</h4>
               <tr>
                  <td>Package</td>
                  <td>{saleData.chosen_package.name}</td>
               </tr>
               <tr>
                  <td>Appliance(s) covered</td>
                  <td>
                     <ul className="appliances-covered-list">
                        {Object.entries(saleData.chosen_appliances.object).map(appliance => (
                           <li>{appliance[0]} (x{appliance[1]})</li>
                        ))}
                     </ul>
                  </td>
               </tr>
               <tr>
                  <td>Payment schedule</td>
                  <td style={{textTransform: 'capitalize'}}>{saleData.chosen_package.chosen_frequency}</td>
               </tr>
               <tr>
                  <td>Cost</td>
                  <td>£{parseFloat(saleData.chosen_package.chosen_cost).toFixed(2)}</td>
               </tr>
            </tbody>
         </table>

         <br/>

         {/*<button
            className="welcome-button secondary refresh"
            onClick={refreshComponent}
         >Ready to start again?</button>*/}
      </div>
   )
}

export default SaleCompleted