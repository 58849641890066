import React, { useEffect, useState } from "react";
import Error from "../../components/global/error";
import required_fields from "./resources/required_fields_user";
import { useUserAuth } from "../../context/userAuthContext";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { detect } from "detect-browser";
import axios from "axios";

const CreateUser = ({
   userData, 
   partnerData,
   setShowScreen, 
   getMetricData,
   setPassParams,
   permissions
}) => {
   const { user, userDetails } = useUserAuth();
   const browser = detect()

   const [newUserData, updateNewUserData] = useState({
      first_name: '',
      last_name: '',
      email_address: '',
      user_type: '',
      partner: '',
      active: true,
      created: {
         by: {
            email_address: userDetails.email_address
         }
      }
   })
   const [errors, setErrors] = useState({
      first_name: false,
      last_name: false,
      email_address: false,
      user_type: false,
      partner: false
   })

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Creating user...</h3>
         </div>
      )
   }

   // Set permission levels of user
   const [permissionScope, setPermissionScope] = useState('')

   useEffect(() => {
      const actions_array = permissions.actions
      for (let i = 0; i < actions_array.length; i++) {
         const el = actions_array[i];
         
         if(el.name === 'create_users') {
            setPermissionScope(el.scope)

            if(el.scope !== 'global') {
               updateNewUserData({
                  ...newUserData,
                  partner: userDetails.partner
               })
            }
         }
      }
   }, [])

   

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateNewUserData({
         ...newUserData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {}

      for (let i = 0; i < required_fields.length; i++) {
         const el = required_fields[i];
         
         if(el === 'email_address') {
            if(newUserData[el].includes("@") === false || newUserData[el].includes(".") === false || newUserData[el].length < 6) {
               errors_count += 1;
               errors_object[el] = true
            }
         } else {
            if(newUserData[el] === '') {
               errors_count += 1;
               errors_object[el] = true
            }
         }
      }

      setErrors({
         ...errors,
         ...errors_object
      })

      if(errors_count === 0) {
         return true
      } else {
         nav();
         return false
      }
   }

   const nav = () => {
      window.scrollTo({ top: 410, behavior: 'smooth' });
   }

   const submitUser = async (event) => {
      event.preventDefault();

      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }

               await axios({
                  method: 'post',
                  url: 'https://api.appliancesure.com/two/admin/portal/users/createNewUser',
                  headers: {
                     Authorization: 'Bearer ' + user
                  },
                  data: {
                     data: newUserData,
                     requester: userDetails.first_name + " " + userDetails.last_name,
                     log_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then( async (value) => {
                  const resp = value.data.success;

                  if(resp === true) {
                     await getMetricData();

                     setTimeout(() => {
                        setPassParams({user: newUserData.email_address});
                        setShowScreen('manage_users');
                        resolve("Done")
                     }, 1000)
                  } else {
                     alert(value.data.reason)
                     resolve("Yes")
                  }
               })
               .catch(() => {
                  alert("There was an error")
                  resolve("Yes")
               })
            })
         , 'submit')
      }
   }
   
   return (
      <div className="screen-inner-container">
         <SubmitLoading/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Create a new user</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>New user details <span role="img" aria-label="jsx-a11y/accessible-emoji">✏️</span></h2>

               <br/>

               <form onChange={handleFillInForm}>
                  <div className="form-2col">
                     <span>
                        <label htmlFor="first_name" className="form-label">First name</label>
                        <input
                           id="first_name"
                           name="first_name"
                           type="text"
                           className="form-input"
                           value={newUserData.first_name}
                           placeholder="Enter user's first name"
                        />

                        {
                           errors.first_name ? (
                              <Error type="warning" message="Please enter the first name"/>
                           ) : null
                        }
                     </span>

                     <span>
                        <label htmlFor="last_name" className="form-label">Last name</label>
                        <input
                           id="last_name"
                           name="last_name"
                           type="text"
                           className="form-input"
                           value={newUserData.last_name}
                           placeholder="Enter user's last name"
                        />

                        {
                           errors.last_name ? (
                              <Error type="warning" message="Please enter the last name"/>
                           ) : null
                        }
                     </span>
                  </div>

                  <label htmlFor="email_address" className="form-label">Email address</label>
                  <input
                     id="email_address"
                     name="email_address"
                     type="text"
                     className="form-input"
                     value={newUserData.email_address}
                     placeholder="Enter user's email address"
                  />

                  {
                     errors.email_address ? (
                        <Error type="warning" message="Please enter the email address"/>
                     ) : null
                  }

                  <label htmlFor="user_type" className="form-label">Permission type</label>
                  <label className="description">An admin can see reports and manage users</label>

                  <select
                     id="user_type"
                     name="user_type"
                     className="form-input"
                     value={newUserData.user_type}
                  >
                     <option value="" disabled selected>Choose an option</option>
                     <option value="Admin">Admin</option>
                     {
                        permissionScope === 'global' ? (
                           <React.Fragment>
                              <option value="Super Admin">Super Admin</option>
                              <option value="Upsell Agent">Upsell Agent</option>
                              <option value="Quality Controller">Quality Controller</option>
                           </React.Fragment>
                        ) : null
                     }
                     <option value="Sales Agent">Sales Agent</option>
                     
                  </select>

                  {
                     errors.user_type ? (
                        <Error type="warning" message="Please select a permission type"/>
                     ) : null
                  }

                  
                  
                  {
                     permissionScope === 'global' ? (
                        <React.Fragment>
                           <label htmlFor="partner" className="form-label">Partner</label>
                           
                           <select
                              id="partner"
                              name="partner"
                              className="form-input"
                              value={newUserData.partner}
                           >
                              <option value="" disabled selected>Choose a partner</option>
                              {partnerData.map((partner) => (
                                 <option value={partner.partner_name}>{partner.partner_name}</option>
                              ))}
                           </select>
                        </React.Fragment>
                     ) : null
                  }
                  

                  {
                     errors.partner ? (
                        <Error type="warning" message="Please select the partner the user belongs to"/>
                     ) : null
                  }

                  <button
                     className="welcome-button secondary"
                     onClick={submitUser}
                  >Create user</button>
               </form>
            </div>
         </div>
      </div>
   )
}

export default CreateUser