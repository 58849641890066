import axios from "axios";
import React, { useEffect } from "react";

const URLViewer = ({ 
   content,
   setLaunchContent,
   setEditContent,
   permissions
}) => {
   useEffect(() => {
      axios({
         method: 'put',
         url: 'https://api.appliancesure.com/two/admin/portal/knowledge/addViewCount',
         data: {
            title: content.title,
            category: content.category
         }
      })
   }, [])

   return (
      <div className="center-modal-backdrop">
         <div className="script-modal-container knowledge-content">
            <div className="close-modal-positioning-container">
               <div className='close-modal-container top-right' onClick={() => setLaunchContent('')}>
                  <button className='close-modal'/>
               </div>
            </div>
         
            <a
               className="external-url-content"
               href={content.url}
               target="_blank"
               rel="noopener noreferrer"
            >Click to be redirected to {content.url.split("/")[2]}</a>

            <br/>

            <h3>{content.title}</h3>

            <p>{content.description}</p>
            <p className="disclaimer">View count: {content.views}</p>
         
            {
               permissions === 'edit' ? (
                  <button 
                     className="welcome-button secondary"
                     onClick={() => setEditContent(true)}
                  >Edit link</button>
               ) : null
            }
         
         </div>
      </div>
   )
}

export default URLViewer