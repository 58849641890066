import axios from "axios";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../context/userAuthContext";
import AgentKPIPerformance from "./reports/agent_kpi_performance";
import LeadBatchPerformanceAnalysis from "./reports/lead_batch_performance_analysis";
import LeadDataSupplierComparison from "./reports/lead_data_supplier_comparison";
import report_categories from "./reports/resources/report_categories";
import TeamKPIPerformance from "./reports/team_kpi_performance";

const Reporting = ({
   showReportScreen,
   setShowReportScreen
}) => {
   // Variables
   const [reportsList, setReportsList] = useState('')

   const { user, userDetails } = useUserAuth();

   const getReportsList = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/reporting/getReportsList',
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            setReportsList(value.data.reports)
         } else {
            setReportsList([])
         }
      })
      .catch(() => {
         setReportsList([])
      })
   }

   useEffect(() => {
      getReportsList()
   }, [])

   //--- JSX ---//
   const generateReportsList = () => {
      let jsx = []

      if(reportsList === '') {
         jsx.push("Please wait...")
      } else {
         let report_jsx = {
            sales: [],
            lead_data: []
         }

         for (let i = 0; i < reportsList.length; i++) {
            const report = reportsList[i];
            
            report_jsx[report_categories[report.report_name]].push(
               <li onClick={() => setShowReportScreen(`${report.report_name}__${i}`)}>{report.report_name}</li>
            )
         }

         if(report_jsx.sales.length > 0) {
            jsx.push(
               <React.Fragment>
                  <br/>
                  <h3>Sales</h3>

                  {report_jsx.sales}
               </React.Fragment>
            )
         }

         if(report_jsx.lead_data.length > 0) {
            jsx.push(
               <React.Fragment>
                  <br/>
                  <br/>

                  <h3>Lead data</h3>
                  {report_jsx.lead_data}
               </React.Fragment>
            )
         }

         if(jsx.length === 0) {
            jsx.push(
               <p>There are no reports currently available</p>
            )
         }
      }

      return jsx
   }

   const chooseScreen = () => {
      const report_screen_parts = showReportScreen.split("__")
      const name = report_screen_parts[0]
      const index = report_screen_parts[1]

      switch (name) {
         case "Agent KPI performance":
            return <AgentKPIPerformance
                     permissions={reportsList[index].permissions[userDetails.user_type].scope}
                     setShowScreen={setShowReportScreen}
                  />

         case "Team KPI performance":
            return <TeamKPIPerformance
                     permissions={reportsList[index].permissions[userDetails.user_type].scope}
                     setShowScreen={setShowReportScreen}
                  />

         case "Lead batch performance analysis":
            return <LeadBatchPerformanceAnalysis
                     setShowScreen={setShowReportScreen}
                  />

         case "Lead data supplier comparison":
            return <LeadDataSupplierComparison
                     setShowScreen={setShowReportScreen}
                  />
      }
   }

   return (
      <React.Fragment>
         {showReportScreen === '' ? (
            <div className="screen-inner-container">
               <h1>Reporting</h1>

               <div className="two-col-grid-container">
                  <div className="grid-container">
                     <h2>Pre-configured reports <span role="img" aria-label="jsx-a11y/accessible-emoji">📈</span></h2>
                     
                     <ul className="grid-list">
                        {generateReportsList()}
                     </ul>
                  </div>
               </div>
            </div>
         ) : chooseScreen()}
      </React.Fragment>
   )
}

export default Reporting