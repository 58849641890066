import axios from "axios";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../context/userAuthContext";
import AboutSystem from "./admin_ops/about_system";
import CreateIPAddress from "./admin_ops/create_ip_address";
import CreatePartner from "./admin_ops/create_partner";
import CreateProduct from "./admin_ops/create_product";
import CreateUser from "./admin_ops/create_user";
import ManagePartners from "./admin_ops/manage-partners";
import ManageIpWhitelist from "./admin_ops/manage_ip_whitelist";
import ManageProducts from "./admin_ops/manage_products";
import ManageScripts from "./admin_ops/manage_scripts";
import ManageTimetables from "./admin_ops/manage_timetables";
import ManageUsers from "./admin_ops/manage_users";
import ManageUserPermissions from "./admin_ops/manage_user_permissions";
import { sub_modules_order } from "./resources/admin_orders";

const AdminScreen = ({
   showAdminScreen,
   setShowAdminScreen,
   permissions
}) => {
   const { user, userDetails } = useUserAuth();
   const [metricCounts, setMetricCounts] = useState({
      active_users: 0,
      active_partners: 0,
      active_products: 0
   })

   // Data
   const [userData, setUserData] = useState([])
   const [partnerData, setPartnerData] = useState([])
   const [productData, setProductData] = useState([])

   // States
   const [passParams, setPassParams] = useState('')

   useEffect(() => {
      getMetricData();
   }, [])

   const getMetricData = async () => {
      
      new Promise(async (resolve, reject) => {
         try {
            // User data 
            const userLookup = await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/users/getUserList',
               headers: {
                  Authorization: 'Bearer ' + user
               }
            })

            const users = userLookup.data.records;

            let user_count = 0

            for (let i = 0; i < users.length; i++) {
               const record = users[i];
               
               if(record.active === true) {
                  user_count++
               }
            }

            setUserData(users)

            // Partner data
            
            const partnerLookup = await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/partners/getPartnerList'
            })

            const partners = partnerLookup.data.records;

            let partner_count = 0;

            for (let i = 0; i < partners.length; i++) {
               const record = partners[i];
               
               if(record.active === true) {
                  partner_count++
               }
            }

            setPartnerData(partners)

            // Product data
            const productLookup = await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/products/getProductList'
            })

            const products = productLookup.data.records;

            let product_count = 0;

            for (let i = 0; i < products.length; i++) {
               const record = products[i];
               
               if(record.active === true) {
                  product_count++
               }
            }

            setProductData(products)

            setMetricCounts({
               active_users: user_count,
               active_partners: partner_count,
               active_products: product_count
            })

            resolve("Success")
         }

         catch(err) {
            reject(err)
         }
      })
      
      return {
         success: true
      }
   }

   const chooseScreen = () => {
      switch (showAdminScreen) {
         case "manage_users":
            return <ManageUsers
                     userData={userData}
                     partnerData={partnerData}
                     setShowScreen={setShowAdminScreen}
                     getMetricData={getMetricData}
                     setPassParams={setPassParams}
                     passParams={passParams}
                     permissions={permissions.sub_modules.users}
                  />
         case "create_user":
            return <CreateUser
                     userData={userData}
                     partnerData={partnerData}
                     setShowScreen={setShowAdminScreen}
                     getMetricData={getMetricData}
                     setPassParams={setPassParams}
                     permissions={permissions.sub_modules.users}
                  />

         case "manage_partners":
            return <ManagePartners
                     partnerData={partnerData}
                     productData={productData}
                     setShowScreen={setShowAdminScreen}
                     getMetricData={getMetricData}
                     setPassParams={setPassParams}
                     passParams={passParams}
                  />

         case "create_partner":
            return <CreatePartner
                     partnerData={partnerData}
                     productData={productData}
                     setShowScreen={setShowAdminScreen}
                     getMetricData={getMetricData}
                     setPassParams={setPassParams}
                  />

         case "manage_products":
            return <ManageProducts
                     productData={productData}
                     partnerData={partnerData}
                     setShowScreen={setShowAdminScreen}
                     getMetricData={getMetricData}
                     setPassParams={setPassParams}
                     passParams={passParams}
                  />

         case "create_product":
            return <CreateProduct
                     productData={productData}
                     partnerData={partnerData}
                     setShowScreen={setShowAdminScreen}
                     getMetricData={getMetricData}
                     setPassParams={setPassParams}
                  />

         case "manage_scripts":
            return <ManageScripts
                     setShowScreen={setShowAdminScreen}
                  />

         case "manage_ip_whitelist":
            return <ManageIpWhitelist
                     partnerData={partnerData}
                     setShowScreen={setShowAdminScreen}
                  />
         
         case "create_ip_address":
            return <CreateIPAddress
                     partnerData={partnerData}
                     setShowScreen={setShowAdminScreen}
                  />
         case 'about':
            return <AboutSystem
                     setShowScreen={setShowAdminScreen}
                  />

         case 'manage_timetables':
            return <ManageTimetables
                     setShowScreen={setShowAdminScreen}
                  />

         case 'user_permissions':
            return <ManageUserPermissions
                     setShowScreen={setShowAdminScreen}
                  />

         default:
            return null
      }
   }

   // Create navigation options per permissions
   const actionOptions = (action) => {
      switch (action) {
         case 'manage_users':
            return <li onClick={() => setShowAdminScreen('manage_users')}>Manage users</li>

         case 'create_users':
            return <li onClick={() => setShowAdminScreen('create_user')}>Create new user</li>

         case 'manage_partners':
            return <li onClick={() => setShowAdminScreen('manage_partners')}>Manage partners</li>

         case 'create_partners':
            return <li onClick={() => setShowAdminScreen('create_partner')}>Create new partner</li>

         case 'manage_products':
            return <li onClick={() => setShowAdminScreen('manage_products')}>Manage products</li>

         case 'create_products':
            return <li onClick={() => setShowAdminScreen('create_product')}>Create new products</li>

         case 'manage_scripts':
            return <li onClick={() => setShowAdminScreen('manage_scripts')}>Manage scripts</li>
         
         case 'manage_ip_whitelist':
            return <li onClick={() => setShowAdminScreen('manage_ip_whitelist')}>IP restriction</li>

         case 'manage_timetables':
            return <li onClick={() => setShowAdminScreen('manage_timetables')}>Access timetables</li>
            
         case 'user_permissions':
            return <li onClick={() => setShowAdminScreen('user_permissions')}>User permissions</li>
         
         case 'about':
            return <li onClick={() => setShowAdminScreen('about')}>About this system</li>

         default:
            return null
      }
   }

   const generateNavigationOptions = () => {
      let jsx = []

      const sub_modules = permissions.sub_modules
      const sub_modules_array = Object.entries(sub_modules)

      for (let i = 0; i < sub_modules_array.length; i++) {
         const name = sub_modules_array[i][0];
         const details = sub_modules_array[i][1];

         

         if(details.access_granted === true) {
            let sub_module_jsx = []

            sub_module_jsx.push(<h3>{name.charAt(0).toUpperCase() + name.slice(1).replaceAll("_", " ")}</h3>)

            // Get individual actions
            for (let ind = 0; ind < details.actions.length; ind++) {
               const name = details.actions[ind].name;
               
               const action_jsx = actionOptions(name)

               sub_module_jsx.push(action_jsx)
            }

            const sm_order_index = sub_modules_order[name]

            jsx.push(<span key={sm_order_index + 100}>{sub_module_jsx}</span>)
         }
      }

      jsx.sort((a, b) => a.key - b.key)

      return jsx;
   }

   return (
      <React.Fragment>
         { showAdminScreen === '' ? (
            <div className="screen-inner-container">
               <h1>Admin</h1>

               {
                  userDetails.user_type === 'Super Admin' ? (
                     <div className="two-col-grid-container">
                        <div className="grid-container fill">
                           <div className="admin-metrics-container">
                              <div className="admin-metric">
                                 <h3>{metricCounts.active_users}</h3>
                                 <p>Active users</p>
                              </div>
      
                              <div className="admin-metric">
                                 <h3>{metricCounts.active_partners}</h3>
                                 <p>Active partners</p>
                              </div>
      
                              <div className="admin-metric">
                                 <h3>{metricCounts.active_products}</h3>
                                 <p>Active products</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  ) : null
               }
               
               <div className="two-col-grid-container">
                  <div className="grid-container">
                     <h2>Actions 🛠</h2>

                     <br/>
                     
                     <ul className="grid-list admin-actions-list">
                        {generateNavigationOptions()}
                     </ul>
                  </div>
               </div>
            </div>
         ) : (
            chooseScreen()
         )}
       
      </React.Fragment>
   )
}

export default AdminScreen