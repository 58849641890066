import React, { useEffect, useState } from "react";
import Error from "../../components/global/error";
import required_fields from "./resources/required_fields_product";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../context/userAuthContext";
import { detect } from "detect-browser";
import axios from "axios";

const CreateProduct = ({
   productData,
   partnerData,
   setShowScreen,
   getMetricData,
   setPassParams
}) => {
   const { userDetails } = useUserAuth();

   const [newProductData, updateNewProductData] = useState({
      product_name: '',
      product_type: '',
      min_appliances: 1,
      max_appliances: 1,
      pricing_model: '',
      complex_pricing: false,
      payment_frequency: [],
      cost: {
         monthly_cost: 0,
         annual_cost: 0,
         complex_pricing: {}
      },
      length_of_contract: '',
      active: true,
      partner_access: [],
      created: {
         by: userDetails.email_address
      }
   })

   const [errors, setErrors] = useState({
      product_name: false,
      pricing_model: false,
      payment_frequency: false,
      length_of_contract: false,
      no_of_appliances: false,
      monthly_cost: false,
      annual_cost: false,
      complex_pricing: false
   })

   const [partners, setPartners] = useState([]);
   const [partnersSelect, updatePartnersSelect] = useState({})
   const browser = detect()
   

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Creating product...</h3>
         </div>
      )
   }

   useEffect(() => {
      let active_partners_list = []
      for (let i = 0; i < partnerData.length; i++) {
         const el = partnerData[i];
         
         if(el.active === true) {
            active_partners_list.push(el)
         }
      }

      setPartners(active_partners_list)
   }, [])

   // Change partner access in productData
   useEffect(() => {
      const partners_array = Object.entries(partnersSelect)

      const selected = []
      for (let i = 0; i < partners_array.length; i++) {
         const el = partners_array[i];
         
         if(el[1] === true) {
            selected.push(el[0])
         }
      }

      updateNewProductData({
         ...newProductData,
         partner_access: selected
      })
   }, [partnersSelect])

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      let complex_pricing_object = {}
      if(newProductData.complex_pricing === true) {
         for (let i = 0; i < newProductData.max_appliances; i++) {
            complex_pricing_object[(i+1).toString()] = {
               monthly_cost: 0,
               annual_cost: 0
            }
         }
      }
      
      if(name === 'payment_frequency') {
         if(val === 'any') {
            updateNewProductData({
               ...newProductData,
               cost: {
                  ...newProductData.cost,
                  monthly_cost: 0,
                  annual_cost: 0,
                  complex_pricing: complex_pricing_object
               },
               payment_frequency: ['monthly', 'annually']
            })
         } else {
            updateNewProductData({
               ...newProductData,
               cost: {
                  ...newProductData.cost,
                  monthly_cost: 0,
                  annual_cost: 0,
                  complex_pricing: complex_pricing_object
               },
               payment_frequency: [val]
            })
         }
      } else if (name === "monthly_cost" || name === 'annual_cost') {
         updateNewProductData({
            ...newProductData,
            cost: {
               ...newProductData.cost,
               [name]: val
            }
         })
      } else if (name === 'pricing_model') {
         if(newProductData.complex_pricing === true) {
            alert("Complex pricing is only available for 'Cost per appliance', please disable complex pricing to proceed")
         } else {
            updateNewProductData({
               ...newProductData,
               [name]: val,
               cost: {
                  ...newProductData.cost
               }
            })
         }
      } else {
         updateNewProductData({
            ...newProductData,
            [name]: val
         })
      }

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleApplianceNumbers = (e, field, direction) => {
      e.preventDefault();

      setErrors({
         ...errors,
         no_of_appliances: false
      })

      let complex_pricing_object = {}
      if(newProductData.complex_pricing === true) {
         let new_max = newProductData.max_appliances;
         if(direction === 'up') {
            new_max += 1
         } else {
            new_max -= 1
         }
         for (let i = 0; i < new_max; i++) {
            complex_pricing_object[(i+1).toString()] = {
               monthly_cost: 0,
               annual_cost: 0
            }
         }
      }

      if(direction === 'up') {
         if(newProductData[field] < 15) {
            if(field === 'min_appliances' && newProductData.complex_pricing === true) {
               alert("Complex pricing is only available when min appliances is set to 1, please disable complex pricing to proceed")
            } else {
               if(field === 'min_appliances' && newProductData.min_appliances === newProductData.max_appliances) {
                  // Don't make change
               } else {
                  updateNewProductData({
                     ...newProductData,
                     [field]: newProductData[field] + 1,
                     cost: {
                        ...newProductData.cost,
                        complex_pricing: complex_pricing_object
                     }
                  })
               }
            }
         }
      } else {
         if(newProductData[field] > 1) {
            if(field === 'max_appliances' && newProductData.min_appliances === newProductData.max_appliances) {
               // Don't make change
            } else if (field === 'max_appliances' && newProductData.complex_pricing === true && newProductData.max_appliances === 2) {
               alert("Complex pricing is only available when max appliances is more than 2, please disable complex pricing to proceed")
            } else {
               updateNewProductData({
                  ...newProductData,
                  [field]: newProductData[field] - 1,
                  cost: {
                     ...newProductData.cost,
                     complex_pricing: complex_pricing_object
                  }
               })
            }
         }
      }
   }

   const handleChangePartnerAccess = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      updatePartnersSelect({
         ...partnersSelect,
         [name]: val
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0
      let errors_object = {}

      for (let i = 0; i < required_fields.length; i++) {
         const el = required_fields[i];
         
         if(el === "monthly_cost") {
            if((newProductData.cost.monthly_cost === 0 || newProductData.cost.monthly_cost === '') && newProductData.payment_frequency.includes("monthly") && newProductData.complex_pricing === false) {
               errors_count += 1
               errors_object[el] = true
            }
         } else if(el === "annual_cost") {
            if((newProductData.cost.annual_cost === 0 || newProductData.cost.annual_cost === '') && newProductData.payment_frequency.includes("annually") && newProductData.complex_pricing === false) {
               errors_count += 1
               errors_object[el] = true
            }
         } else if(el === 'no_of_appliances') {
            if(newProductData.min_appliances > newProductData.max_appliances) {
               errors_count += 1
               errors_object[el] = true
            }
         } else if(el === 'complex_pricing') {
            if(newProductData.complex_pricing === true) {
               // Iterate through all complex_pricing
               const complex_pricing_array = Object.entries(newProductData.cost.complex_pricing)
               
               for (let ind = 0; ind < complex_pricing_array.length; ind++) {
                  const option = complex_pricing_array[ind];
                  
                  if(newProductData.payment_frequency.includes("monthly") && option[1].monthly_cost === 0) {
                     errors_count += 1
                     errors_object.complex_pricing = true
                  }

                  if(newProductData.payment_frequency.includes("annually") && option[1].annual_cost === 0) {
                     errors_count += 1
                     errors_object.complex_pricing = true
                  }
               }
            }
         } else if (newProductData[el] === '') {
            errors_count += 1
            errors_object[el] = true
         }
      }

      setErrors({
         ...errors,
         ...errors_object
      })

      if(errors_count === 0) {
         return true
      } else {
         return false
      }
   }

   const handleChangeComplexPricing = (event) => {
      const val = event.target.value === 'true';

      if(val === true) {
         let update_object = {}
         for (let i = 0; i < newProductData.max_appliances; i++) {
            update_object[(i+1).toString()] = {
               monthly_cost: 0,
               annual_cost: 0
            }
         }

         updateNewProductData({
            ...newProductData,
            complex_pricing: val,
            cost: {
               ...newProductData.cost,
               complex_pricing: update_object
            }
         })
      } else {
         updateNewProductData({
            ...newProductData,
            complex_pricing: val,
            cost: {
               ...newProductData.cost,
               complex_pricing: {}
            }
         })
      }
   }

   const submitProduct = async (event) => {
      event.preventDefault();
      if (handleDataValidation() === true) {
         let product_data = {}

         if(newProductData.complex_pricing === true) {
            product_data = {
               ...newProductData,
               cost: newProductData.cost.complex_pricing
            }
         } else if (newProductData.pricing_model === 'appliance') {
            product_data = {
               ...newProductData,
               cost: {
                  per_appliance: {
                     monthly_cost: newProductData.cost.monthly_cost,
                     annual_cost: newProductData.cost.annual_cost
                  }
               }
            }
         } else {
            product_data = {
               ...newProductData,
               cost: {
                  monthly_cost: newProductData.cost.monthly_cost,
                  annual_cost: newProductData.cost.annual_cost
               }
            }
         }

         try {
            trackPromise(
               new Promise( async (resolve) => {
                  try {
                     // Get IP & device details
                     let ip_address = 'Unknown'

                     try {
                        const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                        ip_address = ip_lookup.data.IPv4
                     }
                  
                     catch (err) {
                        console.log(err)
                     }

                     await axios({
                        method: 'post',
                        url: 'https://api.appliancesure.com/two/admin/portal/products/createNewProduct',
                        data: {
                           data: product_data,
                           log_details: {
                              ip_address: ip_address,
                              action_by: {
                                 name: userDetails.first_name + " " + userDetails.last_name,
                                 partner: userDetails.partner,
                                 email_address: userDetails.email_address
                              },
                              browser: browser.name,
                              os: browser.os
                           }
                        }
                     })
                     .then( async (value) => {
                        if(value.data.success === true) {
                           await getMetricData();

                           setTimeout(() => {
                              setPassParams({partner: newProductData.product_name});
                              setShowScreen('manage_products')
                              resolve("Yes")
                           }, 1000)
                        } else {
                           // There was an error
                           alert(value.data.reason)
                           resolve("Yes")
                        }
                     }).catch(() => {
                        alert("There was an error")
                        resolve("Yes")
                     })
                  }
                  
                  catch (err) {
                     console.log(err)
                  }
               })
            , 'submit')
         }
   
         catch (err) {
            console.log(err)
         }
      }
   }


   const handleInputComplexPricing = (event) => {
      const val = event.target.value;
      const frequency = event.target.dataset.frequency;
      const index = event.target.dataset.index;

      updateNewProductData({
         ...newProductData,
         cost: {
            ...newProductData.cost,
            complex_pricing: {
               ...newProductData.cost.complex_pricing,
               [index]: {
                  ...newProductData.cost.complex_pricing[index],
                  [frequency]: val
               }
            }
         }
      })

      setErrors({
         ...errors,
         complex_pricing: false
      })
   }

   return (
      <div className="screen-inner-container">
         <SubmitLoading/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Create a new product</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>New product details <span role="img" aria-label="jsx-a11y/accessible-emoji">✏️</span></h2>

               <br/>

               <form onChange={handleFillInForm}>
                  <label htmlFor="product_name" className="form-label">Product name*</label>
                     <input
                        id="product_name"
                        name="product_name"
                        type="text"
                        className="form-input"
                        value={newProductData.product_name}
                        placeholder="What is the product called?"
                     />

                     {
                        errors.product_name ? (
                           <Error message="Please enter the name of the product"/>
                        ) : null
                     }

                     <label htmlFor="product_type" className="form-label">Type</label>
                     <select
                        id="product_type"
                        name="product_type"
                        className="form-input"
                        value={newProductData.product_type}
                     >
                        <option value="" selected disabled>Choose a product type</option>
                        <option value="standard">New sale</option>
                        <option value="upgrade">Upgrade</option>
                     </select>

                     {
                        newProductData.product_type === 'standard' ? (
                           <React.Fragment>
                              <label htmlFor="use_upgrades" className="form-label">Available in upgrades</label>
                           </React.Fragment>
                        ) : null
                     }

                     <label className="form-label">No. of appliances*</label>
                     <p className="description">The number of appliances that can be signed up to in the package.</p>

                     <div className="form-2col">
                        <div className="counter-container">
                           <button
                              className="counter-button down"
                              onClick={(e) => handleApplianceNumbers(e, 'min_appliances', 'down')}
                           />
                           <h4>{newProductData.min_appliances}</h4>
                           <button
                              className="counter-button up"
                              onClick={(e) => handleApplianceNumbers(e, 'min_appliances', 'up')}
                           />
                        </div>

                        <div className="counter-container">
                        <button
                              className="counter-button down"
                              onClick={(e) => handleApplianceNumbers(e,'max_appliances', 'down')}
                           />
                           <h4>{newProductData.max_appliances}</h4>
                           <button
                              className="counter-button up"
                              onClick={(e) => handleApplianceNumbers(e, 'max_appliances', 'up')}
                           />
                        </div>

                        <label className="no-label">Minimum</label>
                        <label className="no-label">Maximum</label>
                     </div>

                     {
                        errors.no_of_appliances ? (
                           <React.Fragment>
                              <br/>
                              <Error message="Minimum no. of appliances can not be higher than maximum"/>
                           </React.Fragment>
                        ) : <br/>
                     }
      
                     <label htmlFor="pricing_model" className="form-label">Pricing model*</label>
                     <select
                        className="form-input"
                        name="pricing_model"
                        id="pricing_model"
                        value={newProductData.pricing_model}
                     >
                        <option value="" selected disabled>Choose a model</option>
                        <option value="appliance">Cost per appliance</option>
                        <option value="package">Cost for package</option>
                     </select>

                     {
                        errors.pricing_model ? (
                           <Error message="Please enter the pricing model"/>
                        ) : null
                     }

                     <label htmlFor="payment_frequency" className="form-label">Payment frequency*</label>
                     <select
                        className="form-input"
                        name="payment_frequency"
                        id="payment_frequency"
                     >
                        <option value="" selected disabled>Choose a frequency</option>
                        <option value="monthly">Monthly</option>
                        <option value="annually">Annual</option>
                        <option value="any">Either</option>
                     </select>

                     {
                        errors.pricing_model ? (
                           <Error message="Please enter the payment frequency"/>
                        ) : null
                     }
                  </form>

                     {
                        newProductData.pricing_model === 'appliance' && newProductData.min_appliances === 1 && newProductData.max_appliances > 1 ? (
                           <React.Fragment>
                              <label htmlFor="complex_pricing" className="form-label">Complex pricing</label>
                              <select
                                 className="form-input"
                                 name="complex_pricing"
                                 id="complex_pricing"
                                 value={newProductData.complex_pricing}
                                 onChange={handleChangeComplexPricing}
                              >
                                 <option value={true}>Yes</option>
                                 <option value={false}>No</option>
                              </select>
                           </React.Fragment>
                        ) : null
                     }

                     {
                        newProductData.complex_pricing === true ? (
                           <React.Fragment>
                              <div className="partner-access-scrollarea" style={{marginTop: -10, marginBottom: 30}}>
                                 <table className="activity-log-table">
                                    <tr>
                                       <th>#</th>
                                       <th>Monthly</th>
                                       <th>Annual</th>
                                    </tr>
                                    {Object.entries(newProductData.cost.complex_pricing).map(option => (
                                       <tr>
                                          <td># {option[0]}</td>
                                          <td>
                                             <input
                                                type="number"
                                                step="0.01"
                                                value={newProductData.payment_frequency.includes("monthly") ? newProductData.cost.complex_pricing[option[0]].monthly_cost : ''}
                                                className="complex-price-input"
                                                disabled={!newProductData.payment_frequency.includes("monthly")}
                                                data-index={option[0]}
                                                data-frequency="monthly_cost"
                                                onChange={handleInputComplexPricing}
                                             />
                                          </td>
                                          <td>
                                             <input
                                                type="number"
                                                step="0.01"
                                                value={newProductData.payment_frequency.includes("annually") ? newProductData.cost.complex_pricing[option[0]].annual_cost : ''}
                                                className="complex-price-input"
                                                disabled={!newProductData.payment_frequency.includes("annually")}
                                                data-index={option[0]}
                                                data-frequency="annual_cost"
                                                onChange={handleInputComplexPricing}
                                             />
                                          </td>
                                       </tr>
                                    ))}
                                 </table>
                              </div>

                              {
                                 errors.complex_pricing ? (
                                    <Error message="There is/are pricing elements missing from the above table"/>
                                 ) : null
                              }
                           </React.Fragment>
                        ) : null 
                     }

                  <form onChange={handleFillInForm}>
                     {
                        newProductData.complex_pricing === true ? null : (
                           <React.Fragment>
                              {
                                 newProductData.payment_frequency.includes("monthly") && newProductData.payment_frequency !== '' ? (
                                    <React.Fragment>
                                       <label className="form-label" htmlFor="monthly_cost">Monthly cost*</label>
                                       <p className="description">How much is the monthly cost per {newProductData.pricing_model}?</p>

                                       <input
                                          type="number"
                                          min="0.00"
                                          max="10000.00"
                                          step="0.01"
                                          className="form-input currency"
                                          name="monthly_cost"
                                          value={newProductData.cost.monthly_cost}
                                       />

                                       <p className="currency-mask">£</p>

                                       {
                                          errors.monthly_cost ? (
                                             <div style={{marginTop: '-40px'}}>
                                                <Error message="Please enter the monthly cost"/>
                                             </div>
                                             
                                          ) : null
                                       }
                                    </React.Fragment>
                                 ) : null
                              }

                              {
                                 newProductData.payment_frequency.includes("annually") && newProductData.payment_frequency !== '' ? (
                                    <React.Fragment>
                                       <label className="form-label" htmlFor="annual_cost">Annual cost*</label>
                                       <p className="description">How much is the annual cost per {newProductData.pricing_model}?</p>

                                       <input
                                          type="number"
                                          min="0.00"
                                          max="10000.00"
                                          step="0.01"
                                          className="form-input currency"
                                          name="annual_cost"
                                          value={newProductData.cost.annual_cost}
                                       />

                                       <p className="currency-mask">£</p>

                                       {
                                          errors.annual_cost ? (
                                             <div style={{marginTop: '-40px'}}>
                                                <Error message="Please enter the annual cost"/>
                                             </div>
                                             
                                          ) : null
                                       }
                                    </React.Fragment>
                                 ) : null
                              }
                           </React.Fragment>
                        )
                     }
                     

                     <label className="form-label" htmlFor="length_of_contract">Length of contract*</label>
                     <p className="description">What is the minimum term of the contract (in months)</p>

                     <input
                        type="number"
                        min="1"
                        max="48"
                        step="1"
                        id="length_of_contract"
                        className="form-input"
                        name="length_of_contract"
                        value={newProductData.length_of_contract}
                        placeholder="Length of contract in months"
                     />

                     {
                        errors.length_of_contract ? (
                           <Error message="Please enter the contract length"/>
                        ) : null
                     }
               </form>

               <h3>Partner access <span role="img" aria-label="jsx-a11y/accessible-emoji">🤝</span></h3>

                  <div className="partner-access-scrollarea">
                     {partners.map((partner) => (
                        <div className="partner-access-row">
                           <h5>{partner.partner_name}</h5>
                           <label className="switchbox standard">
                              <input
                                 id="auto-update"
                                 type="checkbox"
                                 name={partner.partner_name}
                                 onChange={handleChangePartnerAccess}
                              />
                              <span className="slider-switchbox standard"></span>
                           </label>
                        </div>
                     ))}
                  </div>
                  
                  <button
                     className="welcome-button secondary"
                     onClick={submitProduct}
                  >Create product</button>
            </div>
         </div>
      </div>
   )
}

export default CreateProduct