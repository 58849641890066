import React from "react"

const QuickActionsTile = ({
   setShowAdminScreen,
   setShowScreen,
   actions
}) => {
   const actionsSelect = (action) => {
      switch (action) {
         case 'create_new_user':
            return <li onClick={() => {setShowScreen('admin'); setShowAdminScreen('create_user')}}>Create new user</li>

         case 'manage_users':
            return <li onClick={() => {setShowScreen('admin'); setShowAdminScreen('manage_users')}}>Manage users</li>

         case 'see_reports':
            return <li onClick={() => setShowScreen('reporting')}>See reports</li>

         case 'monitor_quality':
            return <li onClick={() => setShowScreen('quality_control')}>Monitor quality</li>
         
         default:
            return null
      }
   }

   return (
      <div className="grid-container">
         <h2>Quick actions <span role="img" aria-label="jsx-a11y/accessible-emoji">🚀</span></h2>

         <ul className="grid-list actions">
            {actions.map(action => {
               return actionsSelect(action)
            })}
         </ul>
      </div>
   )
}

export default QuickActionsTile