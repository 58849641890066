import React from "react"

const ExistingPolicy = ({
   upgradeData,
   updateStage,
   updateScriptStage
}) => {
   return (
      <React.Fragment>
         <div className="grid-container">
            <h2>Existing policy</h2>

            <br/>

            <div className="table-wrapper">
               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <th colSpan={2}><h4>Customer details</h4></th>
                     </tr>
                     <tr>
                        <td>Name</td>
                        <td>{upgradeData.personal_details.first_name} {upgradeData.personal_details.last_name}</td>
                     </tr>
                     <tr>
                        <td>Phone number</td>
                        <td>{upgradeData.personal_details.telephone.replaceAll("+44", "0")}</td>
                     </tr>
                     <tr>
                        <td>Email address</td>
                        <td>{upgradeData.personal_details.email_address || "-"}</td>
                     </tr>
                     <tr>
                        <td>Address</td>
                        <td>
                           {upgradeData.personal_details.address.line_1}<br/>
                           {upgradeData.personal_details.address.line_2 !== null ? (
                              <React.Fragment>
                                 {upgradeData.personal_details.address.line_2}<br/>
                              </React.Fragment>
                           ) : null}
                           {upgradeData.personal_details.address.city}<br/>
                           {upgradeData.personal_details.address.postcode}
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <br/>

            <div className="table-wrapper">
               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <th colSpan={2}><h4>Policy details</h4></th>
                     </tr>
                     <tr>
                        <td>Service number</td>
                        <td>{upgradeData.current_package.service_number}</td>
                     </tr>
                     <tr>
                        <td>Product</td>
                        <td>{upgradeData.current_package.name}</td>
                     </tr>
                     <tr>
                        <td>Appliance(s) covered</td>
                        <td>{Object.entries(upgradeData.current_package.chosen_appliances.object).map((entry, i) => (
                           <React.Fragment>
                              {i > 0 ? ', ' : ''}{entry[0]} (x{entry[1]})
                           </React.Fragment>
                        ))}</td>
                     </tr>
                     <tr>
                        <td>Price</td>
                        <td>£{upgradeData.current_package.chosen_cost.toFixed(2)}</td>
                     </tr>
                     <tr>
                        <td>Payment frequency</td>
                        <td>{upgradeData.current_package.chosen_frequency}</td>
                     </tr>
                     <tr>
                        <td>Sign up date</td>
                        <td>{new Date(upgradeData.current_package.sign_up_date).toLocaleDateString('en-GB')}</td>
                     </tr>
                     <tr>
                        <td>Remaining contract</td>
                        <td>{upgradeData.current_package.contract_remaining}</td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <br/>

            <button 
               className="welcome-button secondary"
               onClick={() => updateStage(3)}
            >
               Continue
            </button>
         </div>
      </React.Fragment>
   )
}

export default ExistingPolicy