import React, { useEffect, useState } from "react";
import replaceVariablePlaceholders from "../functions/replaceVariablePlaceholders";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";

const Script = ({
   scriptStage,
   saleData,
   scriptPackageCosts,
   forceScriptData,
   streetName,
   agent
}) => {
   const [scriptContent, setScriptContent] = useState('<p></p>')
   const [showScript, setShowScript] = useState(false)

   const LoadingScript = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'script'});

      return (
         promiseInProgress &&
         <div className="inline-script-loader" style={{margin: 20}}>
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={40} width={40}/>
            <h3>Loading...</h3>
         </div>
      )
   }

   useEffect(() => {
      fetchScriptHTML();
   }, [])

   useEffect(() => {
      fetchScriptHTML()
   }, [scriptStage, forceScriptData])

   const fetchScriptHTML = async () => {
      trackPromise(
         new Promise( async (resolve, reject) => {
            await axios({
               method: 'get',
               url: 'https://api.appliancesure.com/two/admin/portal/scripts/getScriptById',
               params: {
                  script_id: scriptStage
               }
            })
            .then((value) => {
               const data = value.data.script
               const content = replaceVariablePlaceholders(data.content, saleData, scriptPackageCosts, streetName, agent)

               setScriptContent(content)
               resolve(true)
            })
            .catch((err) => {
               reject(err)
            })
         })  
      , 'script')
   }

   const generateHTML = () => {
      return {__html: scriptContent}
   }

   return (
      <React.Fragment>
         <div className={`script-window-container ${showScript ? 'show' : 'hide'}`}>
            <button className="script-tag" onClick={() => setShowScript(!showScript)}>
               {showScript ? 'Close script' : 'Open script'} &nbsp;
            </button>

            <div className="script-container">
               <div className="script-header">My lines <span role="img" aria-label="jsx-a11y/accessible-emoji">🎤</span></div>
               <LoadingScript/>
               <div className="script-inner-container" dangerouslySetInnerHTML={generateHTML()}/>
            </div>
         </div>
         
         
      </React.Fragment>
   )
}

export default Script