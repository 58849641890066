import axios from "axios";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../../../context/userAuthContext";
import Error from "../../../../components/global/error";

const FindSalesCall = ({
   updateStage,
   markedCallData,
   updateMarkedCallData
}) => {
   const { user } = useUserAuth();

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'lookup'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Looking up record...</h3>
         </div>
      )
   }

   const [errors, setErrors] = useState({
      not_found: false,
      phone_number: false,
      service_number: false
   })

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateMarkedCallData({
         ...markedCallData,
         call_data: {
            ...markedCallData.call_data,
            [name]: val
         }
      })

      setErrors({
         ...errors,
         [name]: false,
         not_found: false
      })
   }

   useEffect(() => {
      updateMarkedCallData({
         ...markedCallData,
         call_data: {
            ...markedCallData.call_data,
            service_number: ''
         }
      })
   }, [])

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {}

      if(markedCallData.call_data.service_number === '' || markedCallData.call_data.service_number.startsWith("APS") === false) {
         errors_count++
         errors_object.service_number = true
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })
      }
   }

   const handleResetSearch = () => {
      updateMarkedCallData({
         ...markedCallData,
         call_data: {
            select_method: '',
            record_verified: false,
            service_number: ''
         }
      })
   }

   const handleLookupRecord = async (event) => {
      event.preventDefault();

      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'get',
                  url: `https://api.appliancesure.com/two/admin/portal/quality/salesCall/getRecord`,
                  headers: {
                     Authorization: 'Bearer ' + user
                  },
                  params: {
                     service_number: markedCallData.call_data.service_number
                  }
               })
               .then((value) => {
                  const response = value.data;

                  if(response.found === true) {
                     setTimeout(() => {
                        console.log(response)
                        updateMarkedCallData({
                           ...markedCallData,
                           call_data: {
                              ...markedCallData.call_data,
                              record_verified: true,
                              ...response.record
                           }
                        })

                        resolve("Yes")
                     }, 1000)
                  } else {
                     setTimeout(() => {
                        setErrors({
                           ...errors,
                           not_found: true
                        })
   
                        resolve("Yes")
                     }, 1000)
                     
                  }
               })
               .catch(() => {
                  setTimeout(() => {
                     setErrors({
                        ...errors,
                        not_found: true
                     })

                     resolve("Yes")
                  }, 1000)
               })
            })
         , 'lookup')
      }
   }

   return (
      <div className="grid-container">
         <SubmitLoading/>
         {
            markedCallData.call_data.record_verified === false ? (
               <React.Fragment>
                  <button className="back-button" onClick={() => updateStage(1)}>Go back</button>
                  
                  <br/>
                  <br/>
               </React.Fragment>
            ) : null
         }

         <h2>Find a call</h2>

         <br/>

         {/* Once the file has been looked up and confirmed*/}
         {
            markedCallData.call_data.record_verified === false ? (
               <React.Fragment>
                  <label className="form-label">How will you find a call?</label>
                  <form className="select-option-grid" onChange={handleFillInForm}>
                     <span>
                        <input
                           className="select-option-trigger invisible"
                           type="radio"
                           value="random"
                           id="random"
                           name="select_method"
                           disabled
                           checked={markedCallData.call_data.select_method === 'random'}
                        />

                        <label htmlFor="random" style={{cursor: 'not-allowed'}} className="select-option">Random</label>
                     </span>

                     <span>
                        <input
                           className="select-option-trigger invisible"
                           type="radio"
                           value="manual"
                           id="manual"
                           name="select_method"
                           checked={markedCallData.call_data.select_method === 'manual'}
                        />

                        <label htmlFor="manual" className="select-option">Manual</label>
                     </span>
                  </form>
               </React.Fragment>
            ) : (
               <React.Fragment>
                  <div className="manual-address-container auto-completed">
                     <h3>Call details</h3>

                     <br/>

                     <label className="form-label" htmlFor="service_number">Service number</label>
                     <input
                        className="form-input"
                        id="service_number"
                        disabled
                        value={markedCallData.call_data.service_number}
                     />

                     <label className="form-label" htmlFor="phone_number">Phone number</label>
                     <input
                        className="form-input"
                        id="phone_number"
                        disabled
                        value={markedCallData.call_data.Called_number}
                     />

                     <label className="form-label" htmlFor="phone_number">Call time</label>
                     <input
                        className="form-input"
                        id="phone_number"
                        disabled
                        value={new Date(markedCallData.call_data.recording.file_name.substring(0, 19) +  "." + markedCallData.call_data.recording.file_name.substring(21, 32)).toLocaleString('en-GB')}
                     />

                     <label className="form-label" htmlFor="duration">Call duration</label>
                     <input
                        className="form-input"
                        id="duration"
                        disabled
                        value={`${(markedCallData.call_data.duration / 60).toFixed(2)} minutes`}
                     />

                     <label className="form-label" htmlFor="product">Product</label>
                     <input
                        className="form-input"
                        id="product"
                        disabled
                        value={markedCallData.call_data.Policy_Type}
                     />
                  </div>

                  <br/>

                  <div className="stacked-buttons">
                     <button 
                        className="welcome-button secondary"
                        onClick={() => updateStage(3)}
                     >Continue</button>

                     <button
                        className="plain-text-button"
                        onClick={handleResetSearch}
                     >Search again</button>
                  </div>
                  
               </React.Fragment>
            )
         }

         {
            markedCallData.call_data.select_method === 'manual' && markedCallData.call_data.record_verified === false ? (
               <form onChange={handleFillInForm}>

                  <br/>

                  <label htmlFor="service_number" className="form-label">Service number</label>

                  <input
                     value={markedCallData.call_data.service_number || ''}
                     className="form-input"
                     name="service_number"
                     id="service_number"
                     type="text"
                     placeholder="e.g. APS-30297"
                  />

                  {
                     errors.service_number ? (
                        <Error message="Please enter a valid service number"/>
                     ) : null
                  }

                  {
                     errors.not_found ? (
                        <p style={{marginTop: 0}} className="info-statement">This record could not be found. Please try again. (Be sure to enter the phone number starting with a 0). Remember, the call must not have not have already been marked.</p>
                     ) : null
                  }

                  <br/>

                  <button
                     className="welcome-button secondary"
                     onClick={handleLookupRecord}
                  >
                     Look up record
                  </button>
               </form>
            ) : markedCallData.call_data.select_method === 'random' && markedCallData.call_data.record_verified === false ? (
               <React.Fragment>

               </React.Fragment>
            ) : null
         }

         
         
      </div>
   )
}

export default FindSalesCall