import React, { useState } from "react";
import Error from "../../../components/global/error";

const CallType = ({
   updateStage,
   markedCallData,
   updateMarkedCallData,
   permittedCallTypes
}) => {
   const [errors, setErrors] = useState({
      call_type: false
   })

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateMarkedCallData({
         ...markedCallData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const goToNextPage = () => {
      if(markedCallData.call_type === '') {
         setErrors({...errors, call_type: true})
      } else {
         updateStage(2)
      }
   }

   return (
      <div className="grid-container">
         <h2>Call type</h2>

         <br/>

         <label className="form-label">What kind of call is this?</label>
         <form className="select-option-grid" onChange={handleFillInForm}>
            {
               permittedCallTypes.map(type => (
                  <span>
                     <input
                        className="select-option-trigger invisible"
                        type="radio"
                        value={type}
                        id={type}
                        name="call_type"
                        checked={markedCallData.call_type === type}
                     />

                     <label htmlFor={type} className="select-option">{type.charAt(0).toUpperCase() + type.slice(1).replaceAll("_", " ")}</label>
                  </span>
               ))
            }
         </form>

         {
            errors.call_type ? (
               <React.Fragment>
                  <br/>
                  <Error message="Please select a call type" />
               </React.Fragment>
            ) : null
         }

         <br/>

         <button
            className="welcome-button secondary"
            onClick={goToNextPage}
         >Continue</button>

      </div>
   )
}

export default CallType