import React, { useState, useEffect, useRef } from "react";
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from "draft-js-import-html";
import { FaBold as Bold, FaUnderline as Underline, FaItalic as Italic, FaListUl as UnList } from 'react-icons/fa'
import 'draft-js/dist/Draft.css'
import variables_list from "../resources/variables_list";
import ActivityLog from "../../../components/local/activity-log";

const ScriptEditor = ({
   scriptContent,
   updateScriptContent,
   handleCloseScriptManager,
   handleSaveScriptChanges
}) => {
   const initialState = stateFromHTML(scriptContent.content)
   const [stylesSelected, setStylesSelected] = useState({
      bold: false,
      underline: false,
      italic: false,
      list: false
   })
   const [showChangeLog, setShowChangeLog] = useState(false)

   const handleResetChangeLog = () => {
      setShowChangeLog(false)
   }

   const [editorContent, setEditorContent] = useState(EditorState.createWithContent(
      initialState
   ))

   useEffect(() => {
      updateScriptContent({
         ...scriptContent,
         content: stateToHTML(editorContent.getCurrentContent())
      })
   }, [editorContent])

   const editor = useRef(null)

   const handleKeyCommand = (command, editorContent) => {
      const newState = RichUtils.handleKeyCommand(editorContent, command)
      setStylesSelected({
         ...stylesSelected,
         [command]: !stylesSelected[command]
      })

      if (newState) {
        setEditorContent(newState)
        return 'handled';
      }
  
      return 'not-handled';
   }

   const makeTextBold = (e) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         bold: !stylesSelected.bold
      })
      setEditorContent(RichUtils.toggleInlineStyle(editorContent, 'BOLD'))
      editor.current.focus();
   }

   const makeTextUnderline = (e) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         underline: !stylesSelected.underline
      })
      setEditorContent(RichUtils.toggleInlineStyle(editorContent, 'UNDERLINE'))
      editor.current.focus();
   }

   const makeTextItalic = (e) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         italic: !stylesSelected.italic
      })
      setEditorContent(RichUtils.toggleInlineStyle(editorContent, 'ITALIC'))
      editor.current.focus();
   }

   const handleInsertVariable = (event) => {
      event.preventDefault();
      editor.current.focus()
      const name = event.target.value.toLowerCase().replaceAll(" ", "_")
      
      const pushText = () => {
         const currentContent = editorContent.getCurrentContent();
         const currentSelection = editorContent.getSelection();

         const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            `{{${name}}}`
         )

         const newEditorState = EditorState.push(editorContent, newContent, 'insert-characters')
         return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
      }

      setEditorContent(pushText())
   }

   const makeTextList = (e) => {
      e.preventDefault();
      setStylesSelected({
         ...stylesSelected,
         list: !stylesSelected.list
      })
      setEditorContent(RichUtils.toggleBlockType(editorContent, 'unordered-list-item'))
      editor.current.focus();
   }

   return (
      <div className="center-modal-backdrop">
         <div className="script-modal-container">
            <span>
               <h2>{scriptContent.page} {`>`} {scriptContent.name}</h2>
               <p>{scriptContent.description}</p>

               <button 
                  className="plain-text-button" 
                  style={{textDecoration: 'underline'}}
                  onClick={() => setShowChangeLog(true)}   
               >View change log</button>

               {
                  showChangeLog ? (
                     <ActivityLog
                        log={scriptContent.change_log}
                        path={['Scripts', scriptContent.page, scriptContent.name]}
                        resetModal={handleResetChangeLog}
                     />
                  ) : null
               }
            </span>

            <div className="script-editor-container">
               <div className="script-editor-styles">
                  <button
                     className={`script-style-button ${stylesSelected.bold ? 'selected' : ''}`}
                     onMouseDown={(e) => makeTextBold(e)}
                  ><Bold/></button>

                  <button
                     className={`script-style-button ${stylesSelected.underline ? 'selected' : ''}`}
                     onMouseDown={(e) => makeTextUnderline(e)}
                  ><Underline/></button>

                  <button
                     className={`script-style-button ${stylesSelected.italic ? 'selected' : ''}`}
                     onMouseDown={(e) => makeTextItalic(e)}
                  ><Italic/></button>

                  <button
                     className={`script-style-button ${stylesSelected.list ? 'selected' : ''}`}
                     onMouseDown={(e) => makeTextList(e)}
                  ><UnList/></button>

                  <select value="" onChange={handleInsertVariable} className="variable-insert-select">
                     <option value="" disabled>Insert variable</option>
                     {variables_list.map(variable => (
                        <option value={variable}>{variable}</option>
                     ))}
                  </select>


               </div>
               <div className="script-editor-area" onClick={() => editor.current.focus()}>
                  <Editor
                     ref={editor}
                     editorState={editorContent}
                     onChange={editorState => setEditorContent(editorState)}
                     handleKeyCommand={handleKeyCommand}
                     placeholder="Create your script here"
                  />
               </div>
            </div>
            
            <div className="script-button-container">
               <button
                  className="welcome-button secondary"
                  style={{width: 'fit-content'}}
                  onClick={handleSaveScriptChanges}
               >Save changes</button>

               <button
                  className="plain-text-button"
                  onClick={handleCloseScriptManager}
               >Cancel</button>
            </div>
         </div>
      </div>
   )
}

export default ScriptEditor