import axios from "axios";
import React, { useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import ScriptEditor from "./components/script_editor";
import script_index from "./resources/script_index";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { detect } from "detect-browser";
import SavedSuccessfully from "../../components/saved-successfully";

const ManageScripts = ({
   setShowScreen
}) => {
   const [showScriptList, setShowScriptList] = useState('')
   const [showScriptManager, setShowScriptManager] = useState(false)
   const [savedSuccessfully, setSavedSuccessfully] = useState('')
   const [scriptContent, updateScriptContent] = useState({
      name: '',
      id: '',
      description: '',
      content: '',
      page: ''
   })
   

   const browser = detect()
   const { userDetails } = useUserAuth()

   const EditLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'edit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Saving changes...</h3>
         </div>
      )
   }

   const handleShowScriptManager = async (script) => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/scripts/getScriptById',
         params: {
            script_id: script.name
         }
      })
      .then((value) => {
         const response = value.data.script;

         updateScriptContent({
            name: `Script ${response.title}`,
            id: response.title,
            description: script.description,
            content: response.content,
            change_log: response.activity_log,
            page: showScriptList
         })

         setShowScriptManager(true)
      })
   }

   const handleCloseScriptManager = () => {
      setShowScriptManager(false)
      updateScriptContent({
         name: '',
         id: '',
         description: '',
         content: '',
         page: ''
      })
   }

   const handleSaveScriptChanges = async () => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }

            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'put',
               url: 'https://api.appliancesure.com/two/admin/portal/scripts/editScript',
               params: {
                  script_id: scriptContent.id
               },
               data: {
                  content: scriptContent.content,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then((value) => {
               if(value.data.success === true) {
                  setTimeout(() => {
                     setShowScriptManager(false)
                     updateScriptContent({
                        name: '',
                        id: '',
                        description: '',
                        content: '',
                        page: ''
                     })
                     setSavedSuccessfully('Script saved successfully')
                     resolve("Yes")
                  }, 1000)
               } else {
                  alert("There was an error")
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'edit')
   }

   const showScriptListTile = () => {
      let jsx = []

      if(showScriptList !== '') {
         const script_list = script_index[showScriptList];

         let options_jsx = []

         for (let i = 0; i < script_list.length; i++) {
            const el = script_list[i];
            
            options_jsx.push(
               <li onClick={() => handleShowScriptManager(el)}>
                  <span>
                     <strong>Script {el.name}</strong> 
                     <br/> 
                     {el.description}
                  </span>
               </li>
            )
         }

         jsx.push(
            <div className="grid-container">
               <button className="back-button" onClick={() => setShowScriptList('')}>Close</button>
               <br/>
               <br/>
               <h2>{showScriptList}</h2>
               <br/>
               <ul className="grid-list">
                  {options_jsx}
               </ul>
            </div>
         )
      }
      
      return jsx;
   }

   return (
      <div className="screen-inner-container">
         <EditLoader/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Manage scripts</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>Screens <span role="img" aria-label="jsx-a11y/accessible-emoji">🎥</span></h2>

               <ul className="grid-list">
                  <li className={showScriptList === 'Package' ? 'selected' : ''} onClick={() => setShowScriptList('Package')}>Package</li>
                  <li className={showScriptList === 'Appliances' ? 'selected' : ''} onClick={() => setShowScriptList('Appliances')}>Appliances</li>
                  <li className={showScriptList === 'Personal details' ? 'selected' : ''} onClick={() => setShowScriptList('Personal details')}>Personal details</li>
                  <li className={showScriptList === 'Payment' ? 'selected' : ''} onClick={() => setShowScriptList('Payment')}>Payment</li>
                  <li className={showScriptList === 'Legal' ? 'selected' : ''} onClick={() => setShowScriptList('Legal')}>Legal</li>
                  <li className={showScriptList === 'Success' ? 'selected' : ''} onClick={() => setShowScriptList('Success')}>Success</li>
               </ul>
            </div>

            {showScriptListTile()}
         </div>

         {
            showScriptManager ? (
               <ScriptEditor
                  scriptContent={scriptContent}
                  updateScriptContent={updateScriptContent}
                  handleCloseScriptManager={handleCloseScriptManager}
                  handleSaveScriptChanges={handleSaveScriptChanges}
               />
            ) : null
         }

         {
            savedSuccessfully !== '' ? (
               <SavedSuccessfully
                  content={savedSuccessfully}
                  reset={setSavedSuccessfully}
               />
            ) : null
         }
      </div>
   )
}

export default ManageScripts