const required_fields = [
   "product_name",
   "pricing_model",
   "product_type",
   "payment_frequency",
   "no_of_appliances",
   "length_of_contract",
   "monthly_cost",
   "annual_cost",
   "complex_pricing"
]

export default required_fields