import React from "react";

const AboutSystem = ({ setShowScreen }) => {
   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>About this system</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>Specifications <span role="img" aria-label="jsx-a11y/accessible-emoji">💻</span></h2>
               
               <table className="tariff-information-table">
                  <tr>
                     <td>Region</td>
                     <td>eu-west-2 (Europe/London)</td>
                  </tr>
                  <tr>
                     <td>Runtime</td>
                     <td>Node.js v16.13.1</td>
                  </tr>
                  <tr>
                     <td>Server distribution</td>
                     <td>Linux CentOS v7.9.2009</td>
                  </tr>
                  <tr>
                     <td>Processor</td>
                     <td>Intel (R) Xeon (R) Gold 5220 CPU @ 2.20GHz</td>
                  </tr>
                  <tr>
                     <td>Browser requirements</td>
                     <td>Google Chrome</td>
                  </tr>
                  <tr>
                     <td>Firewall configuration</td>
                     <td>Enabled</td>
                  </tr>
                  <tr>
                     <td>Time-based restriction</td>
                     <td>Enabled</td>
                  </tr>
                  <tr>
                     <td>Roles-based hierarchy</td>
                     <td>Enabled</td>
                  </tr>
                  <tr>
                     <td>2-Factor Authentication</td>
                     <td>Disabled</td>
                  </tr>
               </table>
            </div>
         </div>
      </div>
   )
}

export default AboutSystem