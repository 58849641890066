const countryFlag = (country) => {
   switch (country) {
      case "India":
         return "🇮🇳"
      
      case "Pakistan":
         return "🇵🇰"

      case "Phillipines":
         return "🇵🇭"

      case "South Africa":
         return "🇿🇦"

      case "United Kingdom":
         return "🇬🇧"
      
      case "United States":
         return "🇺🇸"
      
      default:
         return "🌍"
   }
}

const country_directory = [
   "India",
   "Pakistan",
   "Phillipines",
   "South Africa",
   "United Kingdom",
   "United States",
   "Other"
]

export { country_directory }
export default countryFlag