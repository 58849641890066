import React, { useEffect, useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import PresentationViewer from "./knowledge_content/presentation-viewer";
import AddNewContent from "./knowledge_content/add_new_content";
import TextViewer from "./knowledge_content/text-viewer";
import EditContent from "./knowledge_content/edit_content";
import SavedSuccessfully from "../components/saved-successfully";
import URLViewer from "./knowledge_content/url-viewer";

const KnowledgeBase = ({ permissions }) => {
   const [showKnowledgeScreen, setShowKnowledgeScreen] = useState('')
   const [contentList, setContentList] = useState([])
   const [createContent, setCreateContent] = useState(false)
   const [editContent, setEditContent] = useState(false)
   const [launchContent, setLaunchContent] = useState('')
   const [savedSuccessfully, setSavedSuccessfully] = useState('')

   const LoadingTracker = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'get_content'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Verifying email address...</h3>
         </div>
      )
   }

   const contentViewer = () => {
      if( launchContent !== '') {
         switch (contentList[launchContent].media_format) {
            case "presentation":
               return <PresentationViewer
                        content={contentList[launchContent]}
                        setLaunchContent={setLaunchContent}
                        setEditContent={setEditContent}
                        permissions={permissions.permissions}
                     />
            case "text":
               return <TextViewer
                        content={contentList[launchContent]}
                        setLaunchContent={setLaunchContent}
                        setEditContent={setEditContent}
                        permissions={permissions.permissions}
                     />
            
            case "url":
               return <URLViewer
                        content={contentList[launchContent]}
                        setLaunchContent={setLaunchContent}
                        setEditContent={setEditContent}
                        permissions={permissions.permissions}
                     />
            
            default:
               return null
         }
      }
   }

   const getContentByCategory = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/knowledge/getContentByCategory',
         params: {
            category: showKnowledgeScreen
         }
      })
      .then((value) => {
         const response = value.data.success

         if(response === true) {
            setContentList(value.data.records)
         }
      })
   }

   useEffect(() => {
      if(showKnowledgeScreen !== '') {
         getContentByCategory()
      }
   }, [showKnowledgeScreen])

   const showContentListTile = () => {
      let jsx = []

      if(showKnowledgeScreen !== '') {
         jsx.push(
            <div className="grid-container">
               <h2>{showKnowledgeScreen.charAt(0).toUpperCase() + showKnowledgeScreen.slice(1).replaceAll("_", " ")}</h2>

               <br/>
               
               {
                  contentList.length > 0 ? (
                     <ul className="grid-list">
                        {[...contentList.sort((a, b) => a.series_episode - b.series_episode)].map((record, i) => (
                           <li onClick={() => setLaunchContent(i)}>{showKnowledgeScreen === 'introductory_training' ? `EP${record.series_episode} - ` : ''}{record.title}</li>
                        ))}
                     </ul>
                  ) : <p>There is no content currently available</p>
               }

               {
                  showKnowledgeScreen !== 'introductory_training' ? (
                     <React.Fragment>
                        <br/>
                        
                        {
                           permissions.permissions === 'edit' ? (
                              <button onClick={() => setCreateContent(true)} className="welcome-button secondary">Add new content</button>
                           ) : null
                        }
                        
                     </React.Fragment>
                  ) : null
               }
               
               
            </div>
         )
         
      }

      return jsx
   }

   return (
      <div className="screen-inner-container">
         <LoadingTracker/>
         <h1>Knowledge base</h1>
         
         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>Categories <span role="img" aria-label="jsx-a11y/accessible-emoji">🗂</span></h2>

               <br/>

               <ul className="grid-list">
                  <li className={showKnowledgeScreen === 'introductory_training' ? 'selected' : ''} onClick={() => setShowKnowledgeScreen('introductory_training')}>Introductory training</li>
                  <li className={showKnowledgeScreen === 'objection_handling' ? 'selected' : ''} onClick={() => setShowKnowledgeScreen('objection_handling')}>Objection handling</li>
                  <li className={showKnowledgeScreen === 'product_knowledge' ? 'selected' : ''} onClick={() => setShowKnowledgeScreen('product_knowledge')}>Product knowledge</li>
                  <li className={showKnowledgeScreen === 'competitor_analysis' ? 'selected' : ''} onClick={() => setShowKnowledgeScreen('competitor_analysis')}>Competitor analysis</li>
                  <li className={showKnowledgeScreen === 'policies' ? 'selected' : ''} onClick={() => setShowKnowledgeScreen('policies')}>Policies</li>
               </ul>
            </div>

            {showContentListTile()}
         </div>

         {contentViewer()}

         {
            createContent ? (
               <AddNewContent
                  category={showKnowledgeScreen}
                  setCreateContent={setCreateContent}
                  getContentByCategory={getContentByCategory}
                  process="create"
                  setSavedSuccessfully={setSavedSuccessfully}
               />
            ) : null
         }

         {
            editContent ? (
               <EditContent
                  content={contentList[launchContent]}
                  setEditContent={setEditContent}
                  getContentByCategory={getContentByCategory}
                  process="edit"
                  setSavedSuccessfully={setSavedSuccessfully}
               />
            ) : null
         }

         {
            savedSuccessfully !== '' ? (
               <SavedSuccessfully
                  content={savedSuccessfully}
                  reset={setSavedSuccessfully}
               />
            ) : null
         }
      </div>
   )
}

export default KnowledgeBase