import React, { createContext, useContext, useState } from "react";
import jwt from 'jsonwebtoken'
import { useNavigate } from "react-router-dom";

const userAuthContext = createContext();

export const UserAuthContextProvider = ({ children }) => {
   const access_token = localStorage.getItem("user_auth")
   const [user, setUser] = useState(access_token || '')
   const [userDetails, setUserDetails] = useState(jwt.decode(access_token) || {})

   const navigate = useNavigate();

   const LogOut = () => {
      localStorage.removeItem("user_auth")
      setUser("")
      setUserDetails({})
      navigate("/")
   }

   /*useEffect(() => {
      setUser(access_token)
      setUserDetails(jwt.decode(access_token))
   }, [])*/
   
   return (
      <userAuthContext.Provider value={{ user, userDetails, LogOut, setUserDetails, setUser }}>
         {children}
      </userAuthContext.Provider>
   )
}

export const useUserAuth = () => {
   return useContext(userAuthContext)
}