import React, { useEffect, useState } from "react";
import Error from "../../components/global/error";
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input";
import countryFlag, { country_directory } from "./resources/country_directory";
import required_fields from "./resources/required_fields_partner";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { detect } from "detect-browser";
import { useUserAuth } from "../../context/userAuthContext";

const CreatePartner = ({
   partnerData,
   productData,
   setShowScreen,
   getMetricData,
   setPassParams
}) => {
   const { userDetails } = useUserAuth();

   const [contactPhone, updateContactPhone] = useState('')
   const [newPartnerData, updateNewPartnerData] = useState({
      partner_name: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      legal_name: '',
      company_number: '',
      country_authority: '',
      address: {
         line_1: '',
         line_2: '',
         line_3: '',
         county_state: '',
         city: '',
         country: '',
         postcode: ''
      },
      created: {
         by: {
            email_address: userDetails.email_address
         }
      },
      products: []
   })

   const [errors, setErrors] = useState({
      partner_name: false,
      legal_name: false,
      company_number: false,
      country_authority: false,
      line_1: false,
      city: false,
      postcode: false,
      country: false,
      contact_name: false,
      contact_email: false, 
      products: false
   })

   const [showSections, updateShowSections] = useState({
      legal: false,
      address: false,
      contact: false,
      products: false
   })

   const [products, setProducts] = useState([])
   const [productsSelect, updateProductsSelect] = useState({})

   const browser = detect()

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Creating user...</h3>
         </div>
      )
   }

   // Put all available products in products
   useEffect(() => {
      let active_products_list = []
      for (let i = 0; i < productData.length; i++) {
         const el = productData[i];
         
         if(el.active === true) {
            active_products_list.push(el)
         }
      }
      setProducts(active_products_list)
   }, [])

   useEffect(() => {
      updateNewPartnerData({
         ...newPartnerData,
         contact_phone: contactPhone
      })
   }, [contactPhone])

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;
      const type = event.target.dataset.type
      
      if(type !== 'section') {
         if(name !== '') {
            if(type === 'address') {
               updateNewPartnerData({
                  ...newPartnerData,
                  address: {
                     ...newPartnerData.address,
                     [name]: val
                  }
               })
            } else if (name === 'country_authority' && val !== 'Other') {
               updateNewPartnerData({
                  ...newPartnerData,
                  country_authority: val,
                  address: {
                     ...newPartnerData.address,
                     country: val
                  }
               })
            } else {
               updateNewPartnerData({
                  ...newPartnerData,
                  [name]: val
               })
            }
            
            
            setErrors({
               ...errors,
               [name]: false
            })
         }
      }
   }

   const handleUpdateSection = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      updateShowSections({
         ...showSections,
         [name]: val
      })
   }

   const handleChangeProductAccess = (event) => {
      const name = event.target.name;
      const val = event.target.checked;

      updateProductsSelect({
         ...productsSelect,
         [name]: val
      })

      setErrors({
         ...errors,
         products: false
      })
   }

   // Change product access in partnerData
   useEffect(() => {
      const products_array = Object.entries(productsSelect)

      const selected = []
      for (let i = 0; i < products_array.length; i++) {
         const el = products_array[i];
         
         if(el[1] === true) {
            selected.push(el[0])
         }
      }

      updateNewPartnerData({
         ...newPartnerData,
         products: selected
      })
   }, [productsSelect])

   const nav = () => {
      window.scrollTo({ top: 410, behavior: 'smooth' });
   }

   const handleDataValidation = () => {
      let errors_count = 0
      let errors_object = {}
      let sections_object = {}

      for (let i = 0; i < required_fields.length; i++) {
         const el = required_fields[i];
         
         if(el[0] === 'products') {
            if(newPartnerData.products.length === 0) {
               errors_count += 1
               errors_object[el[0]] = true
               sections_object[el[1]] = true
            }
         } else if (el[0] === 'email') {
            if(newPartnerData.contact_email.includes("@") === false || newPartnerData.contact_email.includes("." === false)) {
               errors_count += 1
               errors_object[el[0]] = true
               sections_object[el[1]] = true
            }
         } else if(el[1] === 'address') {
            if(newPartnerData.address[el[0]] === '') {
               errors_count += 1
               errors_object[el[0]] = true
               sections_object[el[1]] = true
            }
         } else {
            if(newPartnerData[el[0]] === '') {
               errors_count += 1
               errors_object[el[0]] = true
               sections_object[el[1]] = true
            }
         }
      }

      setErrors({
         ...errors,
         ...errors_object
      })

      updateShowSections({
         ...showSections,
         ...sections_object
      })

      if(errors_count === 0) {
         return true
      } else {
         nav();
         return false
      }
   }

   const submitPartner = async (event) => {
      event.preventDefault();
      
      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               try {
                  // Get IP & device details
                  let ip_address = 'Unknown'

                  try {
                     const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                     ip_address = ip_lookup.data.IPv4
                  }
               
                  catch (err) {
                     console.log(err)
                  }

                  await axios({
                     method: 'post',
                     url: 'https://api.appliancesure.com/two/admin/portal/partners/createNewPartner',
                     data: {
                        data: {
                           contact_phone: contactPhone,
                           ...newPartnerData
                        },
                        log_details: {
                           ip_address: ip_address,
                           action_by: {
                              name: userDetails.first_name + " " + userDetails.last_name,
                              partner: userDetails.partner,
                              email_address: userDetails.email_address
                           },
                           browser: browser.name,
                           os: browser.os
                        }
                     }
                  }).then( async (value) => {
                     if(value.data.success === true) {
                        // Proceed
                        await getMetricData();

                        setTimeout(() => {
                           setPassParams({partner: newPartnerData.partner_name});
                           setShowScreen('manage_partners')
                           resolve("Yes")
                        }, 1000)
                     } else {
                        // There was an error
                        alert(value.data.reason)
                        resolve("Yes")
                     }
                  }).catch(() => {
                     alert("There was an error")
                     resolve("Yes")
                  })

                  /*const response = await createNewCompany(newPartnerData)
                  if(response.success === true) {
                     const resp = await getMetricData()
                     if(resp.success === true) {
                        setTimeout(() => {
                           setPassParams({partner: newPartnerData.partner_name});
                           setShowScreen('manage_partners')
                        }, 1000)
                     }
                  } else {
                     alert(response.reason)
                  }*/
               }
         
               catch (err) {
                  console.log(err)
               }
            })
         , 'submit')
      }
   }

   return (
      <div className="screen-inner-container">
         <SubmitLoading/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Create a new partner</h2>

         <div className="two-col-grid-container">
            <div className="grid-container">
               <h2>New partner details <span role="img" aria-label="jsx-a11y/accessible-emoji">✏️</span></h2>

               <br/>

               <form onChange={handleFillInForm}>
                  <label htmlFor="partner_name" className="form-label">Partner name*</label>
                  <label htmlFor="partner_name" className="description">(Trading as), don't include words like "limited".</label>
                  <input
                     id="partner_name"
                     name="partner_name"
                     type="text"
                     className="form-input"
                     value={newPartnerData.partner_name}
                     placeholder="Enter name of the new partner"
                  />

                  {
                     errors.partner_name ? (
                        <Error message="Please enter the name of the partner"/>
                     ) : null
                  }

                  {/* Legal section */}

                  <input
                     className="invisible accordion-trigger"
                     type="checkbox"
                     name="legal"
                     data-type="section"
                     checked={showSections.legal}
                     onClick={handleUpdateSection}
                     id="legal"
                  />

                  <label htmlFor="legal" className="accordion-label">
                     <h3>Legal</h3>
                  </label>
                  
                  <div className="form-section-container">
                     <label htmlFor="legal_name" className="form-label" style={{marginTop: 10}}>Legal name of business*</label>
                     <input
                        id="legal_name"
                        name="legal_name"
                        type="text"
                        className="form-input"
                        value={newPartnerData.legal_name}
                        placeholder="Enter legal name of the business"
                     />

                     {
                        errors.legal_name ? (
                           <Error message="Please enter the legal name of the partner"/>
                        ) : null
                     }

                     <label htmlFor="company_number" className="form-label">Company registration number*</label>
                     <input
                        id="company_number"
                        name="company_number"
                        type="text"
                        className="form-input"
                        value={newPartnerData.company_number}
                        placeholder="Enter the company's registration number"
                     />

                     {
                        errors.company_number ? (
                           <Error message="Please enter the partner's company number"/>
                        ) : null
                     }

                     <label htmlFor="country_authority" className="form-label">Country authority*</label>
                     <select
                        className="form-input icon"
                        name="country_authority"
                        id="country_authority"
                        value={newPartnerData.country_authority}
                     >
                        <option value="" default disabled>Choose a country</option>
                        {country_directory.map(country => (
                           <option value={country}>{country}</option>
                        ))}
                     </select>

                     <div className="form-icon">
                        {countryFlag(newPartnerData.country_authority)}
                     </div>

                     {
                        errors.company_number ? (
                           <React.Fragment>
                              <div style={{marginTop: -50}}/>
                              <Error message="Please enter the partner's company number"/>
                           </React.Fragment>
                           
                        ) : null
                     }
                  </div>

                  <br/>

                  {/* Registered address section */}
                  <input
                     className="invisible accordion-trigger"
                     type="checkbox"
                     name="address"
                     data-type="section"
                     checked={showSections.address}
                     onChange={handleUpdateSection}
                     id="address"
                  />

                  <label htmlFor="address" className="accordion-label">
                     <h3>Registered address</h3>
                  </label>

                  <div className="form-section-container">
                     <label htmlFor="line_1" className="form-label" style={{marginTop: 10}}>Line 1*</label>
                     <input
                        id="line_1"
                        name="line_1"
                        type="text"
                        className="form-input"
                        data-type="address"
                        value={newPartnerData.address.line_1}
                        placeholder="Enter the first line of the address"
                     />

                     {
                        errors.line_1 ? (
                           <Error message="Please enter line 1 of the address"/>
                        ) : null
                     }

                     <label htmlFor="line_2" className="form-label">Line 2</label>
                     <input
                        id="line_2"
                        name="line_2"
                        type="text"
                        className="form-input"
                        data-type="address"
                        value={newPartnerData.address.line_2}
                        placeholder="Enter the second line of the address"
                     />

                     <label htmlFor="line_3" className="form-label">Line 3</label>
                     <input
                        id="line_3"
                        name="line_3"
                        type="text"
                        className="form-input"
                        data-type="address"
                        value={newPartnerData.address.line_3}
                        placeholder="Enter the third line of the address"
                     />

                     <label htmlFor="county_state" className="form-label">County / State</label>
                     <input
                        id="county_state"
                        name="county_state"
                        type="text"
                        className="form-input"
                        data-type="address"
                        value={newPartnerData.address.county_state}
                        placeholder="Enter the county / state"
                     />

                     <label htmlFor="city" className="form-label">City*</label>
                     <input
                        id="city"
                        name="city"
                        type="text"
                        className="form-input"
                        data-type="address"
                        value={newPartnerData.address.city}
                        placeholder="Enter the city"
                     />

                     {
                        errors.city ? (
                           <Error message="Please enter the city"/>
                        ) : null
                     }

                     <label htmlFor="postcode" className="form-label">Postcode / zipcode*</label>
                     <input
                        id="postcode"
                        name="postcode"
                        type="text"
                        className="form-input"
                        data-type="address"
                        value={newPartnerData.address.postcode}
                        placeholder="Enter the postcode / zipcode"
                     />

                     {
                        errors.postcode ? (
                           <Error message="Please enter the postcode or zipcode"/>
                        ) : null
                     }

                     <label htmlFor="country" className="form-label">Country*</label>
                     <input
                        id="country"
                        name="country"
                        type="text"
                        className="form-input"
                        data-type="address"
                        value={newPartnerData.address.country}
                        placeholder="Enter the county or state"
                     />

                     {
                        errors.partner_name ? (
                           <Error message="Please enter the country"/>
                        ) : null
                     }
                  </div>

                  <br/>

                  {/* Key contact */}
                  <input
                     className="invisible accordion-trigger"
                     type="checkbox"
                     name="contact"
                     data-type="section"
                     checked={showSections.contact}
                     onClick={handleUpdateSection}
                     id="contact"
                  />

                  <label htmlFor="contact" className="accordion-label">
                     <h3>Key contact</h3>
                  </label>
                  
                  <div className="form-section-container">
                     <label htmlFor="contact_name" className="form-label" style={{marginTop: 10}}>Contact name*</label>
                     <input
                        id="contact_name"
                        name="contact_name"
                        type="text"
                        className="form-input"
                        value={newPartnerData.contact_name}
                        placeholder="Enter name of primary contact"
                     />

                     {
                        errors.contact_name ? (
                           <Error message="Please enter the name of the key contact"/>
                        ) : null
                     }

                     <label htmlFor="contact_email" className="form-label">Contact email*</label>
                     <input
                        id="contact_email"
                        name="contact_email"
                        type="text"
                        className="form-input"
                        value={newPartnerData.contact_email}
                        placeholder="Enter email of primary contact"
                     />

                     {
                        errors.contact_email ? (
                           <Error message="Please enter the email of the key contact"/>
                        ) : null
                     }

                     <label className="form-label">Contact phone</label>
                     <PhoneInput
                        placeholder="Enter phone no. of primary contact"
                        value={contactPhone}
                        onChange={updateContactPhone}
                        defaultCountry="GB"
                     />
                  </div>
               </form>

               <br/>

               {/* Product access */}

               <input
                  className="invisible accordion-trigger"
                  type="checkbox"
                  name="products"
                  data-type="section"
                  checked={showSections.products}
                  onClick={handleUpdateSection}
                  id="products"
               />

               <label htmlFor="products" className="accordion-label">
                  <h3>Product access</h3>
               </label>

               <div className="form-section-container">
                  <div className="access-selected-container" style={{marginTop: 10}}>
                     {newPartnerData.products.map(el => (
                        <button 
                           className="access-selected-item"
                           onClick={() => updateProductsSelect({...productsSelect, [el]: false})}
                        >{el}</button>
                     ))}
                  </div>

                  <div className="partner-access-scrollarea">
                     {products.map((product) => (
                        <div className="partner-access-row">
                           <h5>{product.product_name}</h5>
                           <label className="switchbox standard">
                              <input
                                 id="auto-update"
                                 type="checkbox"
                                 name={product.product_name}
                                 onChange={handleChangeProductAccess}
                                 checked={productsSelect[product.product_name]}
                              />
                              <span className="slider-switchbox standard"></span>
                           </label>
                        </div>
                     ))}
                  </div>

                  {
                     errors.products ? (
                        <div style={{marginTop: '35px'}}>
                           <Error message="Please select at least one product for the partner to access"/>
                        </div>
                        
                     ) : null
                  }
               </div>


               <br/>

               <button
                  onClick={submitPartner}
                  className="welcome-button secondary"
               >Create partner</button>
            </div>
         </div>
      </div>
   )
}

export default CreatePartner