import axios from "axios";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import ConfirmModal from "../../components/global/confirm-modal";
import ActivityLog from "../../components/local/activity-log";
import { useUserAuth } from "../../context/userAuthContext";
import SavedSuccessfully from "../../components/saved-successfully";
import { detect } from "detect-browser";

const ManageUsers = ({ 
   userData, 
   partnerData,
   setShowScreen, 
   getMetricData,
   setPassParams,
   passParams,
   permissions
}) => {
   // States
   const [showActiveUsers, setShowActiveUsers] = useState(true)
   const [searchByPartner, setSearchByPartner] = useState('')
   const [selectUser, setSelectUser] = useState('')
   const [savedSuccessfully, setSavedSuccessfully] = useState('')
   const [changeUserType, setChangeUserType] = useState(false)
   const [newUserType, setNewUserType] = useState('')

   const [operationsModal, setOperationsModal] = useState('')

   const browser = detect();
   const { userDetails, user } = useUserAuth();

   // Set permission levels of user
   const [permissionScope, setPermissionScope] = useState('')

   const SendInviteLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'send_invite'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Sending invite...</h3>
         </div>
      )
   }

   const ProcessingLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'change'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Processing request...</h3>
         </div>
      )
   }

   useEffect(() => {
      setChangeUserType(false)
      setNewUserType('')
   }, [selectUser])

   useEffect(() => {
      const actions_array = permissions.actions
      for (let i = 0; i < actions_array.length; i++) {
         const el = actions_array[i];
         
         if(el.name === 'manage_users') {
            setPermissionScope(el.scope)

            if(el.scope !== 'global') {
               setSearchByPartner(userDetails.partner)
            }
         }
      }
   }, [])

   // Check if params have been passed to preload user
   useEffect(() => {
      if(passParams.user) {
         findParamUser()
      }

      if(passParams.partner) {
         setSearchByPartner(passParams.partner)
         setPassParams('')
      }
   }, [])

   const findParamUser = () => {
      let user_index = '';

      for (let i = 0; i < userData.length; i++) {
         const el = userData[i];
         
         if(el.email_address === passParams.user) {
            user_index = i
         }
      }

      if(user_index === '') {
         return null
      } else {
         setSelectUser(user_index)
         setPassParams('')
      }
   }

   const createUserList = () => {
      let jsx = [];

      for (let i = 0; i < userData.length; i++) {
         const user_details = userData[i];
         
         if(user_details.partner.includes(searchByPartner) && user_details.active === showActiveUsers) {
            jsx.push(
               <React.Fragment>
                  <li className={selectUser === i ? 'selected' : ''} onClick={() => setSelectUser(i)}>{user_details.first_name} {user_details.last_name} { searchByPartner === '' ? `[${user_details.partner}]` : null}</li>
               </React.Fragment>
            )
         }
      }

      return jsx;
   }

   const handleResendAccountInvite = async (user_details) => {
      trackPromise(
         new Promise( async (resolve) => {
            await axios({
               method: 'post',
               url: 'https://api.appliancesure.com/two/admin/portal/account/sendAccountSetup',
               headers: {
                  Authorization: "Bearer " + user
               },
               data: {
                  user_data: {
                     first_name: user_details.first_name,
                     last_name: user_details.last_name,
                     email_address: user_details.email_address
                  },
                  requester: userDetails.first_name + " " + userDetails.last_name
               }
            })
            .then((value) => {
               if(value.data.success === true) {
                  setTimeout(() => {
                     setOperationsModal('')
                     setSavedSuccessfully('Invite re-sent successfully')
                     resolve("Yes")
                  }, 1000)
               } else {
                  alert("There was an error")
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'send_invite')
   }

   const handleChangeUserStatus = async (email_address, status) => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }
         
            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'put',
               url: 'https://api.appliancesure.com/two/admin/portal/users/changeUserStatus',
               headers: {
                  Authorization: "Bearer " + user
               },
               params: {
                  email_address: email_address
               },
               data: {
                  new_status: status,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then( async (value) => {
               if(value.data.success === true) {
                  await getMetricData();

                  setTimeout(() => {
                     setShowActiveUsers(status)
                     setOperationsModal('')
                     setSavedSuccessfully(`User ${status ? 're-activated' : 'suspended'} successfully`)
                     resolve("Yes")
                  }, 1000)
               } else {
                  alert(value.data.reason)
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'change')
   }

   const handleChangeUserType = async (email_address, type, partner) => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }
         
            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'put',
               url: 'https://api.appliancesure.com/two/admin/portal/users/changeUserType',
               headers: {
                  Authorization: "Bearer " + user
               },
               params: {
                  email_address: email_address
               },
               data: {
                  new_type: type,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then( async (value) => {
               if(value.data.success === true) {
                  await getMetricData();

                  setTimeout(() => {
                     setSavedSuccessfully('Permissions changed successfully')
                     setOperationsModal('')
                     resolve("Yes")
                  }, 1000)
               } else {
                  alert("There was an error")
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'change')
   }

   const handleSendPasswordReset = async (user_details) => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }
         
            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'post',
               url: "https://api.appliancesure.com/two/admin/portal/account/sendPasswordReset",
               headers: {
                  Authorization: "Bearer " + user
               },
               data: {
                  user_data: user_details,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then( async (value) => {
               if(value.data.success === true) {
                  await getMetricData();

                  setTimeout(() => {
                     setSavedSuccessfully('Password reset sent successfully')
                     setOperationsModal('')
                     resolve('')
                  }, 1000)
               } else {
                  // There was an error
                  alert(value.data.reason)
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'change')
   }

   const handleChangeStatusSearch = (event) => {
      const val = event.target.value;

      if(val === 'true') {
         setShowActiveUsers(true)
      } else {
         setShowActiveUsers(false)
      }
   }
   
   const handleChangePartnerSearch = (event) => {
      const val = event.target.value;

      setSearchByPartner(val)
   }

   const handleResetOperationsModal = () => {
      setOperationsModal('')
   }

   const showUserTile = () => {
      let jsx = []

      if(selectUser !== '') {
         const user_details = userData[selectUser]
         jsx.push(
            <div className="grid-container">
               <button className="back-button" onClick={() => setSelectUser('')}>Close</button>
               <br/>
               <br/>
               <h2>{user_details.first_name} {user_details.last_name}  <span className={user_details.account_status === 'pending' ? 'suspended-record' : 'active-record'}>{user_details.account_status === 'pending' ? '[Pending]' : null}</span></h2>
               <br/>

               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <td>First name</td>
                        <td>{user_details.first_name}</td>
                     </tr>
                     <tr>
                        <td>Last name</td>
                        <td>{user_details.last_name}</td>
                     </tr>
                     <tr>
                        <td>Email</td>
                        <td style={{lineBreak: 'anywhere'}}>{user_details.email_address}</td>
                     </tr>
                     <tr>
                        <td>Partner</td>
                        <td>{user_details.partner}</td>
                     </tr>
                     <tr>
                        <td>User type</td>
                        <td>{user_details.user_type}</td>
                     </tr>
                     <tr>
                        <td>User since</td>
                        <td>{new Date(user_details.created.at).toLocaleDateString('en-GB')}</td>
                     </tr>
                  </tbody>
               </table>

               <br/>
               <br/>

               <h3>Actions 🛠</h3>

               <ul className="grid-list">
                  {
                     user_details.active ? (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Suspend ${user_details.email_address}`)}>Suspend user</li>
                           <li onClick={() => setOperationsModal(`Reset password - ${user_details.email_address}`)}><span>Reset password</span></li>
                           <li onClick={() => setChangeUserType(true)}>Change user permission level</li>

                           {
                              changeUserType ? (
                                 <React.Fragment>
                                    <br/>
                                    <label className="form-label">New user type</label>

                                    <select
                                       className="form-input"
                                       onChange={(e) => { setOperationsModal(`Change user type - ${user_details.email_address}`); setNewUserType(e.target.value)}}
                                    >
                                       <option value="" selected disabled>Select a new user type</option>
                                       <option value="Admin" style={{display: user_details.user_type === 'Admin' ? 'none' : 'block'}}>Admin</option>

                                       {
                                          permissionScope === 'global' ? (
                                             <React.Fragment>
                                                <option value="Super Admin" style={{display: user_details.user_type === 'Super Admin' ? 'none' : 'block'}}>Super Admin</option>
                                                <option value="Upsell Agent" style={{display: user_details.user_type === 'Upsell Agent' ? 'none' : 'block'}}>Upsell Agent</option>
                                             </React.Fragment>
                                          ) : null
                                       }

                                       <option value="Sales Agent" style={{display: user_details.user_type === 'Sales Agent' ? 'none' : 'block'}}>Sales Agent</option>
                                       <option value="Quality Controller" style={{display: user_details.user_type === 'Quality Controller' ? 'none' : 'block'}}>Quality Controller</option>
                                    </select>

                                    <br/>
                                 </React.Fragment>
                              )  : null 
                           }

                           <li onClick={() => setOperationsModal(`See user activity - ${user_details.email_address}`)}>See user activity</li>
                           {
                              user_details.account_status === 'pending' ? (
                                 <li onClick={() => setOperationsModal(`Resend account invite - ${user_details.email_address}`)}>Resend account invite</li>
                              ) : null
                           }
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Re-activate ${user_details.email_address}`)}>Reactivate user</li>
                           <li onClick={() => setOperationsModal(`See user activity - ${user_details.email_address}`)}>See user activity</li>
                        </React.Fragment>
                     )
                  }

                  {/* Operations */}
                  {/*--- Suspend / Re-activate user ---*/}
                  {operationsModal === `Suspend ${user_details.email_address}` ? (
                     <ConfirmModal 
                        onConfirm={() => handleChangeUserStatus(user_details.email_address, false)}
                        onReject={handleResetOperationsModal}
                        operation={`suspend ${user_details.first_name}'s account`}
                     />
                  ) : null}

                  {operationsModal === `Re-activate ${user_details.email_address}` ? (
                     <ConfirmModal 
                        onConfirm={() => handleChangeUserStatus(user_details.email_address, true, user_details.partner)}
                        onReject={handleResetOperationsModal}
                        operation={`re-activate ${user_details.first_name}'s account`}
                     />
                  ) : null}

                  {/*--- Change user permissions type ---*/}
                  {operationsModal === `Change user type - ${user_details.email_address}` ? (
                     <ConfirmModal 
                        onConfirm={() => handleChangeUserType(user_details.email_address, newUserType, user_details.partner)}
                        onReject={handleResetOperationsModal}
                        operation={`change ${user_details.first_name}'s permissions to ${newUserType}`}
                     />
                  ) : null}

                  {/*--- Password reset ---*/}
                  {operationsModal === `Reset password - ${user_details.email_address}` ? (
                     <ConfirmModal 
                        onConfirm={() => handleSendPasswordReset(user_details)}
                        onReject={handleResetOperationsModal}
                        operation={`send ${user_details.first_name} a password reset email`}
                     />
                  ) : null}

                  {/*--- See user activity ---*/}
                  {operationsModal === `See user activity - ${user_details.email_address}` ? (
                     <ActivityLog
                        log={user_details.activity_log}
                        path={['Users', user_details.partner, `${user_details.first_name} ${user_details.last_name}`]}
                        resetModal={handleResetOperationsModal}
                     />
                  ) : null}

                  {/*--- Resend account invite ---*/}
                  {operationsModal === `Resend account invite - ${user_details.email_address}` ? (
                     <ConfirmModal
                        onConfirm={() => handleResendAccountInvite(user_details)}
                        onReject={handleResetOperationsModal}
                        operation={`resend an account invite to ${user_details.first_name}`}
                     />
                  ) : null}
               </ul>
            </div>
         )
      }

      return jsx;
   }

   return (
      <div className="screen-inner-container">
         <SendInviteLoader/>
         <ProcessingLoader/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Manage users</h2>

         <div className="two-col-grid-container">
            <div>
               {/* Filters */}
               <div className="filter-options-container">
                  <span>
                     <label className="form-label">User status</label>
                     <select 
                        className="form-input admin-row"
                        onChange={handleChangeStatusSearch}
                        value={showActiveUsers}
                     >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                     </select>
                  </span>

                  {
                     permissionScope === 'global' ? (
                        <span>
                           <label className="form-label">Partner</label>
                           <select
                              className="form-input admin-row"
                              value={searchByPartner}
                              onChange={handleChangePartnerSearch}
                           >
                              <option value="" selected disabled>Select a partner</option>
                              {
                                 partnerData.map((partner) => (
                                    <option value={partner.partner_name}>{partner.partner_name}</option>
                                 ))
                              }
                           </select>
                        </span>
                     ) : null
                  }
               </div>

               {/* Users */}
               <div className="grid-container">
                  <h2>Users <span role="img" aria-label="jsx-a11y/accessible-emoji">👨</span></h2>

                  <ul className="grid-list">
                     {createUserList()}
                  </ul>

                  <button
                     onClick={() => setShowScreen('create_user')}
                     className="welcome-button secondary"
                  >Create new user</button>
               </div>
            </div>

            {showUserTile()}

            {
               savedSuccessfully !== '' ? (
                  <SavedSuccessfully
                     content={savedSuccessfully}
                     reset={setSavedSuccessfully}
                  />
               ) : null
            }
         </div>
      </div>
   )
}

export default ManageUsers