import React, { useState, useEffect } from "react";
import Error from "../../components/global/error";

const Appliances = ({
   upgradeData,
   updateUpgradeData,
   updateStage,
   updateScriptStage,
   availableAppliances
}) => {
   // States
   const [errors, setErrors] = useState(false)
   const [forceCalculation, setForceCalculation] = useState(0)

   // Variables
   const no_of_appliances = upgradeData.chosen_package.appliances.min === upgradeData.chosen_package.appliances.max ? upgradeData.chosen_package.appliances.min : `${upgradeData.chosen_package.appliances.min} - ${upgradeData.chosen_package.appliances.max}`

   useEffect(() => {
      // Work on pricing
      let worked_pricing = 0

      const appliances_array = upgradeData.chosen_package.chosen_appliances.array;
      const pricing = upgradeData.chosen_package.cost;

      if(upgradeData.chosen_package.complex_pricing === true) {
         for (let i = 0; i < appliances_array.length; i++) {
            if(upgradeData.current_package.chosen_frequency === 'Monthly') {
               worked_pricing += parseFloat(pricing[i + 1].monthly_cost)
            } else {
               worked_pricing += parseFloat(pricing[i + 1].annual_cost)
            }
         }
      } else if (upgradeData.chosen_package.pricing_model === 'package') {
         if(upgradeData.current_package.chosen_frequency === 'Monthly') {
            worked_pricing = parseFloat(pricing.monthly_cost)
         } else {
            worked_pricing = parseFloat(pricing.annual_cost)
         }
      } else {
         if(upgradeData.current_package.chosen_frequency === 'Monthly') {
            worked_pricing = appliances_array.length * parseFloat(pricing.per_appliance.monthly_cost)
         } else {
            worked_pricing = appliances_array.length * parseFloat(pricing.per_appliance.annual_cost)
         }
      }

      updateUpgradeData({
         ...upgradeData,
         chosen_package: {
            ...upgradeData.chosen_package,
            chosen_cost: worked_pricing
         }
      })
   }, [forceCalculation])

   useEffect(() => {
      setForceCalculation(forceCalculation + 1)
   }, [])

   const handleChangeApplianceQty = (e, max) => {
      e.preventDefault();
      const type = e.target.value;
      const name = e.target.name;

      if(type === 'increase') {
         if((upgradeData.chosen_package.chosen_appliances.object[name] < max || upgradeData.chosen_package.chosen_appliances.object[name] === undefined) && upgradeData.chosen_package.chosen_appliances.array.length < upgradeData.chosen_package.appliances.max) {
            let new_appliance_value = upgradeData.chosen_package.chosen_appliances

            if(new_appliance_value.object[name] === undefined) {
               new_appliance_value.object[name] = 1
               new_appliance_value.array.push(name)
            } else {
               new_appliance_value.object[name] += 1
               new_appliance_value.array.push(`${name} ${new_appliance_value.object[name]}`)
            }

            updateUpgradeData({
               ...upgradeData,
               chosen_package: {
                  ...upgradeData.chosen_package,
                  chosen_appliances: new_appliance_value
               }
            })
         }

         setForceCalculation(forceCalculation + 1)
      } else if (type === 'decrease') {
         let min_amount = 0;

         if(upgradeData.current_package.chosen_appliances.object[name] !== undefined) {
            min_amount = upgradeData.current_package.chosen_appliances.object[name]
         }

         if(upgradeData.chosen_package.chosen_appliances.object[name] > min_amount) {
            let new_appliance_value = upgradeData.chosen_package.chosen_appliances

            if(upgradeData.chosen_package.chosen_appliances.object[name] === 1) {
               delete new_appliance_value.object[name]
            } else {
               new_appliance_value.object[name] -= 1
            }

            let new_appliance_array = []
            for (let i = 0; i < Object.entries(new_appliance_value.object).length; i++) {
               const el = Object.entries(new_appliance_value.object)[i];
               
               if(el[1] > 0) {
                  for (let ind = 0; ind < el[1]; ind++) {
                     if(ind === 0) {
                        new_appliance_array.push(el[0])
                     } else {
                        new_appliance_array.push(`${el[0]} ${ind+1}`)
                     }
                  }
               }
            }

            new_appliance_value.array = new_appliance_array

            updateUpgradeData({
               ...upgradeData,
               chosen_package: {
                  ...upgradeData.chosen_package,
                  chosen_appliances: new_appliance_value
               }
            })

            setForceCalculation(forceCalculation + 1)
         } 
      }
   }
      
   const createApplianceFeed = () => {
      let jsx = []

      let popular_appliances_jsx = []

      for (let i = 0; i < availableAppliances.length; i++) {
         const el = availableAppliances[i];
         
         // Most popular appliances
         if(el.most_popular === true) {
            popular_appliances_jsx.push(
               <div className="appliance-most-popular">
                  <img className="most-popular-image" alt={el.name} src={`/images/appliances/${el.code_name}.png`}/>
                  
                  <h4>{el.name}</h4>

                  <div className="incdec-appliance on">
                     <button 
                        className="decrease-app"
                        value="decrease"
                        name={el.name}
                        onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                     />

                     <p>{upgradeData.chosen_package.chosen_appliances.object[el.name] || 0}</p>
                     <button 
                        className="increase-app"
                        value="increase"
                        name={el.name}
                        onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                     />
                  </div>
               </div>
            )
         }
      }

      jsx.push(
         <React.Fragment>
            <input
               className="invisible accordion-trigger"
               type="checkbox"
               name="popular"
               data-type="section"
               id="popular"
               defaultChecked={true}
            />

            <label htmlFor="popular" className="accordion-label">
               <h3>Most popular appliances</h3>
            </label>
            
            <div className="form-section-container">
               <div className="popular-appliance-container">
                  {popular_appliances_jsx}
               </div>
            </div>
            
            <br/>
         </React.Fragment>
      )

      // Alphabetise appliances
      let sort_az_appliances = {}
      for (let i = 0; i < availableAppliances.length; i++) {
         const el = availableAppliances[i];
         const initial = el.name.substring(0, 1)

            if(!sort_az_appliances[initial]) {
               sort_az_appliances[initial] = []
            }

            sort_az_appliances[initial].push(el)
            sort_az_appliances[initial].sort()
      }

      const appliances_array = Object.entries(sort_az_appliances)
         
         for (let i = 0; i < appliances_array.length; i++) {
            const letter = appliances_array[i];
            const letter_appliances = letter[1];
            
            jsx.push(
               <React.Fragment>
                  <br/>
                  <h3>{letter[0]}</h3>
               </React.Fragment>
            )

            for (let ind = 0; ind < letter_appliances.length; ind++) {
               const el = letter_appliances[ind];
               
               jsx.push(
                  <div className="appliance-choice-container">
                     <p>{el.name}</p>

                     {
                        upgradeData.chosen_package.chosen_appliances.object[el.name] !== undefined ? (
                           <div className="incdec-appliance on">
                              <button 
                                    className="decrease-app"
                                    value="decrease"
                                    name={el.name}
                                    onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                              />

                              <p>{upgradeData.chosen_package.chosen_appliances.object[el.name] || 0}</p>
                              <button 
                                    className="increase-app"
                                    value="increase"
                                    name={el.name}
                                    onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                              />
                           </div>
                        ) : (
                           <button
                              className="add-appliance"
                              onClick={(e) => handleChangeApplianceQty(e, el.max_limit)}
                              value="increase"
                              name={el.name}
                           />
                        )
                     }
                  </div>
               )
            }
         }

      return jsx
   }
   
   return (
      <div className="grid-container">
         <button className="back-button" onClick={() => updateStage(3)}>Go back</button>
         <h2>Appliances</h2>

         <p>You've chosen the <strong>{upgradeData.chosen_package.name}</strong>. This comes with {no_of_appliances} {no_of_appliances === 1 ? 'appliance' : 'appliances'}, please select which {no_of_appliances === 1 ? 'appliance' : 'appliances'} you would like to cover:</p>

         <p className="appliance-counter">{upgradeData.chosen_package.chosen_appliances.array.length} / {upgradeData.chosen_package.appliances.max} appliances selected</p>

         <hr/>
         <br/>

         {createApplianceFeed()}
      </div>
   )
}

export default Appliances