import React from "react"

const ApplianceDetails = ({ details, customerAge }) => {
   return (
      <React.Fragment>
         <br/>
         <h4>Further details</h4>

         <table className="tariff-information-table">
            <tbody>
               <tr>
                  <td>Appliance age</td>
                  <td>{details.age}</td>
               </tr>
               <tr>
                  <td>Appliance brand</td>
                  <td>{details.brand}</td>
               </tr>
               <tr>
                  <td>Customer age</td>
                  <td>{customerAge}</td>
               </tr>
            </tbody>
         </table>
      </React.Fragment>
   )
}

export default ApplianceDetails