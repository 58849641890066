import React, { useState } from "react";
import CallType from "./mark_screens/call_type";
import ConfirmDetails from "./mark_screens/confirm_details";
import FindACall from "./mark_screens/find_a_call";
import MarkCall from "./mark_screens/mark_call";
import MarkCompleted from "./mark_screens/success";

const MarkNewCall = ({
   setShowScreen,
   permissions
}) => {
   // Get list of available call types
   let permitted_call_types = []
   const permissions_array = Object.entries(permissions)

   for (let i = 0; i < permissions_array.length; i++) {
      const el = permissions_array[i];
      
      if(el[1].access_granted === true) {
         permitted_call_types.push(el[0])
      }
   }

   const [stage, updateStage] = useState(1)
   const [markedCallData, updateMarkedCallData] = useState({
      call_type: '',
      call_data: {
         select_method: '',
         record_verified: false,
         phone_number: ''
      },
      performance: {
         start_time: new Date().toISOString()
      },
      question_count: 0,
      score: 100,
      breakdown: {
         passes: 0,
         minor_fails: 0,
         major_fails: 0
      },
      markings: []
   })

   const chooseScreen = () => {
      switch(stage) {
         case 1:
            return <CallType
                     updateStage={updateStage}
                     markedCallData={markedCallData}
                     updateMarkedCallData={updateMarkedCallData}
                     permittedCallTypes={permitted_call_types}
                  />

         case 2:
            return <FindACall
                     updateStage={updateStage}
                     markedCallData={markedCallData}
                     updateMarkedCallData={updateMarkedCallData}
                     callType={markedCallData.call_type}
                  />

         case 3:
            return <MarkCall
                     updateStage={updateStage}
                     markedCallData={markedCallData}
                     updateMarkedCallData={updateMarkedCallData}
                     callType={markedCallData.call_type}
                  />

         case 4:
            return <ConfirmDetails
                     updateStage={updateStage}
                     markedCallData={markedCallData}
                     updateMarkedCallData={updateMarkedCallData}
                     callType={markedCallData.call_type}
                  />

         case 5:
            return <MarkCompleted
                     markedCallData={markedCallData}
                     callType={markedCallData.call_type}
                  />
         
         default:
            return null
      }
   }

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to quality control</button>
         <h2>Mark a new call</h2>

         <div className="progress-bar-col-grid-container">
            {/* Progress bar */}
            <div className="progress-bar-container">
               <div className={`progress-stage ${stage >= 1 ? 'completed' : ''}`}>
                  <div className='progress-circle'/>

                  <h4>Call type</h4>
               </div>

               <div className={`progress-stage ${stage >= 2 ? 'completed' : ''}`}>
                  <div className='progress-circle'/>

                  <h4>Find a call</h4>
               </div>

               <div className={`progress-stage ${stage >= 3 ? 'completed' : ''}`}>
                  <div className='progress-circle'/>

                  <h4>Mark call</h4>
               </div>

               <div className={`progress-stage ${stage >= 4 ? 'completed' : ''}`}>
                  <div className='progress-circle'/>

                  <h4>Confirm details</h4>
               </div>

               <div className={`progress-stage ${stage >= 5 ? 'completed' : ''}`}>
                  <div className='progress-circle'/>

                  <h4>Done <span role="img" aria-label="jsx-a11y/accessible-emoji">🎉</span></h4>
               </div>
            </div>

            {chooseScreen()}
         </div>
      </div>
   )
}

export default MarkNewCall