import axios from "axios";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Error from "../../components/global/error";
import { useUserAuth } from "../../context/userAuthContext"

const Package = ({
   saleData,
   updateSaleData,
   updateStage,
   updateScriptStage,
   packages,
   setPackages,
   setScriptPackageCosts
}) => {
   const { user, userDetails } = useUserAuth();

   const [operationsModal, setOperationsModal] = useState('');

   const [errors, setErrors] = useState({
      email_address: false,
      invalid_email: false
   })

   // Promise tracker loader
   const SendingLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Sending quote...</h3>
         </div>
      )
   }

   useEffect(() => {
      getAvailablePackages()
   }, [])

   useEffect(() => {
      setErrors({
         email_address: false,
         invalid_email: false
      })
   }, [operationsModal])

   const getAvailablePackages = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/products/getAvailableProducts',
         params: {
            partner_name: userDetails.partner
         },
         headers: {
            Authorization: "Bearer " + user
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            // Set appliance costings for script
            let update_package_costs = {
               single_monthly: '',
               single_annual: '',
               platinum_monthly: '',
               platinum_annual: ''
            }

            for (let i = 0; i < value.data.records.length; i++) {
               const record = value.data.records[i];
               
               if(record.appliances.max === 1) {
                  update_package_costs.single_monthly = record.cost.monthly_cost
                  update_package_costs.single_annual = record.cost.annual_cost
               }
               
               if(record.appliances.min === 5 && record.appliances.max === 5) {
                  update_package_costs.platinum_monthly = record.cost.monthly_cost
                  update_package_costs.platinum_annual = record.cost.annual_cost
               } 
            }

            setScriptPackageCosts(update_package_costs)

            setPackages(value.data.records)
         }
      })
      .catch(() => {
         alert("There was an error fetching available products")
      })

      if(saleData.campaign.campaign_type === 'warranty_expiry') {
         updateScriptStage('1.2')
      } else {
         updateScriptStage('1.1')
      }
   }

   const goToNextPage = (product_index) => {
      const product_details = packages[product_index];
      let lead_appliance_repost = {}
      if(saleData.campaign.lead_appliance !== '') {
         lead_appliance_repost = {
            object: {
               [saleData.campaign.lead_appliance]: 1
            },
            array: [saleData.campaign.lead_appliance]
         }
      }

      updateSaleData({
         ...saleData,
         chosen_package: {
            ...saleData.chosen_package,
            name: product_details.product_name,
            appliances: {
               min: product_details.appliances.min,
               max: product_details.appliances.max
            },
            payment_frequency: product_details.payment_frequency,
            cost: product_details.cost,
            pricing_model: product_details.pricing_model,
            length_of_contract: product_details.length_of_contract,
            complex_pricing: product_details.complex_pricing,
            zoho_id: product_details.zoho_id
         },
         chosen_appliances: {
            ...saleData.chosen_appliances,
            ...lead_appliance_repost
         }
      })

      if(saleData.campaign.lead_appliance !== '' && product_details.appliances.max === 1) {
         updateStage(4)
      } else {
         updateStage(3)
      }
   }

   const handleSubmitEmailQuote = (product_details) => {
      const email = saleData.personal_details.email_address
      if(saleData.personal_details.first_name !== '') {
         if(email.length > 5 && email.includes("@") && email.includes(".")) {
            // Verify the email address
            trackPromise(
               new Promise( async (resolve) => {
                  const verify_email = await axios({
                     method: 'get',
                     url: "https://api.appliancesure.com/zero/gen/email/verification",
                     params: {
                        email_address: email
                     }
                  })
   
                  const response = verify_email.data.valid
   
                  if(response === true) {
                     updateSaleData({
                        ...saleData,
                        personal_details: {
                           ...saleData.personal_details,
                           email_verified: response
                        }
                     })
   
                     // Send email
                     try {
                        await axios({
                           method: 'post',
                           url: "https://api.appliancesure.com/one/onboarding/new/sendQuoteEmail",
                           data: {
                              customer_data: saleData.personal_details,
                              product_details: product_details
                           }
                        })
                        .then(() => {
                           alert("Email was sent successfully")
                           setOperationsModal("")
                           resolve("Finished")
                        })
                        .catch(() => {
                           alert("There was an error, please try again later")
                           resolve("Finished")
                        })
                     }
   
                     catch {
                        alert("There was an error, please try again later")
                        resolve("Finished")
                     }
                  } else {
                     setErrors({
                        ...errors,
                        invalid_email: true
                     })
                  }
               })
            , 'submit')
         } else {
            setErrors({
               ...errors,
               email_address: true
            })
         }
      } else {
         setErrors({
            ...errors,
            first_name: true
         })
      }
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      updateSaleData({
         ...saleData,
         personal_details: {
            ...saleData.personal_details,
            [name]: val
         }
      })

      if(name === 'email_address') {
         setErrors({
            ...errors,
            email_address: false,
            invalid_email: false
         })
      } else {
         setErrors({
            ...errors,
            [name]: false
         })
      } 
   }

   const emailQuoteModal = (record) => {
      return (
         <React.Fragment>
            <h2>Send email quote - {record.product_name}</h2>
            <p>If there is any information missing or incorrect below, please fix this now.</p>

            <br/>

            <label className="form-label" htmlFor="email_address">First name</label>
            <input
               type="text"
               className="form-input"
               placeholder="Please enter the customer's first name"
               onChange={handleFillInForm}
               name="first_name"
               value={saleData.personal_details.first_name}
            />

            {
               errors.first_name ? (
                  <React.Fragment>
                     <div style={{marginTop: -20}}/>
                     <Error message="Please enter a first name"/>
                     <div style={{marginBottom: 20}}/>
                  </React.Fragment>
               ) : null
            }

            <label className="form-label" htmlFor="email_address">Email address</label>
            <input
               type="text"
               className="form-input"
               placeholder="Please enter an email address"
               onChange={handleFillInForm}
               name="email_address"
               value={saleData.personal_details.email_address}
            />

            {
               errors.email_address ? (
                  <React.Fragment>
                     <div style={{marginTop: -20}}/>
                     <Error message="Please enter a valid email address"/>
                  </React.Fragment>
               ) : null
            }

            {
               errors.invalid_email ? (
                  <React.Fragment>
                     <div style={{marginTop: -20}}/>
                     <Error type="warning" message="According to our system, this is not a valid email address"/>
                  </React.Fragment>
               ) : null
            }

            <br/>

            <button 
               className="welcome-button secondary"
               onClick={() => handleSubmitEmailQuote(record)}
            >Send quote</button>
         </React.Fragment>
      )
   }

   const createProductFeed = () => {
      let jsx = []

      if(saleData.campaign.campaign_type === 'warranty_expiry') {
         jsx.push(<h3>{saleData.campaign.lead_appliance} only</h3>)
      } else {
         jsx.push(<h3>Single appliance</h3>)
      }

      // Single appliance packages
      let single_appliance_jsx = []
      for (let i = 0; i < packages.length; i++) {
         const el = packages[i];
         
         if(el.appliances.max === 1) {
            single_appliance_jsx.push(
               <div className="package-record-wrapper">
                  <div className="package-record-line"/>
                  <div className="package-record-container">
                     <div className="package-record-upper">
                        <span>
                           <h4>{el.product_name}</h4>
                           <p>
                              {
                                 el.payment_frequency.length > 1 ? (
                                    `£${el.cost.monthly_cost} pcm or £${el.cost.annual_cost} annually`
                                 ) : el.payment_frequency[0] === 'monthly' ? (
                                    `£${el.cost.monthly_cost} per month`
                                 ) : `£${el.cost.annual_cost} per year`
                              }
                           </p>

                           <ul className="package-features-list">
                              <li>{el.payment_frequency.length === 1 ? `Pay ${el.payment_frequency}` : 'Pay monthly or annually'}</li>
                              <li>Price per {el.pricing_model}</li>
                              <li>{el.length_of_contract} month contract</li>
                              <li>1 appliance</li>
                           </ul>
                        </span>
                        
                        <div className="button-container">
                           <button className="welcome-button green" onClick={() => goToNextPage(i)}>Choose</button>
                           <button className="welcome-button primary" onClick={() => setOperationsModal(`Email quote - ${el.product_name}`)}>Email quote</button>
                        </div>

                        {operationsModal === `Email quote - ${el.product_name}` ? (
                           <div className="center-modal-backdrop">
                              <div className="user-activity-modal-container" style={{maxWidth: 700}}>
                                 <div className='close-modal-container activity-modal' onClick={() => setOperationsModal('')}>
                                    <button className='close-modal'/>
                                 </div>

                                 {emailQuoteModal(el)}
                              </div>
                           </div>
                        ) : null}
                        
                     </div>
                  </div>
               </div>
            )
         }
      }

      jsx.push(
         <div className="package-container" style={single_appliance_jsx.length === 0 ? { alignItems: "flex-start"} : {}}>
            {single_appliance_jsx.length > 0 ? single_appliance_jsx : <p>No products currently available</p>}
         </div>
      )


      // Multi appliance packages
      jsx.push(
         <React.Fragment>
            <br/>
            <h3>Multi-appliance packages</h3>
         </React.Fragment>
      )

      let multi_appliance_jsx = []
      for (let i = 0; i < packages.length; i++) {
         const el = packages[i];

         if(el.appliances.max > 1) {
            multi_appliance_jsx.push(
               <div className="package-record-wrapper">
                  <div className="package-record-line"/>
                  <div className="package-record-container">
                     <div className="package-record-upper">
                        <span>
                           <h4>{el.product_name}</h4>
                           <p>
                              {
                                 el.complex_pricing === true ? (
                                    // Complex pricing workings
                                    <React.Fragment>
                                       <button 
                                          className="plain-text-button"
                                          onClick={() => setOperationsModal(`See pricing - ${el.product_name}`)}
                                       >Click to see pricing</button>

                                       {operationsModal === `See pricing - ${el.product_name}` ? (
                                          <div className="center-modal-backdrop">
                                             <div className="confirm-modal-container">
                                                <h3>{el.product_name}</h3>
                                                <h4>Pricing structure</h4>

                                                <table className="activity-log-table">
                                                   <tbody>
                                                      <tr>
                                                         <th>#</th>
                                                         <th>Monthly</th>
                                                         <th>Annually</th>
                                                      </tr>
                                                      {Object.entries(el.cost).map(option => (
                                                         <tr>
                                                            <td>Appliance {option[0]}</td>
                                                            <td>{option[1].monthly_cost === '' ? 'N/A' : '£' + parseFloat(option[1].monthly_cost).toFixed(2)}</td>
                                                            <td>{option[1].annual_cost === '' ? 'N/A' : '£' + parseFloat(option[1].annual_cost).toFixed(2)}</td>
                                                         </tr>
                                                      ))}
                                                   </tbody>
                                                </table>

                                                <button className="confirm-modal-button no" onClick={() => setOperationsModal('')}>
                                                   Cancel
                                                </button>
                                             </div>
                                          </div>
                                       ) : null}
                                    </React.Fragment>
                                 ) : (
                                    <React.Fragment>
                                       {
                                          el.payment_frequency.length > 1 ? (
                                             `£${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.monthly_cost).toFixed(2) : parseFloat(el.cost.monthly_cost).toFixed(2)} pcm or £${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.annual_cost).toFixed(2) : parseFloat(el.cost.annual_cost).toFixed(2)} annually`
                                          ) : el.payment_frequency[0] === 'monthly' ? (
                                             `£${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.monthly_cost).toFixed(2) : parseFloat(el.cost.monthly_cost).toFixed(2)} per month`
                                          ) : `£${el.pricing_model === 'appliance' ? parseFloat(el.cost.per_appliance.annual_cost).toFixed(2) : parseFloat(el.cost.annual_cost).toFixed(2)} per year`
                                       }
                                    </React.Fragment>
                                 )
                              }
                              
                           </p>

                           <ul className="package-features-list">
                              <li>{el.payment_frequency.length === 1 ? `Pay ${el.payment_frequency}` : 'Pay monthly or annually'}</li>
                              <li>Price per {el.pricing_model}</li>
                              <li>{el.length_of_contract} month contract</li>
                              {
                                 el.complex_pricing ? <li>Discounted pricing available</li> : null
                              }
                              <li>{el.appliances.min === el.appliances.max ? el.appliances.min : `${el.appliances.min} - ${el.appliances.max}`} appliances</li>
                           </ul>
                        </span>
                        
                        <div className="button-container">
                           <button className="welcome-button green" onClick={() => goToNextPage(i)}>Choose</button>
                           <button className="welcome-button primary" onClick={() => setOperationsModal(`Email quote - ${el.product_name}`)}>Email quote</button>
                        </div>

                        {operationsModal === `Email quote - ${el.product_name}` ? (
                           <div className="center-modal-backdrop">
                              <div className="user-activity-modal-container" style={{maxWidth: 700}}>
                                 <div className='close-modal-container activity-modal' onClick={() => setOperationsModal('')}>
                                    <button className='close-modal'/>
                                 </div>

                                 {emailQuoteModal(el)}
                              </div>
                           </div>
                        ) : null}
                     </div>
                  </div>
               </div>
            )
         }
      }

      jsx.push(
         <div className="package-container" style={multi_appliance_jsx.length === 0 ? { alignItems: "flex-start"} : {}}>
            {multi_appliance_jsx.length > 0 ? multi_appliance_jsx : <p>No products currently available</p>}
         </div>
      )

      return jsx
   }

   return (
      <div className="grid-container">
         <SendingLoader/>
         <button className="back-button" onClick={() => updateStage(1)}>Go back</button>
         <h2>Package</h2>

         <br/>

         {createProductFeed()}
      </div>
   )
}

export default Package