import React from "react"
import { ThreeDots } from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";

const LeaderboardTile = ({ data }) => {
   const DataLoadingIcon = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

      return (
         promiseInProgress &&
         <React.Fragment>
            <ThreeDots type="rotatingLines" color="#03989e" height={48} width={80} wrapperStyle={{justifySelf: 'center'}} />
         </React.Fragment>
      )
   }

   const generateTile = () => {
      let jsx = []

      if(data !== '') {
         let leaderboard_jsx = []

         for (let i = 0; i < data.length; i++) {
            const record = data[i];
            
            leaderboard_jsx.push(
               <React.Fragment key={record.sales}>
                  <tr>
                     <td>{record.agent_name}</td>
                     <td>{record.sales}</td>
                     <td>{record.appliances}</td>
                  </tr>
               </React.Fragment>
            )
         }

         jsx.push(
            <div className="table-wrapper">
               <table className="manage-items-table">
                  <tbody>
                     <tr>
                        <th>Name</th>
                        <th>Sales</th>
                        <th>Appliances</th>
                     </tr>
                     {leaderboard_jsx}
                  </tbody>
               </table>
            </div>
         )
      }

      return jsx
   }

   const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

   return (
      <div className="grid-container">
         <h2>Leaderboard <span role="img" aria-label="jsx-a11y/accessible-emoji">📊</span></h2>

         <br/>

         <div className="leaderboard-tile-container">
            {
               promiseInProgress ? (
                  <DataLoadingIcon/>
               ) : data === '' || data.length === 0 ? (
                  <p className="no-data-loader" style={{textAlign: 'center'}}>No sales to display</p>
               ) : (
                  <React.Fragment>
                     {generateTile()}
                  </React.Fragment>
               )
            }
         </div>
      </div>
   )
}

export default LeaderboardTile