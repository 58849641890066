import React, { useEffect, useState } from "react"
import AsyncSelect from 'react-select/async'
import list_of_months from "../quality_control_screens/resources/list_of_months"
import { trackPromise, usePromiseTracker } from "react-promise-tracker"
import axios from "axios"
import { useUserAuth } from "../../context/userAuthContext"
import { ThreeDots } from "react-loader-spinner"
import { FaCircle as Circle } from 'react-icons/fa'
import { AiOutlineLine as LineIcon } from 'react-icons/ai'
import { GiPlainSquare as SquareIcon } from 'react-icons/gi'
import { LineChart, ComposedChart, BarChart, PieChart, Cell, Pie, Bar, Line, XAxis, ResponsiveContainer, YAxis, Tooltip, CartesianGrid, ReferenceLine, Label, Legend } from 'recharts'
import CountUp from 'react-countup'

const LeadDataSupplierComparison = ({ setShowScreen }) => {
   const { user } = useUserAuth()

   const [stats, setStats] = useState('')
   const [suppliers, setSuppliers] = useState([])
   const [supplierCount, setSupplierCount] = useState(0)

   const [changes, setChanges] = useState(0)

   const [selectedSuppliers, setSelectedSuppliers] = useState([])

   // Filters
   const [batchScopeFilter, setBatchScopeFilter] = useState('')

   // Data accuracy filter
   const [includeBurn, setIncludeBurn] = useState('without_burn')

   const changeBatchCountScope = (event) => {
      let val = event.target.value;

      if(val !== batchScopeFilter) {
         setChanges(changes + 1)
      }

      if(val !== 'all') {
         val = parseInt(val)
      }

      setBatchScopeFilter(val)
   }

   useEffect(() => {
      setChanges(changes + 1)
   }, [selectedSuppliers])

   // Multi select styles
   const selectStyles = {
      control: (provided, state) => ({
         ...provided,
         width: 200,
         height: '100%',
         borderRadius: 0,
         outline: 'none',
         boxShadow: 'none',
         border: 'none!important',
         textAlign: 'center',
         color: 'black',
         overflow: 'hidden',
         textOverflow: 'elipsis',
         flexWrap: 'nowrap'
      }),
      singleValue: (provided, state) => ({
         ...provided,
         color: 'black',
         textAlign: 'center'
      }),
      option: (provided, state) => ({
         ...provided,
         textAlign: 'center',
         backgroundColor: '#FFF',
         cursor: 'pointer'
      })
   }

   const DataLoadingIcon = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

      return (
         promiseInProgress &&
         <React.Fragment>
            <ThreeDots type="rotatingLines" color="#03989e" height={80} width={80}/>
         </React.Fragment>
      )
   }

   const supplierOptions = async () => {
      const lookup = await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/leadData/getSupplierList',
         headers: {
            Authorization: "Bearer " + user
         }
      })
      
      try {
         let options = []

         for (let i = 0; i < lookup.data.suppliers.length; i++) {
            const supplier = lookup.data.suppliers[i];
            
            options.push({
               label: supplier,
               value: supplier
            })
         }

         setSupplierCount(options.length)
   
         return options
      }

      catch {
         return []
      }
   }

   const checkForChanges = () => {
      if(changes > 0) {
         getDashboardStats()
      }
   }

   useEffect(() => {
      checkForChanges()
   }, [batchScopeFilter])

   const multiValueContainer = ({ selectProps, data }) => {
      const label = data.label;
      const index = selectProps.value.findIndex(selected => selected.label === label)

      if(index === 0) {
         return `${selectedSuppliers.length} / ${supplierCount} selected`
      } else {
         return null
      }
   }

   //----------------- REPORT ---------------------//
   const getDashboardStats = () => {
      if(selectedSuppliers.length !== 0 && batchScopeFilter !== '') {
         let suppliers_list = []
         for (let i = 0; i < selectedSuppliers.length; i++) {
            const supplier = selectedSuppliers[i];
            
            suppliers_list.push(supplier.value)
         }

         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'get',
                  url: 'https://api.appliancesure.com/two/admin/portal/reporting/leadDataSupplierComparison',
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  params: {
                     suppliers: suppliers_list,
                     batch_count: batchScopeFilter
                  }
               })
               .then((value) => {
                  if(value.data.success === true) {
                     setStats({
                        ...value.data.data,
                        selected_suppliers: suppliers_list
                     })
                  } else {
                     setStats("No data")
                  }

                  setChanges(0)
                  resolve("Yes")
               })
               .catch(() => {
                  alert("There was an error getting the requested data, please try again")
                  resolve("Yes")
               })
            })
        , 'data_load')
      }
   }

   //---------------------------------------
   //----- JSX GENERATION FOR TILES ------//
   //---------------------------------------

   //--- Tile #1 | Sales breakdown
   const generateSalesBreakdownTile = () => {
      let jsx = []

      const colors = ["#03989e", "#00c2cb", "#7AC200", "#4caf50", "#424242"]

      if(stats !== '') {
         stats.sales_breakdown.sort((b, a) => a.count - b.count)

         let total_sales = 0
         for (let i = 0; i < stats.sales_breakdown.length; i++) {
            const el = stats.sales_breakdown[i];
            
            total_sales += el.count
         }

         jsx.push(
            <React.Fragment>
               <br/>

               {/* Pie chart */}
               <div className="chart-container" style={{marginTop: 10}}>
                  <ResponsiveContainer height={200} width="100%">
                     <PieChart width={200} height={200} margin={{ top: -30, right: 0, left: 0, bottom: 0 }}>
                        <Pie
                           dataKey="count"
                           data={stats.sales_breakdown}
                           outerRadius={80}
                           isAnimationActive={true}
                           fill
                        >
                           {stats.sales_breakdown.map((entry, index) => (
                              <Cell key={`percentage-cell-${index}`} fill={colors[stats.selected_suppliers.indexOf(entry.supplier)]}/>
                           ))}
                        </Pie>
                     </PieChart>
                  </ResponsiveContainer>
               </div>

               {
                  stats.sales_breakdown.map((item, index) => (
                     <React.Fragment>
                        <h5 style={{margin: 0}}><Circle style={{color: colors[stats.selected_suppliers.indexOf(item.supplier)]}}/> {item.supplier}</h5>
                        <p style={{marginTop: 5}} className="disclaimer">Count: {item.count} ({((item.count / total_sales) * 100).toFixed(2)}%)</p>
                     </React.Fragment>
                  ))
               }
            </React.Fragment>
         )
         
      }

      return jsx
   }

   //--- Tile #2 | Usage over time
   const generateUsageOverTimeTile = () => {
      let jsx = []

      const colors = ["#03989e", "#00c2cb", "#7AC200", "#4caf50", "#424242"]

      const CustomLabel = ({ active, payload, label }) => {
         if(active && payload && payload.length) {
            return (
               <div className="custom-chart-label">
                  <div className="table-wrapper">
                     <table className="tariff-information-table">
                        <tbody>
                           {stats.selected_suppliers.map((supplier, index) => (
                              <React.Fragment>
                                 {
                                    payload[0].payload[`${supplier}_total`] > 0 ? (
                                       <React.Fragment>
                                          <tr>
                                             <th colSpan={2}>{supplier}</th>
                                          </tr>
                                          <tr>
                                             <td><SquareIcon style={{color: colors[index]}}/> Total attempts</td>
                                             <td>{payload[0].payload[`${supplier}_total`]}</td>
                                          </tr>
                                          <tr>
                                             <td><LineIcon style={{color: colors[index]}}/> Answered</td>
                                             <td>{payload[0].payload[`${supplier}_answered`]} ({((payload[0].payload[`${supplier}_answered`] / payload[0].payload[`${supplier}_total`]) * 100).toFixed(2)}%)</td>
                                          </tr>
                                          <tr>
                                             &nbsp;
                                          </tr>
                                       </React.Fragment>
                                    ) : null
                                 }
                              </React.Fragment>
                           ))}
                        </tbody>
                     </table>
                  </div>
                  <p>{new Date(label).toLocaleDateString("en-GB")}</p>
               </div>
            )
         }
      }

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>

               <div className="chart-container">
                  <ResponsiveContainer height={215} width="100%">
                     <ComposedChart data={stats.usage_over_time} margin={{ top: 10, right: -10, left: 0, bottom: 0 }}>
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey="date"/>
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        {stats.selected_suppliers.map((supplier, index) => (
                           <React.Fragment>
                              <Bar dataKey={`${supplier}_total`} fill={colors[index]} maxBarSize={20} yAxisId="left" stackId="a" />
                              <Line dataKey={`${supplier}_answered`} stroke={colors[index]} yAxisId="right" />
                           </React.Fragment>
                        ))}
                        <Tooltip content={<CustomLabel/>} cursor={false}/>
                     </ComposedChart>
                  </ResponsiveContainer>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #3 | Answer rates
   const generateAnswerRatesTile = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>

               <center>
                  <CountUp
                     start={0}
                     end={stats.answer_rates.average}
                     decimals={2}
                     duration={0.5}
                     className="quality-score"
                     style={{fontSize: 50}}
                     suffix="%"
                  />

                  <p className="count-up-comment">Average between suppliers</p>
               </center>

               <table className="tariff-information-table">
                  <tbody>
                     {stats.answer_rates.data.map(item => (
                        <tr>
                           <td>{item.supplier}</td>
                           <td>{item.answer_rate}%</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </React.Fragment>
         )
      }

      return jsx
   }

   //--- Tile #4 | Average call times
   const generateAverageCallTimes = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>

               <center>
                  <span style={{fontSize: 50}} className="quality-score">{stats.average_call_times.average}</span>

                  <p className="count-up-comment">Average between suppliers</p>
               </center>

               <table className="tariff-information-table">
                  <tbody>
                     {stats.average_call_times.data.map(item => (
                        <tr>
                           <td>{item.supplier}</td>
                           <td>{item.as_time}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </React.Fragment>
         )
      }

      return jsx
   }

   // Tile #5 | Batch leaderboard
   const generateBatchLeaderboard = () => {
      let jsx = []

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>

               <div className="table-wrapper">
                  <table className="activity-log-table sticky-table-header-hover">
                     <tbody>
                        <tr className="">
                           <th>Batch number</th>
                           <th>Sales</th>
                           <th>Conversion %</th>
                           <th>Connect rate</th>
                           <th>Avg. call time</th>
                        </tr>
                        {stats.batch_leaderboard.map(item => (
                           <tr>
                              <td>{item.batch_number}</td>
                              <td>{item.sales}</td>
                              <td>{item.conversion_rate.toFixed(2)}%</td>
                              <td>{item.connect_rate.toFixed(2)}%</td>
                              <td>{item.average_call_time}</td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
      }

      return jsx
   }

   // Tile #6 | Data accuracy
   const generateDataAccuracyTile = () => {
      let jsx = []

      if(stats !== '') {
         // Table rows
         let rows_jsx = []

         for (let i = 0; i < stats.data_accuracy.data.length; i++) {
            const el = stats.data_accuracy.data[i];
            
            rows_jsx.push(
               <React.Fragment key={el[includeBurn].percentage}>
                  <tr>
                     <td>{el.supplier}</td>
                     <td>{el[includeBurn].percentage.toFixed(2)}%</td>
                  </tr>
               </React.Fragment>
            )
         }

         rows_jsx.sort((a, b) => a.key - b.key)

         jsx.push(
            <React.Fragment>
               <br/>

               <center>
                  <CountUp
                     start={0}
                     end={stats.data_accuracy.average[includeBurn].percentage}
                     decimals={2}
                     duration={0.5}
                     className="quality-score"
                     style={{fontSize: 50}}
                     suffix="%"
                  />

                  <p className="count-up-comment">% of incorrect data, average between suppliers</p>
               </center>

               <div className="activity-log-counts" style={{marginBottom: 0}}>
                  <label htmlFor="includeBurn" className="form-label">Scope</label>
                  <select
                     id="includeBurn"
                     className="form-input"
                     value={includeBurn}
                     onChange={(e) => setIncludeBurn(e.target.value)}
                     style={{marginBottom: 15}}
                  >
                     <option value="with_burn">Include burned leads</option>
                     <option value="without_burn">Exclude burned leads</option>
                  </select>
               </div>

               <div className="table-wrapper">
                  <table className="tariff-information-table">
                     <tbody>
                        {rows_jsx}
                     </tbody>
                  </table>
               </div>
            </React.Fragment>
         )
         
      }

      return jsx
   }

   // Tile #7 | Device breakdown
   const generateDeviceBreakdownTile = () => {
      let jsx = []

      const colors = ["#03989e", "#00c2cb", "#7AC200", "#4caf50", "#424242"]

      if(stats !== '') {
         jsx.push(
            <React.Fragment>
               <br/>


               {/* Pie chart */}
               <div className="chart-container" style={{marginTop: 10}}>
                  <ResponsiveContainer height={200} width="100%">
                     <PieChart width={200} height={200} margin={{ top: -30, right: 0, left: 0, bottom: 0 }}>
                        <Pie
                           dataKey="value"
                           data={stats.device_breakdown.average}
                           outerRadius={80}
                           isAnimationActive={true}
                           fill
                        >
                           {stats.device_breakdown.average.map((entry, index) => (
                              <Cell key="value" fill={colors[index]}/>
                           ))}
                        </Pie>
                     </PieChart>
                  </ResponsiveContainer>
               </div>

               {
                  stats.device_breakdown.average.map((item, index) => (
                     <React.Fragment>
                        <h5 style={{margin: 0}}><Circle style={{color: colors[index]}}/> {item.name.charAt(0).toUpperCase() + item.name.slice(1)}</h5>
                        <p style={{marginTop: 5}} className="disclaimer">Count: {item.value} ({item.percentage}%)</p>
                     </React.Fragment>
                  ))
               }

               <table className="tariff-information-table">
                  <tbody>
                     {stats.device_breakdown.data.map(item => (
                        <tr>
                           <td>{item.supplier}</td>
                           <td>L&nbsp;-&nbsp;{item.landline}% <br/>
                              M&nbsp;-&nbsp;{item.mobile}%</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </React.Fragment>
         )
      }

      return jsx
   }

   const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to reporting</button>
         <h2>Lead data supplier comparison</h2>

         <br/>
         <br/>

         {
            stats !== '' ? (
               <button disabled={promiseInProgress} style={{marginBottom: 10}} onClick={getDashboardStats} className="plain-text-button refresh">Refresh data</button> 
            ) : null
         }

         <div className="data-comparison-grid-container">
            {/* FILTER BAR */}
            <div className="qa-dashboard-filter-bar" id="bar">
               {/* Left hand side of bar */}
               <ul className="filter-bar-list left">
                  <li>
                     <AsyncSelect
                        className="qa-dashboard-filter-select"
                        styles={selectStyles}
                        loadOptions={supplierOptions}
                        cacheOptions
                        defaultOptions
                        placeholder="Select suppliers"
                        components={{
                           IndicatorSeparator: () => null,
                           MultiValueContainer: multiValueContainer
                        }}
                        isSearchable={false}
                        isMulti={true}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        onChange={setSelectedSuppliers}
                        value={selectedSuppliers}
                        closeMenuOnSelect={false}
                        onBlur={checkForChanges}
                        isDisabled={promiseInProgress}
                     />
                  </li>
               </ul>

               {/* Right hand side of bar */}
               <ul className="filter-bar-list right">
                  <li>
                     <select 
                        className="qa-dashboard-filter-select"
                        value={batchScopeFilter}
                        onChange={changeBatchCountScope}
                        disabled={promiseInProgress}
                     >
                        <option value="" disabled>Select comparison scope</option>
                        <option value="1">Most recent batch</option>
                        <option value="2">Last 2 batches</option>
                        <option value="5">Last 5 batches</option>
                        <option value="10">Last 10 batches</option>
                        <option value="all">All time</option>
                     </select>
                  </li>
               </ul>
            </div>

            {/*----------------------------------------
            --------------- TILE JSX ------------------
            ----------------------------------------*/}

            {/* Sales breakdown */}
            <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile1">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Sales breakdown</h4>

                        {generateSalesBreakdownTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Usage over time */}
            <div style={{overflow: 'visible'}} className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile2">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Usage over time</h4>

                        {generateUsageOverTimeTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Answer rates */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile3">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Answer rates</h4>

                        {generateAnswerRatesTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Average call time */}
            <div className={`qa-dashboard-tile ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile4">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Average call time</h4>

                        {generateAverageCallTimes()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Batch performance leaderboard */}
            <div className={`qa-dashboard-tile triple-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile5">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Batch leaderboard</h4>

                        {generateBatchLeaderboard()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Data accuracy */}
            <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile6">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Data accuracy</h4>

                        {generateDataAccuracyTile()}
                     </React.Fragment>
                  )
               }
            </div>

            {/* Device breakdown */}
            <div className={`qa-dashboard-tile double-height ${promiseInProgress || stats === 'No data' || stats === '' ? 'loading' : ''}`} id="tile7">
               {
                  promiseInProgress ? (
                     <DataLoadingIcon/>
                  ) : stats === '' ? (
                     <p className="no-data-loader">Select filters</p>
                  ) : stats === 'No data' ? (
                     <p className="no-data-loader">No data</p>
                  ) : (
                     <React.Fragment>
                        <h4>Device breakdown</h4>

                        {generateDeviceBreakdownTile()}
                     </React.Fragment>
                  )
               }
            </div>
         </div>
      </div>
   )
}

export default LeadDataSupplierComparison