import React, { useEffect, useState } from "react";
import './styles.css'
import { Routes as Switch, Route } from 'react-router-dom'
import axios from "axios";
import { FaScroll as Scroll } from 'react-icons/fa'

// Components
import NewSale from "./screens/app_index";
import ProtectedRoute from "./components/global/protected-route";
import Login from "./screens/login";
import { UserAuthContextProvider } from "./context/userAuthContext";
import { RotatingLines } from "react-loader-spinner";
import AccountSetup from "./screens/account_setup";
import PasswordReset from "./screens/admin_ops/reset_password";
import axiosRetry from "axios-retry";

const App = () => {
  // Check security requirements before allowing app access
  const [authenticated, setAuthenticated] = useState("pending")

  axiosRetry(axios, { 
    retries: 5,
    retryDelay: (retryCount) => {
      console.log(`Error - retry attempt: ${retryCount}`);
      return retryCount * 500;
    },
    retryCondition: (error) => {
      return error.response.status === 502
    }
  })

  const verifyIpAddress = async () => {
    try {
      const ipLookup = await axios.get("https://geolocation-db.com/json/")
      const ip_address = ipLookup.data.IPv4;

      // Verify this IP address is whitelisted
      const verifyWhitelist = await axios({
        method: 'get',
        url: 'https://api.appliancesure.com/two/admin/portal/access/verifyIP',
        params: {
          ip_address: ip_address
        }
      })

      const response = verifyWhitelist.data;

      return response.verified
    }

    catch {
      setAuthenticated('error')
    }
  }

  const runVerification = async () => {
    const ip_verification = await verifyIpAddress()

    if(ip_verification === true) {
      if(window.top === window.self) {
        setAuthenticated('authenticated')
      } else {
        setAuthenticated('inside_connexone')
      }
      
    } else {
      setAuthenticated('ip_restricted')
    }
  }

  useEffect(() => {
    runVerification()
  }, [])

  switch (authenticated) {
    case 'pending':
      return (
        <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Loading...</h3>
        </div>
      )

    case 'error':
      return (
          <div className="promise-tracker-container">
            <i className="error-icon"/>
            <h3>Oops, something went wrong!</h3>
            <p style={{marginTop: -10}}>There was an error authenticating your account, please try again.</p>
          </div>
      )

    case 'ip_restricted':
      return (
          <div className="promise-tracker-container">
            <i className="forbidden-icon"/>
            <h3>Your IP address has been blocked</h3>
            <p style={{marginTop: -10}}>Please speak to your manager to gain access.</p>
          </div>
      )
    
    case "inside_connexone":
      return (
        <div className="connex-one-embed-container">
          <h2>Start script <Scroll/></h2>
          <p>Click the button in the bottom right to begin your script</p>
          <img src="/images/bottom-right-arrow.png" alt="Begin script inside of Connex One" style={{width: 80, marginLeft: 40}}/>
        </div>
      )
    
    case 'authenticated':
      return (
        <UserAuthContextProvider>
          <Switch>
            <Route exact path="/password-reset" element={<PasswordReset/>}/>

            <Route exact path="/account-setup" element={<AccountSetup/>}/>

            <Route exact path="/login" element={<Login/>}/>
    
            <Route exact path="" element={
              <ProtectedRoute>
                <NewSale/>
              </ProtectedRoute>}/>
          </Switch>
        </UserAuthContextProvider>
      )

    default:
      return "404"
  }
}

export default App;