import axios from "axios";
import React, { useEffect } from "react";

const TextViewer = ({
   content,
   setLaunchContent,
   setEditContent,
   permissions
}) => {
   useEffect(() => {
      axios({
         method: 'put',
         url: 'https://api.appliancesure.com/two/admin/portal/knowledge/addViewCount',
         data: {
            title: content.title,
            category: content.category
         }
      })
   }, [])

   const generateHTML = () => {
      return {__html: content.text}
   }

   return (
      <div className="center-modal-backdrop">
         <div className="script-modal-container knowledge-content">
            <div className="close-modal-positioning-container">
               <div className='close-modal-container top-right' onClick={() => setLaunchContent('')}>
                  <button className='close-modal'/>
               </div>
            </div>

            
           
            <div className="kb-content-container" dangerouslySetInnerHTML={generateHTML()}/>

            <div className="article-details-container">
               <h3>{content.title}</h3>
               <p>{content.description}</p>
               <p className="disclaimer">View count: {content.views}</p>

               <br/>
               
               {
                  permissions === 'edit' ? (
                     <button 
                        className="welcome-button secondary"
                        onClick={() => setEditContent(true)}
                     >Edit content</button>
                  ) : null
               }
               
            </div>

            
         </div>
      </div>
   )
}

export default TextViewer