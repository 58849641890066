import React, { useState } from "react";

const ActivityLog = ({ log, path, resetModal }) => {
   const [totalResults, setTotalResults] = useState(log.length)
   const [showIndex, setShowIndex] = useState([1, log.length > 3 ? 3 : log.length])

   const createActivityLog = () => {
      let jsx = []
      log.sort((b, a) => new Date(a.timestamp) - new Date(b.timestamp))

      for (let i = showIndex[0] - 1; i < showIndex[1]; i++) {
         const el = log[i];
         
         jsx.push(
            <React.Fragment>
               <tr>
                  <td>{el.action}</td>
                  <td>{new Date(el.timestamp).toLocaleString("en-GB")}</td>
                  <td>{el.further_details.action_by.name}<br/>{el.further_details.action_by.email_address}</td>
                  <td>{el.further_details.os}, {el.further_details.browser.charAt(0).toUpperCase() + el.further_details.browser.slice(1)}<br/>{el.further_details.ip_address}</td>
               </tr>
            </React.Fragment>
         )
      }

      return jsx
   }

   const handleNextPage = () => {
      const starting_index = showIndex[1] + 1
      let end_index = starting_index + 2

      if(end_index > log.length) {
         end_index = log.length
      }

      setShowIndex([starting_index, end_index])
   }

   const handlePreviousPage = () => {
      let starting_index = showIndex[0] - 4;
      if (starting_index < 1) starting_index = 1
      const end_index = starting_index + 2

      setShowIndex([starting_index, end_index])
   }

   return (
      <div className="center-modal-backdrop">
         <div className="user-activity-modal-container">
            <div className='close-modal-container activity-modal' onClick={resetModal}>
               <button className='close-modal'/>
            </div>

            <h2>Activity log</h2>
            <p>{path.join(" > ")}</p>

            <br/>
            
            <table className="activity-log-table">
               <tbody>
                  <tr>
                     <th>Activity</th>
                     <th>Timestamp</th>
                     <th>By</th>
                     <th>Location</th>
                  </tr>
                  {
                     log.length === 0 ? (
                        <tr>
                           <td colSpan={4}>This activity log is empty</td>
                        </tr>
                     ) : (
                        <React.Fragment>
                           {createActivityLog()}
                        </React.Fragment>
                     )
                  }
                  
               </tbody>
            </table>
            <div className="activity-log-counts">
               {
                  showIndex[0] > 1 ? (
                     <button 
                        className="plain-text-button arrow-left"
                        onClick={handlePreviousPage}
                     >Previous page</button>
                  ) : null
               }

               {
                  showIndex[1] < totalResults ? (
                     <button 
                        className="plain-text-button arrow-right"
                        onClick={handleNextPage}
                     >Next page</button>
                  ) : null
               }

               <br/>
               <br/>
               <p><strong>Showing results {showIndex[0]} - {showIndex[1]}</strong></p>
               <p>Total results {totalResults}</p>
            </div>
         </div>
      </div>
   )
}

export default ActivityLog