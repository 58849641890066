import axios from "axios";
import React, { useEffect, useState } from "react";

const AuditTrail = () => {
   // Variables

   // States
   const [records, setRecords] = useState([])
   const [totalResults, setTotalResults] = useState(0)
   const [showIndex, setShowIndex] = useState([1, 0])
   const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})

   // Filters
   //const [categoryFilter, setCategoryFilter] = useState('')
   //const [dateFilter, setDateFilter] = useState('')
   //const [actionByFilter, setActionByFilter] = useState('')

   useEffect(() => {
      if(records.length === 0) {
         fetchAuditRecords()
      }
   }, [])

   const fetchAuditRecords = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/logs/getAuditLogs',
         params: {
            lastEvaluatedKey: lastEvaluatedKey
         }
      })
      .then((value) => {
         if(value.data.success === true) {

            const new_array = [...records, ...value.data.records]

            const starting_index = showIndex[1] + 1
            
            const end_index = new_array.length
            
            setRecords(new_array)
            setTotalResults(new_array.length)
            setLastEvaluatedKey(value.data.lastEvaluatedKey)
            
            setShowIndex([starting_index, end_index])
            
         }
      })
   }

   const createAuditLog = () => {
      let jsx = []

      records.sort((b, a) => new Date(a.timestamp) - new Date(b.timestamp))

      for (let i = showIndex[0] - 1; i < showIndex[1]; i++) {
         const el = records[i];
         
         jsx.push(
            <tr>
               <td>{el.action}</td>
               <td>{new Date(el.timestamp).toLocaleString("en-GB")}</td>
               <td>{el.category.charAt(0).toUpperCase() + el.category.slice(1)}</td>
               <td>{el.further_details.action_by.name}<br/>{el.further_details.action_by.email_address}</td>
                  <td>{el.further_details.os}, {el.further_details.browser.charAt(0).toUpperCase() + el.further_details.browser.slice(1)}<br/>{el.further_details.ip_address}</td>
            </tr>
         )
      }

      return jsx;
   }

   const handleNextPage = async () => {
      const starting_index = showIndex[1] + 1
      let end_index = starting_index + 9
      
      if(records.length < end_index && lastEvaluatedKey !== undefined) {
         await fetchAuditRecords()
      } else {
         if(end_index > records.length) {
            end_index = records.length
         }

         setShowIndex([starting_index, end_index])
      }
   }

   const handlePreviousPage = () => {
      let starting_index = showIndex[0] - 10;
      if (starting_index < 1) starting_index = 1
      const end_index = starting_index + 9

      setShowIndex([starting_index, end_index])
   }

   return (
      <div className="screen-inner-container">
         <h1>Audit Trail</h1>

         <p>An audit trail is a chronological set of records to provide documentation of an event or change that has taken place within our system.</p>

      <br/>
         {/*<h2>Filters</h2>
         <br/>
         <div className="filter-options-container">
            <span>
               <label className="form-label">Category</label>
               <select
                  className="form-input admin-row"
               >
                  <option value="">Any</option>
                  <option value="users">Users</option>
                  <option value="products">Products</option>
                  <option value="partners">Partners</option>
                  <option value="scripts">Scripts</option>
               </select>
            </span>

            <span>
               <label className="form-label">Date</label>
               <select
                  className="form-input admin-row"
               >
                  <option value="">Any</option>
                  <option value="users">Users</option>
                  <option value="products">Products</option>
                  <option value="partners">Partners</option>
                  <option value="scripts">Scripts</option>
               </select>
            </span>

            <span>
               <label className="form-label">Action by</label>
               <select
                  className="form-input admin-row"
               >
                  <option value="">Any</option>
                  <option value="users">Users</option>
                  <option value="products">Products</option>
                  <option value="partners">Partners</option>
                  <option value="scripts">Scripts</option>
               </select>
            </span>
         </div>*/}

         <table className="activity-log-table">
            <tbody>
               <tr>
                  <th>Activity</th>
                  <th>Timestamp</th>
                  <th>Category</th>
                  <th>By</th>
                  <th>Location</th>
               </tr>
               {createAuditLog()}
            </tbody>
         </table>

         <div className="activity-log-counts">
               {
                  showIndex[0] > 1 ? (
                     <button 
                        className="plain-text-button arrow-left"
                        onClick={handlePreviousPage}
                     >Previous page</button>
                  ) : null
               }

               {
                  (showIndex[1] < totalResults) || (showIndex[1] === totalResults && lastEvaluatedKey !== undefined) ? (
                     <button 
                        className="plain-text-button arrow-right"
                        onClick={handleNextPage}
                     >Next page</button>
                  ) : null
               }

               <br/>
               <br/>
               <p><strong>Showing results {showIndex[0]} - {showIndex[1]}</strong></p>
               <p>Total results {lastEvaluatedKey !== undefined ? '- Many' : totalResults}</p>
            </div>
      </div>
   )
}

export default AuditTrail