import axios from "axios";
import React, { useEffect, useState } from "react";
import ConfirmModal from "../../components/global/confirm-modal";

const ManageIpWhitelist = ({ 
   partnerData,
   setShowScreen
}) => {
   // Filters
   const [showActiveIPs, setShowActiveIPs] = useState(true)
   const [filterByCompany, setFilterByCompany] = useState('')

   // States
   const [IpAddresses, setIpAddresses] = useState([])
   const [selectIpAddress, setSelectIpAddress] = useState('')
   const [operationsModal, setOperationsModal] = useState('')

   const getIpWhitelist = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/access/getIpWhitelist'
      })
      .then((value) => {
         const response = value.data.records

         setIpAddresses(response)
      })
      .catch(() => {
         
      })
   }

   useEffect(() => {
      getIpWhitelist()
   }, [])

   const handleChangeStatusSearch = (event) => {
      const val = event.target.value;

      if(val === 'true') {
         setShowActiveIPs(true)
      } else {
         setShowActiveIPs(false)
      }
   }

   const handleChangeIpStatus = async (ip_address, company, new_status) => {
      await axios({
         method: 'put',
         url: 'https://api.appliancesure.com/two/admin/portal/access/changeIPStatus',
         data: {
            ip_address: ip_address,
            company: company,
            new_status: new_status
         }
      })
      .then((value) => {
         const response = value.data.success

         if(response === true) {
            handleResetOperationsModal()
            getIpWhitelist()
            setShowActiveIPs(new_status)
         } else {
            handleChangeIpStatus()
            alert("There was an error, please try again later")
         }
      })
   }

   const handleResetOperationsModal = () => {
      setOperationsModal('')
   }

   const createIpList = () => {
      let jsx = []

      for (let i = 0; i < IpAddresses.length; i++) {
         const record = IpAddresses[i];
         
         if(record.active === showActiveIPs && record.company.includes(filterByCompany)) {
            jsx.push(
               <li className={selectIpAddress === i ? 'selected' : ''} onClick={() => setSelectIpAddress(i)}>
                  <span>
                     <strong>{record.ip_address}</strong>
                     <br/>
                     {record.description}
                  </span>
               </li>
            )
         }
      }

      return jsx;
   }

   const showSelectedIPTile = () => {
      let jsx = []

      if(selectIpAddress !== '') {
         const ip_details = IpAddresses[selectIpAddress]

         jsx.push(
            <div className="grid-container">
               <button className="back-button" onClick={() => setSelectIpAddress('')}>Close</button>
               <br/>
               <br/>
               <h2>{ip_details.ip_address} <span className={ip_details.active ? 'active-record' : 'suspended-record'}>[{ip_details.active ? 'Active' : 'Suspended'}]</span></h2>
               <p style={{marginTop: 5}}>{ip_details.description}</p>
               <br/>

               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <td>IP type</td>
                        <td>{ip_details.ip_type}</td>
                     </tr>
                     <tr>
                        <td>Company</td>
                        <td>{ip_details.company}</td>
                     </tr>
                     <tr>
                        <td>Added by</td>
                        <td style={{lineBreak: 'anywhere'}}>{ip_details.added.by}</td>
                     </tr>
                     <tr>
                        <td>Added time</td>
                        <td>{new Date(ip_details.added.at).toLocaleString('en-GB')}</td>
                     </tr>
                  </tbody>
               </table>

               <br/>

               <h3>Actions 🛠</h3>

               <ul className="grid-list">
                  {
                     ip_details.active ? (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Disable ${ip_details.ip_address}`)}>Remove from whitelist</li>
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Re-add ${ip_details.ip_address}`)}>Re-add to whitelist</li>
                        </React.Fragment>
                     )
                  }
               </ul>

               {/* Operations */}
               {/*--- Suspend / Re-active IP ---*/}
               {operationsModal === `Disable ${ip_details.ip_address}` ? (
                  <ConfirmModal
                     onConfirm={() => handleChangeIpStatus(ip_details.ip_address, ip_details.company, false)}
                     onReject={handleResetOperationsModal}
                     operation={`disable ${ip_details.ip_address}`}
                  />
               ) : null} 
               
               {operationsModal === `Re-add ${ip_details.ip_address}` ? (
                  <ConfirmModal
                     onConfirm={() => handleChangeIpStatus(ip_details.ip_address, ip_details.company, true)}
                     onReject={handleResetOperationsModal}
                     operation={`re-add ${ip_details.ip_address} to the whitelist`}
                  />
               ) : null} 
            </div>
         )
      }

      return jsx
   }

   return (
      <div className="screen-inner-container">
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Manage IP whitelist</h2>

         <div className="two-col-grid-container">
            <div>
               {/* Filters */}
               <div className="filter-options-container">
                  <span>
                     <label className="form-label">IP status</label>
                     <select 
                        className="form-input admin-row"
                        value={showActiveIPs}
                        onChange={handleChangeStatusSearch}
                     >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                     </select>
                  </span>

                  <span>
                     <label className="form-label">Company</label>
                     <select 
                        className="form-input admin-row"
                        value={filterByCompany}
                        onChange={(e) => setFilterByCompany(e.target.value)}
                     >
                        <option value="">All companies</option>
                        {partnerData.map(partner => (
                           <option value={partner.partner_name}>{partner.partner_name}</option>
                        ))}
                     </select>
                  </span>
               </div>

               {/* IPs */}
               <div className="grid-container">
                  <h2>IP addresses<span role="img" aria-label="jsx-a11y/accessible-emoji">🔐</span></h2>

                  <ul className="grid-list">
                     {createIpList()}
                  </ul>

                  <button
                     className="welcome-button secondary"
                     onClick={() => setShowScreen('create_ip_address')}
                  >Add new IP address</button>
               </div>
            </div>
            
            {showSelectedIPTile()}
         </div>
      </div>
   )
}

export default ManageIpWhitelist