import React, { useEffect, useState } from "react";
import ConfirmModal from "../../components/global/confirm-modal";
import Error from "../../components/global/error";
import axios from "axios";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { detect } from "detect-browser";
import { useUserAuth } from "../../context/userAuthContext";
import ActivityLog from "../../components/local/activity-log";

const ManagePartners = ({
   partnerData,
   productData,
   setShowScreen,
   getMetricData,
   setPassParams,
   passParams,
}) => {
   // States
   const [showActivePartners, setShowActivePartners] = useState(true)
   const [selectPartner, setSelectPartner] = useState('')
   const [operationsModal, setOperationsModal] = useState('')
   const [availableProducts, setAvailableProducts] = useState([])
   const [grantAccessError, setGrantAccessError] = useState(false)
   const [grantProductAccess, setGrantProductAccess] = useState({})

   // Firebase DB
   const browser = detect()
   const { userDetails } = useUserAuth();

   const handleChangeStatusSearch = (event) => {
      const val = event.target.value;

      if(val === 'true') {
         setShowActivePartners(true)
      } else {
         setShowActivePartners(false)
      }
   }

   const ChangeStatusLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'change_status'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Processing request...</h3>
         </div>
      )
   }

   useEffect(() => {
      if(passParams.partner) {
         findParamPartner();
      }
   })

   const findParamPartner = () => {
      let partner_index = '';

      for (let i = 0; i < partnerData.length; i++) {
         const el = partnerData[i];
         
         if(el.partner_name === passParams.partner) {
            partner_index = i
         }
      }

      if(partner_index === '') {
         return null
      } else {
         setSelectPartner(partner_index)
         setPassParams('')
      }
   }

   const handleResetOperationsModal = () => {
      setOperationsModal('')
   }

   const handleCloseGrantProductAccess = () => {
      setOperationsModal('')
      setGrantAccessError(false)
      setGrantProductAccess({})
   }

   const handleChangeGrantProductAccess = (e, product) => {
      const value = e.target.checked;

      setGrantProductAccess({
         ...grantProductAccess,
         [product]: value
      })

      setGrantAccessError(false)
   }

   const handleGrantProductAccess = async (partner) => {
      const productAccessArray = Object.entries(grantProductAccess)

      let changes = [];
      for (let i = 0; i < productAccessArray.length; i++) {
         const el = productAccessArray[i];
         
         if(el[1] === true) {
            changes.push(el[0])
         }
      }

      if(changes.length > 0) {
         trackPromise(
            new Promise( async (resolve) => {
               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }
               
               await axios({
                  method: 'put',
                  url: 'https://api.appliancesure.com/two/admin/portal/products/grantProductAccess',
                  params: {
                     changes_by: 'partner',
                     primary_name: partner
                  },
                  data: {
                     changes: changes,
                     log_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then( async (value) => {
                  if(value.data.success === true) {
                     await getMetricData();

                     setTimeout(() => {
                        setOperationsModal('')
                        resolve("Yes")
                     }, 1000)
                  } else {
                     // There was an error
                     alert(value.data.reason)
                     resolve("Yes")
                  }
               })
               .catch(() => {
                  alert("There was an error")
                  resolve("Yes")
               })
            })
         , 'change_status')
      } else {
         setGrantAccessError(true)
      }
   }

   const handleChangePartnerStatus = async (partner, status) => {
      trackPromise(
         new Promise ( async ( resolve ) => {
            try {
               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }

               await axios({
                  method: 'put',
                  url: 'https://api.appliancesure.com/two/admin/portal/partners/changePartnerStatus',
                  params: {
                     partner_name: partner
                  },
                  data: {
                     new_status: status,
                     stamp_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then((val) => {
                  if(val.data.success === true) {
                     setShowActivePartners(status)
                     getMetricData();
                  } else {
                     alert("There was an error")
                  }

                  setOperationsModal('')
                  resolve("Yes")
               })
               .catch((err) => {
                  alert(err)
                  setOperationsModal('')
                  resolve("Yes")
               })
            }

            catch {
               alert("There was an error")
               setOperationsModal('')
               resolve("Yes")
            }
         })
      , 'change_status')
      
   }

   const handleRemovePartnerAccess = async (partner, product) => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }
         
            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'put',
               url: 'https://api.appliancesure.com/two/admin/portal/products/removeProductAccess',
               params: {
                  partner_name: partner
               },
               data: {
                  product_name: product,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then( async (value) => {
               if(value.data.success === true) {
                  await getMetricData();

                  setTimeout(() => {
                     setOperationsModal('')
                     resolve("Yes")
                  }, 1000)
               } else {
                  // There was an error
                  alert(value.data.reason)
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'change_status')
   }

   const handleOpenGrantProductAccess = async (partner) => {
      try {
         setOperationsModal(`Grant access - ${partner}`)
         setGrantAccessError(false)
         setGrantProductAccess({})

         const productLookup = await axios({
            method: 'get',
            url: 'https://api.appliancesure.com/two/admin/portal/products/getNoAccessProducts',
            params: {
               partner_name: partner
            }
         })

         const productArray = productLookup.data.records || []

         setAvailableProducts(productArray)
      }

      catch (err) {
         console.log(err)
         alert(err)
      }
   }

   const goToManageUsers = (partner) => {
      setPassParams({partner: partner})
      setShowScreen('manage_users')
   }

   const createPartnerList = () => {
      let jsx = []

      for (let i = 0; i < partnerData.length; i++) {
         const partner = partnerData[i];
         
         if(partner.active === showActivePartners) {
            jsx.push(
               <li className={selectPartner === i ? 'selected' : ''} onClick={() => setSelectPartner(i)}>{partner.partner_name}</li>
            )
         }
      }

      return jsx
   }

   const showPartnerTile = () => {
      let jsx = []

      if(selectPartner !== '') {
         const partner_details = partnerData[selectPartner]

         let address = []
         const adrs = partner_details.legal.address;
         let addressArray = [adrs.line_1, adrs.line_2, adrs.line_3, adrs.city, adrs.county_state, adrs.postcode, adrs.country]

         for (let i = 0; i < addressArray.length; i++) {
            const el = addressArray[i];
            
            if(el !== '') {
               address.push(el, <br/>)
            }
         }

         jsx.push(
            <div className="grid-container">
               <button className="back-button" onClick={() => setSelectPartner('')}>Close</button>
               <br/>
               <br/>
               <h2>{partner_details.partner_name} <span className={partner_details.active ? 'active-record' : 'suspended-record'}>[{partner_details.active ? 'Active' : 'Suspended'}]</span></h2>
               <br/>
               <br/>

               <input
                  className="invisible accordion-trigger"
                  type="radio"
                  name="manage-section"
                  id={`legal_${partner_details.partner_name}`}
               />

               <label htmlFor={`legal_${partner_details.partner_name}`} className="accordion-label">
                  <h3>Legal details</h3>
               </label>
               
               <div className="form-section-container">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Legal name</td>
                           <td>{partner_details.legal.legal_name}</td>
                        </tr>
                        <tr>
                           <td>Company number</td>
                           <td>{partner_details.legal.company_number}</td>
                        </tr>
                        <tr>
                           <td>Registered authority</td>
                           <td>{partner_details.legal.country_authority}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               

               <br/>

               <input
                  className="invisible accordion-trigger"
                  type="radio"
                  name="manage-section"
                  id={`location_${partner_details.partner_name}`}
               />

               <label htmlFor={`location_${partner_details.partner_name}`} className="accordion-label">
                  <h3>Location</h3>
               </label>
               
               <div className="form-section-container">
                  <p>{address}</p>
               </div>
               
               <br/>

               <input
                  className="invisible accordion-trigger"
                  type="radio"
                  name="manage-section"
                  id={`contact_${partner_details.partner_name}`}
               />

               <label htmlFor={`contact_${partner_details.partner_name}`} className="accordion-label">
                  <h3>Key contact</h3>
               </label>
               
               <div className="form-section-container">
                  <table className="tariff-information-table">
                     <tbody>
                        <tr>
                           <td>Name</td>
                           <td>{partner_details.primary_contact.name}</td>
                        </tr>
                        <tr>
                           <td>Email</td>
                           <td>{partner_details.primary_contact.email}</td>
                        </tr>
                        <tr>
                           <td>Phone</td>
                           <td>{partner_details.primary_contact.phone || "None"}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <br/>

               <input
                  className="invisible accordion-trigger"
                  type="radio"
                  name="manage-section"
                  id={`access_${partner_details.partner_name}`}
               />

               {
                  partner_details.active ? (
                     <React.Fragment>
                        <label htmlFor={`access_${partner_details.partner_name}`} className="accordion-label">
                           <h3>Product access</h3>
                        </label>

                        <div className="form-section-container">
                           <div className="partner-access-scrollarea">
                              {partner_details.products.map((product) => (
                                 <React.Fragment>
                                    <div className="partner-access-row">
                                       <h5>{product}</h5>

                                       <button
                                          className="remove-plain-text-button"
                                          onClick={() => setOperationsModal(`Remove ${partner_details.partner_name}'s access to ${product}`)}
                                       >Remove access</button>

                                       {/*--- Remove access ---*/}
                                       {operationsModal === `Remove ${partner_details.partner_name}'s access to ${product}` ? (
                                          <ConfirmModal 
                                             onConfirm={() => handleRemovePartnerAccess(partner_details.partner_name, product)}
                                             onReject={handleResetOperationsModal}
                                             operation={`remove ${partner_details.partner_name}'s access to ${product}`}
                                          />
                                       ) : null}
                                    </div>
                                 </React.Fragment>
                              ))}
                              <p className="partner-access-blank">This partner doesn't currently have access to any products.</p>
                           </div>

                           <button
                              className="plain-text-button"
                              onClick={() => handleOpenGrantProductAccess(partner_details.partner_name)}
                           >Grant product access</button>

                           {/*--- Grant access to partner ---*/}
                           {operationsModal === `Grant access - ${partner_details.partner_name}` ? (
                              <div className="center-modal-backdrop">
                                 <div className="confirm-modal-container">
                                    <h3>Grant product access to {partner_details.partner_name}</h3>

                                    <div className="partner-access-scrollarea">
                                       {availableProducts.map(product => (
                                          <React.Fragment>
                                             <div className="partner-access-row">
                                                <h5>{product.product_name}</h5>
                                                
                                                <label className="switchbox standard">
                                                   <input
                                                      id="auto-update"
                                                      type="checkbox"
                                                      name={product.product_name}
                                                      value={grantProductAccess[product.product_name] || false}
                                                      onChange={(e) => handleChangeGrantProductAccess(e, product.product_name)}
                                                   />
                                                   <span className="slider-switchbox standard"></span>
                                                </label>
                                             </div>
                                          </React.Fragment>
                                       ))}
                                       <p className="partner-access-blank">This partner currently has access to all available products.</p>
                                    </div>

                                    {
                                       grantAccessError ? (
                                          <Error
                                             type="warning"
                                             message="Please make at least one change"
                                          />
                                       ) : null
                                    }

                                    <div className="confirm-modal-button-container">
                                       <button className="confirm-modal-button no" onClick={handleCloseGrantProductAccess}>
                                          Cancel
                                       </button>

                                       <button className="confirm-modal-button yes" onClick={() => handleGrantProductAccess(partner_details.partner_name)}>
                                          Save
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           ) : null}
                        </div>
                     </React.Fragment>
                  ) : null
               }

               <br/>
               <br/>
               
               <h3>Actions 🛠</h3>

               <ul className="grid-list">
                  {
                     partner_details.active ? (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Suspend ${partner_details.partner_name}`)}>Suspend partner</li>
                           <li onClick={() => goToManageUsers(partner_details.partner_name)}>Manage users</li>
                           <li onClick={() => setOperationsModal(`See partner activity - ${partner_details.partner_name}`)}>See partner activity</li>
                           <li>Create user</li>
                           <li>See reports</li>
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Re-activate ${partner_details.partner_name}`)}>Re-activate partner</li>
                        </React.Fragment>
                     )
                  }

                  {/* Operations */}
                  {operationsModal === `Suspend ${partner_details.partner_name}` ? (
                     <ConfirmModal 
                        onConfirm={() => handleChangePartnerStatus(partner_details.partner_name, false)}
                        onReject={handleResetOperationsModal}
                        operation={`suspend ${partner_details.partner_name}`}
                     />
                  ) : null}

                  {operationsModal === `Re-activate ${partner_details.partner_name}` ? (
                     <ConfirmModal 
                        onConfirm={() => handleChangePartnerStatus(partner_details.partner_name, true)}
                        onReject={handleResetOperationsModal}
                        operation={`re-activate ${partner_details.partner_name}`}
                     />
                  ) : null}

                  {/*--- See partner activity ---*/}
                  {operationsModal === `See partner activity - ${partner_details.partner_name}` ? (
                     <ActivityLog
                        log={partner_details.activity_log}
                        path={['Partners', partner_details.partner_name]}
                        resetModal={handleResetOperationsModal}
                     />
                  ) : null}
               </ul>
            </div>
         )
      }

      return jsx;
   }

   return (
      <div className="screen-inner-container">
         <ChangeStatusLoader/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Manage partners</h2>

         <div className="two-col-grid-container">
            <div>
               {/* Filters */}
               <div className="filter-options-container">
                  <span>
                     <label className="form-label">Partner status</label>
                     <select 
                        className="form-input admin-row"
                        onChange={handleChangeStatusSearch}
                        value={showActivePartners}
                     >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                     </select>
                  </span>
               </div>

               {/* Partners */}
               <div className="grid-container">
                  <h2>Partners <span role="img" aria-label="jsx-a11y/accessible-emoji">🤝</span></h2>

                  <ul className="grid-list">
                     {createPartnerList()}
                  </ul>

                  <button
                     onClick={() => setShowScreen('create_partner')}
                     className="welcome-button secondary"
                  >Create new partner</button>
               </div>
            </div>

            {showPartnerTile()}
         </div>
      </div>
   )
}

export default ManagePartners