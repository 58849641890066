import React from "react"
import { ThreeDots } from "react-loader-spinner"
import { usePromiseTracker } from "react-promise-tracker";

const DailyVitalsTile = ({ data }) => {
   const DataLoadingIcon = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

      return (
         promiseInProgress &&
         <React.Fragment>
            <ThreeDots type="rotatingLines" color="#FFF" height={48} width={80} wrapperStyle={{justifySelf: 'center'}} />
         </React.Fragment>
      )
   }

   const generateTile = () => {
      let jsx = []

      if(data !== '') {
         jsx.push(
            <React.Fragment>
               <div className="admin-metric">
                  <h3>{data.sales || 0}</h3>
                  <p>Sales</p>
               </div>
               <div className="admin-metric">
                  <h3>{data.appliances || 0}</h3>
                  <p>Appliances</p>
               </div>
               <div className="admin-metric">
                  <h3>{data.cancellations || 0}</h3>
                  <p>Cancellations</p>
               </div>
            </React.Fragment>
         )
      }

      return jsx;
   }

   const { promiseInProgress } = usePromiseTracker({ area: 'data_load'});

   return (
      <div>
         <h3 style={{marginBottom: 10}}>Today's vitals</h3>
         
         <div style={{display: 'grid'}}>
            <div className="grid-container fill">
               <div className="admin-metrics-container" style={promiseInProgress ? { gridTemplateColumns: '100%' } : {}}>
                  {
                     promiseInProgress ? (
                        <DataLoadingIcon/>
                     ) : data === '' || data.sales === undefined ? (
                        <p className="no-data-loader">No data</p>
                     ) : (
                        <React.Fragment>
                           {generateTile()}
                        </React.Fragment>
                     )
                  }
               </div>
            </div>
         </div>
      </div>
   )
}

export default DailyVitalsTile