import React from "react";

const Legal = ({
   upgradeData,
   updateUpgradeData,
   updateStage,
   updateScriptStage
}) => {

   const goBack = () => {
      if(upgradeData.chosen_package.single_upgrade === true) {
         updateStage(3)
      } else {
         updateStage(4)
      }
   }

   const handleAgreeTCs = () => {
      updateUpgradeData({
         ...upgradeData,
         tandc_agreement: !upgradeData.tandc_agreement
      })
   }

   return (
      <div className="grid-container">
         <button className="back-button" onClick={goBack}>Go back</button>
         <h2>Legal</h2>
         
         <br/>
         
         <h3>Terms & conditions</h3>

         <p>I have been read the principle <a href="https://appliancesure.com/terms-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a> of this contract and agree in principle. I understand I have a 14 day cooling off period to read through these documents in more detail and cancel during this period if I no longer wish to proceed.</p>

         <div className='checkbox-form-container'>
            <input
               type="checkbox"
               className='checkbox-trigger invisible'
               name="terms_service"
               id="terms_service"
               checked={upgradeData.tandc_agreement}
               onChange={handleAgreeTCs}
            />
            <label htmlFor='terms_service' className="checkbox"/>

            <span style={{textAlign: 'left'}}>
               <label htmlFor='terms_service' className='form-label'>I agree to the principle terms</label>
               <span>In order to proceed, it is mandatory to gain a clear yes from the customer that they wish to proceed.</span>
            </span>
         </div>

         {
            upgradeData.tandc_agreement === true ? (
               <React.Fragment>
                  <br/>
                  <br/>
                  <button
                     className="welcome-button secondary"
                     onClick={() => updateStage(6)}
                  >
                     Continue
                  </button>
               </React.Fragment>
            ) : null
         }
      </div>
   )
}

export default Legal