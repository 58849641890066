import React from "react";
import ConfirmLeadSourceDetails from "./lead_source/confirm_details";
import ConfirmSalesCallDetails from "./sales_call/confirm_details";

const ConfirmDetails = ({
   updateStage,
   markedCallData,
   updateMarkedCallData,
   callType
}) => {
   switch (callType) {
      case "lead_source":
         return <ConfirmLeadSourceDetails
                  updateStage={updateStage}
                  markedCallData={markedCallData}
                  updateMarkedCallData={updateMarkedCallData}
               />

      case "sales_call":
         return <ConfirmSalesCallDetails
                  updateStage={updateStage}
                  markedCallData={markedCallData}
                  updateMarkedCallData={updateMarkedCallData}
               />
   }
}

export default ConfirmDetails