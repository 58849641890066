import React, { useState } from "react";
import Error from "../../components/global/error";
import { MdOutlineRemoveCircle as Remove } from 'react-icons/md' 
import { useUserAuth } from "../../context/userAuthContext";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { detect } from "detect-browser";
import axios from "axios";

const CreateScorecard = ({
   setShowScreen,
   permissions
}) => {
   // Get list of available call types
   let permitted_scorecard_types = []
   const permissions_array = Object.entries(permissions)

   for (let i = 0; i < permissions_array.length; i++) {
      const el = permissions_array[i];
      
      if(el[1].access_granted === true) {
         permitted_scorecard_types.push(el[0])
      }
   }

   const { user, userDetails } = useUserAuth();
   const browser = detect();

   const [newScorecardData, setNewScorecardData] = useState({
      title: '',
      description: '',
      type: '',
      question_count: 0,
      questions: [
         {
            name: "",
            guidelines: "",
            weighting: 'minor'
         }
      ],
      created: {
         by: userDetails.email_address
      }
   })

   const [errors, setErrors] = useState({
      title: false,
      description: false,
      type: false,
      questions: false
   })

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Creating scorecard...</h3>
         </div>
      )
   }

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      setNewScorecardData({
         ...newScorecardData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false
      })
   }

   const handleChangeComplianceQ = (e, i, field) => {
      let val = e.target.value;
      if(field === 'weighting') {
         const checked = e.target.checked;

         if(checked === true) {
            val = 'major'
         } else {
            val = 'minor'
         }
      }

      setNewScorecardData({
         ...newScorecardData.questions[i] = {
            ...newScorecardData.questions[i],
            [field]: val
         },
         ...newScorecardData,
      })

      setErrors({
         ...errors,
         questions: false
      })
   }

   const addNewComplianceRow = () => {
      setNewScorecardData({
         ...newScorecardData,
         questions: [
            ...newScorecardData.questions,
            {
               name: '',
               guidelines: '',
               weighting: 'minor'
            }
         ]
      })
   }

   const removeComplianceRow = (i) => {
      if(newScorecardData.questions.length > 1) {
         setNewScorecardData({
            ...newScorecardData,
            questions: [...newScorecardData.questions.slice(0, i), ...newScorecardData.questions.slice(i + 1)]
         })
      }
   }

   const handleDataValidation = () => {
      const required_fields = [
         "title",
         "description",
         "type"
      ]

      let errors_count = 0;
      let errors_object = {}

      for (let i = 0; i < required_fields.length; i++) {
         const el = required_fields[i];
         
         if(newScorecardData[el] === '') {
            errors_count++
            errors_object[el] = true
         }

         for (let i = 0; i < newScorecardData.questions.length; i++) {
            const el = newScorecardData.questions[i];
            
            if(el.name === '') {
               errors_count++
               errors_object.questions = true
            }
         }
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         return false
      }
   }

   const handleSubmit = () => {
      if(handleDataValidation() === true) {
         let final_object = newScorecardData
         // Format data
         for (let i = 0; i < newScorecardData.questions.length; i++) {            
            final_object.question_count += 1
            final_object.questions[i].order = i + 1
         }

         delete final_object.name;
         delete final_object.guidelines;
         delete final_object.order;
         delete final_object.weighting;

         trackPromise(
            new Promise( async (resolve) => {
               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }

               await axios({
                  method: 'post',
                  url: 'https://api.appliancesure.com/two/admin/portal/quality/createNewScorecard',
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  data: {
                     data: final_object,
                     log_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then( async (value) => {
                  const resp = value.data.success;

                  if(resp === true) {
                     setShowScreen('manage_scorecards')
                     resolve("Yes")
                  } else {
                     alert(value.data.reason)
                     resolve("Yes")
                  }
               })
               .catch(() => {
                  alert("There was an error")
                  resolve("Yes")
               })
            })
         , 'submit')
      }
   }

   const generateComplianceRows = () => {
      let jsx = []

      let questions = newScorecardData.questions;

      for (let i = 0; i < questions.length; i++) {
         const el = questions[i];
         
         jsx.push(
            <tr>
               <td>#{i + 1}</td>
               <td>
                  <textarea
                     className="qa-text-input"
                     placeholder="Enter your question"
                     value={el.name}
                     onChange={(e) => handleChangeComplianceQ(e, i, 'name')}
                  />
               </td>
               <td >
                  <textarea
                     className="qa-text-input"
                     placeholder="Enter any marking guidelines"
                     value={el.guidelines}
                     onChange={(e) => handleChangeComplianceQ(e, i, 'guidelines')}
                  />
               </td>
               <td>
                  <label className="switchbox standard">
                     <input
                        id="auto-update"
                        type="checkbox"
                        onChange={(e) => handleChangeComplianceQ(e, i, 'weighting')}
                        checked={el.weighting === 'major'}
                     />
                     <span className="slider-switchbox standard negative"></span>
                  </label>
               </td>
               <td>
                  {
                     i !== 0 ? (
                        <button 
                           style={{fontSize: 20, color: 'red'}} 
                           className="plain-text-button" 
                           onClick={() => removeComplianceRow(i)}
                        >
                           <Remove/>
                        </button>
                     ) : null
                  }
               </td>
            </tr>
         )
      }

      return jsx;
   }

   return (
      <div className="screen-inner-container">
         <SubmitLoading/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to quality control</button>
         <h2>Create new scorecard</h2>

            <br/>
            <br/>
            <div className="grid-container">
               <h2>Scorecard details <span role="img" aria-label="jsx-a11y/accessible-emoji">✏️</span></h2>

               <br/>

               <form onChange={handleFillInForm} className="form-2col">
                  <span>
                     <label htmlFor="title" className="form-label">Title</label>
                     <input
                        type="text"
                        id="title"
                        name="title"
                        className="form-input"
                        placeholder="Enter a title for the scorecard"
                        value={newScorecardData.title}
                     />

                     {
                        errors.title ? (
                           <Error message="Please give this scorecard a title"/>
                        ) : null
                     }

                     <label htmlFor="description" className="form-label">Description</label>
                     <input
                        type="text"
                        id="description"
                        name="description"
                        className="form-input"
                        placeholder="Enter a short description"
                        value={newScorecardData.description}
                     />

                     {
                        errors.description ? (
                           <Error message="Please give this scorecard a description"/>
                        ) : null
                     }

                     <label htmlFor="type" className="form-label">Scorecard type</label>
                     <select
                        id="type"
                        name="type"
                        className="form-input"
                        value={newScorecardData.type}
                     >
                        <option value="" selected disabled>Select a scorecard type</option>
                        {permitted_scorecard_types.map(type => (
                           <option value={type}>{type.charAt(0).toUpperCase() + type.slice(1).replaceAll("_", " ")}</option>
                        ))}
                     </select>

                     {
                        errors.type ? (
                           <Error message="Please select the type of scorecard"/>
                        ) : null
                     }
                  </span>
               </form>

               <br/>

               <h3>The scorecard</h3>

               <br/>

               <table className="manage-items-table">
                  <tr>
                     <th>&nbsp;</th>
                     <th>Question</th>
                     <th>Marking guidelines</th>
                     <th>Major fail</th>
                     <th>&nbsp;</th>
                  </tr>
                  {generateComplianceRows()}
               </table>
               <div className="close-modal-positioning-container">
                  <button 
                     className="plain-text-button"
                     onClick={addNewComplianceRow}
                  >Add new question</button>
               </div>
               
               {
                  errors.questions ? (
                     <Error message="The 'Question' field cannot be left blank in any question row, please check and resolve"/>
                  ) : null
               }

               <button 
                  className="welcome-button secondary"
                  onClick={handleSubmit}
               >Create scorecard</button>
            </div>
      </div>
   )  
}

export default CreateScorecard