import React, { useState } from "react";
import axios from "axios";
import Error from "../../components/global/error";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../context/userAuthContext";
import TextEditor from "../../components/global/text-editor";

const EditContent = ({
   content,
   setEditContent,
   getContentByCategory,
   setSavedSuccessfully
}) => {
   const { userDetails } = useUserAuth()

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Saving content...</h3>
         </div>
      )
   }

   const [contentData, setContentData] = useState({
      title: content.title,
      description: content.description,
      category: content.category,
      media_format: content.media_format,
      text: content.text || '',
      url: content.url || '',
      added: {
         by: userDetails.email_address
      }
   })

   const handleFillInForm = (event) => {
      const name = event.target.name;
      const val = event.target.value;

      setContentData({
         ...contentData,
         [name]: val
      })

      setErrors({
         ...errors,
         [name]: false,
         already_exists: false
      })
   }

   const [errors, setErrors] = useState({
      title: false,
      description: false,
      category: false,
      media_format: false,
      text: false,
      url: false,
      already_exists: false
   })

   const handleUpdateContent = (e) => {
      setContentData({
         ...contentData,
         text: e
      })

      setErrors({
         ...errors,
         text: false
      })
   }

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {};

      if(contentData.media_format === 'text') {
         if(contentData.text === '') {
            errors_count++
            errors_object.text = true
         }
      } else if (contentData.media_format === 'url' || contentData.media_format === 'presentation') {
         if(contentData.url.includes("http") === false || contentData.url.includes("://") === false || contentData.url.includes(".") === false) {
            errors_count++
            errors_object.url = true
         }
      }

      if (errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         return false
      }
   }

   const handleSaveContent = async () => {
      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'post',
                  url: 'https://api.appliancesure.com/two/admin/portal/knowledge/editContent',
                  data: contentData
               })
               .then((value) => {
                  if(value.data.success === false) {
                     alert("There was an error, please try again later")
                  } else {
                     getContentByCategory()
                  }
                  
                  resolve("Resolved")
                  setSavedSuccessfully('Content saved successfully')
                  setEditContent(false)
               })
               .catch(() => {
                  alert("There was an error")
                  resolve("Resolved")
               })
            })
         , 'submit')
      }
   }


   return (
      <div className="center-modal-backdrop">
         <SubmitLoading/>
         <div className="script-modal-container knowledge-content">
            <h2>Edit content <span role="img" aria-label="jsx-a11y/accessible-emoji">📝</span></h2>

            {
               contentData.media_format === 'text' ? (
                  <React.Fragment>
                     <br/>
                     <label className="form-label">Content</label>
                     <br/>
                     <TextEditor
                        initialContent={content.text}
                        updateSource={handleUpdateContent}
                     />

                     <br/>
                     <br/>
                  </React.Fragment>
               ) : contentData.media_format === 'url' ? (
                  <React.Fragment>
                     <br/>
                     <label htmlFor="url" className="form-label">URL</label>

                     <input
                        className="form-input"
                        type="text"
                        name="url"
                        onChange={handleFillInForm}
                        value={contentData.url}
                        placeholder="Enter the URL you'd like to link to"
                     />

                     {
                        errors.url ? (
                           <Error
                              message="Please enter a valid url"
                           />
                        ) : null
                     }
                  </React.Fragment>
               ) : contentData.media_format === 'presentation' ? (
                  <React.Fragment>
                     <label htmlFor="url" className="form-label">Embed URL</label>

                     <input
                        className="form-input"
                        type="text"
                        name="url"
                        onChange={handleFillInForm}
                        value={contentData.url}
                        placeholder="Enter the Canva link to embed your URL"
                     />

                     {
                        errors.url ? (
                           <Error
                              message="Please enter a valid url"
                           />
                        ) : null
                     }
                  </React.Fragment>
               ) : null
            }

            {
               errors.already_exists ? (
                  <p style={{marginTop: -10}} className="info-statement">A content entry of this title and category already exists</p>
               ) : null
            }

            <div className="script-button-container">
               <button
                  className="welcome-button secondary"
                  style={{width: 'fit-content'}}
                  onClick={handleSaveContent}
               >Save changes</button>

               <button
                  className="plain-text-button"
                  onClick={() => setEditContent('')}
               >Cancel</button>
            </div>
         </div>
      </div>
   )
}

export default EditContent