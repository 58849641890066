const bank_names = {
   "NAT WEST BANK PLC": {
      name: "Natwest",
      url: ""
   },
   "BARCLAYS BANK UK PLC": {
      name: "Barclays",
      url: "barclays"
   },
   "NATIONWIDE BLDG SCTY": {
      name: "Nationwide",
      url: "nationwide"
   }, 
   "METRO BANK": {
      name: "Metro Bank",
      url: "metro-bank"
   }, 
   "Bank of Scotland plc": {
      name: "Bank of Scotland",
      url: "bank-of-scotland"
   }, 
   "TSB": {
      name: "TSB",
      url: "tsb"
   }, 
   "Santander": {
      name: "Santander",
      url: "santander"
   }, 
   "LLOYDS BANK PLC": {
      name: "Lloyds Bank",
      url: "lloyds-bank"
   }, 
   "HSBC UK BANK PLC": {
      name: "HSBC",
      url: "hsbc"
   }, 
   "YORKSHIRE BANK": {
      name: "Yorkshire Bank",
      url: "yorkshire-bank"
   }, 
   "CO-OPERATIVE BANK": {
      name: "The Co-Operative Bank",
      url: "cooperative-bank"
   }, 
   "HALIFAX": {
      name: "Halifax",
      url: "halifax"
   }, 
   "ROYAL BANK OF SCOT": {
      name: "Royal Bank of Scotland",
      url: "rbs"
   }, 
   "ALLIED IRISH BANK GB": {
      name: "Allied Irish Bank",
      url: "allied-irish-bank"
   }, 
   "BOI UK PLC": {
      name: "Bank of Ireland",
      url: "bank-of-ireland"
   }, 
   "CLYDESDALE": {
      name: "Clydesdale Bank",
      url: "clydesdale-bank"
   }, 
   "MODULR": {
      name: "Revolut",
      url: "revolut"
   }, 
   "MONZO BANK LIMITED": {
      name: "Monzo",
      url: "monzo",
   }, 
   "STARLING BANK LTD": {
      name: "Starling Bank",
      url: "starling-bank"
   }, 
}

export default bank_names