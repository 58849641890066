import axios from "axios"
import { detect } from "detect-browser"
import React, { useEffect, useState } from "react"
import { useUserAuth } from "../../context/userAuthContext"
import { MdOutlineRemoveCircle as Remove } from 'react-icons/md' 
import Error from "../../components/global/error"
import { trackPromise, usePromiseTracker } from "react-promise-tracker"
import { RotatingLines } from "react-loader-spinner"
import SavedSuccessfully from "../../components/saved-successfully"
import ConfirmModal from "../../components/global/confirm-modal"
import ActivityLog from "../../components/local/activity-log"

const ManageScorecards = ({
   setShowScreen,
   permissions
}) => {
   // States
   const [records, setRecords] = useState([])

   const [errors, setErrors] = useState({

   })

   const [showActiveScorecards, setShowActiveScorecards] = useState(true)
   const [scorecardTypeFilter, setScorecardTypeFilter] = useState('')
   const [selectScorecard, setSelectScorecard] = useState('')
   const [permissionMode, setPermissionMode] = useState('view only')
   const [savedSuccessfully, setSavedSuccessfully] = useState('')

   const [editScorecard, updateEditScorecard] = useState('')
   let originalScorecard = {}
   if(selectScorecard !== '') {
      originalScorecard = records[selectScorecard]
   }

   const [operationsModal, setOperationsModal] = useState('')

   const mode_emojis = {
      'view only': '👁',
      'edit': '✏️'
   }

   const browser = detect();
   const { userDetails, user } = useUserAuth()

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Making changes...</h3>
         </div>
      )
   }

   useEffect(() => {
      // Check if sales_call is granted
      if(permissions.sales_call.access_granted) {
         setScorecardTypeFilter('sales_call')
      } else if (permissions.non_sales_call.access_granted) {
         setScorecardTypeFilter('non_sales_call')
      } else if(permissions.lead_source.access_granted) {
         setScorecardTypeFilter('lead_source')
      }
   }, [])

   const getScorecards = async () => {
      await axios({
         method: 'get',
         url: 'https://api.appliancesure.com/two/admin/portal/quality/getAllScorecardsByType',
         headers: {
            Authorization: "Bearer " + user
         },
         params: {
            type: scorecardTypeFilter
         }
      })
      .then((value) => {
         if(value.data.success === true) {
            setRecords(value.data.records)
         } else {
            setRecords("No data")
         }
      })
   }

   useEffect(() => {
      setSelectScorecard('')
      setPermissionMode('view only')

      if(scorecardTypeFilter !== '') {
         getScorecards()
      }
   }, [scorecardTypeFilter])

   const setEditScorecard = () => {
      if(selectScorecard !== '') {
         updateEditScorecard({
            ...records[selectScorecard]
         })
      }
   }

   useEffect(() => {
      setPermissionMode('view only')
      setEditScorecard()
   }, [selectScorecard])

   useEffect(() => {
      setPermissionMode('view only')
      setSelectScorecard('')
   }, [showActiveScorecards])

   const createScorecardList = () => {
      let jsx = []

      if(records === 'No data') {
         jsx.push("NO DATA")
      } else {
         for (let i = 0; i < records.length; i++) {
            const record = records[i];
            
            if(record.active === showActiveScorecards) {
               jsx.push(
                  <li className={selectScorecard === i ? 'selected' : ''} onClick={() => setSelectScorecard(i)}>{record.title}</li>
               )
            }
         }

         if(jsx.length === 0) {
            jsx.push(
               <p>There are no {showActiveScorecards ? 'active' : 'inactive'} scorecards to display.</p>
            )
         }
      }

      return jsx
   }

   const handleChangeComplianceQ = (e, i, field) => {
      let val = e.target.value;
      if(field === 'weighting') {
         const checked = e.target.checked;

         if(checked === true) {
            val = 'major'
         } else {
            val = 'minor'
         }
      }

      let updated_array = [...editScorecard.questions]
      updated_array[i][field] = val

      updateEditScorecard({
         ...editScorecard,
         questions: updated_array
      })

      setErrors({
         ...errors,
         questions: false
      })
   }

   const addNewComplianceRow = () => {
      updateEditScorecard({
         ...editScorecard,
         questions: [
            ...editScorecard.questions,
            {
               name: '',
               guidelines: '',
               weighting: 'minor'
            }
         ]
      })
   }

   const removeComplianceRow = (i) => {
      if(editScorecard.questions.length > 1) {
         updateEditScorecard({
            ...editScorecard,
            questions: [...editScorecard.questions.slice(0, i), ...editScorecard.questions.slice(i + 1)]
         })
      }
   }

   const handleChangeScorecardStatus = (title, type, status) => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }
         
            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'put',
               url: 'https://api.appliancesure.com/two/admin/portal/quality/changeScorecardStatus',
               headers: {
                  Authorization: "Bearer " + user
               },
               data: {
                  new_status: status,
                  type: type,
                  title: title,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then( async (value) => {
               if(value.data.success === true) {
                  await getScorecards();

                  setTimeout(() => {
                     setShowActiveScorecards(status)
                     setOperationsModal('')
                     setSavedSuccessfully(`Scorecard ${status ? 're-activated' : 'suspended'} successfully`)
                     resolve("Yes")
                  }, 1000)
               } else {
                  alert(value.data.reason)
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'submit')
   }

   const handleResetOperationsModal = () => {
      setOperationsModal('')
   }

   const handleDiscardChanges = () => {
      setPermissionMode('view only')
      setEditScorecard()
   }

   const handleDataValidation = () => {
      let errors_count = 0;
      let errors_object = {}

      if(editScorecard.description === '') {
         errors_count++
         errors_object.description = true
      } 

      for (let i = 0; i < editScorecard.questions.length; i++) {
         const el = editScorecard.questions[i];
         
         if(el.name === '') {
            errors_count++
            errors_object.questions = true
         }
      }

      if(errors_count === 0) {
         return true
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         return false
      }
   }

   const handleSubmitChanges = async () => {
      if(handleDataValidation() === true) {
         trackPromise(
            new Promise( async (resolve) => {
               let final_object = editScorecard

               for (let i = 0; i < editScorecard.questions.length; i++) {
                  final_object.questions[i].order = i + 1
               }

               final_object.question_count = editScorecard.questions.length

               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }

               await axios({
                  method: 'put',
                  url: 'https://api.appliancesure.com/two/admin/portal/quality/editScorecard',
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  data: {
                     data: final_object,
                     log_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then( async (value) => {
                  setTimeout( async () => {
                     const resp = value.data.success;

                     if(resp === true) {
                        await getScorecards()
                        setSavedSuccessfully('Changes saved successfully')
                        setPermissionMode('view only')
                        resolve("Yes")
                     } else {
                        alert(value.data.reason)
                        resolve("Yes")
                     }
                  }, 1000)
               })
            })
         , 'submit')
      }
   }

   const showScorecardTile = () => {
      let jsx = []

      if(selectScorecard !== '' && editScorecard !== '') {
         const type = editScorecard.type.charAt(0).toUpperCase() + editScorecard.type.slice(1).replaceAll("_", " ")

         jsx.push(
            <div className="grid-container">
               <button className="back-button" onClick={() => setSelectScorecard('')}>Close</button>
               <br/>
               <br/>
               <h2>{editScorecard.title}</h2>
               <p className="description">{editScorecard.description}</p>

               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <td>Status</td>
                        <td>{editScorecard.active === true ? 'Active' : 'Inactive'}</td>
                     </tr>
                     <tr>
                        <td>Created at</td>
                        <td>{new Date(editScorecard.created.at).toLocaleString('en-GB')}</td>
                     </tr>
                     <tr>
                        <td>Created by</td>
                        <td>{editScorecard.created.by}</td>
                     </tr>
                  </tbody>
               </table>

               <h3>Actions<span role="img" aria-label="jsx-a11y/accessible-emoji">🛠</span></h3>

               <ul className="grid-list">
                  {
                     editScorecard.active ? (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Suspend ${editScorecard.title} ${editScorecard.type}`)}>Suspend use of scorecard</li>
                           <li onClick={() => setOperationsModal(`See user activity - ${editScorecard.title} ${editScorecard.type}`)}>View activity log</li>
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Re-activate ${editScorecard.title} ${editScorecard.type}`)}>Re-activate scorecard</li>
                           <li onClick={() => setOperationsModal(`See user activity - ${editScorecard.title} ${editScorecard.type}`)}>View activity log</li>
                        </React.Fragment>
                     )
                  }

                  {/* Operations */}
                  {/*--- Suspend / Re-activate scorecard ---*/}
                  {operationsModal === `Suspend ${editScorecard.title} ${editScorecard.type}` ? (
                     <ConfirmModal
                        onConfirm={() => handleChangeScorecardStatus(editScorecard.title, editScorecard.type, false)}
                        onReject={handleResetOperationsModal}
                        operation={`suspend '${editScorecard.title}'`}
                     />
                  ) : null}

                  {operationsModal === `Re-activate ${editScorecard.title} ${editScorecard.type}` ? (
                     <ConfirmModal
                        onConfirm={() => handleChangeScorecardStatus(editScorecard.title, editScorecard.type, true)}
                        onReject={handleResetOperationsModal}
                        operation={`re-activate '${editScorecard.title}'`}
                     />
                  ) : null}

                  {/* See activity log */}
                  {operationsModal === `See user activity - ${editScorecard.title} ${editScorecard.type}` ? (
                     <ActivityLog
                        log={editScorecard.activity_log}
                        path={['Scorecards', type, editScorecard.title]}
                        resetModal={handleResetOperationsModal}
                     />
                  ) : null}
               </ul>

               <p className={`permission-mode ${permissionMode === 'edit' ? 'edit' : 'view'}`}>You are currently in {permissionMode} mode {mode_emojis[permissionMode]}           
               {
                  permissionMode === 'view only' && showActiveScorecards ? (
                     <button className="edit-mode-button" onClick={() => setPermissionMode('edit')}>Edit timetable</button>
                  ) : null
               }
               </p>

               <label htmlFor="title" className="form-label">Title</label>
               <input
                  type="text"
                  id="title"
                  name="title"
                  className="form-input"
                  value={editScorecard.title}
                  disabled
               />

               <label htmlFor="description" className="form-label">Description</label>
               <input
                  type="text"
                  id="description"
                  name="description"
                  className="form-input"
                  value={editScorecard.description}
                  disabled={permissionMode === 'view only'}
                  onChange={(e) => updateEditScorecard({...editScorecard, description: e.target.value})}
                  placeholder="Enter a short description"
               />

               {
                  permissionMode === 'edit' && errors.description ? (
                     <Error message="Please enter a description for this scorecard" />
                  ) : null
               }

               <label htmlFor="type" className="form-label">Type</label>
               <input
                  type="text"
                  id="type"
                  name="type"
                  className="form-input"
                  value={type}
                  disabled
               />

               <h3>Scorecard</h3>

               <br/>

               <div className="table-wrapper">
                  <table className="manage-items-table">
                     <tbody>
                        <tr>
                           <th>&nbsp;</th>
                           <th>Question</th>
                           <th>Marking guidelines</th>
                           <th>Major fail</th>
                           <th>&nbsp;</th>
                        </tr>
                        {editScorecard.questions.map((question, index) => (
                           <React.Fragment>
                              <tr>
                                 <td>#{index + 1}</td>
                                 <td>
                                    <textarea
                                       className="qa-text-input"
                                       placeholder="Enter your question"
                                       disabled={permissionMode === 'view only'}
                                       value={question.name}
                                       onChange={(e) => handleChangeComplianceQ(e, index, 'name')}
                                    />
                                 </td>
                                 <td>
                                    <textarea
                                       className="qa-text-input"
                                       placeholder="Enter any marking guidelines"
                                       disabled={permissionMode === 'view only'}
                                       value={question.description}
                                       onChange={(e) => handleChangeComplianceQ(e, index, 'description')}
                                    />
                                 </td>
                                 <td>
                                    <label className="switchbox standard">
                                       <input
                                          id="auto-update"
                                          type="checkbox"
                                          disabled={permissionMode === 'view only'}
                                          checked={question.weighting === 'major'}
                                          onChange={(e) => handleChangeComplianceQ(e, index, 'weighting')}
                                       />
                                       <span className="slider-switchbox standard negative"></span>
                                    </label>
                                 </td>
                                 <td>
                                    {
                                       permissionMode === 'edit' && index !== 0 ? (
                                          <button 
                                             style={{fontSize: 20, color: 'red'}} 
                                             className="plain-text-button" 
                                             onClick={() => removeComplianceRow(index)}
                                          >
                                             <Remove/>
                                          </button>
                                       ) : null
                                    }
                                 </td>
                              </tr>
                           </React.Fragment>
                        ))}
                     </tbody>
                  </table>
                  {
                     permissionMode === 'edit' ? (
                        <React.Fragment>
                           <div className="close-modal-positioning-container">
                              <button 
                                 className="plain-text-button"
                                 onClick={addNewComplianceRow}
                              >Add new question</button>
                           </div>

                           {
                              errors.questions ? (
                                 <Error message="The 'Question' field cannot be left blank in any question row, please check and resolve"/>
                              ) : null
                           }

                           <br/>

                           <button
                              className="welcome-button secondary"
                              onClick={handleSubmitChanges}
                           >Save changes</button>

                           <button
                              className="welcome-button primary"
                              onClick={handleDiscardChanges}
                           >Discard changes</button>
                        </React.Fragment>
                     ) : null
                  }
               </div>
               
            </div>
         )
      }

      return jsx
   }

   return (
      <div className="screen-inner-container">
         <SubmitLoading/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to quality control</button>
         <h2>Manage quality scorecards</h2>

         <div className="two-col-grid-container">
            <div>
               {/* Filters */}
               <div className="filter-options-container">
                  <span>
                     <label className="form-label">Score type</label>
                     <select
                        className="form-input admin-row"
                        onChange={(e) => setScorecardTypeFilter(e.target.value)}
                        value={scorecardTypeFilter}
                     >
                        <option value="" selected disabled>Select a call type</option>
                        <option value="sales_call" style={{display: permissions.sales_call.access_granted ? 'block' : 'none'}}>Sales call</option>
                        <option value="non_sales_call" style={{display: permissions.non_sales_call.access_granted ? 'block' : 'none'}}>Non sales call</option>
                        <option value="lead_source" style={{display: permissions.lead_source.access_granted ? 'block' : 'none'}}>Lead source</option>
                     </select>
                  </span>

                  <span>
                     <label className="form-label">Active status</label>
                     <select
                        className="form-input admin-row"
                        onChange={(e) => setShowActiveScorecards(e.target.value === 'true')}
                        value={showActiveScorecards}
                     >
                        <option value="" selected disabled>Select an active status</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                     </select>
                  </span>
               </div>


               {/* Scorecards */}
               <div className="grid-container">
                  <h2>Scorecards <span role="img" aria-label="jsx-a11y/accessible-emoji">⚖️</span></h2>

                  <ul className="grid-list">
                     {createScorecardList()}
                  </ul>

                  <button
                     className="welcome-button secondary"
                  >Create new scorecard</button>
               </div>
            </div>

            {showScorecardTile()}

            {
               savedSuccessfully !== '' ? (
                  <SavedSuccessfully
                     content={savedSuccessfully}
                     reset={setSavedSuccessfully}
                  />
               ) : null
            }
         </div>
      </div>
   )
}

export default ManageScorecards