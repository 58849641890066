import axios from "axios"
import React, { useState, useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
import { trackPromise, usePromiseTracker } from "react-promise-tracker"
import Error from "../../components/global/error"
import { useUserAuth } from "../../context/userAuthContext"

const CampaignType = ({
   upgradeData,
   updateUpgradeData,
   availableAppliances,
   updateStage,
   updateScriptStage
}) => {
   const [errors, setErrors] = useState({
      service_number: false,
      not_found: false,
      error: false
   })

   const { user } = useUserAuth();

   // Promise tracker
   const SubmitLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'lookup_policy' });

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Looking up policy...</h3>
         </div>
      )
   }

   useEffect(() => {
      updateScriptStage('')
   }, [])

   const handleChangeCampaignType = (event) => {
      const val = event.target.value;

      updateUpgradeData({
         ...upgradeData,
         campaign: {
            campaign_type: val,
            lead_appliance: ''
         }
      })
   }

   const handleChangeLeadAppliance = (event) => {
      const val = event.target.value;

      updateUpgradeData({
         ...upgradeData,
         campaign: {
            ...upgradeData.campaign,
            lead_appliance: val
         }
      })
   }

   const handleChangeServiceNumber = (event) => {
      const val = event.target.value;

      updateUpgradeData({
         ...upgradeData,
         current_package: {
            ...upgradeData.current_package,
            service_number: val
         }
      })

      setErrors({
         ...errors,
         service_number: false,
         error: false,
         not_found: false
      })
   }

   const handleLookupPolicy = () => {
      const service_number = upgradeData.current_package.service_number
      if(service_number.length > 6 && service_number.includes("APS")) {
         // Lookup policy
         trackPromise(
            new Promise( async (resolve) => {
               await axios({
                  method: 'get',
                  url: 'https://api.appliancesure.com/two/admin/portal/upgrades/lookupPolicy',
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  params: {
                     service_number: service_number
                  }
               })
               .then((value) => {
                  if(value.data.found === true) {
                     updateUpgradeData({
                        ...upgradeData,
                        ...value.data.record
                     })

                     updateStage(2)
                  } else {
                     setErrors({
                        ...errors,
                        not_found: true
                     })
                  }

                  resolve("Yes")
               })
               .catch(() => {
                  setErrors({
                     ...errors,
                     error: true
                  })

                  resolve("No")
               })
            })
         , 'lookup_policy')
      } else {
         setErrors({
            ...errors,
            service_number: true
         })
      }
   }

   return (
      <div className="grid-container">
         <SubmitLoader/>
         <h2>Campaign</h2>

         <br/>

         <label className="form-label">What kind of campaign is this sale part of?</label>
         <form className="select-option-grid" onChange={handleChangeCampaignType}>
            <span>
               <input
                  className="select-option-trigger invisible"
                  type="radio"
                  value="warranty_expiry"
                  id="warranty_expiry"
                  name="campaign_type"
                  checked={upgradeData.campaign.campaign_type === 'warranty_expiry'}
               />

               <label htmlFor="warranty_expiry" className="select-option">Warranty expiry</label>
            </span>

            <span>
               <input
                  className="select-option-trigger invisible"
                  type="radio"
                  value="free_choice"
                  id="free_choice"
                  name="campaign_type"
                  checked={upgradeData.campaign.campaign_type === 'free_choice'}
                  disabled
               />

               <label htmlFor="free_choice" className="select-option">Free choice</label>
            </span>
         </form>
         
         {
            upgradeData.campaign.campaign_type === 'warranty_expiry' ? (
               <form onChange={handleChangeLeadAppliance}>
                  <br/>
                  <label htmlFor="lead_appliance" className="form-label">Lead appliance</label>
                  <select
                     className="form-input"
                     name="lead_appliance"
                     id="lead_appliance"
                     value={upgradeData.campaign.lead_appliance}
                  >
                     <option value="" disabled>Select an appliance</option>
                     {
                        availableAppliances.map((appliance, i) => (
                           <option value={appliance.name}>{appliance.name}</option>
                        ))
                     }
                  </select>
               
                  {
                     errors.lead_appliance ? (
                        <Error message="Please select a lead appliance" />
                     ) : null
                  }
               </form>
            ) : null
         }

         {
            upgradeData.campaign.lead_appliance !== '' || upgradeData.campaign.campaign_type === 'free_choice' ? (
               <React.Fragment>
                  <br/>
                  <h3>Policy lookup</h3>

                  <br/>

                  <label htmlFor="service_number" className="form-label">Service number</label>
                  <input
                     className="form-input"
                     type="text"
                     value={upgradeData.current_package.service_number}
                     onChange={handleChangeServiceNumber}
                     placeholder="Enter a service number"
                  />

                  {
                     errors.service_number ? (
                        <Error message="Please enter a valid Appliance Sure service number"/>
                     ) : null
                  }

                  <br/>

                  <button
                     className="welcome-button secondary"
                     onClick={handleLookupPolicy}
                  >
                     Look up policy
                  </button>

                  {
                     errors.error ? (
                        <React.Fragment>
                           <br/>
                           <br/>
                           <br/>
                           <Error message="There was a technical error, please try again"/>
                        </React.Fragment>
                     ) : null
                  }

                  {
                     errors.not_found ? (
                        <p className="info-statement">This service number could not be found inside of our CRM, please try another</p>
                     ) : null
                  }
               </React.Fragment>
            ) : null
         }
      </div>
   )
}

export default CampaignType