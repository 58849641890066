import React, { useEffect, useState } from "react";
import ConfirmModal from "../../components/global/confirm-modal";
import Error from "../../components/global/error";
import ActivityLog from "../../components/local/activity-log";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { detect } from "detect-browser";
import { useUserAuth } from "../../context/userAuthContext";
import axios from "axios";
import SavedSuccessfully from "../../components/saved-successfully";

const ManageProducts = ({
   productData,
   partnerData,
   setShowScreen,
   getMetricData,
   setPassParams,
   passParams
}) => {
   // States
   const [partners, setPartners] = useState([])
   const [selectProduct, setSelectProduct] = useState('')
   const [showActiveProducts, setShowActiveProducts] = useState(true)
   const [showProductType, setShowProductType] = useState('standard')
   const [operationsModal, setOperationsModal] = useState('')
   const [savedSuccessfully, setSavedSuccessfully] = useState('')

   const [grantProductAccess, setGrantProductAccess] = useState({})
   const [grantAccessError, setGrantAccessError] = useState(false)

   const browser = detect();
   const { user, userDetails } = useUserAuth();

   const handleChangeProductStatusSearch = (event) => {
      const val = event.target.value;

      if(val === 'true') {
         setShowActiveProducts(true)
      } else {
         setShowActiveProducts(false)
      }
   }

   const ChangeStatusLoader = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'change_status'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Processing request...</h3>
         </div>
      )
   }

   useEffect(() => {
      if(passParams.product) {
         findParamProduct()
      }
   }, [])

   const findParamProduct = () => {
      let product_index = '';

      for (let i = 0; i < productData.length; i++) {
         const el = productData[i];
         
         if(el.product_name === passParams.product) {
            product_index = i
         }
      }

      if(product_index === '') {
         return null
      } else {
         setSelectProduct(product_index)
         setPassParams('')
      }
   }

   useEffect(() => {
      const active_partners_list = []

      for (let i = 0; i < partnerData.length; i++) {
         const el = partnerData[i];
         
         if(el.active === true) {
            active_partners_list.push(el)
         }
      }

      setPartners(active_partners_list)
   }, [])

   const handleChangeProductStatus = async (product, status) => {
      trackPromise(
         new Promise ( async ( resolve ) => {
            try {
               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }

               await axios({
                  method: 'put',
                  url: 'https://api.appliancesure.com/two/admin/portal/products/changeProductStatus',
                  params: {
                     product_name: product
                  },
                  data: {
                     new_status: status,
                     stamp_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then((val) => {
                  if(val.data.success === true) {
                     setShowActiveProducts(status)
                     getMetricData();
                     setSavedSuccessfully('Status changed successfully')
                  } else {
                     alert("There was an error")
                  }

                  setOperationsModal('')
                  resolve("Yes")
               })
               .catch((err) => {
                  alert(err)
                  setOperationsModal('')
                  resolve("Yes")
               })
            }

            catch {
               alert("There was an error")
               setOperationsModal('')
               resolve("Yes")
            }
         })
      , 'change_status')
   }

   const handleRemovePartnerAccess = async (partner, product) => {
      trackPromise(
         new Promise( async (resolve) => {
            // Get IP & device details
            let ip_address = 'Unknown'

            try {
               const ip_lookup = await axios.get("https://geolocation-db.com/json/")
               ip_address = ip_lookup.data.IPv4
            }
         
            catch (err) {
               console.log(err)
            }

            await axios({
               method: 'put',
               url: 'https://api.appliancesure.com/two/admin/portal/products/removeProductAccess',
               params: {
                  partner_name: partner
               },
               data: {
                  product_name: product,
                  log_details: {
                     ip_address: ip_address,
                     action_by: {
                        name: userDetails.first_name + " " + userDetails.last_name,
                        partner: userDetails.partner,
                        email_address: userDetails.email_address
                     },
                     browser: browser.name,
                     os: browser.os
                  }
               }
            })
            .then( async (value) => {
               if(value.data.success === true) {
                  await getMetricData();

                  setTimeout(() => {
                     setOperationsModal('')
                     setSavedSuccessfully('Status changed successfully')
                     resolve("Yes")
                  }, 1000)
               } else {
                  // There was an error
                  alert(value.data.reason)
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error")
               resolve("Yes")
            })
         })
      , 'change_status')
   }

   const handleCloseGrantPartnerAccess = () => {
      setOperationsModal('')
      setGrantAccessError(false)
      setGrantProductAccess({})
   }

   const handleOpenGrantPartnerAccess = (product) => {
      setOperationsModal(`Grant access - ${product}`)
      setGrantAccessError(false)
      setGrantProductAccess({})
   }

   const handleChangeGrantPartnerAccess = (e, product) => {
      const value = e.target.checked;

      setGrantProductAccess({
         ...grantProductAccess,
         [product]: value
      })

      setGrantAccessError(false)
   }

   const handleChangeUpgradeStatus = (product, status) => {
      trackPromise(
         new Promise ( async ( resolve ) => {
            try {
               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }

               await axios({
                  method: 'put',
                  url: 'https://api.appliancesure.com/two/admin/portal/products/changeUpgradeStatus',
                  params: {
                     product_name: product
                  },
                  headers: {
                     Authorization: "Bearer " + user
                  },
                  data: {
                     new_status: status,
                     stamp_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then((val) => {
                  if(val.data.success === true) {
                     getMetricData();
                     setSavedSuccessfully('Status changed successfully')
                  } else {
                     alert("There was an error")
                  }

                  setOperationsModal('')
                  resolve("Yes")
               })
               .catch((err) => {
                  alert(err)
                  setOperationsModal('')
                  resolve("Yes")
               })
            }

            catch {
               alert("There was an error")
               setOperationsModal('')
               resolve("Yes")
            }
         })
      , 'change_status')
   }

   const handleGrantPartnerAccess = async (product) => {
      const partnerAccessArray = Object.entries(grantProductAccess)

      let changes = [];
      for (let i = 0; i < partnerAccessArray.length; i++) {
         const el = partnerAccessArray[i];
         
         if(el[1] === true) {
            changes.push(el[0])
         }
      }

      if(changes.length > 0) {
         trackPromise(
            new Promise( async (resolve) => {
               // Get IP & device details
               let ip_address = 'Unknown'

               try {
                  const ip_lookup = await axios.get("https://geolocation-db.com/json/")
                  ip_address = ip_lookup.data.IPv4
               }
            
               catch (err) {
                  console.log(err)
               }
               
               await axios({
                  method: 'put',
                  url: 'https://api.appliancesure.com/two/admin/portal/products/grantProductAccess',
                  params: {
                     changes_by: 'product',
                     primary_name: product
                  },
                  data: {
                     changes: changes,
                     log_details: {
                        ip_address: ip_address,
                        action_by: {
                           name: userDetails.first_name + " " + userDetails.last_name,
                           partner: userDetails.partner,
                           email_address: userDetails.email_address
                        },
                        browser: browser.name,
                        os: browser.os
                     }
                  }
               })
               .then( async (value) => {
                  if(value.data.success === true) {
                     await getMetricData();

                     setTimeout(() => {
                        setOperationsModal('')
                        setSavedSuccessfully('Access granted successfully')
                        resolve("Yes")
                     }, 1000)
                  } else {
                     // There was an error
                     alert(value.data.reason)
                     resolve("Yes")
                  }
               })
               .catch(() => {
                  alert("There was an error")
                  resolve("Yes")
               })
            })
         , 'change_status')
      } else {
         setGrantAccessError(true)
      }
   }

   const handleResetOperationsModal = () => {
      setOperationsModal('')
   }

   const createProductList = () => {
      let jsx = []

      for (let i = 0; i < productData.length; i++) {
         const product = productData[i];
         
         if(product.active === showActiveProducts && (showProductType === product.product_type || (showProductType === 'upgrade' && product.use_upgrades === true))) {
            jsx.push(
               <li className={selectProduct === i ? 'selected' : ''} onClick={() => setSelectProduct(i)}>{product.product_name}</li>
            )
         }
      }

      return jsx
   }

   const showProductTile = () => {
      let jsx = []

      if(selectProduct !== '') {
         const product_details = productData[selectProduct]

         // Variables
         const payment_frequency = product_details.payment_frequency;

         jsx.push(
            <div className="grid-container">
               <button className="back-button" onClick={() => setSelectProduct('')}>Close</button>
               <br/>
               <br/>
               <h2>{product_details.product_name} <span className={product_details.active ? 'active-record' : 'suspended-record'}>[{product_details.active ? 'Active' : 'Suspended'}]</span></h2>
               <br/>
               <br/>
               
               <table className="tariff-information-table">
                  <tbody>
                     <tr>
                        <td>Pricing model</td>
                        <td>Per {product_details.pricing_model}</td>
                     </tr>
                     <tr>
                        <td>Payment frequency</td>
                        <td style={{textTransform: 'capitalize'}}>{payment_frequency.length > 1 ? 'Monthly / Annually' : payment_frequency}</td>
                     </tr>
                     {
                        product_details.pricing_model === 'appliance' ? (
                           <tr>
                              <td>Complex pricing</td>
                              <td>{product_details.complex_pricing ? 'Yes' : 'No'}</td>
                           </tr>
                        ) : null
                     }
                     {
                        product_details.complex_pricing ? (
                           <React.Fragment>
                              <tr>
                                 <td>Cost</td>
                                 <td>
                                    <button 
                                       className="plain-text-button"
                                       onClick={() => setOperationsModal(`See pricing - ${product_details.product_name}`)}
                                    >Click to see pricing</button>
                                 </td>
                              </tr>
                              

                              {operationsModal === `See pricing - ${product_details.product_name}` ? (
                                 <div className="center-modal-backdrop">
                                    <div className="confirm-modal-container">
                                       <h3>{product_details.product_name}</h3>
                                       <h4>Pricing structure</h4>

                                       <table className="activity-log-table">
                                          <tbody>
                                             <tr>
                                                <th>#</th>
                                                <th>Monthly</th>
                                                <th>Annually</th>
                                             </tr>
                                             {Object.entries(product_details.cost).map(option => (
                                                <tr>
                                                   <td>Appliance {option[0]}</td>
                                                   <td>{option[1].monthly_cost === 0 ? 'N/A' : '£' + parseFloat(option[1].monthly_cost).toFixed(2)}</td>
                                                   <td>{option[1].annual_cost === 0 ? 'N/A' : '£' + parseFloat(option[1].annual_cost).toFixed(2)}</td>
                                                </tr>
                                             ))}
                                          </tbody>
                                       </table>

                                       <button className="confirm-modal-button no" onClick={() => setOperationsModal('')}>
                                          Cancel
                                       </button>
                                    </div>
                                 </div>
                              ) : null}
                           </React.Fragment>
                        ) : (
                           <React.Fragment>
                              {
                                 product_details.payment_frequency.includes("monthly") ? (
                                    <tr>
                                       <td>Monthly cost</td>
                                       {
                                          product_details.pricing_model === 'appliance' ? (
                                             <td>£{parseFloat(product_details.cost.per_appliance.monthly_cost).toFixed(2)} p/a</td>
                                          ) : (
                                             <td>£{parseFloat(product_details.cost.monthly_cost).toFixed(2)}</td>
                                          )
                                       }
                                       
                                    </tr>
                                 ) : null
                              }
                              {
                                 product_details.payment_frequency.includes("annually") ? (
                                    <tr>
                                       <td>Annual cost</td>
                                       {
                                          product_details.pricing_model === 'appliance' ? (
                                             <td>£{parseFloat(product_details.cost.per_appliance.annual_cost).toFixed(2)} p/a</td>
                                          ) : (
                                             <td>£{parseFloat(product_details.cost.annual_cost).toFixed(2)}</td>
                                          )
                                       }
                                    </tr>
                                 ) : null
                              }
                           </React.Fragment>
                        )
                     }
                     
                     <tr>
                        <td>No. of appliances</td>
                        <td>{product_details.appliances.min === product_details.appliances.max ? product_details.appliances.min : `${product_details.appliances.min} - ${product_details.appliances.max}`}</td>
                     </tr>
                     <tr>
                        <td>Length of contract</td>
                        <td>{product_details.length_of_contract} months</td>
                     </tr>
                     <tr>
                        <td>Available for upgrades</td>
                        <td>{product_details.use_upgrades ? 'Yes' : 'No'}</td>
                     </tr>
                  </tbody>
               </table>

               <br/>

               <h3>Partner access <span role="img" aria-label="jsx-a11y/accessible-emoji">🤝</span></h3>

               <div className="partner-access-scrollarea">
                  {product_details.partner_access.length > 0 ? (
                     <React.Fragment>
                        {product_details.partner_access.map((partner) => (
                           <React.Fragment>
                              <div className="partner-access-row">
                                 <h5>{partner}</h5>

                                 <button 
                                    className="remove-plain-text-button"
                                    onClick={() => setOperationsModal(`Remove ${partner}'s access to ${product_details.product_name}`) }
                                 >Remove access</button>
                              </div>

                              {/*--- Remove access ---*/}
                              {operationsModal === `Remove ${partner}'s access to ${product_details.product_name}` ? (
                                 <ConfirmModal 
                                    onConfirm={() => handleRemovePartnerAccess(partner, product_details.product_name)}
                                    onReject={handleResetOperationsModal}
                                    operation={`remove ${partner}'s access to ${product_details.product_name}`}
                                 />
                              ) : null}
                           </React.Fragment>
                        ))}
                     </React.Fragment>
                  ) : 'No partners currently have access to this product.'}
               </div>

               <button 
                  className="plain-text-button"
                  onClick={() => handleOpenGrantPartnerAccess(product_details.product_name)}
               >Grant partner access</button>

               {/*--- Grant access to product ---*/}
               {operationsModal === `Grant access - ${product_details.product_name}` ? (
                  <React.Fragment>
                     <div className="center-modal-backdrop">
                        <div className="confirm-modal-container">
                           <h3>Grant access to {product_details.product_name}</h3>

                           <div className="partner-access-scrollarea">
                              {partners.map((partner) => (
                                 <React.Fragment>
                                    {
                                       partner.products.includes(product_details.product_name) === false ? (
                                          <div className="partner-access-row">
                                             <h5>{partner.partner_name}</h5>
                                             <label className="switchbox standard">
                                                <input
                                                   id="auto-update"
                                                   type="checkbox"
                                                   name={partner.partner_name}
                                                   value={grantProductAccess[partner.partner_name] || false}
                                                   onChange={(e) => handleChangeGrantPartnerAccess(e, partner.partner_name)}
                                                />
                                                <span className="slider-switchbox standard"></span>
                                             </label>
                                          </div>
                                       ) : null
                                    }
                                 </React.Fragment>
                              ))}
                              <p className="partner-access-blank">All partners currently have access to this product.</p>
                           </div>

                           {
                              grantAccessError ? (
                                 <Error
                                    type="warning"
                                    message="Please make at least one change"
                                 />
                              ) : null
                           }

                           <div className="confirm-modal-button-container">
                              <button className="confirm-modal-button no" onClick={handleCloseGrantPartnerAccess}>
                                 Cancel
                              </button>

                              <button className="confirm-modal-button yes" onClick={() => handleGrantPartnerAccess(product_details.product_name)}>
                                 Save
                              </button>
                           </div>
                        </div>
                     </div>
                  </React.Fragment>
               ) : null}

               <br/>
               <br/>

               <h3>Actions 🛠</h3>

               <ul className="grid-list">
                  {
                     product_details.active ? (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Suspend ${product_details.product_name}`)}>Disable product</li>
                           {
                              product_details.use_upgrades ? (
                                 <li onClick={() => setOperationsModal(`Disable upgrades for ${product_details.product_name}`)}>Disable use for upgrades</li>
                              ) : (
                                 <li onClick={() => setOperationsModal(`Enable upgrades for ${product_details.product_name}`)}>Enable use for upgrades</li>
                              )
                           }
                           <li onClick={() => setOperationsModal(`See product activity - ${product_details.product_name}`)}>See product activity</li>
                           <li>See sales figures</li>
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <li onClick={() => setOperationsModal(`Re-activate ${product_details.product_name}`)}>Re-activate product</li>
                        </React.Fragment>
                     )
                  }
               </ul>

               {/* Operations */}
               {operationsModal === `Suspend ${product_details.product_name}` ? (
                  <ConfirmModal 
                     onConfirm={() => handleChangeProductStatus(product_details.product_name, false)}
                     onReject={handleResetOperationsModal}
                     operation={`suspend sales of ${product_details.product_name}`}
                  />
               ) : null}

               {operationsModal === `Re-activate ${product_details.product_name}` ? (
                  <ConfirmModal 
                     onConfirm={() => handleChangeProductStatus(product_details.product_name, true)}
                     onReject={handleResetOperationsModal}
                     operation={`restart sales of ${product_details.product_name}`}
                  />
               ) : null}

               {/* See product activity */}
               {operationsModal === `See product activity - ${product_details.product_name}` ? (
                  <ActivityLog
                     log={product_details.activity_log}
                     path={['Products', product_details.product_name]}
                     resetModal={handleResetOperationsModal}
                  />
               ) : null}

               {/* Use for upgrades */}
               {operationsModal === `Enable upgrades for ${product_details.product_name}` ? (
                  <ConfirmModal
                     onConfirm={() => handleChangeUpgradeStatus(product_details.product_name, true)}
                     onReject={handleResetOperationsModal}
                     operation={`enable upgrade sales for ${product_details.product_name}`}
                  />
               ) : null}

               {operationsModal === `Disable upgrades for ${product_details.product_name}` ? (
                  <ConfirmModal
                     onConfirm={() => handleChangeUpgradeStatus(product_details.product_name, false)}
                     onReject={handleResetOperationsModal}
                     operation={`disable upgrade sales for ${product_details.product_name}`}
                  />
               ) : null}
            </div>
         )
      }

      return jsx
   }

   return (
      <div className="screen-inner-container">
         <ChangeStatusLoader/>
         <button className="back-button" onClick={() => setShowScreen('')}>Back to admin</button>
         <h2>Manage products</h2>

         <div className="two-col-grid-container">
            <div>
               {/* Filters */}
               <div className="filter-options-container">
                  <span>
                     <label className="form-label">Product status</label>
                     <select 
                        className="form-input admin-row"
                        value={showActiveProducts}
                        onChange={handleChangeProductStatusSearch}
                     >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                     </select>
                  </span>

                  <span>
                     <label className="form-label">Type</label>
                     <select
                        className="form-input admin-row"
                        value={showProductType}
                        onChange={(e) => setShowProductType(e.target.value)}
                     >
                        <option value="standard">New sale</option>
                        <option value="upgrade">Upgrade</option>
                     </select>
                  </span>
               </div>

               {/* Products */}
               <div className="grid-container">
                  <h2>Products <span role="img" aria-label="jsx-a11y/accessible-emoji">🛍</span></h2>

                  <ul className="grid-list">
                     {createProductList()}
                  </ul>

                  <button
                     onClick={() => setShowScreen('create_product')}
                     className="welcome-button secondary"
                  >Create new product</button>
               </div>
            </div>

            {showProductTile()}
         </div>

         {
            savedSuccessfully !== '' ? (
               <SavedSuccessfully
                  content={savedSuccessfully}
                  reset={setSavedSuccessfully}
               />
            ) : null
         }
      </div>
   )
}

export default ManageProducts