import React, { useEffect } from "react";
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css';

const CardPayment = ({
   upgradeData,
   updateUpgradeData,
   updateStage,
   updateScriptStage
}) => {
   const handleInputChange = (event) => {
      const { name, value } = event.target;

      updateUpgradeData({
         ...upgradeData,
         pci_details: {
            ...upgradeData.pci_details,
            [name]: value
         }
      })
   }

   const calculateProRataPayment = () => {
      // Get full year price difference
      const old_price = upgradeData.current_package.chosen_cost;
      const new_price = upgradeData.chosen_package.chosen_cost;

      const price_difference = new_price - old_price

      // Get representative pro-rata timespan
      const todays_date = new Date()
      const next_payment_date = new Date(upgradeData.current_package.next_payment_date)

      const milliseconds_to_charge = next_payment_date - todays_date
      const days_to_charge = Math.floor((milliseconds_to_charge / (24 * 60 * 60 * 1000)))
      const percentage_to_charge = days_to_charge / 365

      const pro_rata_amount = price_difference * percentage_to_charge

      updateUpgradeData({
         ...upgradeData,
         chosen_package: {
            ...upgradeData.chosen_package,
            pro_rata_payment: parseFloat(pro_rata_amount.toFixed(2))
         }
      })
   }

   useEffect(() => {
      // Calculate pro-rata payment
      if(upgradeData.chosen_package.pro_rata_payment === '') {
         calculateProRataPayment()
      }
   }, [upgradeData])

   const handleFillInForm = (event) => {
      const { name, value } = event.target;

      updateUpgradeData({
         ...upgradeData,
         pci_details: {
            ...upgradeData.pci_details,
            [name]: value
         }
      })
   }

   const handleChangeFocus = (event) => {
      updateUpgradeData({
         ...upgradeData,
         pci_details: {
            ...upgradeData.pci_details,
            focus: event.target.name
         }
      })
   }

   return (
      <div className="grid-container">
         <button className="back-button" onClick={() => updateStage(6)}>Go back</button>
         <h2>Card payment</h2>

         <p>For all annual customers that upgrade their plans, we must take a card payment for the pro-rata amount for the rest of the contractual year. Thereafter, the change will be affected in the direct debit.</p>
         <div className="payment-options-container">
            <h5 style={{marginTop: 0, marginBottom: 5}}>Accepted payment methods</h5>
            <div>
               <img src="/images/visa-logo.jpg" alt="Visa logo"/>
               <img src="/images/mastercard-logo.jpg" alt="Mastercard logo"/>
               <img src="/images/maestro-logo.jpg" alt="Maestro logo"/>
               <img src="/images/jcb-logo.jpg" alt="JCB logo"/>
            </div>
         </div>

         <br/>

         <input
            className="invisible accordion-trigger"
            type="checkbox"
            name="cancellation-policy"
            id="cancellation-policy"
         />

         <label htmlFor="cancellation-policy" className="accordion-label">
            <h3>Cancellation policy</h3>
         </label>

         <div className="form-section-container">
            <p>From today, you have the right to cancel your policy upgrade within 14 days, without liability for any costs and a full refund of the payment you make today. This is referred to as your 'cooling off period'. You can do so by filling in our online form at <a href="https://appliancesure.com/cancellation" target="_blank">https://appliancesure.com/cancellation</a> or by returning the cancellation form attached to your terms and conditions.</p>
            
            <p>If you cancel outside of your cooling period, you will be subject to our standard cancellation policy which you can view below:</p>

            <a className="welcome-button black" href="https://appliancesure.com/cancellation-policy" target="_blank">View full policy</a>
         </div>

         <br/>
         <hr/>
         <br/>

         <p style={{marginBottom: 5}}>To pay today:</p>
         <p style={{marginTop: 0, fontSize: 36}} className="quality-score">£{parseFloat(upgradeData.chosen_package.pro_rata_payment).toFixed(2)}</p> 

         <Cards
            cvc={upgradeData.pci_details.cvc}
            expiry={upgradeData.pci_details.expiry}
            focused={upgradeData.pci_details.focus}
            name={upgradeData.pci_details.name}
            number={upgradeData.pci_details.number}
         />

         <form style={{marginTop: 30}} onChange={handleFillInForm}>
            <label className="form-label">Name on card</label>
            <input
               className="form-input"
               value={upgradeData.pci_details.name}
               name="name"
               id="name"
               placeholder="e.g. Mr John Smith"
               onFocus={handleChangeFocus}
            />

            <label className="form-label">Card number</label>
            <input
               className="form-input"
               value={upgradeData.pci_details.number}
               name="number"
               id="number"
               maxLength="22"
               placeholder="e.g. 1234 5678 9123 4567"
               onFocus={handleChangeFocus}
            />

            <div className="form-2col">
               <span>
                  <label className="form-label">Expiry date</label>
                  <input
                     className="form-input"
                     value={upgradeData.pci_details.expiry}
                     name="expiry"
                     id="expiry"
                     maxLength="5"
                     placeholder="e.g. 01/24"
                     onFocus={handleChangeFocus}
                  />
               </span>

               <span>
                  <label className="form-label">CVV</label>
                  <input
                     className="form-input"
                     value={upgradeData.pci_details.cvc}
                     name="cvc"
                     id="cvc"
                     maxLength="4"
                     placeholder="e.g. 123"
                     onFocus={handleChangeFocus}
                  />
               </span>
            </div>
         </form>

         <br/>

         <button 
            className="welcome-button secondary"
         >
            Process upgrade
         </button>
      </div>
   )
}

export default CardPayment