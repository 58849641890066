const script_index = {
   "Package": [
      {
         "name": "1.1",
         "description": "Choosing package if campaign is 'Free choice'."
      },
      {
         "name": "1.2",
         "description": "Choosing package if campaign is 'Warranty expiry'."
      }
   ],
   "Appliances": [
      {
         "name": "2.1",
         "description": "Choosing an appliance to cover when campaign is 'Free choice'."
      },
      {
         "name": "2.2",
         "description": "Choosing appliances for a multi-cover package when campaign is 'Free choice'."
      },
      {
         "name": "2.3",
         "description": "Choosing appliances for a multi-cover package when campaign is 'Warranty expiry'."
      }
   ],
   "Personal details": [
      {
         "name": "3.1",
         "description": "Asking the customer for name, email, contact number and postcode"
      },
      {
         "name": "3.2",
         "description": "Asking the customer for which address they are from list"
      },
      {
         "name": "3.3",
         "description": "If address is not found for postcode lookup, manually input address"
      },
      {
         "name": "3.4",
         "description": "Confirming / asking the customer for details if data pre-provisioned"
      }
   ],
   "Payment": [
      {
         "name": "4.1",
         "description": "Asking which payment frequency if the product allows multiple"
      },
      {
         "name": "4.2",
         "description": "Collection of bank details"
      }
   ],
   "Legal": [
      {
         "name": "5.1",
         "description": "Reading of terms & conditions"
      },
      {
         "name": "5.2",
         "description": "Asking the customer about marking opt-in"
      }
   ],
   "Success": [
      {
         "name": "6.1",
         "description": "The sale has been completed, summary and next steps"
      }
   ]
}

export default script_index