import React from "react";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { RotatingLines } from "react-loader-spinner";
import { useUserAuth } from "../../../../context/userAuthContext";
import axios from "axios";

const ConfirmLeadSourceDetails = ({
   updateStage,
   markedCallData,
   updateMarkedCallData
}) => {
   const { user, userDetails } = useUserAuth();

   // Fail / pass count
   let passes = 0;
   let minor_fails = 0;
   let major_fails = 0;

   for (let i = 0; i < markedCallData.markings.length; i++) {
      const record = markedCallData.markings[i];
      
      if(record.q_score === 'pass') {
         passes++
      } else {
         if(record.weighting === 'minor') {
            minor_fails++
         } else {
            major_fails++
         }
      }
   }

   const SubmitLoading = () => {
      const { promiseInProgress } = usePromiseTracker({ area: 'submit'});

      return (
         promiseInProgress &&
         <div className="promise-tracker-container">
            <RotatingLines type="rotatingLines" strokeColor="#03989e" height={80} width={80}/>
            <h3>Submitting score...</h3>
         </div>
      )
   }

   const handleSubmit = async () => {
      let final_object = {
         ...markedCallData,
         breakdown: {
            passes: passes,
            minor_fails: minor_fails,
            major_fails: major_fails
         },
         performance: {
            ...markedCallData.performance,
            end_time: new Date().toISOString(),
            time_to_mark: ((new Date() - new Date(markedCallData.performance.start_time)) / 60) / 1000
         },
         submitted: {
            by: {
               name: userDetails.first_name + " " + userDetails.last_name,
               email_address: userDetails.email_address,
               partner: userDetails.partner
            }
         }
      }

      delete final_object.name;
      delete final_object.guidelines;
      delete final_object.order;
      delete final_object.q_score;
      delete final_object.weighting;

      trackPromise(
         new Promise( async (resolve) => {
            await axios({
               method: 'post',
               url: "https://api.appliancesure.com/two/admin/portal/quality/submitScore",
               headers: {
                  Authorization: "Bearer " + user
               },
               data: final_object
            })
            .then((value) => {
               const response = value.data;

               if(response.success === true) {
                  setTimeout( async () => {
                     updateMarkedCallData({
                        ...final_object,
                        reference_number: response.score_id
                     })

                     // Close queue item
                     await axios({
                        method: 'put',
                        url: 'https://api.appliancesure.com/two/admin/portal/quality/leadSource/closeItem',
                        headers: {
                           Authorization: "Bearer " + user
                        },
                        data: {
                           phone_number: final_object.call_data.phone_number,
                           batch_number: final_object.call_data.batch_number,
                           file_name: final_object.call_data.recording_file
                        }
                     })
                     .catch(() => {
                        console.error("Error with closing items")
                     })

                     // If the call has failed its mark, suppress the file
                     await axios({
                        method: 'post',
                        url: 'https://api.appliancesure.com/two/admin/leads/suppress/leadQaFail',
                        headers: {
                           Authorization: 'Bearer ' + user
                        },
                        data: {
                           phone_number: final_object.call_data.phone_number,
                           batch_number: final_object.call_data.batch_number
                        }
                     })
                     .catch(() => {
                        console.error("Error suppressing file")
                     })

                     updateStage(5)
                     resolve("Yes")
                  }, 1000)
               } else {
                  alert(value.reason)
                  resolve("Yes")
               }
            })
            .catch(() => {
               alert("There was an error, please try again")
               resolve("Yes")
            })
         })
      , 'submit')
   }

   return (
      <div className="grid-container">
         <SubmitLoading/>
         <button className="back-button" onClick={() => updateStage(3)}>Go back</button>
         
         <br/>
         <br/>
         
         <h3>Confirm details</h3>

         <p>Please confirm that all of the details below are correct and then submit to send the marking to our database.</p>

         <table className="tariff-information-table">
            <tbody>
               <tr>
                  <td>Score</td>
                  <td>{markedCallData.score.toFixed(2)}%</td>
               </tr>
               <tr>
                  <td>Passed marks</td>
                  <td>{passes}</td>
               </tr>
               <tr>
                  <td>Minor fails</td>
                  <td>{minor_fails}</td>
               </tr>
               <tr>
                  <td>Major fails</td>
                  <td>{major_fails}</td>
               </tr>
               <tr>
                  <td>Phone number</td>
                  <td>{markedCallData.call_data.phone_number}</td>
               </tr>
               <tr>
                  <td>Batch number</td>
                  <td>{markedCallData.call_data.batch_number}</td>
               </tr>
               <tr>
                  <td>Source</td>
                  <td>{markedCallData.call_data.source}</td>
               </tr>
            </tbody>
         </table>

         <br/>

         <button
            onClick={handleSubmit}
            className="welcome-button secondary"
         >Submit</button>
      </div>
   )
}

export default ConfirmLeadSourceDetails